<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderTask,i) in indexedWorkOrderTask" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4" md="8">
                        <v-text-field
                            label="Instruction Name *" 
                            v-model="workOrderTask.name"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Sort Order" 
                            v-model="workOrderTask.sort_order"
                            type="number"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-chip
                        v-if="workOrderTask.id !== ''"
                        class="ma-2"
                        :color="workOrderTask.status == 'DONE' ? 'green' : 'red'"
                        text-color="white"
                        >
                        <span v-if="workOrderTask.status == 'DONE'">done</span>
                        <span v-if="workOrderTask.status !== 'DONE'">not done</span>
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <div v-html="workOrderTask.note"></div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-row>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_1" :src="workOrderTask.image_1" :src_large="workOrderTask.image_1" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_2" :src="workOrderTask.image_2" :src_large="workOrderTask.image_2" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_3" :src="workOrderTask.image_3" :src_large="workOrderTask.image_3" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_4" :src="workOrderTask.image_4" :src_large="workOrderTask.image_4" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_5" :src="workOrderTask.image_5" :src_large="workOrderTask.image_5" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_6" :src="workOrderTask.image_6" :src_large="workOrderTask.image_6" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <div class="subtitle">REQUIREMENTS</div>
                <v-row>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="workOrderTask.require_note"
                            label="Note"
                            hide-details="auto"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="workOrderTask.require_image"
                            label="Image"
                            hide-details="auto"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row v-if="!disableEdit">
                    <v-col class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workOrderTask.index, workOrderTask.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workOrderTask.id, workOrderTask.name, workOrderTask.sort_order, workOrderTask.require_note, workOrderTask.require_image )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div  v-if="!disableEdit">
            <v-btn class="mr-4 mt-2" @click="newWorkOrderTask()">Add Task</v-btn>
        </div>
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
        props: {
            disableEdit: {
                type: Boolean
            },
            workOrderId: {
                type: Number
            },
        },
        components: {
        EnlargeableImage
        },
		data() {
            return {
                id: [],
                loading: true,
                workOrderTasksbyChecklist: [],
                users:[],
                assetListFull: [],
                masterParamList: [],
                baseParamList: [],
                additionalDataLoaded: false,
            }
        },
        computed: {
            indexedWorkOrderTask () {
                return this.workOrderTasksbyChecklist.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderTasksbyChecklist().then((res) => {
                        this.workOrderTasksbyChecklist = res;
                    });
                }
            },
			async getWorkOrderTasksbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderTask.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderTask() {
                // this.$router.push({ name: 'workOrderTaskForm', params: {workOrderId: this.workOrderId } })
                this.workOrderTasksbyChecklist.push({id: '', name: '', order: this.workOrderId, sort_order: 1, require_status: false, require_reading: false, require_image: false });
            },
            save(id, name, sort_order, require_note, require_image ) {
                this.saveWorkOrderTask(id, name, sort_order, require_note, require_image).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderTask(id, name, sort_order, require_note, require_image) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workOrderTask.save( {id: id, name: name, sort_order: sort_order, require_note: require_note, require_image:require_image, order: this.workOrderId });
                    } else {
                        res = await this.$api.workOrderTask.new({name: name, sort_order: sort_order, require_note: require_note, require_image:require_image, order: this.workOrderId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderTask.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderTasksbyChecklist, index)
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>