<template>
    <v-container fluid class="pa-0 list-checklist">
        <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
                <div v-if="this.profile.role == 'SYSADMIN'">
                    <v-btn
                        color="primary"
                        icon
                        @click="checkerLayout = !checkerLayout"
                        >
                        <v-icon v-if="!checkerLayout">mdi-view-agenda</v-icon>
                        <v-icon v-if="checkerLayout">mdi-card-outline</v-icon>
                    </v-btn>
                </div>
                <v-btn
                    color="primary"
                    dark
                    @click="expandCalendar = !expandCalendar"
                    outlined
                    >
                    <v-icon>mdi-calendar</v-icon> {{today}}
                </v-btn>
            </v-col>
        </v-row>
        <v-expand-transition>
        <v-date-picker v-show="expandCalendar" class="mt-2" full-width landscape v-model="today"></v-date-picker>
        </v-expand-transition>
        <v-row>
        <v-col cols="12">
            <v-tabs
                background-color="primary"
                show-arrows 
                center-active
                centered grow >
                <v-tabs-slider />
                <v-tab>Open</v-tab>
                <v-tab>Checked</v-tab>
                <v-tab>Verified</v-tab>
                <v-tab-item>
                    <v-progress-circular v-if="loadingOpen"
                    class="mt-4 mb-4"
                    :size="50"
                    :width="3"
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    <div v-if="!checkerLayout">
                    <v-card v-for="(checklist, index) in this.sortedOpenChecklist" v-bind:key="index" @click="edit(checklist.id, checklist.open_dt)"
                        class="mx-auto text-left mt-2"
                    >
                        <v-card-text class="d-flex flex-wrap align-center">
                        <v-img class="mr-2" contain v-if="checklist.checkplan" :src="getCheckplanIcon(checklist.checkplan) || icongraphicPlaceholder" max-width="70" width="70" height="70"></v-img>
                        <v-col xs="auto" class="pt-0 pb-0">
                            <div class="text--primary">
                                {{checklist.name}}
                            </div>
                            <div class="text--primary" v-if="checklist.check_due_dt">
                                Due: {{checklist.check_due_dt | datetimestamp}}
                            </div>
                        </v-col>
                        <v-col cols="4" class="text-right">
                            <v-chip
                            v-if="profileId !== '0'"
                            class="ma-2"
                            color="primary"
                            small
                            v-html="checkCheckerVerify(checklist.checker_set, checklist.verifier_set)"
                            >
                            </v-chip>
                        </v-col>
                        </v-card-text>
                    </v-card>
                    </div>
                    <div v-if="checkerLayout">
                        <div v-for="(checklist, index) in this.sortedOpenChecklist" v-bind:key="index">
                            <checkerList :id="checklist.id" :profileId="profileId" :checkplanList="checkplanList"></checkerList>
                        </div>
                    </div>
                    <div v-if="this.sortedOpenChecklist.length == 0 && !loadingOpen" class="pt-2 pb-2">
                        You have no open checklist assigned to you today.
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-progress-circular v-if="loadingOpen"
                    class="mt-4 mb-4"
                    :size="50"
                    :width="3"
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    <v-card v-for="(checklist, index) in this.sortedCheckedChecklist" v-bind:key="index" @click="edit(checklist.id, checklist.open_dt)"
                        class="mx-auto text-left mt-2"
                    >
                        <v-card-text class="d-flex flex-wrap align-center">
                        <v-img class="mr-2" contain v-if="checklist.checkplan" :src="getCheckplanIcon(checklist.checkplan)" max-width="70" width="70" height="70"></v-img>
                        <v-img class="mr-2" contain v-if="!checklist.checkplan" :src="icongraphicPlaceholder" max-width="70" width="70" height="70"></v-img>
                        <v-col xs="auto" class="pt-0 pb-0">
                            <div class="text--primary">
                                {{checklist.name}}
                            </div>
                            <div class="text--primary" v-if="checklist.check_due_dt">
                                Due: {{checklist.check_due_dt | datetimestamp}}
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="text--secondary caption text-right" v-html="checkCheckerVerify(checklist.checker_set, checklist.verifier_set)">
                            </div>
                        </v-col>
                        </v-card-text>
                    </v-card>
                    <div v-if="this.sortedCheckedChecklist.length == 0 && !loadingChecked" class="pt-2 pb-2">
                        You have no checked checklist assigned to you today.
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-progress-circular v-if="loadingOpen"
                    class="mt-4 mb-4"
                    :size="50"
                    :width="3"
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    <v-card v-for="(checklist, index) in this.sortedVerifiedChecklist" v-bind:key="index" @click="edit(checklist.id, checklist.open_dt)"
                        class="mx-auto text-left mt-2"
                    >
                        <v-card-text class="d-flex flex-wrap align-center">
                        <v-img class="mr-2" contain v-if="checklist.checkplan" :src="getCheckplanIcon(checklist.checkplan)" max-width="70" width="70" height="70"></v-img>
                        <v-img class="mr-2" contain v-if="!checklist.checkplan" :src="icongraphicPlaceholder" max-width="70" width="70" height="70"></v-img>
                        <v-col xs="auto" class="pt-0 pb-0">
                            <div class="text--primary">
                                {{checklist.name}}
                            </div>
                            <div class="text--primary" v-if="checklist.check_due_dt">
                                Due: {{checklist.check_due_dt | datetimestamp}}
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="text--secondary caption text-right" v-html="checkCheckerVerify(checklist.checker_set, checklist.verifier_set)">
                            </div>
                        </v-col>
                        </v-card-text>
                    </v-card>
                    <div v-if="this.sortedVerifiedChecklist.length == 0 && !loadingVerified" class="pt-2 pb-2">
                        You have no verified checklist assigned to you today.
                    </div>
                </v-tab-item>
                
            </v-tabs>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
    import checkerList from '@/components/check/checker-check-list.vue';
	export default {
        components: {
            checkerList
        },
        props: {
            profileId:{},
        },
		data: () => ({
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			success: false,
            openChecklist: [],
            checkedChecklist: [],
            verifiedChecklist: [],
            today: null,
            setDate: null,
            expandCalendar: false,
			loadingOpen: true,
            loadingChecked: true,
            loadingVerified: true,
            checkplanList:[],
            profile: [],
            checkerLayout: false,
		}),
        computed: {
            sortedOpenChecklist () {
                if (this.profileId == 0 ) {
                    return this.openChecklist.filter((checklist => (checklist.checker_data_complete == false)) ).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                } else {
                    return this.openChecklist.filter((checklist => ((checklist.checker_set.includes( this.profileId ) || checklist.verifier_set.includes( this.profileId )) && checklist.checker_data_complete == false)) ).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                }
            },
            sortedCheckedChecklist () {
                if (this.profileId == 0 ) {
                    return this.openChecklist.filter((checklist => (checklist.checker_data_complete == true && checklist.verifier_data_complete == false )  )).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                } else {
                    return this.openChecklist.filter((checklist => ((checklist.checker_set.includes( this.profileId ) || checklist.verifier_set.includes( this.profileId )) && checklist.checker_data_complete == true && checklist.verifier_data_complete == false ))  ).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                }
            },
            sortedVerifiedChecklist () {
                if (this.profileId == 0 ) {
                    return this.openChecklist.filter((checklist => (checklist.verifier_data_complete == true)) ).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                } else {
                    return this.openChecklist.filter((checklist => (checklist.verifier_set.includes( this.profileId ) && checklist.verifier_data_complete == true)) ).sort((a, b) => {
                        new Date(a.open_dt) > new Date(b.open_dt)
                    })
                }
            },
        },
		methods: {
            // Main Function
            list(date) {
			    this.getChecklists(date).then((res) => {
                    this.openChecklist = res
                });
            },
			async getChecklists(date) {
                try {
                    this.loadingOpen = true
                    const res = await this.$api.checklist.search({ open_date: date });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingOpen = false
                }
            },
            getAdditionalData() {
			    this.getCheckplan().then(res => this.checkplanList = res);
			    this.getProfile().then(res => this.profile = res);
            },
			async getCheckplan() {
                try {
                    const res = await this.$api.checkplan.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getCheckplanIcon(checkplan_id) {
                if ( this.checkplanList.length !== 0 && checkplan_id ) {
                    return this.checkplanList.find(checkplan => checkplan.id === checkplan_id).icongraphic;
                }
            }, 
			edit(id, open_dt) {
                var now = new Date();
                if (now.getTime() > this.$moment(open_dt).valueOf() ) {
                    this.$router.push({ name: 'checkVerifyForm', params: { id } })
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Not Opened Yet',
                        text: 'This checklist opens at '+ this.$moment(open_dt).format('MMMM Do YYYY, h:mm:ss a')
                    }).then((result) => {
                    })
                }
            },
            // Sub Function 
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            statusName () {
                if (true) {
                    return "Active";
                } else {
                    return "Inactive"
                }
            },
            checkCheckerVerify(checker, verifier) {
                var message = '';
                if ( checker.includes( this.profileId )) {
                    message = 'checker';
                }
                if ( checker.includes( this.profileId ) && verifier.includes( this.profileId )) {
                    message += ', '
                }
                if ( verifier.includes( this.profileId )) {
                    message += 'verifier'
                }
                return message;
            }
        },
        watch: {
            today: function() {
                this.list(this.today);
            }
        },
		mounted () {
            this.today = this.$moment().format('YYYY-MM-DD')
            this.list(this.today);
            this.getAdditionalData();
            this.success = true;
		},
	}
</script>