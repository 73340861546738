<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <div class="headline">Hello {{ info.username }}!</div>
                        </v-col>
                    </v-row>
                    <v-divider light></v-divider><v-row class="pt-5 pb-5">
                        <v-col cols="12" sm="12" md="12">
                            <div class="d-flex align-items-middle justify-space-between">
                                <div class="text">
                                    <div class="subtitle-2">Profile Name</div>
                                </div>
                                <v-btn @click="editNames = !editNames" small text>
                                    <span v-if="!editNames">Change <v-icon>mdi-chevron-right</v-icon></span>
                                    <span v-if="editNames"><v-icon>mdi-close</v-icon></span>
                                </v-btn>
                            </div>
                            <v-form
                            v-if="editNames"
                            ref="form"
                            lazy-validation>
                                <v-row >
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Short Name" 
                                            v-model="names.short_name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Full Name" 
                                            v-model="names.full_name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                            color="success"
                                            class="mr-4"
                                            @click="saveName"
                                        >
                                            Save
                                        </v-btn>
                                    </v-col>
                            </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row class="pt-5 pb-5">
                        <v-col cols="12" sm="12" md="12">
                            <div class="d-flex align-items-middle justify-space-between">
                                <div class="text">
                                    <div class="subtitle-2">Profile Info</div>
                                </div>
                                <v-btn @click="editProfile = !editProfile" small text>
                                    <span v-if="!editProfile">Change <v-icon>mdi-chevron-right</v-icon></span>
                                    <span v-if="editProfile"><v-icon>mdi-close</v-icon></span>
                                </v-btn>
                            </div>
                                <v-form
                                v-if="editProfile"
                                ref="form"
                                v-model="emailValid"
                                lazy-validation>
                                    <v-alert v-if="alertEmail" type="warning">
                                        {{alertEmail}}
                                    </v-alert>
                                    <v-row >
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Address 1" 
                                                v-model="profile.address_1"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Address 2" 
                                                v-model="profile.address_2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Address 3" 
                                                v-model="profile.address_3"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="District" 
                                                v-model="profile.district"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="State" 
                                                v-model="profile.state"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-divider light></v-divider>
                                    <v-row >
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Phone" 
                                                v-model="profile.phone"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-divider light></v-divider>
                                    <div class="text mt-5">
                                        <div class="subtitle-2">Tax Details</div>
                                    </div>
                                    <v-row >
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Tax Ref" 
                                                v-model="profile.tax_reference"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-divider light></v-divider>
                                    <div class="text mt-5">
                                        <div class="subtitle-2">Bank Details</div>
                                    </div>
                                    <v-row >
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Bank Name" 
                                                v-model="profile.bank_name"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="Bank Account" 
                                                v-model="profile.bank_account"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn
                                                color="success"
                                                class="mr-4"
                                                @click="saveProfile"
                                            >
                                                Save
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                        </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row class="pt-5 pb-5">
                        <v-col cols="12" sm="12" md="12">
                            <div class="d-flex align-items-middle justify-space-between">
                                <div class="text">
                                    <div class="subtitle-2">Email Address</div>
                                    Your e-mail address is {{info.email}}
                                </div>
                                <v-btn @click="editEmail = !editEmail" small text>
                                    <span v-if="!editEmail">Change <v-icon>mdi-chevron-right</v-icon></span>
                                    <span v-if="editEmail"><v-icon>mdi-close</v-icon></span>
                                </v-btn>
                            </div>
                                <v-form
                                v-if="editEmail"
                                ref="form"
                                v-model="emailValid"
                                lazy-validation>
                                    <v-alert v-if="alertEmail" type="warning">
                                        {{alertEmail}}
                                    </v-alert>
                                    <v-row >
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="New Email" 
                                            v-model="email.email"
                                            :rules="emailRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Confirm Email" 
                                            v-model="newEmailConfirm"
                                            :rules="emailRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                            :disabled="!emailValid"
                                            color="success"
                                            class="mr-4"
                                            @click="changeEmail"
                                        >
                                            Save
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row class="pt-5 pb-5">
                        <v-col cols="12" sm="12" md="12">
                            <div class="d-flex align-items-middle justify-space-between">
                                <div class="text">
                                    <div class="subtitle-2">Password</div>
                                </div>
                                <v-btn @click="editPassword = !editPassword" small text>
                                    <span v-if="!editPassword">Change <v-icon>mdi-chevron-right</v-icon></span>
                                    <span v-if="editPassword"><v-icon>mdi-close</v-icon></span>
                                </v-btn>
                            </div>
                                <v-form
                                v-if="editPassword"
                                ref="form"
                                v-model="passwordValid"
                                lazy-validation>
                                    <v-alert v-if="alertPassword" type="warning">
                                        <ul>
                                            <li v-for="(alert,index) in alertPassword" v-bind:key="index" >{{alert}}</li>
                                        </ul>
                                    </v-alert>
                                    <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            type="password"
                                            label="Old Password" 
                                            v-model="password.old_password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            type="password"
                                            label="New Password" 
                                            v-model="password.password"
                                            :rules="passwordRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            type="password"
                                            :disabled="!password.password"
                                            label="Confirm Password" 
                                            v-model="password.password_confirm"
                                            :rules="passwordRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn
                                            :disabled="!passwordValid"
                                            color="success"
                                            class="mr-4"
                                            @click="changePassword"
                                        >
                                            Save
                                        </v-btn>
                                    </v-col>
                                </v-row>    
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import userForm from '@/components/form/user-form.vue';
	export default {
        components: {
            userForm
        },
		data: () => ({
            loading: true,
            success: false,
            info: {
                username: '',
                email: '', 
            },
            input: {
                id: '',
            },
            password: {
                old_password: '',
                password: '',
                password_confirm:'',
            },
            email: {
                email: '',
            },
            names: {
                full_name:'',
                short_name:'',
            },
            profile: {
                id: '',
                address_1: '',
                address_2: '',
                address_3: '',
                district: '',
                state: '',
                bank_name: '',
                bank_accont: '',
                phone:'',
                tax_reference: ''
            },
            newEmailConfirm: '',
            editNames: false,
            editProfile: false,
            editPassword: false,
            editEmail: false,
            emailValid: true,
            passwordValid: true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            alertPassword: '',
            alertEmail: '',
		}),
		methods: {
            form() {
                this.getUser().then((res) => {
                    this.input.id = res.id;
                    this.profile.id = res.id;
                    this.info.username = res.username;
                    this.info.email = res.email;
                    this.names.short_name = res.short_name;
                    this.names.full_name = res.full_name;
                    this.profile.address_1 = res.profile_data.address_1;
                    this.profile.address_2 = res.profile_data.address_2;
                    this.profile.address_3 = res.profile_data.address_3;
                    this.profile.district = res.profile_data.district;
                    this.profile.state = res.profile_data.state;
                    this.profile.bank_name = res.profile_data.bank_name;
                    this.profile.bank_accont = res.profile_data.bank_accont;
                    this.profile.phone = res.profile_data.phone;
                    this.profile.tax_reference = res.profile_data.tax_reference;
                });
            },
			async getUser() {
                try {
                    this.loading = true
                    const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            saveName() {
                this.saveUserNames().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'OK',
                        }).then((result) => {
                            if (result.value) {
                                this.form();
                            }
                        })
                    }
                })
            },
            saveProfile(){
                this.saveUserProfile().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'OK',
                        }).then((result) => {
                            if (result.value) {
                                this.form();
                            }
                        })
                    }
                })
            },
			async saveUserNames() {
                try {
                    let res;
                    this.saving = true
                    res = await this.$api.user.save({id: this.input.id, full_name: this.names.full_name, short_name: this.names.short_name });
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async saveUserProfile() {
                try {
                    let res;
                    this.saving = true
                    res = await this.$api.accountProfile.save( this.profile );
                    return res;
                } catch (error) {
                    this.alertPassword = error.response;
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            changeEmail(){
                this.sendEmail().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'An email has been sent, please verify',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            if (result.value) {
                                this.form();
                            }
                        })
                    }
                })
            },
			async sendEmail() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.accountProfile.changeEmail( this.email );
                    } else {
                        res = await this.$api.accountProfile.changeEmail( this.email );
                    }
                    return res;
                } catch (error) {
                    this.alertPassword = error.response.data.password;
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            changePassword(){
                this.sendPassword().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Password Changed!',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            if (result.value) {
                                this.form();
                            }
                        })
                    }
                })
            },
			async sendPassword() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.accountProfile.changePassword( this.password );
                    } else {
                        res = await this.$api.accountProfile.changePassword( this.password );
                    }
                    return res;
                } catch (error) {
                    console.log(error.response);
                    this.alertPassword = error.response.data.password;
                    // handle error
                } finally {
                    this.saving = false
                }
            },
		},
		mounted () {
            this.form();
            this.loading = false;
		},
	}
</script>