<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Checkplan Info</v-tab>
                    <v-tab v-if="this.input.id">Child: Asset Plan</v-tab>
                    <v-tab v-if="this.input.id">Child: Recurrance Time</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Name" 
                                    v-model="input.name"
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="12">
                                <div class="caption">Status</div>
                                <v-switch inset v-model="inputStatus" label="Status"></v-switch>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="inputRecurrence">
                            <v-col v-if="inputRecurrence.length > 0">
                                Recurrence:
                                <ul>
                                    <li v-for="(recur,index) in inputRecurrence" v-bind:key="index">
                                        {{ getRecurName(recur)}}
                                        {{(recur.recurrence)}}
                                    </li>
                                </ul>
                                {{  }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <label>Icongraphic</label><br/>
                                <v-img v-if="input.icongraphic" :src="input.icongraphic" width="180" height="180"></v-img>
                                <v-img v-if="!input.icongraphic" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Add or Replace Icongraphic"
                                    v-model="newIcon"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-tooltip bottom v-if="valid" class="mr-4" >
                                <template v-slot:activator="{ on }">
                                    <v-icon v-if="valid" class="mr-2" color="green darken-2" v-on="on">mdi-clipboard-check-outline</v-icon> 
                                </template>
                                <span>
                                    This checkplan is valid.
                                </span>
                                </v-tooltip>
                                <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <assetPlanTable v-on:updateCheckplan="refreshCheckPlan" :checkplanId="input.id"></assetPlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <recurranceTimeTable v-on:updateCheckplan="refreshCheckPlan" :checkplanId="input.id"></recurranceTimeTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import assetPlanTable from '@/components/table/asset-plan-table.vue';
import recurranceTimeTable from '@/components/table/recurrance-time-table.vue';
	export default {
		  components: {
              assetPlanTable,
              recurranceTimeTable,
                wysiwyg
          },
          props: {
                id: Number,
          },
		data: () => ({
			success: false,
			loading: true,
            input:{
                id: '',
                name: '',
                description: '',
                checker: '',
                verifier: '',
                icongraphic: null,
            },
            inputAssetPlan: [],
            inputRecurrence: [],
            recurranceTimeList:[],
            recurrenceLists: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
        computed: {
            userVerifier: function() {
                if (this.users.length > 0) {
                    if (this.input.checker == null) {
                        return (this.users);
                    } else {
                        return (this.users.filter(users => users.id !== this.input.checker));
                    }
                }
            },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getCheckplan().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.icongraphic = res.icongraphic;
                        this.input.description = res.description;
                        this.input.checker = res.checker;
                        this.input.verifier = res.verifier;
                        this.inputAssetPlan = res.asset_plan_set;
                        this.inputRecurrence = res.recurrence_set

                        this.getAdditionalData();
                    });
                }
            },
			async getCheckplan() {
                try {
                    this.loading = true
                    const res = await this.$api.checkplan.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveCheckplan().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'checkplanList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveCheckplan() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();

                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    if (this.newIcon) {
                        formData.append("icongraphic", this.newIcon);
                    }
                    if (this.input.id) {
                        res = await this.$api.checkplan.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.checkplan.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'checkplanList' })
            },
            getAdditionalData() {
			    this.getRecurranceTimebyCheckplan().then(res => this.recurranceTimeList = res);
			    this.getRecurrences().then(res => this.recurrenceLists = res);
            },
			async getRecurranceTimebyCheckplan() {
                try {
                    const res = await this.$api.recurrenceTime.search({ checkplan: this.input.id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getRecurName(id) {
                if ( this.recurrenceLists.length !== 0 && id ) {
                    return this.recurrenceLists.find(recurrance => recurrance.id === id).name;
                }
            },
            refreshCheckPlan() {
                this.form();
                this.getAdditionalData();
            }
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.valid = true;
		},
	}
</script>