<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderHeading,i) in indexedWorkOrderHeading" :key="i">
            <v-card-text>
                <div class="text-caption">{{workOrderHeading.detailLabel}}</div>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <h4>{{getHeadingName(workOrderHeading.heading)}}</h4>
                    </v-col>
                    <v-col cols="6" sm="3" md="3" lg="3">
                        Gross: {{workOrderHeading.gross}}
                    </v-col>
                    <v-col cols="6" sm="3" md="3" lg="3">
                        Net: {{workOrderHeading.net}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Name
                                    </th>
                                    <th class="text-left">
                                        Percentage (%)
                                    </th>
                                    <th class="text-left">
                                        Cost
                                    </th>
                                    <th class="text-left" v-if="!disableEdit">
                                        
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in workOrderHeading.addendum"
                                    :key="item.name"
                                    >
                                        <td width="50%">
                                            {{ getAddendumName(item.addendum) }}
                                        </td>
                                        <td class="pa-2" width="200px">
                                            <v-text-field
                                                solo
                                                v-model="item.percentage"
                                                type="number"
                                                hide-details="auto"
                                                suffix="%"
                                                max="100"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            {{ item.cost }}
                                        </td>
                                        <td class="text-right" v-if="!disableEdit">
                                            <v-btn-toggle>
                                            <v-btn small fab @click="save(item.id, item.percentage)"><v-icon>mdi-content-save</v-icon></v-btn>
                                            </v-btn-toggle>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Vue from 'vue'
import HeadingDialog from '@/components/work/work-order-detail-dialog.vue'
	export default {
        props: {
            disableEdit: {
                type: Boolean
            },
            workOrderId: {
                type: Number
            },
        },
        components:{
            HeadingDialog,
            EnlargeableImage
        },
		data() {
            return {
                id: [],
                loading: true,
                workOrderHeadingsbyOrder: [],
                addendumList: [],
                additionalDataLoaded: false,
                detailSelected: null,
                headingLoaded:false,
                detailDialog: false,
                hasParent: false,
                search: null,
                caseSensitive: false,
                headingList:[],
                availableDetail:null,
            }
        },
        computed: {
            indexedWorkOrderHeading () {
                return this.workOrderHeadingsbyOrder.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderHeadingsbyOrder().then((res) => {
                        this.workOrderHeadingsbyOrder = res;
                        this.workOrderHeadingsbyOrder.forEach((item, index) => { 
                            this.getWorkOrderAddendum(item.id).then((res2) => {
                                Vue.set(item,'addendum', res2);
                            });
                        })  
                    });
                }
            },
			async getWorkOrderHeadingsbyOrder() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderHeading.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getWorkOrderAddendum(id) {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderAddendum.search({ order_heading: id});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderHeading() {
                // this.$router.push({ name: 'workOrderHeadingForm', params: {workOrderId: this.workOrderId } })
                this.workOrderHeadingsbyOrder.push({id: '', detail: '', quantity: 0, order: this.workOrderId, detailDialog: false });
            },
            save(id, percentage ) {
                this.saveWorkOrderAddendum(id, percentage).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderAddendum(id, percentage) {
                try {
                    this.saving = true
                    const res = await this.$api.workOrderAddendum.save( {id: id, percentage: percentage});
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getAdditionalData() {
                this.getAddendums().then(res => this.addendumList = res);
                this.getWorkHeadings().then((res) => {
                    this.headingList = res;
                });
            },
            async getWorkHeadings() {
                try {
                    const res = await this.$api.invoiceHeading.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAddendums() {
                try {
                    const res = await this.$api.addendum.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshUnit(index, detail) {
                this.getDetailUnit(index, detail);
            },
            getDetailUnit(index, id) {
                this.getDetailInfo(id).then((res) => {
                    // this.workOrderHeadingsbyOrder[index].unit = res.unit_data.symbol;
                    Vue.set(this.workOrderHeadingsbyOrder[index],'unit', res.unit_data.symbol);
                    Vue.set(this.workOrderHeadingsbyOrder[index],'detailLabel', res.heading_path);
                });
            },
            async getDetailInfo(id) {
                try {
                    const res = await this.$api.invoiceDetail.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeDetailValue({ value, detailIndex }) {
                Vue.set(this.workOrderHeadingsbyOrder[detailIndex],'detail', value);
                this.refreshUnit(detailIndex, value)
            },
            getHeadingName(id) {
                if ( this.headingList.length > 0 && id ) {
                    return this.headingList.find(heading => heading.id === id).name;
                }
            },
            getAddendumName(id) {
                if ( this.addendumList.length > 0 && id ) {
                    return this.addendumList.find(addendum => addendum.id === id).name;
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>