<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderDetail,i) in indexedWorkOrderDetail" :key="i">
            <v-card-text>
                <div class="text-caption">{{workOrderDetail.detailLabel}}</div>
                <v-row>
                    <v-col cols="12" sm="4" md="4">
                        <v-autocomplete
                        :items="detailList" 
                        item-value="id" 
                        name="item"
                        item-text="name"
                        label="Detail" 
                        v-model="workOrderDetail.detail"
                        hide-details="auto"
                        readonly
                        @change="refreshUnit(workOrderDetail.index, workOrderDetail.detail)"
                        ></v-autocomplete>
                        <HeadingDialog :headingList="headingList" :showProp="workOrderDetail.detailDialog" @chosen="changeDetailValue" :detailIndex="workOrderDetail.index"></HeadingDialog>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            label="Quantity"
                            v-model="workOrderDetail.quantity"
                            type="number"
                            hide-details="auto"
                            :suffix="workOrderDetail.unit"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            label="Unit Cost"
                            v-model="workOrderDetail.unit_cost"
                            type="number"
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            label="Cost"
                            v-model="workOrderDetail.cost"
                            type="number"
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-tooltip right v-if="workOrderDetail.detail">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="goToDetailForm(workOrderDetail.detail)"
                            >
                            <v-icon>mdi-text</v-icon>
                            </v-btn>
                        </template>
                        <span>Click to enter detail form, please save first.</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <label>Images <span v-if="workOrderDetail.require_image">( required )</span></label><br/>
                        <v-row>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_1" :src="workOrderDetail.image_1" :src_large="workOrderDetail.image_1" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_2" :src="workOrderDetail.image_2" :src_large="workOrderDetail.image_2" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_3" :src="workOrderDetail.image_3" :src_large="workOrderDetail.image_3" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_4" :src="workOrderDetail.image_4" :src_large="workOrderDetail.image_4" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_5" :src="workOrderDetail.image_5" :src_large="workOrderDetail.image_5" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_6" :src="workOrderDetail.image_6" :src_large="workOrderDetail.image_6" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="!disableEdit">
                    <v-col class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workOrderDetail.index, workOrderDetail.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workOrderDetail.id, workOrderDetail.detail, workOrderDetail.quantity)"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div v-if="!disableEdit">
            <v-btn class="mr-4 mt-2" @click="newWorkOrderDetail()">Add Detail</v-btn>
        </div>
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Vue from 'vue'
import HeadingDialog from '@/components/work/work-order-detail-dialog.vue'
	export default {
        props: {
            disableEdit: {
                type: Boolean
            },
            workOrderId: {
                type: Number
            },
        },
        components:{
            HeadingDialog,
            EnlargeableImage
        },
		data() {
            return {
                id: [],
                loading: true,
                workOrderDetailsbyOrder: [],
                detailList: [],
                additionalDataLoaded: false,
                detailSelected: null,
                headingLoaded:false,
                detailDialog: false,
                hasParent: false,
                search: null,
                caseSensitive: false,
                headingList:[],
                availableDetail:null,
            }
        },
        computed: {
            indexedWorkOrderDetail () {
                return this.workOrderDetailsbyOrder.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderDetailsbyOrder().then((res) => {
                        this.workOrderDetailsbyOrder = res;
                        this.workOrderDetailsbyOrder.forEach((item, index) => { 
                            Vue.set(item,'detailDialog', false);
                            this.getDetailUnit(index, item.detail);
                            // Vue.set(item,'unit', this.getDetailUnit(item.detail));
                        })  
                    });
                }
            },
			async getWorkOrderDetailsbyOrder() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderDetail.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderDetail() {
                // this.$router.push({ name: 'workOrderDetailForm', params: {workOrderId: this.workOrderId } })
                this.workOrderDetailsbyOrder.push({id: '', detail: '', quantity: 0, order: this.workOrderId, detailDialog: false });
            },
            save(id, detail, quantity ) {
                this.saveWorkOrderDetail(id, detail, quantity).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderDetail(id, detail, quantity) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workOrderDetail.save( {id: id, detail: detail, quantity: quantity, order: this.workOrderId});
                    } else {
                        res = await this.$api.workOrderDetail.new({ detail: detail, quantity: quantity, order: this.workOrderId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderDetail.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderDetailsbyOrder, index)
                }
            },
            getAdditionalData() {
                this.getDetails().then(res => this.detailList = res);
                this.getWorkHeadings().then((res) => {
                    this.headingList = res;
                });
            },
            async getWorkHeadings() {
                try {
                    const res = await this.$api.invoiceHeading.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getDetails() {
                try {
                    const res = await this.$api.invoiceDetail.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshUnit(index, detail) {
                this.getDetailUnit(index, detail);
            },
            getDetailUnit(index, id) {
                this.getDetailInfo(id).then((res) => {
                    // this.workOrderDetailsbyOrder[index].unit = res.unit_data.symbol;
                    Vue.set(this.workOrderDetailsbyOrder[index],'unit', res.unit_data.symbol);
                    Vue.set(this.workOrderDetailsbyOrder[index],'detailLabel', res.heading_path);
                });
            },
            async getDetailInfo(id) {
                try {
                    const res = await this.$api.invoiceDetail.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeDetailValue({ value, detailIndex }) {
                Vue.set(this.workOrderDetailsbyOrder[detailIndex],'detail', value);
                this.refreshUnit(detailIndex, value)
            },
            goToDetailForm(id) {
                this.$router.push({ name: 'workDetailForm', params: { id } })
            }
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>