<template>
	<div id="header">
        <v-app-bar
        app
        :clipped-left="true"
        outlined flat
        >
        <v-row align="center">
            <v-col cols="4" class="text-left">
                <v-app-bar-nav-icon @click="toggleDrawer" v-if="mode !== 'simple'" />
                <v-btn
                @click="back"
                icon
                color="primary"
                dark
                >
                    <v-icon small color="grey" dark >mdi-arrow-left</v-icon>
                </v-btn>
                <div class="d-none d-sm-inline-block">{{companyName}}</div>
            </v-col>
            <v-col cols="4">
                <v-toolbar-title class="text-center">
                    {{currentRouteName}} {{currentRoutePageId}}
                    <transition name="fade">
                    <v-progress-circular v-if="tokenCheck"
                    class="ml-2"
                    :size="16"
                    :width="2"
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    </transition>
                </v-toolbar-title>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-tooltip v-if="system_mode == 'set_up'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-wrench</v-icon>
                </template>
                <span>Currently in set up mode</span>
                </v-tooltip>
                <v-tooltip v-if="system_mode == 'production'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-cog</v-icon>
                </template>
                <span>Currently in production mode</span>
                </v-tooltip>
                <v-tooltip v-if="system_mode == 'file_import'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-database-import</v-icon>
                </template>
                <span>Currently in file import mode</span>
                </v-tooltip>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn
                        icon
                        color="primary"
                        dark
                        v-on="on"
                        >
                        <v-icon>mdi-apps</v-icon>
                        </v-btn>
                    </template>
                   <moduleNavigation style="width: 300px; height:300px"></moduleNavigation>
                </v-menu>
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        icon
                        color="primary"
                        dark
                        v-on="on"
                        >
                        <v-avatar color="indigo" size="36">
                            <span class="white--text">{{profileFirstLetter}}</span>
                        </v-avatar>
                        </v-btn>
                    </template>
                    <v-list class="text-left" rounded dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{profile.username}}</v-list-item-title>
                                <v-list-item-subtitle>{{profile.email}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{profile.role}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-menu v-if="this.originalProfile.role == 'SYSADMIN'" :close-on-content-click="false" max-height="300" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                    block
                                    color="primary"
                                    dark
                                    v-on="on"
                                    >
                                    <small>Current User:<br/><span class="d-none d-md-inline-block">{{ profileName }}</span></small>
                                    </v-btn>
                                </template>
                                <v-list class="text-left" rounded dense>
                                    <v-radio-group v-model="profileId" :mandatory="false" hide-details="auto" label="Choose a profile" class="mt-0">
                                        <v-radio v-for="(user, index) in userList" v-bind:key="index" :label="user.username" :value="user.id"></v-radio>
                                    </v-radio-group>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        
                        <v-list-item>
                            <v-list-item-content>
                                <div class="px-2">
                                    <v-switch
                                    v-model="themeColor"
                                    inset
                                    label="Toggle Theme"
                                    persistent-hint
                                ></v-switch>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="(link, index) in userLinks" v-bind:key="index" @click="userLink(link.link)" :disabled="link.disabled">
                            <v-list-item-icon size="12" v-if="link.icon">
                                <v-icon>{{link.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{link.title}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-app-bar>
    </div>
</template>
<script>
import moduleNavigation from '@/components/common/moduleNavigation.vue';
	export default {
        props: {
            drawerStatus: Boolean,
            mode: String,
            globalPref: Array,
        },
		  components: {
              moduleNavigation,
		  },
		data: () => ({
            userLinks:[
                {
                    icon: 'mdi-account',
                    title: 'My Profile',
                    link: 'profile',
                },
                {
                    icon: 'mdi-logout',
                    title: 'Logout',
                    link: 'logout'
                }
            ],
            tokenCheck: false,
            profile: [],
            userList: [],
            profile: [],
            originalProfile: [],
            themeColor: false,
		}),
        computed: {
            companyName: function() {
                if (this.authed && this.globalPref.length > 0) {
                    return (this.globalPref.find(pref => pref.name === 'organization_name').value);
                }
            },
			authed: function() {
				return this.$store.getters.isLoggedIn
			},
			profileFirstLetter() {
                if (Object.keys(this.profile).length > 0) {
				    return this.profile.username.charAt(0).toUpperCase();
                }
            },
            currentRoute() {
                return this.$route.name;
            },
            currentRouteName() {
                return this.$route.meta.title;
            },
            currentRoutePageId() {
                return this.$route.params.lvl;
            },
			system_mode: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'mode').value);
				}
			},
			profileId:{
                get: function () {
                    return this.$store.getters.profileId;
                },
                // setter
                set: function (newValue) {
					this.$store.dispatch('setProfileId', newValue).then((res) => {
					})
					this.$store.dispatch('setProfileRole', this.getUserRole(newValue)).then((res) => {
					})
                }
            },
            profileName() {
				if (this.userList.length > 0) {
                    if (this.$store.getters.profileId) {
                        if (this.$store.getters.profileId !== '0') {
                            return this.userList.find(user => user.id === this.$store.getters.profileId).username;
                        } else {
                            return "Super Admin"
                        }
                    }
				}
            }
        },
        methods: {
            getProfileId() {
                this.getProfile().then((res) => {
                    this.profile = res;
                    this.originalProfile = res;
                });
            },
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			checkTokenPeriodic() {
				this.tokenCheck = true;
				this.$store.dispatch('inspectToken').then((res) => {
				if (res === false) {
					setTimeout(() => this.tokenCheck = false, 1000 );
				} else {
				};
				})
			},
            toggleDrawer() {
                this.$emit('updateDrawer', !this.drawerStatus);
            },
            userLink(link) {
                if (link == 'logout') {
                    this.$store.dispatch('logout');
                }
                if (link == 'profile') {
                    this.$router.push({ name: 'profileForm', params: { id: this.profile.id  } })
                }
            },
            getUserList() {
			    this.getUsers().then((res) => {
                    this.userList = res;
                    this.userList.unshift({ username: 'Super User', role: 'SYSADMIN', id: '0'})
                });
            },
			async getUsers() {
                try {
					const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserRole(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).role;
                }
            }, 
            getUser(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id);
                }
            }, 
            assignNewProfile(user_id){
                console.log('asd');
                this.profile = this.getUser(user_id);
                this.$store.dispatch('setProfileId', this.profile.id).then((res) => {
                })
                this.$store.dispatch('setProfileRole', this.profile.role).then((res) => {
                })
            },
            back() {
                this.$router.back()
            },
        },
		mounted: function () {
            this.themeColor = localStorage.getItem('wormsTheme', this.themeColor) || false;
            this.getProfileId();
            this.getUserList();
			setInterval(() => {
				this.checkTokenPeriodic() 
			}, 30000);
        },
        watch: {
            'themeColor': function(newVal){
                this.$vuetify.theme.dark = (newVal == true) ? true : false;
                localStorage.setItem('wormsTheme', newVal)
            }
        }
    }
</script>