<template>
    <div class="check-page">
        <v-row align="center" v-for="(comp,index) in indexedComponents" :key="index" class="flex-wrap mt-3 mb-3">
            <v-col cols="12" sm="4" class="pt-0 pb-0 d-flex align-center">
                <v-img class="mr-2" contain v-if="comp.component" :src="getComponentIcon(comp.component) || icongraphicPlaceholder" max-width="35" width="35" height="35"></v-img>
                <div v-if="componentList && componentList.length > 0">{{getComponentName(comp.component)}}</div>
                <div v-if="componentList && componentList.length == 0">{{comp.component}}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="5" sm="2" class="pt-0 pb-0">
                <div v-if="profileType =='verifier'">Checker:<br/>{{comp.checker_condition}}</div>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="pt-0 pb-0">
                <v-btn-toggle 
                v-if="profileType =='checker'" 
                dense 
                v-model="comp.checker_condition"
                @change="checked_ts(comp.id, comp.checker_condition)">
                    <v-btn 
                    v-for="(conditionValue, index) in condition" 
                    v-bind:key="index" 
                    :value="conditionValue.value">
                        {{conditionValue.name}}
                    </v-btn>
                </v-btn-toggle>
                <v-btn-toggle 
                v-if="profileType =='verifier'" 
                dense 
                v-model="comp.verifier_condition"
                @change="verified_ts(comp.id, comp.verifier_condition)">
                    <v-btn 
                    v-for="(conditionValue, index) in condition" 
                    v-bind:key="index" 
                    :value="conditionValue.value" >
                        {{conditionValue.name}}
                    </v-btn>
                </v-btn-toggle>
                <div v-if="profileType == 'super'">Readings: {{comp.checker_condition}} - {{comp.verifier_condition}}</div>
            </v-col>
            <v-col cols="2" sm="1" class="pt-0 pb-0">
                <v-btn
                class=""
                icon
                @click="componentChecksbyAssetCheck[comp.index].openNoteDialog = !componentChecksbyAssetCheck[comp.index].openNoteDialog"
                >
                <v-icon>mdi-tooltip-edit-outline</v-icon>
                </v-btn>

                <v-dialog
                v-model="componentChecksbyAssetCheck[comp.index].openNoteDialog"
                max-width="420"
                >
                <v-card>
                    <v-card-title class="headline">Note</v-card-title>
                    <v-card-text>
                        <v-text-field
                            label="Checker Note"
                            :readonly="profileType =='verifier' || profileType =='super' "
                            v-model="comp.checker_note" 
                            hide-details
                            @change="checked_note(comp.id, comp.checker_note)"
                        ></v-text-field>
                        <v-text-field
                            v-if="profileType =='verifier' || profileType =='super' "
                            :readonly="profileType =='super' "
                            label="Verifier Note"
                            v-model="comp.verifier_note" 
                            hide-details
                            @change="verified_note(comp.id, comp.verifier_note)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-if="indexedComponents.length > 0 && profileType =='verifier'" >
            <v-col cols="12" align="end">
                <v-btn @click="bulkVerify()" depressed color="primary">Verify <v-icon class="ml-2">mdi-check-all</v-icon></v-btn>
            </v-col>
        </v-row>
    </div>
    
</template>
<script>
import Vue from 'vue'
	export default {
        props: {
            assetCheckId: {
                type: Number
            },
            assetId: {
                type: Number
            },
            checkverifyStatus: String,
            profileType: {},
            componentList: Array,
        },
		data() {
            return {
                headersComponentCheck: [
                { text: 'Components', align: 'left', sortable: true, value: 'components', },
                { text: ''}
                ],
                id: [],
                loading: true,
                componentChecksbyAssetCheck: [],
                users:[],
                componentIdsFromChecks: [],
                condition: [
                    { 
                        name: 'Unknown',
                        value: 'UNKNOWN'
                    },
                    { 
                        name: 'Good',
                        value: 'GOOD'
                    },
                    { 
                        name: 'Bad',
                        value: 'BAD'
                    },
                    { 
                        name: 'Other',
                        value: 'OTHER'
                    },
                ],
                expandable: {},
                assetCheck: 0,
                multipleAddDialog: false,
                multipleAddComp: [],
                componentSnackbar: false,
            }
        },
        computed: {
            indexedComponents () {
                return this.componentChecksbyAssetCheck.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.assetCheckId) {
                    this.getComponentChecksbyAssetCheck().then((res) => {
                        this.componentChecksbyAssetCheck = res;
                        if (this.componentChecksbyAssetCheck) {
                            this.componentChecksbyAssetCheck.forEach((componentCheck) => {
                                this.componentIdsFromChecks.push(componentCheck.component);
                                Vue.set(componentCheck,'openNoteDialog', false);
                            });
                        }
                    });
                }
            },
            getComponentName(comp_id) {
                if (this.componentList && this.componentList.length !== 0) {
                    return this.componentList.find(comp => comp.id === comp_id).name;
                }
            },
            getComponentIcon(comp_id) {
                if (this.componentList && this.componentList.length !== 0) {
                    return this.componentList.find(comp => comp.id === comp_id).icongraphic;
                }
            },
			async getComponentChecksbyAssetCheck() {
                try {
                    this.loading = true
                    const res = await this.$api.compCheck.search({ asset_check: this.assetCheckId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newComponentCheck() {
                this.componentChecksbyAssetCheck.push({id: '', checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
            },
            newMultipleComponentChecks() {
                this.multipleAddComp.forEach((newComps) => {
                    this.componentChecksbyAssetCheck.push({id: '', component: newComps, checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
                    this.componentIdsFromChecks.push(newComps);
                });
                this.multipleAddComp = [];
                this.multipleAddDialog = false;
            },
            save(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                this.saveAssetCheck(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveAssetCheck(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.compCheck.save( {id: id, component: component, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    } else {
                        res = await this.$api.compCheck.new( {component: component, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id,) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.compCheck.delete(id).then((res) => {
                                this.list();
                                this.$emit('reloadAssetCheck')
                            });
                        }
                    })
                } else {
                    this.$delete(this.componentChecksbyAssetCheck, index)
                    this.componentIdsFromChecks = this.componentIdsFromChecks.filter(item => item !== component) ;
                }
            },
            
            checked_ts(id, checker_condition) {
                var d = new Date();
				this.$api.compCheck.save({id:id, checker_ts: d, checker_condition: checker_condition}).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            verified_ts(id, verifier_condition) {
                var d = new Date();
				this.$api.compCheck.save({ id: id, verifier_ts: d, verifier_condition: verifier_condition }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            bulkVerify() {
                var d = new Date();
                this.$swal({
                    icon: 'warning',
                    title: 'Do you want to bulk verify?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
                        this.verifyLoop(d).then((res) => {
                            if (res.status == 'Done') {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Bulk Verify Complete',
                                    text: res.updated +' updated!',
                                    confirmButtonText: 'Ok',
                                }).then((result) => {
                                    this.list();
                                    this.$emit('reloadAssetCheck')
                                })
                            }
                        });
                    }
                })
            },
			async verifyLoop(d) {
                try {
                    let res;
                    let numberUpdated = 0;
                    this.saving = true
                    for (var i = 0; i < this.indexedComponents.length; i++) {
                        if (this.indexedComponents[i].verifier_condition == '' || this.indexedComponents[i].verifier_condition == 'UNKNOWN') {
                            this.indexedComponents[i].verifier_condition = this.indexedComponents[i].checker_condition;
                            await this.$api.compCheck.save( {id: this.indexedComponents[i].id, verifier_condition: this.indexedComponents[i].verifier_condition, verifier_ts: d });
                            numberUpdated++;
                        }  
                    }
                    return { status:'Done', updated: numberUpdated };
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            checked_note (id, checker_note) {
				this.$api.compCheck.save({ id: id, checker_note: checker_note }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            verified_note (id, verifier_note) {
				this.$api.compCheck.save({ id: id, verifier_note: verifier_note }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
        },
        mounted(){
            this.list();
        }
    }
</script>