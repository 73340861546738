<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
        <v-card class="pa-3 mb-3 mx-auto">
        <v-form lazy-validation
            ref="form"
            v-model="valid">
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name" 
                        v-model="input.name"
                        :rules="nameRules"
                        :counter="50"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-select
                    :items="assetList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="Asset" 
                    v-model="input.asset"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-select
                    :items="baseCompList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="Base Component" 
                    v-model="input.base_component"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-btn-toggle v-model="input.condition">
                        <v-btn value="OTHER">Misc</v-btn>
                        <v-btn value="BAD">Bad</v-btn>
                        <v-btn value="UNKNOWN">Unknown</v-btn>
                        <v-btn value="GOOD">Good</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
			success: false,
            loading: true,
            input: {
                id: '',
                name: '',
                asset: '',
                base_component: '',
                condition: '',
            },
            loading: true,
            assetList: [],
            baseCompList: [],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
        }),
        props: {
            id: Number   
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getComp().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.asset = res.asset;
                        this.input.base_component = res.base_component;
                        this.input.condition = res.condition;
                    });
                }
            },
			async getComp() {
                try {
                    this.loading = true
                    const res = await this.$api.component.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'componentList' })
            },
            save() {
                this.saveComp().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'componentList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveComp() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.component.save( this.input );
                    } else {
                        res = await this.$api.component.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            // Additional Infos
            getAdditionalData() {
			    this.getBaseComps().then(res => this.baseCompList = res);
			    this.getAssets().then(res => this.assetList = res);
            },
			async getBaseComps() {
                try {
                    const res = await this.$api.baseComp.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.success = true;
		},
	}
</script>