<template>
    <v-container fluid class="list-checklist">
        <v-row v-if="!editMode">
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="editChecklist()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
                <!-- <v-select
                :items="existingYearMonths" 
                label="Year - Month" 
                v-model="yearMonthSelected"
                name="item"
                item-value="value"
                item-text="name"
                ></v-select> -->
                <v-row align-content="center">
                    <v-col sm="6">
                        <v-select
                        :items="checkplanList" 
                        label="Checkplan" 
                        v-model="checkplanSelected"
                        name="item"
                        item-value="id"
                        item-text="name"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :disabled="loadingChecklistCalendar"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="today"
                                label="Choose Date of Checklist"
                                :disabled="loadingChecklistCalendar"
                                :prepend-icon="loadingChecklistCalendar ? 'mdi-calendar-sync' : 'mdi-calendar-check'"
                                v-on="on"
                                hint="Orange dots indicates the checklists with the same checkplan selected. " persistent-hint
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="today" no-title
                            :events="otherChecklists"
                            event-color="orange lighten-1">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
            <v-data-table
            v-if="this.checklist"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="this.sortedChecklist"
            :sort-by="['id']"
            :server-items-length="pagination.count"
            hide-default-footer
            :sort-desc="[true]"
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left"><span v-if="row.item.open_dt">{{ row.item.open_dt | moment_dtstamp }}</span></td>
                <td class="text-left">{{ statusName(row.item.is_active) }} <span v-if="row.item.status">{{ + ': ' + row.item.status}}</span></td>
                <td style="width:150px" class="text-xs-right">
                    <v-btn-toggle>
                        <v-btn small fab @click="editChecklist(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab @click="delChecklist(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		data: () => ({
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingCheckplans: [],
            yearMonthSelected: '',
            checkplanSelected: '',
            otherChecklists : [],
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', },
            { text: 'Name', value: 'name' },
            { text: 'Open Datetime', value: 'open_dt' },
            { text: 'Status', value: 'is_active' },
            { text: '', sortable: false,}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			checklist: [],
			loading: true,
            inputId: '',
            inputName: '',
            loading: true,
            checklistId: 0,
            userList: [],
            today: null,
            setDate: null,
            menu: false,
            checkplanList: [],
            loadingChecklistCalendar: false,
		}),
        computed: {
            sortedChecklist () {
                // if(this.checkplanSelected !== '' && this.yearMonthSelected !== '' ) {
                //     return this.checklist.filter((checklist => checklist.yearmonth == this.yearMonthSelected) && (checklist => checklist.checkplan == this.checkplanSelected));
                // } else {
                //     if (this.checkplanSelected !== '' ) {
                //         return this.checklist.filter(checklist => checklist.checkplan == this.checkplanSelected);
                //     } else if (this.yearMonthSelected !== '' ) {
                //         return this.checklist.filter(checklist => checklist.yearmonth == this.yearMonthSelected);
                //     } else {
                //         return this.checklist;
                //     }
                // }
                return this.checklist;
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getChecklists(1, '').then((res) => {
                    this.checklist = res.results;
                    this.pagination.next = res.next;
                    this.pagination.count = res.count;
                    this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getChecklists(page, checkplan) {
                try {
                    this.loading = true
                    const res = await this.$api.checklist.search({ limit:this.perPage, offset: this.perPage*(page - 1), checkplan: this.checkplanSelected, open_date: this.today });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
                this.getCheckplan().then((res) => {
                    this.checkplanList = res
                    this.checkplanList.unshift({name: "All", id: '' })
                });
                this.getCalendarChecklist();
            },
			getCalendarChecklist() {
                this.otherChecklists = [];
                this.getOtherChecklist().then((res)=>{
                    res.forEach((item) => { 
                        this.otherChecklists.push(this.$moment(item.open_dt).format('YYYY-MM-DD'))
                    })
                })
            },
			async getOtherChecklist() {
                try {
                    this.loadingChecklistCalendar = true
                    const res = await this.$api.checklist.search({ checkplan: this.checkplanSelected });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingChecklistCalendar = false
                 }
            },
			async getCheckplan() {
                try {
                    const res = await this.$api.checkplan.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            // getCheckplanName(checkplan_id) {
            //     if ( this.checkplanList.length !== 0 && checkplan_id ) {
            //         return this.checkplanList.find(checkplan => checkplan.id === checkplan_id).name;
            //     }
            // }, 
			editChecklist(id) {
                this.$router.push({ name: 'checklistForm', params: { id } })
            },
            delChecklist(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.checklist.delete(id).then(res => this.list() );
                    }
                })
            },
            // Sub Function 
            statusName () {
                if (true) {
                    return "Active";
                } else {
                    return "Inactive"
                }
            }
		},
		mounted () {
            this.today = this.$moment().format('YYYY-MM-DD')
            this.list();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
            today: function() {
                this.list(this.today);
            },
            pagination: {
                handler(val){
                    this.getChecklists(this.pagination.current, this.checkplanSelected).then((res) => {
                        this.checklist = res.results;
                    });
                },
                deep: true
            },
            checkplanSelected: function(val) {
                this.getCalendarChecklist();
                this.getChecklists(this.pagination.current, this.checkplanSelected).then((res) => {
                    this.checklist = res.results;
                });
            }
        },
	}
</script>