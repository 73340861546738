<template>
    <v-card flat class="pa-3 mb-3 mx-auto">
        <div class="print" id="printMe">
            <table class="text-center" width="100%" v-if="pdfData.worker">
                <tr>
                    <td>{{pdfData.worker.full_name}}</td>
                </tr>
                <tr v-if="pdfData.worker.address_1">
                    <td>{{ pdfData.worker.address_1 }}</td>
                </tr>
                <tr v-if="pdfData.worker.address_2">
                    <td>{{ pdfData.worker.address_2 }}</td>
                </tr>
                <tr v-if="pdfData.worker.address_3">
                    <td>{{ pdfData.worker.address_3 }}</td>
                </tr>
                <tr>
                    <td>Tel: {{pdfData.worker.phone}} &nbsp;&nbsp;&nbsp; Email: {{pdfData.worker.email}}</td>
                </tr>
            </table>
            <br/>
            <br/>
            <h2 class="text-center">INVOIS</h2>
            <br/>
            <br/>
            <table class="text-left" width="100%">
                <tr>
                    <td valign="top" >
                        {{getUserName(claimInfo.vetted_by)}}<br/>
                        {{core__organization_name}}<br/>
                        {{pdfData.costcenter}}<br/>
                        <br/>
                        ( U/P: {{pdfData.worker.short_name}} )
                    </td>
                    <td valign="top" >
                        No. Invois: {{pdfData.id}}<br/>
                        Tarikh: {{claimInfo.audited_ts | moment_dtstamp}}<br/>
                        No. Indent:<br/>
                    </td>
                </tr>
            </table>
            <br/>
            <h3>{{pdfData.name}}</h3>
            <p v-if="claimInfo.description">{{claimInfo.description}}</p>
            <br/>
            <table class="table table-bordered text-left" width="100%">
                <thead>
                    <tr>
                        <th width="100">NO</th>
                        <th width="70%">KETERANGAN</th>
                        <th width="20%" class="text-right">JUMLAH (RM)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in order_set_table" v-bind:key="index">
                        <td>{{index+1}}</td>
                        <td>{{order.name}}</td>
                        <td class="text-right">{{order.net}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="text-left">
                        <td></td>
                        <td>
                            BANK: {{bank_name}}<br/>
                            NO AKAUN: {{bank_account}}
                        </td>
                        <td></td>
                    </tr>
                    <tr class="text-right">
                        <td colspan="2">Jumlah Besar:</td>
                        <td class="text-right">{{pdfData.cost}}</td>
                    </tr>
                </tfoot>
            </table>
            <br/>
            <strong>RINGGIT MALAYSIA: {{inWords(pdfData.cost.replace(',',''))}} {{centsWords(pdfData.cost.replace(',',''))}}</strong>
            <br/>
            <br/>
            <!-- <br/>
            <br/> -->
            <!-- <table width="100%">
                <tr>
                    <td>Disediakan Oleh: {{getUserName(claimInfo.vetted_by)}}</td>
                    <td>Disemak Oleh: {{getUserName(claimInfo.audited_by)}}</td>
                </tr>
            </table> -->
            <table class="table table-bordered text-left mb-4 mt-4" width="100%">
                <thead>
                    <tr>
                        <th width="150">
                            
                        </th>
                        <th width="30%" style="height:40px">
                        </th>
                        <th width="30%">
                        </th>
                        <th width="30%">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="height:100px">
                            Stamp
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td style="height:100px">
                            Signature
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Name
                        </td>
                        <td>
                        </td>
                        <td>

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Title
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table>
            <hr class="mb-4" />
            <table class="table table-bordered text-left mb-4 mt-4" width="100%" v-for="(order_set, index) in pdfData.order_set" v-bind:key="index">
                <thead>
                    <tr>
                        <th width="120" colspan="1">No. {{index+1}}</th>
                        <th width="100" colspan="5">
                            Order ID:{{order_set.id}}<br/>
                            Ext Ref ID: {{order_set.ext_ref_id}}<br/>
                            Name: {{order_set.name}}
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(order_heading, index2) in order_set.order_heading_set">
                    <tr>
                        <td colspan="6" style="background:#eee">{{order_heading.heading}} </td>
                    </tr>
                    <tr>
                        <th width="120">#</th>
                        <th width="60%">Detail</th>
                        <th width="120">Qty</th>
                        <th width="40px">Unit</th>
                        <th width="100">Cost/unit</th>
                        <th width="100" class="text-right">Cost</th>
                    </tr>
                    <tr v-for="(order_detail, index2) in order_heading.order_detail_set" v-bind:key="index2">
                        <td>{{index2+1}}</td>
                        <td>{{order_detail.code}}: {{order_detail.path}} </td>
                        <td>{{order_detail.quantity}}</td>
                        <td>{{order_detail.unit}}</td>
                        <td>{{order_detail.unit_cost}}</td>
                        <td class="text-right">{{order_detail.cost}}</td>
                    </tr>
                    <tr>
                        <td colspan="5">Gross:</td>
                        <td class="text-right">
                            {{  order_heading.gross }}
                        </td>
                    </tr><tr>
                        <th width="120">#</th>
                        <th width="60%" colspan="3">Item</th>
                        <th width="120">Percentage</th>
                        <th width="100" class="text-right">Cost</th>
                    </tr>
                    <tr v-for="(order_addendum, index3) in order_heading.order_addendum_set" v-bind:key="index3">
                        <td>{{index3+1}}</td>
                        <td colspan="3">{{order_addendum.addendum}} </td>
                        <td>{{order_addendum.percentage}}</td>
                        <td class="text-right">{{order_addendum.cost}}</td>
                    </tr>
                    <tr>
                        <td colspan="5">Net:</td>
                        <td class="text-right">
                            {{  order_heading.net }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5"><strong>Order Gross:</strong></td>
                        <td class="text-right">
                            <strong>{{  order_set.gross }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" style="background:#ccc"><strong>Order Net:</strong></td>
                        <td class="text-right" style="background:#ccc">
                            <strong>{{  order_set.net }}</strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <table class="table table-bordered text-left mb-4 mt-4" width="100%">
                <thead>
                    <tr>
                        <th colspan="4" class="text-center">Invoice Heading</th>
                    </tr>
                    <tr>
                        <th width="100">#</th>
                        <th width="60%">Name</th>
                        <th width="100">Gross</th>
                        <th width="40px">Net</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(invoice_heading, index) in pdfData.invoice_heading_set" v-bind:key="index">
                        <td>{{index+1}}</td>
                        <td>{{invoice_heading.name}}</td>
                        <td>{{invoice_heading.gross}}</td>
                        <td>{{invoice_heading.net}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3" style="background:#ccc"><strong>Net Total:</strong></td>
                        <td class="text-right" style="background:#ccc">
                            <strong>{{  pdfData.invoice_heading_set.reduce((a, c) => a + parseFloat(c.net), 0).toFixed(2) }}</strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <strong>RINGGIT MALAYSIA: {{inWords(pdfData.invoice_heading_set.reduce((a, c) => a + parseFloat(c.net), 0).toFixed(2))}} {{centsWords(pdfData.invoice_heading_set.reduce((a, c) => a + parseFloat(c.net), 0).toFixed(2))}}</strong>
            <table class="table table-bordered text-left mb-4 mt-4" width="100%">
                <thead>
                    <tr>
                        <th width="150">
                            
                        </th>
                        <th width="30%" style="height:40px">
                        </th>
                        <th width="30%">
                        </th>
                        <th width="30%">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="height:100px">
                            Stamp
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td style="height:100px">
                            Signature
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Name
                        </td>
                        <td>
                        </td>
                        <td>

                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Title
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="pdf" ref="pdf"></div>
    </v-card>
</template>
<style lang="scss">
    #printMe {
        max-width: 720px;
        font-size: 12px;
    }
    .table-bordered {
        border-collapse:collapse;
        max-width: 99%;
    }
    .table-bordered th, .table-bordered td {
        border:1px solid #aaa;
        padding: 6px;
    }
    .print table td, .print table th {
        page-break-inside: avoid;
    }
</style>
<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
	export default {
		data: () => ({
            first: ['','SATU ','DUA ','TIGA ','EMPAT ', 'LIMA ','ENAM ','TUJUH ','LAPAN ','SEMBILAN ','SEPULUH ','SEBELAS ',' DUA BELAS ','TIGA BELAS ','EMPAT BELAS ','LIMBA BELAS ','ENAM BELAS ','TUJUH BELAS ','LAPAN BELAS ','SEMBILAN BELAS '],
            tens: ['', '', 'DUA PULUH','TIGA PULUH','EMPAT PULUH','LIMA PULUH', 'ENAM PULUH','TUJUH PULUH','LAPAN PULUH','SEMBILAN PULUH'],
            mad: ['', 'RIBU,','JUTA,','BILION,','TRILION,']
        }),
        props:{
            workerInfo: Object,
            claimInfo: Object,
            globalPref: Array,
            userList: Array,
            costCenterList: Array,
            order_set_table: Array,
            pdfData: Object,
        },
        computed:{
            address_1: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_1;
                }
            },
            address_2: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_2;
                }
            },
            address_3: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_3;
                }
            },
            phone: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.phone;
                }
            },
            email: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.email;
                }
            },
            bank_name: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.bank_name;
                }
            },
            bank_account: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.bank_account;
                }
            },
            core__organization_name: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'core__organization_name').value;
                }
            }
        },
		methods: {
            inWords (number) {
                let word = '';
                for (let i = 0; i < this.mad.length; i++) {
                let tempNumber = number%(100*Math.pow(1000,i));
                if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
                if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
                    word = this.first[Math.floor(tempNumber/Math.pow(1000,i))] + this.mad[i] + ' ' + word;
                } else {
                    word = this.tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + ' ' + this.first[Math.floor(tempNumber/Math.pow(1000,i))%10] + this.mad[i] + ' ' + word;
                }
                }

                tempNumber = number%(Math.pow(1000,i+1));
                if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = this.first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'RATUS ' + word;
            }
                return word;
            },
            centsWords(number){
                console.log(number.toString().split(".")[1]);
                if (number.toString().split(".")[1] && number.toString().split(".")[1] !== '00') {
                    return ' DAN ' +this.inWords(number.toString().split(".")[1]) + ' SEN';
                }
            },
            print () {
                window.scrollTo(0, 0);
                // html2canvas(document.querySelector('#printMe'), {width: 960, imageTimeout: 5000, useCORS: true}).then(canvas => {
                //     document.getElementById('pdf').appendChild(canvas)
                //     let img = canvas.toDataURL('image/png')
                //     let pdf = new jsPDF('p', 'mm', 'a4')
                //     let pageHeight= pdf.internal.pageSize.height;
                //     let y = 500 // Height position of new content
                //     if (y >= pageHeight) {
                //         pdf.addPage();
                //         y = 0 // Restart height position
                //     }
                //     pdf.addImage(img, "JPEG", 10, 10);
                //     pdf.save('relatorio-remoto.pdf')
                //     document.getElementById('pdf').innerHTML = ''
                    
                // })
	            const htmlSource = document.querySelector("#printMe");
                html2canvas(htmlSource, {scrollY: -window.scrollY}).then(function(canvas) {
                    // create a new window
                    var nWindow = window.open('');
                    // append the canvas to the body
                    nWindow.document.body.appendChild(canvas);
                    setTimeout(function () { nWindow.print(); }, 500);
                    nWindow.onfocus = function () { setTimeout(function () { nWindow.close(); }, 500); }
                    // reload the page
                });
            },
            closePDF() {
                this.$emit('closePDF')
            },
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getCostCenter(cost_center_id) {
                if ( this.costCenterList.length !== 0 && cost_center_id ) {
                    return this.costCenterList.find(costcenter => costcenter.id === cost_center_id).name;
                }
            },
            calculateSum(val){
                var sum = 0;
                for(let value in val){
                    sum += val[value]['order_detail_set.cost']
                }
                return sum;
            },
		},
		mounted () {
		},
	}
</script>