<template>
    <v-container class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs 
                    background-color="primary">
                    <v-tabs-slider />
                    <v-tab>Order</v-tab>
                    <v-tab v-if="this.input.id" :disabled="info.status == 'OPEN'">Parameter</v-tab>
                    <v-tab v-if="this.input.id" :disabled="info.status == 'OPEN'">Instruction</v-tab>
                    <v-tab v-if="this.input.id" :disabled="info.status == 'OPEN'">Detail</v-tab>
                    <v-tab v-if="this.input.id && profileId == input.supervisor" :disabled="info.status == 'OPEN'">Heading</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                    label="Title *" 
                                    v-model="input.name"
                                    hide-details="auto"
                                    :readonly=!canEditRecord
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    label="Status" 
                                    v-model="info.status"
                                    hide-details="auto"
                                    readonly
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-autocomplete
                                :items="userList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Supervisor" 
                                v-model="input.supervisor"
                                    hide-details="auto"
                                    readonly
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-autocomplete
                                :items="userList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Worker" 
                                v-model="input.worker"
                                    hide-details="auto"
                                    :readonly=!canEditRecord
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <div v-if="!canEditRecord" v-html="input.description"></div>
                                <wysiwyg v-if="canEditRecord"
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="input.id">
                            <v-col cols="12" sm="12" md="6">
                                <v-datetime-picker label="Open Datetime *" 
                                    disabled v-model="objOpenDateTime"> 
                                    <template v-slot:dateIcon>
                                        <v-icon>mdi-calendar-month-outline</v-icon>
                                    </template>
                                    <template v-slot:timeIcon>
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-datetime-picker label="Due Datetime *" 
                                    disabled v-model="objDueDateTime"> 
                                    <template v-slot:dateIcon>
                                        <v-icon>mdi-calendar-month-outline</v-icon>
                                    </template>
                                    <template v-slot:timeIcon>
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <!-- <div class="mt-3" v-if="input.asset">
                            Current Asset: {{info.asset_data.name}} from {{info.asset_data.system__name}} in {{info.asset_data.location__name}} ( ID: {{input.asset}} ) <v-btn text ><v-icon>mdi-pencil</v-icon></v-btn>
                        </div> -->
                        <v-row v-if="!editAsset">
                            <v-col cols="12" sm="12" md="12">
                                <div v-if="info.asset_data"><label><strong>Asset Name: </strong></label>{{info.asset_data.name}}<br/></div>
                                <div v-if="info.asset_data"><label><strong>System Name: </strong></label>{{info.asset_data.system__name}}<br/></div>
                                <div v-if="info.asset_data"><label><strong>Location: </strong></label>{{info.asset_data.location__name}}<br/></div>
                                <v-btn color="primary" v-if="profileId == input.supervisor" @click="editAsset = !editAsset"><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="editAsset"> 
                            <v-col cols="12" sm="4" md="4">
                                <label>Filter by </label><br/>
                                <v-btn-toggle v-model="assetSearchSwitch">
                                    <v-btn value="system">
                                        <v-icon left>mdi-shape</v-icon>
                                        <span class="hidden-sm-and-down">System</span>
                                    </v-btn>
                                    <v-btn value="location">
                                        <v-icon left>mdi-map-marker</v-icon>
                                        <span class="hidden-sm-and-down">Location</span>
                                    </v-btn>
                                </v-btn-toggle>
                                <v-row>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'system'">
                                        <v-select
                                        :items="systemList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="System" 
                                        v-model="info.asset_data.system__id"
                                            hide-details="auto"
                                            @change="filterBySystem()"
                                        ></v-select>
                                        <v-select
                                        :items="genericList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Generic" 
                                        v-model="info.asset_data.generic__id"
                                            hide-details="auto"
                                            @change="filterByGeneric()"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'location'">
                                        <v-select
                                        :items="locationList0" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 1" 
                                        v-model="info.location[0]"
                                            hide-details="auto"
                                            @change="filterByLoc(0, info.location[0])"
                                        ></v-select>
                                        <v-select
                                        :items="locationList1" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 2" 
                                        v-model="info.location[1]"
                                            hide-details="auto"
                                            @change="filterByLoc(1, info.location[1])"
                                        ></v-select>
                                        <v-select
                                        :items="locationList2" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 3" 
                                        v-model="info.location[2]"
                                            hide-details="auto"
                                            @change="filterByLoc(2, info.location[2])"
                                        ></v-select>
                                        <v-select
                                        :items="locationList3" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 4" 
                                        v-model="info.location[3]"
                                            hide-details="auto"
                                            @change="filterByLoc(3, info.location[3])"
                                        ></v-select>
                                        <v-select
                                        :items="locationList4" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 5" 
                                        v-model="info.location[4]"
                                            hide-details="auto"
                                            @change="filterByLoc(4, info.location[4])"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-autocomplete
                                        :items="assetList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Asset" 
                                        v-model="input.asset"
                                            hide-details="auto"
                                        ></v-autocomplete>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :readonly=!canEditRecord
                                :items="scopeList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Scope *" 
                                v-model="input.scope"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :readonly=!canEditRecord
                                :items="work_type" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Work Type" 
                                v-model="input.work_type"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :readonly=!canEditRecord
                                :items="urgency" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Urgency" 
                                v-model="input.urgency"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row v-if="!canEditRecord">
                            <v-col cols="12" md="12">
                                <label>Images</label><br/>
                                <v-row>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_1" :src="input.image_1" :src_large="input.image_1" />
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_2" :src="input.image_2" :src_large="input.image_2" />
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_3" :src="input.image_3" :src_large="input.image_3" />
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_4" :src="input.image_4" :src_large="input.image_4" />
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_5" :src="input.image_5" :src_large="input.image_5" />
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_6" :src="input.image_6" :src_large="input.image_6" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="!canEditRecord">
                            <v-col cols="12" md="12">
                                <label>Files</label><br/>
                                <ul>
                                    <li v-if="info.file_1"><small v-if="info.file_1"><a :href="info.file_1" target="_blank">{{order_file_1_label}}</a></small></li>
                                    <li v-if="info.file_2"><small v-if="info.file_2"><a :href="info.file_2" target="_blank">{{order_file_2_label}}</a></small></li>
                                    <li v-if="info.file_3"><small v-if="info.file_3"><a :href="info.file_3" target="_blank">{{order_file_3_label}}</a></small></li>
                                    <li v-if="info.file_4"><small v-if="info.file_4"><a :href="info.file_4" target="_blank">{{order_file_4_label}}</a></small></li>
                                    <li v-if="info.file_5"><small v-if="info.file_5"><a :href="info.file_5" target="_blank">{{order_file_5_label}}</a></small></li>
                                    <li v-if="info.file_6"><small v-if="info.file_6"><a :href="info.file_6" target="_blank">{{order_file_6_label}}</a></small></li>
                                </ul>
                            </v-col>
                        </v-row>
                        <v-row v-if="canEditRecord">
                            <v-col cols="12" md="12">
                                <label class="font-weight-bold">Images</label><br/>
                                <v-row>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_1 && !newimage_1" :src="input.image_1" :src_large="input.image_1" />
                                        <v-img class="ba-1" contain v-if="!input.image_1 && !newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_1" :src="newimage_1Preview" :src_large="newimage_1Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_1Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_1_label"
                                            v-model="newimage_1"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_2 && !newimage_2" :src="input.image_2" :src_large="input.image_2" />
                                        <v-img class="ba-1" contain v-if="!input.image_2 && !newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_2" :src="newimage_2Preview" :src_large="newimage_2Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_2Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_2_label"
                                            v-model="newimage_2"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_3 && !newimage_3" :src="input.image_3" :src_large="input.image_3" />
                                        <v-img class="ba-1" contain v-if="!input.image_3 && !newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_3" :src="newimage_3Preview" :src_large="newimage_3Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_3Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_3_label"
                                            v-model="newimage_3"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_4 && !newimage_4" :src="input.image_4" :src_large="input.image_4" />
                                        <v-img class="ba-1" contain v-if="!input.image_4 && !newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_4" :src="newimage_4Preview" :src_large="newimage_4Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_4Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_4_label"
                                            v-model="newimage_4"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_5 && !newimage_5" :src="input.image_5" :src_large="input.image_5" />
                                        <v-img class="ba-1" contain v-if="!input.image_5 && !newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_5" :src="newimage_5Preview" :src_large="newimage_5Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_5Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_5_label"
                                            v-model="newimage_5"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_6 && !newimage_6" :src="input.image_6" :src_large="input.image_6" />
                                        <v-img class="ba-1" contain v-if="!input.image_6 && !newimage_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_6" :src="newimage_6Preview" :src_large="newimage_6Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_6Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_6_label"
                                            v-model="newimage_6"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="canEditRecord">
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_1_label"
                                    v-model="input.newfile_1"
                                ></v-file-input>
                                <small v-if="info.file_1">Uploaded: <a class="noline" :href="info.file_1" target="_blank">{{order_file_1_label}}</a></small>
                                <small v-if="!info.file_1">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_2_label"
                                    v-model="input.newfile_2"
                                ></v-file-input>
                                 <small v-if="info.file_2">Uploaded: <a class="noline" :href="info.file_2" target="_blank"> {{order_file_2_label}}></a></small>
                                <small v-if="!info.file_2">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_3_label"
                                    v-model="input.newfile_3"
                                ></v-file-input>
                                <small v-if="info.file_3">Uploaded: <a class="noline" :href="info.file_3" target="_blank"> {{order_file_3_label}}</a></small>
                                <small v-if="!info.file_3">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_4_label"
                                    v-model="input.newfile_4"
                                ></v-file-input>
                                <small v-if="info.file_4">Uploaded: <a class="noline" :href="info.file_4" target="_blank">{{order_file_4_label}}</a></small>
                                <small v-if="!info.file_4">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_5_label"
                                    v-model="input.newfile_5"
                                ></v-file-input>
                                <small v-if="info.file_5">Uploaded: <a class="noline" :href="info.file_5" target="_blank">{{order_file_5_label}}</a></small>
                                <small v-if="!info.file_5">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_6_label"
                                    v-model="input.newfile_6"
                                ></v-file-input>
                                <small v-if="info.file_6">Uploaded: <a class="noline" :href="info.file_6" target="_blank">{{order_file_6_label}}</a></small>
                                <small v-if="!info.file_6">No files uploaded</small>
                            </v-col>
                        </v-row>
                        <v-row class="align-end">
                            <v-col cols="6" class="text-left">
                                <v-btn :v-if="canEditRecord" class="mr-4" color="primary" @click="save()">
                                    <v-icon class="mr-2">mdi-content-save</v-icon>
                                    Save
                                </v-btn>
                                <div v-if="info.status == 'REDO'">Redo Reason: {{info.redo_reason}}</div>
                                <v-text-field
                                class="mb-2"
                                v-if="profileId == input.supervisor && info.status == 'RECORDED'"
                                    label="Redo Reason" 
                                    v-model="info.redo_reason"
                                    hide-details="auto"
                                ></v-text-field>
                                <v-btn v-if="profileId == input.supervisor && info.status == 'RECORDED'" class="mr-4" color="error" @click="bulkStatus('REDO')"><v-icon class="mr-2">mdi-close-circle-outline</v-icon>Redo Work</v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn 
                                    v-if="profileId == input.worker && info.status == 'OPEN'" 
                                    class="mr-4" 
                                    color="primary" 
                                    @click="bulkStatus('WIP')"
                                >
                                    <v-icon class="mr-2">mdi-clipboard</v-icon>Check In
                                </v-btn>
                                <v-btn 
                                    v-if="profileId == input.worker && (info.status == 'WIP' || info.status == 'REDO')" 
                                    class="mr-4"
                                    color="primary"
                                    @click="bulkStatus('RECORDED')"
                                    >
                                    <v-icon class="mr-2">mdi-clipboard</v-icon>Submit Record
                                </v-btn>
                                <v-btn 
                                    v-if="profileId == input.supervisor && info.status == 'RECORDED'" 
                                    class="mr-4" 
                                    color="primary" 
                                    @click="bulkStatus('DONE')"
                                    >
                                    <v-icon class="mr-2">mdi-check</v-icon>Done
                                </v-btn>
                                <v-btn 
                                    v-if="profileId == input.worker && info.status == 'DONE'" 
                                    class="mr-4" 
                                    color="primary" 
                                    @click="bulkStatus('CLAIMED')"
                                    >
                                    <v-icon class="mr-2">mdi-currency-usd</v-icon>Submit Claim
                                </v-btn>
                                <v-btn 
                                    v-if="profileId == input.supervisor && info.status == 'CLAIMED'" 
                                    class="mr-4" 
                                    color="primary" 
                                    @click="bulkStatus('VERIFIED')"
                                    >
                                    <v-icon class="mr-2">mdi-check-decagram</v-icon>Verified
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workOrderAssetParameterWorker v-on:updateWorkProgram="refreshWorkProgramTask" :workOrderId="input.id"></workOrderAssetParameterWorker>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workOrderInstructionWorker v-on:updateWorkProgram="refreshWorkProgramTask" :workOrderId="input.id"></workOrderInstructionWorker>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workOrderDetailWorker v-on:updateWorkProgram="refreshWorkProgramTask" :workOrderId="input.id"></workOrderDetailWorker>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="id && profileId == input.supervisor">
                        <v-row>
                            <v-col cols="12">
                            <workOrderHeadingTable :workOrderId="input.id" />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import workOrderAssetParameterWorker from '@/components/work/work-order-asset-parameter-worker.vue';
import workOrderInstructionWorker from '@/components/work/work-order-instruction-worker.vue';
import workOrderDetailWorker from '@/components/work/work-order-detail-worker.vue';
import workOrderHeadingTable from '@/components/work/work-order-heading-table.vue';
import wysiwyg from '@/components/ui/wysiwyg.vue';
import VueTimepicker from 'vue2-timepicker/src'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
		  components: {
            workOrderInstructionWorker,
            VueTimepicker,
            workOrderDetailWorker,
            workOrderAssetParameterWorker,
            EnlargeableImage,
            workOrderHeadingTable,
            wysiwyg
          },
          props: {
            id: Number,
            globalPref: Array,
          },
		data: () => ({
			success: false,
			loading: true,
            gettingLocation: false,
            errorStr: '',
            input:{
                id: '',
                name: '',
                description: '',
                cost: '',
                time: '',
                crew: null,
                activity: '',
                category: '',
                urgency: '',
                supervisor: '',
                worker: '',
                image_1: null,
                image_2: null,
                image_3: null,
                image_4: null,
                image_5: null,
                image_6: null,
                latitude: null,
                longitude: null,
                newfile_1: null,
                newfile_2: null,
                newfile_3: null,
                newfile_4: null,
                newfile_5: null,
                newfile_6: null,
            },
            info: {
                asset_data: {},
                system:0,
                generic:0,
                location:[null, null, null, null, null],
                status: null,
                redo_reason: '',
                file_1: null,
                file_2: null,
                file_3: null,
                file_4: null,
                file_5: null,
                file_6: null,
            },
            location:[],
            newimage_1: null,
            newimage_2: null,
            newimage_3: null,
            newimage_4: null,
            newimage_5: null,
            newimage_6: null,
            newimage_1Preview: null,
            newimage_2Preview: null,
            newimage_3Preview: null,
            newimage_4Preview: null,
            newimage_5Preview: null,
            newimage_6Preview: null,
            work_type:[
                { value: "CORRECTIVE", name: "corrective (self-initiate)" },
                { value: "PREVENTIVE", name: "preventive (scheduled)" },
                { value: "REACTIVE", name: "reactive (respond to complaint)" }
            ],
            urgency: [
                { value: 1, name: "1 : one" },
                { value: 2, name: "2 : two" },
                { value: 3, name: "3 : three" },
                { value: 4, name: "4 : four" }
            ],
            userList: [],
            scopeList: [],
            assetList: [],
            locationList: [],
            locationList0: [],
            locationList1: [],
            locationList2: [],
            locationList3: [],
            locationList4: [],
            systemList: [],
            genericList: [],
            locationFilteredList: [],
            genericFilteredList: [],
            assetFilteredList: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            openDtMenu: false,
            dueDtMenu: false,
            assetSearchSwitch: '',
            assetSelected: 0,
            editAsset: false,
        }),
        computed: {
            objOpenDateTime: {
                get(){
                    return (new Date(this.input.open_dt))
                },
                set(value) {
                    this.input.open_dt = value
                }
            },
            objDueDateTime: {
                get(){
                    return (new Date(this.input.due_dt))
                },
                set(value) {
                    this.input.due_dt = value
                }
            },
			profileId: function() {
				return this.$store.getters.profileId;
			},
            
            order_image_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_1_label').value;
                }
            },
            order_image_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_2_label').value;
                }
            },
            order_image_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_3_label').value;
                }
            },
            order_image_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_4_label').value;
                }
            },
            order_image_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_5_label').value;
                }
            },
            order_image_6_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_6_label').value;
                }
            },
            order_file_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_1_label').value;
                }
            },
            order_file_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_2_label').value;
                }
            },
            order_file_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_3_label').value;
                }
            },
            order_file_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_4_label').value;
                }
            },
            order_file_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_5_label').value;
                }
            },
            canEditRecord: function(){
                if (this.profileId) {
                    return (this.info.status == 'OPEN' || this.info.status == 'DRAFT') && this.profileId == this.input.supervisor
                }
            },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getWorkRecord().then((res) => {
                        this.grabLoc();
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.scope = res.scope;
                        this.input.asset = res.asset;
                        this.input.time = res.time;
                        this.input.crew = res.crew;
                        this.input.activity = res.activity;
                        this.input.work_type = res.work_type;
                        this.input.urgency = res.urgency;
                        this.input.open_dt = res.open_dt;
                        this.input.due_dt = res.due_dt;

                        this.input.supervisor = res.supervisor;
                        this.input.worker = res.worker;

                        this.input.image_1 = res.image_1;
                        this.input.image_2 = res.image_2;
                        this.input.image_3 = res.image_3;
                        this.input.image_4 = res.image_4;
                        this.input.image_5 = res.image_5;
                        this.input.image_6 = res.image_6;

                        this.info.status = res.status;
                        this.info.redo_reason = res.redo_reason;
                        this.info.asset_data = res.asset_data;
                        this.input.source = res.source;
                        this.getAdditionalData();
                        this.info.file_1 = res.file_1;
                        this.info.file_2 = res.file_2;
                        this.info.file_3 = res.file_3;
                        this.info.file_4 = res.file_4;
                        this.info.file_5 = res.file_5;
                        this.info.file_6 = res.file_6;
                        
                        if (res.asset_data) {
                            this.getLoc(res.asset_data.location__id).then((res2) => {
                                this.info.location[res2.depth] = res2.id;
                                if (res2.depth > 0) {
                                    this.getLoc(res2.parent).then((res3) => { 
                                        this.info.location[res3.depth] = res3.id;
                                        if (res3.depth > 0) {
                                            this.getLoc(res3.parent).then((res4) => { 
                                                this.info.location[res4.depth] = res4.id;
                                                if (res.depth > 0) {
                                                    this.getLoc(res4.parent).then((res5) => { 
                                                        this.info.location[res5.depth] = res5.id;
                                                    })
                                                }       
                                            })
                                        }
                                    })
                                }
                            });
                        }
                    });
                }
            },
            grabLoc() {
                if(!("geolocation" in navigator)) {
                    this.errorStr = 'Geolocation is not available.';
                    return;
                }
                this.gettingLocation = true;
                // get position
                    navigator.geolocation.getCurrentPosition(pos => {
                    this.gettingLocation = false;
                    this.input.latitude = Math.floor(pos.coords.latitude*1000000+0.5)/1000000;
                    this.input.longitude = Math.floor(pos.coords.longitude*1000000+0.5)/1000000;
                }, err => {
                    this.gettingLocation = false;
                    this.errorStr = err.message;
                })
            },
            bulkStatus(status) {
                this.saveWorkRecord().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.setStatus(status).then((res) => {
                            if (res.status == 200 || res.status == 201 ) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Saved',
                                    showCancelButton: true,
                                    reverseButtons: true,
                                    confirmButtonText: 'Return',
                                    cancelButtonText: 'Cancel',
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'workRecordList' })
                                    } else {
                                        this.input.id = res.data.id;
                                        this.form();
                                    }
                                })
                            }
                        });
                    }
                })
			},
			async setStatus(status) {
                try {
                    let res;
                    this.saving = true
                    if (status != 'REDO') {
                        if (status == 'WIP') {
                            res = await this.$api.workOrder.save({id: this.input.id, status: status, latitude: this.input.latitude, longitude: this.input.longitude  });
                        } else {
                            res = await this.$api.workOrder.save({id: this.input.id, status: status });
                        }
                    } else {
                        res = await this.$api.workOrder.save({id: this.input.id, status: status, redo_reason: this.info.redo_reason });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async getWorkRecord() {
                try {
                    const res = await this.$api.workOrder.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			save() {
                this.saveWorkRecord().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workRecordList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
            },
			async saveWorkRecord() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    formData.append("scope", this.input.scope);
                    formData.append("work_type", this.input.work_type);
                    formData.append("urgency", this.input.urgency);
                    formData.append("description", this.input.description);

                    if (this.input.latitude) {
                        formData.append("latitude", this.input.latitude);
                    }
                    if (this.input.longitude) {
                        formData.append("longitude", this.input.longitude);
                    }
                    formData.append("supervisor", this.input.supervisor);
                    formData.append("asset", this.input.asset);
                    formData.append("open_dt", this.input.open_dt);
                    formData.append("due_dt", this.input.due_dt);
                    if (this.newimage_1) {
                        formData.append("image_1", this.newimage_1 );
                    }
                    if (this.newimage_2) {
                        formData.append("image_2", this.newimage_2 );
                    }
                    if (this.newimage_3) {
                        formData.append("image_3", this.newimage_3 );
                    }
                    if (this.newimage_4) {
                        formData.append("image_4", this.newimage_4 );
                    }
                    if (this.newimage_5) {
                        formData.append("image_5", this.newimage_5 );
                    }
                    if (this.newimage_6) {
                        formData.append("image_6", this.newimage_6 );
                    }
                    if (this.input.newfile_1) {
                        formData.append("file_1", this.input.newfile_1);
                    }
                    if (this.input.newfile_2) {
                        formData.append("file_2", this.input.newfile_2);
                    }
                    if (this.input.newfile_3) {
                        formData.append("file_3", this.input.newfile_3);
                    }
                    if (this.input.newfile_4) {
                        formData.append("file_4", this.input.newfile_4);
                    }
                    if (this.input.newfile_5) {
                        formData.append("file_5", this.input.newfile_5);
                    }
                    if (this.input.newfile_6) {
                        formData.append("file_6", this.input.newfile_6);
                    }
                    if (this.input.id) {
                        res = await this.$api.workOrder.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.workOrder.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workRecordList' })
            },
            previewImage(e, previewTarget) {
                if (e) {
                    this[previewTarget] = URL.createObjectURL(e);
                }
            },
            getAdditionalData() {
			    // this.getRecurranceTimebyWorkProgram().then(res => this.recurranceTimeList = res);
                this.getUsers().then(res => this.userList = res);
                this.getWorkScope().then(res => this.scopeList = res);
                this.getAssets().then(res => this.assetList = res);
                this.getSystems().then(res => this.systemList = res);
                this.getGenerics().then(res => this.genericList = res);
                this.getLocsbyDepth(0).then((res) => { 
                    this.locationList0 = res;
                });
                this.getLocsbyDepth(1).then((res) => { 
                    this.locationList1 = res;
                });
                this.getLocsbyDepth(2).then((res) => { 
                    this.locationList2 = res;
                });
                this.getLocsbyDepth(3).then((res) => { 
                    this.locationList3 = res;
                });
                this.getLocsbyDepth(4).then((res) => { 
                    this.locationList4 = res;
                });
            },
            async getAsset() {
                try {
                    const res = await this.$api.assetAsset.get( this.input.asset );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLoc(location_id) {
                try {
                    const res = await this.$api.location.get( location_id );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLocsbyDepth(depth) {
                try {
                    const res = await this.$api.location.search({depth: depth});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenerics() {
                try {
                    const res = await this.$api.assetGeneric.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getWorkScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getComplaints() {
                try {
                    const res = await this.$api.complaintComplaint.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getSystemName(system_id) {
                if ( this.systemList.length !== 0 && system_id ) {
                    return this.systemList.find(system => system.id === system_id).name;
                }
            },
            getGenericName(generic_id) {
                if ( this.genericList.length !== 0 && generic_id ) {
                    return this.genericList.find(generic => generic.id === generic_id).name;
                }
            },
            getLocName(depth, location_id) {
                if (depth == 0) {
                    if ( this.locationList0.length !== 0 && location_id ) {
                        return this.locationList0.find(location => location.id === asset_id).name;
                    }
                }
                if (depth == 1) {
                    if ( this.locationList1.length !== 0 && location_id ) {
                        return this.locationList1.find(location => location.id === asset_id).name;
                    }
                }
                if (depth == 2) {
                    if ( this.locationList2.length !== 0 && location_id ) {
                        return this.locationList2.find(location => location.id === asset_id).name;
                    }
                }
                if (depth == 3) {
                    if ( this.locationList3.length !== 0 && location_id ) {
                        return this.locationList3.find(location => location.id === asset_id).name;
                    }
                }
                if (depth == 4) {
                    if ( this.locationList4.length !== 0 && location_id ) {
                        return this.locationList4.find(location => location.id === asset_id).name;
                    }
                }
            },
            filterBySystem() {
                this.getGenericsbySystem().then((res) => { 
                    this.genericList = res;
                });
                this.getAssetsbyFilter(this.info.system, null, null).then(res => this.assetList = res );
            },
            filterByGeneric() {
                this.getAssetsbyFilter(this.info.system, this.info.generic, null).then((res) => { this.assetList = res } );
            },
            filterByLoc(locationfield, location_id) {
                this.$api.location.search({ depth: locationfield+1, parent: location_id }).then((res) => {
                    if (locationfield+1 == 1) {
                        this.locationList1 = res.data;
                    }
                    if (locationfield+1 == 2) {
                    console.log(res.data)
                        this.locationList2 = res.data;
                    }
                    if (locationfield+1 == 3) {
                        this.locationList3 = res.data;
                    }
                    if (locationfield+1 == 4) {
                        this.locationList4 = res.data;
                    }
                } );
                this.getAssetsbyFilter(null, null, location_id).then((res) => { this.assetList = res } );
            },
            async getChildLoc(depth, parent_id) {
                try {
                    const res = await this.$api.location.search({ depth: depth+1, parent: parent_id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenericsbySystem(system_id) {
                try {
                    const res = await this.$api.assetGeneric.search({ system: this.info.system });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAssetsbyFilter(system, generic, location) {
                try {
                    let res;
                    if (this.assetSearchSwitch == 'system') {
                        res = await this.$api.assetAsset.search({ system: system, generic: generic });
                    } else {
                        res = await this.$api.assetAsset.search({ location: location });
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshWorkProgramTask() {
                this.form();
            },
		},
		mounted () {
            this.form();
            this.valid = true;
		},
	}
</script>