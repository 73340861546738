<template>
    <v-form>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8">
                                <v-btn-toggle
                                v-model="dataBar"
                                mandatory
                                >
                                    <v-btn value="urgency">Urgency</v-btn>
                                    <v-btn value="source">Source</v-btn>
                                    <v-btn value="type">Type</v-btn>
                                    <v-btn value="cost">Cost</v-btn>
                                    <v-btn value="duration">Duration</v-btn>
                                    <v-btn value="scope">Scope</v-btn>
                                    <v-btn value="costcenter">Cost Center</v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                v-model="filterDataChart.frequency"
                                :items="!dateRange ? frequencyOption: frequencyOptionDate"
                                item-text="label"
                                item-value="value"
                                label="Frequency"
                                outlined
                                hide-details="auto"
                                ></v-select>
                                <div v-if="dateRange" class="d-flex align-center">
                                    <v-menu
                                        ref="startDate"
                                        v-model="start_dateMenu"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filterDataChart['start-date']"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details="auto"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="filterDataChart['start-date']"
                                        no-title
                                        scrollable
                                        show-current
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    <span style="margin:0 5px">-</span>
                                    <v-menu
                                        ref="endDate"
                                        v-model="end_dateMenu"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filterDataChart['end-date']"
                                            label="End Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details="auto"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="filterDataChart['end-date']"
                                        no-title
                                        scrollable
                                        show-current
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                                <span class="mt-2 fakeLink" @click="openDateRange()">
                                    <span v-if="!dateRange">Click here to specify a date range</span>
                                    <span v-if="dateRange">Disable Date Range</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete
                                        :items="assetList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Asset" 
                                        v-model="assetSelected"
                                        outlined
                                            hide-details="auto"
                                            :loading="assetLoading"
                                         :filter="assetFilter"
                                        >
                                            <template slot="selection" slot-scope="data">
                                                {{ data.item.name }} <span style="margin-left:5px" v-if="data.item.id">(</span><span v-if="data.item.id">ID: {{ data.item.id }}</span><span v-if="data.item.manual_tag">, Tag: {{ data.item.manual_tag }} </span><span v-if="data.item.id">)</span>
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }} <span style="margin-left:5px" v-if="data.item.id">(</span><span v-if="data.item.id">ID: {{ data.item.id }}</span><span v-if="data.item.manual_tag">, Tag: {{ data.item.manual_tag }} </span><span v-if="data.item.id">)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <v-select
                                        :items="userList" 
                                        item-value="id"
                                        name="item"
                                        outlined
                                        item-text="username"
                                        :label="userStatus ? userStatus[0].toUpperCase() + userStatus.slice(1) : 'User'" 
                                        v-model="userSelected"
                                        hide-details="auto"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-btn-toggle
                                        v-model="userStatus"
                                        >
                                            <v-btn value="worker">
                                                Worker
                                            </v-btn>
                                            <v-btn value="supervisor">
                                                Supervisor
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <BarChart
                        v-if="loaded && (dataBar == 'urgency' || dataBar == 'source' || dataBar == 'type' || dataBar == 'scope' || dataBar == 'costcenter' )"
                        :height="200"
                        :chartdata="typeChart"
                        :options="chartOptions" />
                        <LineChart
                        v-if="loaded && (dataBar == 'cost' || dataBar == 'duration' )"
                        :height="200"
                        :chartdata="typeChart"
                        :options="chartOptions" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import BarChart from '@/components/chart/bar'
import LineChart from '@/components/chart/line'
	export default {
        components: {
            BarChart,
            LineChart
        },
        
		data() {
            return {
                loaded: false,
                dataBar: 'urgency',
                assetList: [],
                userList: [],
                userStatus: null,
                assetSelected: null,
                userSelected: null,
                assetLoading: false,
                workOrderList: [],
                orderChart:[],
                filterDataChart: {
                    frequency: 'monthly',
                    'start-date': '2021-01-01',
                    'end-date':'0000-00-00',
                },
                chartOptions: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Urgency'
                    },
                    responsive: true,
                    scales: {
                    }
                },
                frequencyOption: [
                    {label: 'Daily', value: 'daily'}, 
                    {label: 'Weekly', value: 'weekly'}, 
                    {label: 'Monthly', value: 'monthly'}, 
                    {label: 'Yearly', value: 'yearly'}
                ],
                frequencyOptionDate: [
                    {label: 'Daily', value: 'daily'}, 
                    {label: 'Weekly', value: 'weekly'}, 
                    {label: 'Monthly', value: 'monthly'}, 
                    {label: 'Yearly', value: 'yearly'}
                ],
                urgencyChart: {},
                dateRange: false,
                start_dateMenu: false,
                end_dateMenu: false,
            }
        },
        computed: {
            typeChart:function() {
                return this.urgencyChart;
            }
            
        },
        methods: {
            load() {
                this.getAssetList();
                this.getUserList();
                this.filterDataChart.frequency = 'monthly'
                this.filterDataChart['end-date'] = this.$moment().format('YYYY-MM-DD');
                this.loadWorkOrder();
            },
            loadWorkOrder() {
                if (this.dataBar == 'urgency' || this.dataBar == 'type' || this.dataBar == 'source' || this.dataBar == 'cost' || this.dataBar == 'status' || this.dataBar == 'costcenter' || this.dataBar == 'scope') {
                    this.getJsonChart(this.dataBar, this.filterDataChart.frequency, this.filterDataChart['end-date'], this.filterDataChart['start-date']).then((res) => {
                        this.urgencyChart = res;
                    });
                } else {
                    this.getJsonChart('open-done', this.filterDataChart.frequency, this.filterDataChart['end-date'], this.filterDataChart['start-date']).then((res) => {
                        this.urgencyChart = res;
                    });
                }
                
            },
            getAssetList() {
			    this.getAssets().then((res) => {
                    this.assetList = res
                    this.assetList.unshift({ name: 'All', id: null})
                });
            },
            getUserList() {
			    this.getUsers().then((res) => {
                    this.userList = res;
                    this.userList.unshift({ username: 'All', role: '', id: null})
                });
            },
			async getAssets() {
                try {
                    this.assetLoading = true
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.assetLoading = false;
                }
            },
			async getUsers() {
                try {
					const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getOrderChart() {
                try {
                    const res = await this.$api.statistics.orderRange( this.filterDataChart );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getJsonChart(category, frequency, end_date, start_date) {
                try {
                    this.loaded = false
                    let res;
                    if (this.dateRange) {
                        if (this.userStatus == 'worker') {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, 'end-date': end_date, 'start-date': start_date, worker: this.userSelected });
                        } else if (this.userStatus == 'supervisor') {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, 'end-date': end_date, 'start-date': start_date, supervisor: this.userSelected  });
                        } else {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, 'end-date': end_date, 'start-date': start_date,  asset: this.assetSelected });
                        }
					    res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, 'end-date': end_date, 'start-date': start_date });
                    } else {
                        if (this.userStatus == 'worker') {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, worker: this.userSelected });
                        } else if (this.userStatus == 'supervisor') {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, supervisor: this.userSelected  });
                        } else {
					        res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, asset: this.assetSelected });
                        }
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loaded = true
                }
			},
            openDateRange() {
                this.dateRange = !this.dateRange;
            },
            assetFilter (item, queryText, itemText) {
                if (item.name !== 'All') {
                    var data = item.name.toLowerCase() + ' ' + item.manual_tag.toLowerCase() + ' ' + item.id
                    var searchText = queryText.toLowerCase()
                    return data.indexOf(searchText) > -1 
                }
            }
        },
        mounted(){
            this.load();
        },
        watch: {
            'dataBar': function(newVal) {
                this.chartOptions.title.text = newVal.charAt(0).toUpperCase() + newVal.slice(1);
                this.loadWorkOrder();
            },
            filterDataChart: {
                handler(val){
                    this.loadWorkOrder();
                },
                deep: true
            },
            'dateRange': function(newVal) {
                this.loadWorkOrder();
            },
            'assetSelected': function(newVal) {
                this.loadWorkOrder();
            },
            'userSelected': function(newVal) {
                if (newVal == null) {
                    this.userStatus = null; 
                } else {
                    if (this.userStatus == null ) {
                        this.userStatus = 'worker'
                    }
                }
                this.loadWorkOrder();   
            }
        }
    }
</script>