<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Template</v-tab>
                    <v-tab v-if="this.input.id">Parameter</v-tab>
                    <v-tab v-if="this.input.id">Instruction</v-tab>
                    <v-tab v-if="this.input.id">Plan</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    label="Name" 
                                    v-model="input.name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-select
                                :items="statusOption" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Status" 
                                v-model="input.status"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="genericList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Generic" 
                                v-model="input.generic"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="workTypeOption" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Work Type" 
                                v-model="input.work_type"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="costCenterList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Cost Center" 
                                v-model="input.costcenter"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="scopeList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Scope" 
                                v-model="input.scope"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="urgency" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Urgency" 
                                v-model="input.urgency"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="userSupervisorList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Supervisor" 
                                v-model="input.supervisor"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="userWorkerList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Worker" 
                                v-model="input.worker"
                                ></v-select>
                            </v-col>
                        </v-row><v-row>
                            <v-col cols="12" md="12">
                                <label class="font-weight-bold">Images</label><br/>
                                <v-row>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_1 && !newimage_1" :src="input.image_1" :src_large="input.image_1" />
                                        <v-img class="ba-1" contain v-if="!input.image_1 && !newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_1" :src="newimage_1Preview" :src_large="newimage_1Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_1Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            label="Image 1"
                                            v-model="newimage_1"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_2 && !newimage_2" :src="input.image_2" :src_large="input.image_2" />
                                        <v-img class="ba-1" contain v-if="!input.image_2 && !newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_2" :src="newimage_2Preview" :src_large="newimage_2Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_2Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            label="Image 2"
                                            v-model="newimage_2"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_3 && !newimage_3" :src="input.image_3" :src_large="input.image_3" />
                                        <v-img class="ba-1" contain v-if="!input.image_3 && !newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_3" :src="newimage_3Preview" :src_large="newimage_3Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_3Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            label="Image 3"
                                            v-model="newimage_3"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_4 && !newimage_4" :src="input.image_4" :src_large="input.image_4" />
                                        <v-img class="ba-1" contain v-if="!input.image_4 && !newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_4" :src="newimage_4Preview" :src_large="newimage_4Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_4Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            label="Image 4"
                                            v-model="newimage_4"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col class="md-20">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_5 && !newimage_5" :src="input.image_5" :src_large="input.image_5" />
                                        <v-img class="ba-1" contain v-if="!input.image_5 && !newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_5" :src="newimage_5Preview" :src_large="newimage_5Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_5Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            label="Image 5"
                                            v-model="newimage_5"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workTemplateParameterTable v-on:updateworkTemplate="refreshworkTemplateTask" :workTemplateId="input.id" :genericId="input.generic"></workTemplateParameterTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workTemplateInstructionTable v-on:updateworkTemplate="refreshworkTemplateTask" :workTemplateId="input.id"></workTemplateInstructionTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workTemplatePlanTable v-on:updateworkTemplate="refreshworkTemplateTask" :workTemplateId="input.id" :globalPref="globalPref"></workTemplatePlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import workTemplateInstructionTable from '@/components/work/work-template-instruction-table.vue';
import workTemplateParameterTable from '@/components/work/work-template-parameter-table.vue';
import workTemplatePlanTable from '@/components/work/work-template-plan-table.vue';
import VueTimepicker from 'vue2-timepicker/src';
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
		  components: {
              workTemplateParameterTable,
              workTemplateInstructionTable,
              workTemplatePlanTable,
              VueTimepicker,
            wysiwyg
          },
          props: {
                id: Number,
                globalPref: Array,
          },
		data: () => ({
			success: false,
			loading: true,
            input:{
                id: '',
                name: '',
                description: '',
                status: 'DRAFT',
                worker: '',
                generic: '',
                supervisor: '',
                work_type: '',
                costcenter:'',
                scope: '',
                urgency: '',
                image_1: null,
                image_2: null,
                image_3: null,
                image_4: null,
                image_5: null,
            },
            newimage_1: null,
            newimage_2: null,
            newimage_3: null,
            newimage_4: null,
            newimage_5: null,
            newimage_1Preview: null,
            newimage_2Preview: null,
            newimage_3Preview: null,
            newimage_4Preview: null,
            newimage_5Preview: null,
            workTypeOption:[
                { value: "CORRECTIVE", name: "corrective (self-initiate)" },
                { value: "PREVENTIVE", name: "preventive (scheduled)" },
                { value: "REACTIVE", name: "reactive (respond to complaint)" }
            ],
            statusOption:[
                { value: "DRAFT", name: "Draft" },
                { value: "OPEN", name: "Open" },
            ],
            scope:[
                { value: "corrective", name: "corrective (self-initiate)" },
                { value: "preventive", name: "preventive (scheduled)" },
                { value: "reactive", name: "reactive (respond to complaint)" }
            ],
            urgency: [
                { value: 1, name: "1 : one" },
                { value: 2, name: "2 : two" },
                { value: 3, name: "3 : three" },
                { value: 4, name: "4 : four" }
            ],
            inputAssetPlan: [],
            inputRecurrence: [],
            recurranceTimeList:[],
            recurrenceLists: [],
            userSupervisorList: [],
            userWorkerList: [],
            scopeList: [],
            costCenterList: [],
            genericList: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
        computed: {
            userVerifier: function() {
                if (this.users.length > 0) {
                    if (this.input.checker == null) {
                        return (this.users);
                    } else {
                        return (this.users.filter(users => users.id !== this.input.checker));
                    }
                }
            },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getworkTemplate().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.status = res.status;
                        this.input.description = res.description;
                        this.input.work_type = res.work_type;
                        this.input.scope = res.scope;
                        this.input.category = res.category;
                        this.input.urgency = res.urgency;
                        this.input.supervisor = res.supervisor;
                        this.input.worker = res.worker;
                        this.input.costcenter = res.costcenter;
                        this.input.generic = res.generic;
                        this.input.image_1 = res.image_1;
                        this.input.image_2 = res.image_2;
                        this.input.image_3 = res.image_3;
                        this.input.image_4 = res.image_4;
                        this.input.image_5 = res.image_5;

                        this.getAdditionalData();
                    });
                }
            },
			async getworkTemplate() {
                try {
                    this.loading = true
                    const res = await this.$api.workTemplate.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveworkTemplate().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workTemplateList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveworkTemplate() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    formData.append("status", this.input.status);
                    formData.append("scope", this.input.scope);
                    formData.append("work_type", this.input.work_type);
                    formData.append("urgency", this.input.urgency);
                    formData.append("generic", this.input.generic);
                    formData.append("costcenter", this.input.costcenter);
                    formData.append("supervisor", this.input.supervisor);
                    formData.append("worker", this.input.worker);
                    if (this.newimage_1) {
                        formData.append("image_1", this.newimage_1 );
                    }
                    if (this.newimage_2) {
                        formData.append("image_2", this.newimage_2 );
                    }
                    if (this.newimage_3) {
                        formData.append("image_3", this.newimage_3 );
                    }
                    if (this.newimage_4) {
                        formData.append("image_4", this.newimage_4 );
                    }
                    if (this.newimage_5) {
                        formData.append("image_5", this.newimage_5 );
                    }
                    if (this.input.id) {
                        res = await this.$api.workTemplate.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.workTemplate.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workTemplateList' })
            },
             getAdditionalData() {
                this.getSupervisors().then(res => this.userSupervisorList = res);
                this.getWorkers().then(res => this.userWorkerList = res);
                this.getWorkScope().then(res => this.scopeList = res);
                this.getCostCenterList().then(res => this.costCenterList = res);
                this.getGenerics().then(res => this.genericList = res);
            },
			async getCostCenterList() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenerics() {
                try {
                    const res = await this.$api.assetGeneric.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getSupervisors() {
                try {
                    const res = await this.$api.user.search({is_supervisor_candidate:true});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getWorkers() {
                try {
                    const res = await this.$api.user.search({is_worker_candidate:true});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getWorkScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRecurranceTimebyworkTemplate() {
                try {
                    const res = await this.$api.recurrenceTime.search({ workTemplate: this.input.id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getRecurName(id) {
                if ( this.recurrenceLists.length !== 0 && id ) {
                    return this.recurrenceLists.find(recurrance => recurrance.id === id).name;
                }
            },
            refreshworkTemplateTask() {
                this.form();
                this.getAdditionalData();
            }
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.valid = true;
		},
	}
</script>