<template>
	<div class="pa-5">
		<v-form @submit.prevent="filter()">
			<v-text-field
				label="Name" 
				v-model="filterFields.name"
			></v-text-field>
			<v-autocomplete ref="internal" 
				v-model="filterFields.system"
				cache-items
				hide-no-data
				:items="systemListSelect"
				name="item"
				item-value="id"
				label="System" 
				item-text="name">
			</v-autocomplete>
			<v-autocomplete ref="internal" 
				v-model="filterFields.generic"
				cache-items
				hide-no-data
				:items="genericListSelect"
				name="item"
				item-value="id"
				label="Generic" 
				item-text="name">
			</v-autocomplete>
				<v-autocomplete
				v-model="filterFields.location"
				:items="locationListSelect"
				label="Location"
				item-text="name"
				item-value="id"
				>
				<template v-slot:selection="data">
					{{ data.item.name }}
				</template>
				<template v-slot:item="data">
					<template>
						<v-list-item-content>
						<v-list-item-title><div class="text-left">{{data.item.name}}</div></v-list-item-title>
						<v-list-item-subtitle><div class="text-left">{{getLocParentName(data.item.parent)}}</div></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</template>
			</v-autocomplete>
            <v-select
            :items="criticalitySelect" 
            item-value="value"
            name="item"
            item-text="text"
            label="Criticality" 
            v-model="filterFields.criticality"
            ></v-select>
            <v-select
            :items="isActiveSelect" 
            item-value="value"
            name="item"
            item-text="text"
            label="Status" 
            v-model="filterFields.is_active"
            ></v-select>
            <v-select
            :items="qrScanSelect" 
            item-value="value"
            name="item"
            item-text="text"
            label="Qr Scan Required" 
            v-model="filterFields.qr_scan_required"
            ></v-select>
			<v-text-field
				label="Purchase Cost" 
				v-model="filterFields.purchase_cost"
			></v-text-field>
			<v-text-field
				label="Manufacturer / Supplier" 
				v-model="filterFields.manufacturer"
			></v-text-field>
			<v-text-field
				label="Manufacturer Serial Number" 
				v-model="filterFields.manufacturer_tag"
			></v-text-field>
			<v-text-field
				label="Asset Tag" 
				v-model="filterFields.manual_tag"
			></v-text-field>
			<v-text-field
				label="Model" 
				v-model="filterFields.model"
			></v-text-field>
			<v-text-field
				label="Person In Charge" 
				v-model="filterFields.person_in_charge"
			></v-text-field>
			<v-text-field
				label="Capacity Min" 
				v-model="filterFields.capacity_min"
			></v-text-field>
			<v-text-field
				label="Capacity Max" 
				v-model="filterFields.capacity_max"
			></v-text-field>
			<v-text-field
				label="Consumption Min" 
				v-model="filterFields.consumption_min"
			></v-text-field>
			<v-text-field
				label="Consumption Max" 
				v-model="filterFields.consumption_max"
			></v-text-field>
			<v-menu
                ref="purchase_dateMenu"
                v-model="purchase_dateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filterFields.purchase_date"
                    label="Purchase Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="filterFields.purchase_date"
                no-title
                scrollable
                show-current
                >
                </v-date-picker>
            </v-menu>
			<v-menu
                ref="warranty_dateMenu"
                v-model="warranty_dateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filterFields.warranty_date"
                    label="Warranty Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="filterFields.warranty_date"
                no-title
                scrollable
                show-current
                >
                </v-date-picker>
            </v-menu>
			<v-btn color="primary" type="submit">Filter</v-btn>
		</v-form>
	</div>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
			genericListSelect:[],
			condition: [
				{
					name: 'All Condition',
					value: ''
				}, 
				{
					name: 'Good',
					value: 'good'
				}, 
				{
					name: 'Partial',
					value: 'partial'
				}, 
				{
					name: 'Bad',
					value: 'bad'
				}, 
				{
					name: 'Misc',
					value: 'misc'
				},
			],
			purchase_dateMenu: false,
            warranty_dateMenu: false,
			level1ListSelect: [],
			level2ListSelect: [],
			level3ListSelect: [],
			level4ListSelect: [],
			level5ListSelect: [],
			systemListSelect: [],
			locationListSelect: [],
            criticalitySelect:[
            { text: 'All', value: null, },
            { text: 'c1', value: 1, },
            { text: 'c2', value: 2 },
            { text: 'c3', value: 3 },
            ],
            isActiveSelect:[
            { text: 'All', value: null, },
            { text: 'Inactive', value: false },
            { text: 'Active', value: true },
            ],
            qrScanSelect:[
            { text: 'All', value: null, },
            { text: 'No', value: false },
            { text: 'Yes', value: true },
            ],
		}),
		props: {
			globalPref: {
				type: Array,
				required: false
			},
			filterFields: {
				type: Object,
			},
			genericList: {
				type: Array,
				required: false
			},
			level1List: {
				type: Array,
				required: false
			},
			level2List: {
				type: Array,
				required: false
			},
			level3List: {
				type: Array,
				required: false
			},
			level4List: {
				type: Array,
				required: false
			},
			level5List: {
				type: Array,
				required: false
			},
			locationList: {
				type: Array,
				required: false
			},
		},
		methods: {
			filter(event){
				this.$emit('filteredData', this.filterFields)
			},
			getSystemList() {
				this.getSystems().then(res => this.systemListSelect = res);
			},
			async getSystems() {
				try {
					this.loading = true
					const res = await this.$api.assetSystem.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            getLocParentName(id) {
                if ( this.locationList.length !== 0 && id ) {
                    return this.locationList.find(loc => loc.id === id).name;
                } else {
                    return;
                }
            },
		},
		mounted() {
			this.getSystemList();
			this.genericListSelect = this.genericList
			this.genericListSelect.unshift({
				id:'',
				name: 'All Base Assets',
			});
			this.level1ListSelect = this.level1List
			this.level2ListSelect = this.level2List
			this.level3ListSelect = this.level3List
			this.level4ListSelect = this.level4List
			this.level5ListSelect = this.level5List
			this.locationListSelect = this.locationList
			this.locationListSelect.unshift({
				id:'',
				name: 'All Location',
			});
			// this.level2ListSelect.unshift({
			// 	id:'',
			// 	name: 'All Location',
			// });
			// this.level3ListSelect.unshift({
			// 	id:'',
			// 	name: 'All Location',
			// });
			// this.level4ListSelect.unshift({
			// 	id:'',
			// 	name: 'All Location',
			// });
			// this.level5ListSelect.unshift({
			// 	id:'',
			// 	name: 'All Location',
			// });
		}
	}
</script>