<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workHeading,i) in workHeadingsbyChecklist" :key="i">
            <v-card-text>
                <v-row align="top">
                    <v-col cols="12" sm="3" md="6">
                        <v-text-field
                            label="Name"
                            v-model="workHeading.name"
                            hide-details="auto"
                            :rules="nameRules"
                            :counter="50"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            label="Own Code"
                            v-model="workHeading.own_code"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                         <v-switch inset v-model="workHeading.is_active" label="Status" readonly></v-switch>
                    </v-col>
                    <v-col  cols="12" sm="3" md="2" class="text-right">
                        <v-btn-toggle>
                            <v-btn small fab icon color="grey"  @click="edit(workHeading.id)">
                            <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" :disabled="workHeading.name.length > 50" @click="save(workHeading.id, workHeading.name, workHeading.own_code, workHeading.is_active, workHeading.description)">
                            <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" @click="remove(workHeading.id)" >
                            <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <wysiwyg
                            :content.sync="workHeading.description"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkHeading()">Add Sub Heading</v-btn>
        </div>
    </div>
    
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
        props: {
            parentHeadingId: {
                type: Number
            },
        },
        components: {
            wysiwyg
        },
		data() {
            return {
                id: [],
                loading: true,
                workHeadingsbyChecklist: [],
                detailList: [],
                additionalDataLoaded: false,
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 50) || 'Name must be less than 50 characters',
                ],
            }
        },
        computed: {
        },
        methods: {
            list() {
                if (this.parentHeadingId) {
                    this.getWorkHeadingsbyChecklist().then((res) => {
                        this.workHeadingsbyChecklist = res;
                        this.workHeadingsbyChecklist.forEach((item, index) => { 
                            Vue.set(item,'index', index);
                        })
                    });
                }
            },
			async getWorkHeadingsbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceHeading.search({ parent: this.parentHeadingId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkHeading() {
                // this.$router.push({ name: 'workHeadingForm', params: {parentHeadingId: this.parentHeadingId } })
                this.workHeadingsbyChecklist.push({id: '', name: '', own_code: 0, parent: this.parentHeadingId, description: '' });
            },
            save(id, name, own_code, is_active, description ) {
                this.saveWorkHeading(id, name, own_code, is_active, description).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkHeading(id, name, own_code, is_active, description) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.invoiceHeading.save( {id: id, name: name, own_code: own_code, is_active: is_active, parent: this.parentHeadingId, description: description});
                    } else {
                        res = await this.$api.invoiceHeading.new({ name: name, own_code: own_code, is_active: is_active, parent: this.parentHeadingId, description: description });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            edit(id) {
                this.$emit('editAnotherHeading', id)
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.invoiceHeading.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workHeadingsbyChecklist, index)
                }
            },
            getAdditionalData() {
                this.getDetails().then(res => this.detailList = res);
            },
            async getDetails() {
                try {
                    const res = await this.$api.invoiceDetail.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>