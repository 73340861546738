var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"list-workHeading",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5 mx-auto text-right"},[(_vm.filter)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","text":"","dark":""},on:{"click":function($event){return _vm.removeFilter()}}},[_c('v-icon',[_vm._v("mdi-window-close")]),_vm._v(" Remove filter ")],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"secondary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter ")],1)]}}]),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('tableFilter',{attrs:{"filterFields":_vm.filterData},on:{"filteredData":_vm.tableFilter}})],1)],1),_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.edit()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ")],1)],1),(this.workHeading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":this.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"single-expand":true,"expanded":_vm.expanded,"show-expand":"","items":this.sortedWorkHeading,"sort-by":['id'],"calculate-widths":"","sort-desc":[true]},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"cursor-pointer"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.depth)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getWorkHeadingName(item.parent))+" ")]),_c('td',{staticClass:"text-xs-center",staticStyle:{"width":"150px"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":"","fab":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","fab":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('td',{staticClass:"text-left"},[(item.description)?_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-information-outline")]):_vm._e()],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"background-color":"#f6f6f6"},attrs:{"colspan":headers.length}},[(item.description)?_c('div',{staticClass:"pa-4"},[_c('div',[_c('strong',[_vm._v("Description")])]),_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})]):_vm._e()])]}}],null,false,2708260612)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }