<template>
    <div>
        <v-card outlined class="mb-4" v-for="(assetCheck,i) in indexedAssetCheck" :key="i">
            <v-card-text>
                <div class="title mb-5 d-flex align-center">
                    <v-img class="mr-2" contain v-if="indexedAssetCheck.length > 0" :src="assetCheck.icongraphic || icongraphicPlaceholder" max-width="35" width="35" height="35"></v-img>
                    {{ assetCheck.asset_name }}
                </div>
                <!-- Checker -->
                <div v-if="checkProfile(assetCheck.checker, assetCheck.verifier) == 'checker'">
                    <div v-if="!assetCheck.checker_data_complete">
                        <v-alert type="info">
                        You can proceed to check now.
                        </v-alert>
                    </div>
                    <div v-if="assetCheck.checker_data_complete">
                        <v-alert type="success">
                        This asset is checked.
                        </v-alert>
                    </div>
                    <paramCheckTable v-if="assetCheck.id && additionalDataLoaded" 
                    :assetListFull="assetListFull" 
                    :baseParamList="baseParamList"
                    :masterParamList="masterParamList"
                    :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                    :checkverifyStatus="checkverifyStatus" 
                    :assetCheckId="assetCheck.id" 
                    :assetId="assetCheck.asset"
                    v-on:reloadAssetCheck="list"
                    >
                    </paramCheckTable>
                    <v-divider></v-divider>
                    <compCheckTable v-if="assetCheck.id && additionalDataLoaded" 
                    :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                    :componentList="componentList"
                    :checkverifyStatus="checkverifyStatus" 
                    :assetCheckId="assetCheck.id" 
                    :assetId="assetCheck.asset"
                    v-on:reloadAssetCheck="list">
                    </compCheckTable>
                </div>
                <!-- Verifier -->
                <div v-if="checkProfile(assetCheck.checker, assetCheck.verifier) == 'verifier'" >
                    <div v-if="!assetCheck.checker_data_complete">
                        <v-alert type="info">
                        This checklist is not fully checked yet.
                        </v-alert>
                    </div>
                    <div v-if="(assetCheck.checker_data_complete && !assetCheck.verifier_data_complete)">
                        <v-alert type="info">
                        This asset is checked and ready to be verified.
                        </v-alert>
                    </div>
                    <div v-if="(assetCheck.verifier_data_complete)" >
                        <v-alert type="success">
                        This asset is checked and verified.
                        </v-alert>
                    </div>
                    <div v-if="(checkverifyStatus == 'CHECKED' || checkverifyStatus == 'VERIFIED')" >
                        <paramCheckTable v-if="assetCheck.id && additionalDataLoaded" 
                        :assetListFull="assetListFull" 
                        :baseParamList="baseParamList"
                        :masterParamList="masterParamList"
                        :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                        :checkverifyStatus="checkverifyStatus" 
                        :assetCheckId="assetCheck.id" 
                        :assetId="assetCheck.asset"
                        v-on:reloadAssetCheck="list">
                        </paramCheckTable>
                        <v-divider></v-divider>
                        <compCheckTable v-if="assetCheck.id && additionalDataLoaded" 
                        :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                        :checkverifyStatus="checkverifyStatus" 
                        :componentList="componentList"
                        :assetCheckId="assetCheck.id" 
                        :assetId="assetCheck.asset"
                        v-on:reloadAssetCheck="list">
                        </compCheckTable>
                    </div>
                </div>
                <!-- Super -->
                <div v-if="checkProfile(assetCheck.checker, assetCheck.verifier) == 'super'" >
                    <div v-if="!assetCheck.checker_data_complete">
                        <v-alert type="info">
                        This checklist is not fully checked yet.
                        </v-alert>
                    </div>
                    <div v-if="(assetCheck.checker_data_complete && !assetCheck.verifier_data_complete)">
                        <v-alert type="info">
                        This asset is checked and ready to be verified.
                        </v-alert>
                    </div>
                    <div v-if="(assetCheck.verifier_data_complete)" >
                        <v-alert type="success">
                        This asset is checked and verified.
                        </v-alert>
                    </div>
                    <div>
                        <paramCheckTable v-if="assetCheck.id && additionalDataLoaded" 
                        :assetListFull="assetListFull" 
                        :baseParamList="baseParamList"
                        :masterParamList="masterParamList"
                        :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                        :checkverifyStatus="checkverifyStatus" 
                        :assetCheckId="assetCheck.id" 
                        :assetId="assetCheck.asset"
                        v-on:reloadAssetCheck="list">
                        </paramCheckTable>
                        <v-divider></v-divider>
                        <compCheckTable v-if="assetCheck.id" 
                        :profileType="checkProfile(assetCheck.checker, assetCheck.verifier)" 
                        :checkverifyStatus="checkverifyStatus" 
                        :componentList="componentList"
                        :assetCheckId="assetCheck.id" 
                        :assetId="assetCheck.asset"
                        v-on:reloadAssetCheck="list">
                        </compCheckTable>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
    
</template>
<script>
import Vue from 'vue'
import compCheckTable from '@/components/check/comp-check-list'
import paramCheckTable from '@/components/check/param-check-list'
	export default {
        props: {
            checklistId: {
                type: Number
            },
            profileId: {},
            checkverifyStatus: String,
        },
        components: {
            compCheckTable,
            paramCheckTable
        },
		data() {
            return {
                headersAssetCheck: [
                {
                    text: 'Asset',
                    align: 'left',
                    sortable: true,
                    value: 'asset',
                },
                { text: 'Checker', value: 'checker', width: '200px' },
                { text: 'Verifier', value: 'verifier', width: '200px' },
                { text: 'Order', value: 'sort_order', width:'100px' },
                { text: ''}
                ],
                id: [],
                loading: true,
                assetChecksbyChecklist: [],
                users:[],
                assetListFull: [],
                masterParamList: [],
                baseParamList: [],
                componentList: [],
                additionalDataLoaded: false,
            }
        },
        computed: {
            indexedAssetCheck () {
                if (this.profileId == 0) {
                    return this.assetChecksbyChecklist.map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }else {
                    return this.assetChecksbyChecklist.filter((checklist => checklist.checker == this.profileId || checklist.verifier == this.profileId) ).map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }
            },
        },
        methods: {
            list() {
                if (this.checklistId) {
                    this.getAssetChecksbyChecklist().then((res) => {
                        this.assetChecksbyChecklist = res;
                        res.forEach((item) => { 
                            this.getAssetName(item.asset).then((response) => {
                                Vue.set(item,'asset_name', response.name);
                                Vue.set(item,'icongraphic', response.icongraphic);
                            })
                        })
                    });
                }
            },
			async getAssetChecksbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.assetCheck.search({ checklist: this.checklistId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getAssetName(asset_id) {
                try {
                    this.loading = true
                    const res = await this.$api.assetAsset.get(asset_id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            }, 
            getAdditionalData() {
			    this.getUsers().then(res => this.users = res);
			    this.getAssets().then(res => this.assetListFull = res);
			    this.getMastParam().then(res => this.masterParamList = res);
                this.getBaseParameters().then(res => this.baseParamList = res);
			    this.getCompsbyAsset().then(res => this.componentList = res);
                this.additionalDataLoaded = true;
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getMastParam() {
                try {
                    const res = await this.$api.mastParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseParameters() {
                try {
                    const res = await this.$api.assetGenericParameter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getCompsbyAsset() {
                try {
                    const res = await this.$api.component.search({ asset: this.assetId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.users.length !== 0 && user_id ) {
                    return this.users.find(user => user.id === user_id).username;
                }
            }, 
            newAssetCheck() {
                // this.$router.push({ name: 'assetCheckForm', params: {checklistId: this.checklistId } })
                this.assetChecksbyChecklist.push({id: '', asset: '', checklist: this.checklistId, asset: '', checker: '', sort_order: '' });
            },
            edit (id) {
                this.$router.push({ name: 'assetCheckForm', params: { id: id, checklistId: this.checklistId } })
            },
            save(id, asset, checker, verifier, sort_order) {
                this.saveAssetCheck(id, asset, checker, verifier, sort_order).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveAssetCheck(id, asset, checker, verifier, sort_order) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.assetCheck.save( {id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checklist: this.checklistId });
                    } else {
                        res = await this.$api.assetCheck.new({id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checklist: this.checklistId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.assetCheck.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.assetChecksbyChecklist, index)
                }
            },
            checkProfile(checker, verifier) {
                if (this.profileId == checker) {
                    return 'checker'
                }
                if (this.profileId == verifier) {
                    return 'verifier'
                }
                if (parseInt(this.profileId) == 0) {
                    return 'super'
                }
            }
        },
        mounted(){
            this.getAdditionalData();
            this.list();
        },
        watch: {
            profileId(id) {
                this.checkProfile();
                this.list();
            }
        }
    }
</script>