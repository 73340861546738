import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import router from '../router'
Vue.use(Vuex)
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

export default new Vuex.Store({
	namespaced: true, // Add this here
	state: {
		isLoggedIn: false,
		jwt: localStorage.getItem('wormsToken'),
		refreshToken: localStorage.getItem('wormsRefreshToken'),
		profileId: null,
		profileRole: '',
		loadingIndicatorCurrent: 0,
		loadingIndicatorTotal:0,
		isLoading: false,
		tokenError: false,
		apiError: false,
		apiErrorText: '',
		expiredLocation: '',
		endpoints: {
		  obtainJWT: process.env.VUE_APP_API+'/api/token/',
		  refreshJWT: process.env.VUE_APP_API+'/api/token/refresh/'
		},
		osKey: false,
		textLabel: {},
		urgencyList: [],
	},

	getters: {
		jwt: state => {
			return state.jwt
		},
		tokenError: state => {
			return state.tokenError
		},
		loadingIndicatorCurrent: state => {
			return state.loadingIndicatorCurrent
		},
		loadingIndicatorTotal: state => {
			return state.loadingIndicatorTotal
		},
		isLoading: state => {
			return state.isLoading
		},
		apiError: state => {
			return state.apiError
		},
		apiErrorText: state => {
			return state.apiErrorText
		},
		expiredLocation: state => {
			return state.expiredLocation
		},
		isLoggedIn: state => {
			if (state.jwt) {
				return true
			} else {
				return false
			}
		},
		profileId:state => {
			return state.profileId
		},
		profileRole:state => {
			return state.profileRole
		},
		osKey:state => {
			return state.osKey
		},
		textLabel:state => {
			return state.textLabel
		},
		urgencyList:state => {
			return state.urgencyList
		}
	},

	mutations: {
		// To update token / renewable token
		updateToken(state, [newToken, refreshToken]){
		  localStorage.setItem('wormsToken', newToken);
		  state.jwt = newToken;
		  if (refreshToken !== null) {
			localStorage.setItem('wormsRefreshToken', refreshToken);
			state.refreshToken = refreshToken;
		  }
		  state.isLoggedIn = true;
		},
		// To remove token
		removeToken(state){
		  localStorage.removeItem('wormsToken');
		  localStorage.removeItem('wormsRefreshToken');
		  state.jwt = null;
		  state.refreshToken = null;
		  state.isLoggedIn = false;
		  state.tokenError = false;
		  state.profileId = null;
		  state.profileRole = null;
		  state.osKey = false;
		},
		isLoading(state, bool){
			state.isLoading = bool;
		},
		updateLoadingCurrent(state, int){
			state.loadingIndicatorCurrent = int;
		},
		updateLoadingTotal(state, int){
			state.loadingIndicatorTotal = int;
		},
		// To display token error
		tokenError(state){
		  state.tokenError = true;
		},
		// Api Error
		apiError(state, error){
		  state.apiErrorText = error;
		  if (error !== '') {
			state.apiError = true;
		  } else {
		  	state.apiError = false;
		  }
		},
		setProfileId(state, profile_id){
			state.profileId = profile_id
		},
		setProfileRole(state, profile_role){
			state.profileRole = profile_role
		},
		expiredLocation(state, loc){
			state.expiredLocation = loc
		},
		saveOneSignal(state, val){
			state.osKey = val
		},
		setGlobalpref(state, globalpref) {
			state.urgencyList = [
                { value: 1, name: globalpref.find(pref => pref.identifier == 'order__urgency_1_label').value },
                { value: 2, name: globalpref.find(pref => pref.identifier == 'order__urgency_2_label').value },
                { value: 3, name: globalpref.find(pref => pref.identifier == 'order__urgency_3_label').value },
			];
			state.textLabel.location__depth_0_label = globalpref.find(pref => pref.identifier == 'location__depth_0_label').value
			state.textLabel.location__depth_1_label = globalpref.find(pref => pref.identifier == 'location__depth_1_label').value
			state.textLabel.location__depth_2_label = globalpref.find(pref => pref.identifier == 'location__depth_2_label').value
			state.textLabel.location__depth_3_label = globalpref.find(pref => pref.identifier == 'location__depth_3_label').value
			state.textLabel.location__depth_4_label = globalpref.find(pref => pref.identifier == 'location__depth_4_label').value
			state.textLabel.location__depth_5_label = globalpref.find(pref => pref.identifier == 'location__depth_5_label').value
			state.textLabel.location__depth_6_label = globalpref.find(pref => pref.identifier == 'location__depth_6_label').value
		}
	},

	actions: {
		// To get token
		obtainToken({commit}, [username, password]){
		  const payload = {
			username: username,
			password: password
		  }
		return axios.post(this.state.endpoints.obtainJWT, payload)
			.then((response)=>{
				axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.access
				this.commit('updateToken', ['Bearer '+response.data.access, response.data.refresh]);
				return response;
			  })
			.catch((error)=>{
				return error.response
			  })
		},
		changeLoading({commit}, bool) {
			this.commit('isLoading', bool);
		},
		changeLoadingCurrent({commit}, int) {
			this.commit('updateLoadingCurrent', int);
		},
		changeLoadingTotal({commit}, int) {
			this.commit('updateLoadingTotal', int);
		},
		// To show token error
		tokenError() {
			this.commit('tokenError');
		},
		apiError({commit}, apiErrorText) {
			this.commit('apiError', apiErrorText);
		},
		// To renew token
		refreshToken(){
		  const payload = {
			refresh: this.state.refreshToken
		  }
			axios.post(this.state.endpoints.refreshJWT, payload)
			  .then((response)=>{
				  console.log('renew and refresh')
				  axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.access
				  this.commit('updateToken', ['Bearer '+response.data.access, null])
				  setTimeout(function(){ router.go() }, 500);
				})
			  .catch((error)=>{
				  if (error.response.status == '401') {
					  console.log('logout and go to home')
					  this.commit('removeToken');
					  router.push('/login');
				  }
			  })
		},
		logout() {
			this.commit('removeToken');
			router.push('/login');
			setTimeout(() => window.location.reload(), 500 );
		},
		// To inspect token for renewal or relogin
		inspectToken(){
			const token = this.state.jwt;
			if(token){
			  const decoded = jwt_decode(token);
			  const exp = decoded.exp
			  	if(((Date.now()/1000) - exp) < 100 ){
					if(!this.state.refreshToken) {
						this.commit('expiredLocation', router.currentRoute);
						this.commit('removeToken');
						router.push('/login');
						setTimeout(() => window.location.reload(), 500 );
					}
				} else if(((Date.now()/1000) - exp) > 100 ){
					if(!this.state.refreshToken) {
						this.commit('expiredLocation', router.currentRoute);
						this.commit('removeToken');
						router.push('/login');
						setTimeout(() => window.location.reload(), 500 );
					} else {
						this.dispatch('refreshToken');
					}
				} else if(((Date.now()/1000) - exp) > 2000 ) {
					this.commit('expiredLocation', router.currentRoute);
					this.commit('removeToken');
					router.push('/login');
					setTimeout(() => window.location.reload(), 500 );
				}
				return false;
			}
		  },
		  setProfileId({commit}, profile_id){
			this.commit('setProfileId', profile_id);
		  },
		  setProfileRole({commit}, role){
			this.commit('setProfileRole', role);
		  },
		  expiredLocation({commit}, loc){
			this.commit('expiredLocation', loc);
		  },
		  saveOneSignal({commit}){
			this.commit('saveOneSignal', true);
		  },
		  setGlobalPref({commit}, globalpref) {
			//   console.log(globalpref);
			  this.commit('setGlobalpref', globalpref);
		  },
	}
});