<template>
    <v-container fluid class="list-workHeading">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                    <v-icon>mdi-window-close</v-icon>
                    Remove filter
                </v-btn>
                <v-dialog v-model="filterDialog" max-width="400">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" dark v-on="on" class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </template>
                    <v-card>
                        <tableFilter :filterFields="filterData" v-on:filteredData="tableFilter"></tableFilter>
                    </v-card>
                </v-dialog>
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="this.workHeading"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :single-expand="true"
            :expanded.sync="expanded" 
            show-expand      
            :items="this.sortedWorkHeading"
            :sort-by="['id']"
            calculate-widths=""
            :sort-desc="[true]"
            class="elevation-1"
            >
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr class="cursor-pointer">
                <td class="text-left">{{item.id}}</td>
                <td class="text-left">
                    {{item.name}} 
                </td>
                <td class="text-left">
                    {{item.depth}} 
                </td>
                <td class="text-left">
                    {{getWorkHeadingName(item.parent)}} 
                </td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
                <td class="text-left">
                    <v-icon v-if="item.description" @click="expand(!isExpanded)">mdi-information-outline</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="background-color:#f6f6f6">
                    <div v-if="item.description" class="pa-4">
                        <div ><strong>Description</strong></div>
                        <div v-html="item.description"></div>
                    </div>
                </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterHeading.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
            headers: [
            { text: 'ID', value: 'id', width: 100 },
            { text: 'Name', value: 'name' },
            { text: 'Depth', value: 'depth' },
            { text: 'Parent', value: 'parent' },
            { text: ''},
            { text: '', value: 'data-table-expand' }
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			workHeading: [],
			loading: true,
            expanded: [],

            filter: false,
            filterDialog: false,
            filterData: {},
            filteredWorkHeading: [],
		}),
        computed: {
            sortedWorkHeading () {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredWorkHeading;
                } else {
                    return this.workHeading;
                }
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getWorkHeading().then((res) => {
                    this.workHeading = res;
                    // this.pagination.next = res.next;
                    // this.pagination.count = res.count;
                    // this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getWorkHeading() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceHeading.search({depth: 0});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getWorkHeadingName(id) {
                if ( this.workHeading.length !== 0 && id ) {
                    return this.workHeading.find(heading => heading.id === id).name;
                }
            },
            edit(id) {
                this.$router.push({ name: 'workHeadingForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.invoiceHeading.delete(id).then(res => this.list() );
                    }
                })
            },
            // Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
			        this.$api.invoiceHeading.search(value).then((res) => {
                        this.filteredWorkHeading = res.data;
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
            },
		},
		mounted () {
            this.list();
		},
	}
</script>