<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workTemplateInstruction,i) in indexedWorkTemplateInstruction" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="8" md="10">
                        <v-text-field
                            label="Instruction Name" 
                            v-model="workTemplateInstruction.name"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Sort Order" 
                            v-model="workTemplateInstruction.sort_order"
                            type="number"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mb-2 mt-2"></v-divider>
                <div class="subtitle">REQUIREMENTS</div>
                <v-row>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="workTemplateInstruction.require_note"
                            label="Note"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="workTemplateInstruction.require_image"
                            label="Image"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workTemplateInstruction.index, workTemplateInstruction.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workTemplateInstruction.id, workTemplateInstruction.text, workTemplateInstruction.sort_order, workTemplateInstruction.require_note, workTemplateInstruction.require_image )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkTemplateInstruction()">Add Task</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            workTemplateId: {
                type: Number
            },
        },
		data() {
            return {
                id: [],
                loading: true,
                workTemplateInstructionsbyTemplate: [],
            }
        },
        computed: {
            indexedWorkTemplateInstruction () {
                return this.workTemplateInstructionsbyTemplate.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workTemplateId) {
                    this.getWorkTemplateInstructionsbyTemplate().then((res) => {
                        this.workTemplateInstructionsbyTemplate = res;
                    });
                }
            },
			async getWorkTemplateInstructionsbyTemplate() {
                try {
                    this.loading = true
                    const res = await this.$api.workTemplateInstruction.search({ template: this.workTemplateId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkTemplateInstruction() {
                // this.$router.push({ name: 'workTemplateInstructionForm', params: {workTemplateId: this.workTemplateId } })
                this.workTemplateInstructionsbyTemplate.push({id: '', text: '', template: this.workTemplateId, sort_order: 1,require_note: false, require_image: false });
            },
            save(id, text, sort_order,  require_note, require_image ) {
                this.saveWorkTemplateInstruction(id, text, sort_order, require_note, require_image).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkTemplateInstruction(id, text, sort_order, require_note, require_image) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workTemplateInstruction.save( {id: id, text: text, sort_order: sort_order, require_note: require_note, require_image:require_image, template: this.workTemplateId });
                    } else {
                        res = await this.$api.workTemplateInstruction.new({text: text, sort_order: sort_order, require_note: require_note, require_image:require_image, template: this.workTemplateId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workTemplateInstruction.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workTemplateInstructionsbyTemplate, index)
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>