<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workTemplateParameter,i) in indexedWorkTemplateParameter" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="4" sm="4" md="4">
                        <v-select
                        :items="genericParamList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Parameter" 
                        v-model="workTemplateParameter.generic_parameter"
                        hide-details="auto"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workTemplateParameter.index, workTemplateParameter.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workTemplateParameter.id, workTemplateParameter.generic_parameter )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkTemplateParameter()">Add Task</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            workTemplateId: {
                type: Number
            },
            genericId: {
                type: Number
            },
        },
		data() {
            return {
                id: [],
                loading: true,
                workTemplateParametersbyTemplate: [],
                genericParamList: [],
            }
        },
        computed: {
            indexedWorkTemplateParameter () {
                return this.workTemplateParametersbyTemplate.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workTemplateId) {
                    this.getWorkTemplateParametersbyTemplate().then((res) => {
                        this.workTemplateParametersbyTemplate = res;
                    });
                }
            },
			async getWorkTemplateParametersbyTemplate() {
                try {
                    this.loading = true
                    const res = await this.$api.workTemplateParameter.search({ template: this.workTemplateId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkTemplateParameter() {
                // this.$router.push({ name: 'workTemplateParameterForm', params: {workTemplateId: this.workTemplateId } })
                this.workTemplateParametersbyTemplate.push({id: '', generic_parameter: '' });
            },
            save(id, generic_parameter ) {
                this.saveWorkTemplateParameter(id, generic_parameter).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkTemplateParameter(id, generic_parameter) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workTemplateParameter.save( {id: id, generic_parameter: generic_parameter, template: this.workTemplateId });
                    } else {
                        res = await this.$api.workTemplateParameter.new({generic_parameter: generic_parameter, template: this.workTemplateId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workTemplateParameter.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workTemplateParametersbyTemplate, index)
                }
            },
            getAdditionalData() {
			    this.getAvailableParameter().then(res => this.genericParamList = res);
            },
			async getAvailableParameter() {
                try {
                    const res = await this.$api.assetGenericParameter.search({ generic: this.genericId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.getAdditionalData();
            this.list();
        }
    }
</script>