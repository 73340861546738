<template>
    <div>
        <v-card outlined class="mb-4" >
            <v-card-text>
                <div v-if="workDetailCostCentersbyDetail.length == 0">
            
                </div>
                <div v-if="workDetailCostCentersbyDetail.length > 0">
                    <!-- <v-row>
                        <v-col>
                            <v-treeview
                                v-if="headingLoaded"
                                :items="headingList"
                                :active="detailSelected"
                                v-model="detailSelected"
                                :filter="headingFilter"
                                :open-all="hasParent"
                                hoverable
                                activatable
                                dense
                                selection-type="independent"
                                expand-icon="mdi-chevron-down"
                                on-icon="mdi-bookmark"
                                off-icon="mdi-bookmark-outline"
                                @update:active="changeHeading"
                            >
                                <template v-slot:label="{ item }">
                                    {{item.code}}: {{item.name}} 
                                </template>
                            </v-treeview> 
                    </v-col>
                    <v-col
                        class="d-flex text-left"
                    >
                        <v-scroll-y-transition mode="out-in">
                        <div
                            v-if="!availableDetail"
                            class="title grey--text text--lighten-1 font-weight-light"
                            style="align-self: center;"
                        >
                            Select a Heading
                        </div>
                        <v-card
                            v-else
                            class="pt-6"
                            flat
                            width="100%"
                        >
                            <v-list
                            :ripple="false"
                            flat
                            subheader
                            >
                            <v-list-item-group
                                :ripple="false"
                                active-class=""
                            >
                            <v-list-item :ripple="false" v-for="(detail,index) in availableDetail" v-bind:key="index">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                    <v-list-item-title>{{detail.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{detail.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div class="d-flex">
                                            <v-text-field
                                                label="Cost/Unit" 
                                                
                                                type="number"
                                            ></v-text-field>
                                            <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove()">mdi-delete</v-icon></v-btn>
                                            <v-btn color="primary" @click="save(workDetailCostCenter.id, workDetailCostCenter.detail, workDetailCostCenter.unit_cost)"><v-icon>mdi-content-save</v-icon></v-btn>
                                        </div>
                                    </v-list-item-action>
                                </template>
                                </v-list-item>
                            </v-list-item-group>
                            </v-list>
                        </v-card>
                        </v-scroll-y-transition>
                    </v-col>
                    </v-row> -->
                    <v-expansion-panels flat accordion>
                        <v-expansion-panel
                            v-for="(workDetailCostCenter,i) in indexedWorkDetailCostCenter"
                            :key="i"
                        >
                            <v-expansion-panel-header>
                                <v-row no-gutters align="center">
                                    <v-col cols="5" sm="5" md="8" class="pa-0 pr-3">
                                        {{getDetailFullName(workDetailCostCenter.detail)}}
                                    </v-col>
                                    <v-col cols="3" sm="3" md="2" class="pa-0 pr-3">
                                        <v-text-field
                                            label="Cost/Unit" 
                                            v-model="workDetailCostCenter.unit_cost"
                                            type="number"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="2" class="pa-0 pr-3 text-right">
                                        <!-- <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workDetailCostCenter.index, workDetailCostCenter.id)">mdi-delete</v-icon></v-btn> -->
                                        <v-btn color="primary" @click="save(workDetailCostCenter.id, workDetailCostCenter.detail, workDetailCostCenter.unit_cost)"><v-icon>mdi-content-save</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div><strong>Description:</strong></div>
                                <div v-html="getDetailDesc(workDetailCostCenter.detail)"></div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-card-text>
        </v-card>
        <!-- <div>
            <v-btn class="mr-4 mt-2" @click="newWorkDetailCostCenter()">Add Cost</v-btn>
        </div> -->
    </div>
    
</template>
<script>
import Vue from 'vue'
import HeadingDialog from '@/components/work/work-order-detail-dialog.vue'
	export default {
        props: {
            costCenterId: {
                type: Number
            },
        },
        components:{
            HeadingDialog,
        },
		data() {
            return {
                id: [],
                loading: true,
                workDetailCostCentersbyDetail: [],
                users:[],
                detailDialog: false,
                workDetailList: [],
                headingList: [],
                headingLoaded:false,
                // headingList:[],
                availableDetail:null,
                chosenDetail: null,
                detailSelected: null,
                hasParent: false,
            }
        },
        computed: {
            headingFilter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            },
            indexedWorkDetailCostCenter () {
                return this.workDetailCostCentersbyDetail.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.costCenterId) {
                    this.getWorkDetailCostCentersbyCostCenter().then((res) => {
                        this.workDetailCostCentersbyDetail = res;
                        this.workDetailCostCentersbyDetail.forEach((item, index) => { 
                            Vue.set(item,'detailDialog', false);
                        })  
                    });
                }
                this.getAdditionalData();
            },
			async getWorkDetailCostCentersbyCostCenter() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceDetailCostCenter.search({ costcenter: this.costCenterId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkDetailCostCenter() {
                // this.$router.push({ name: 'workDetailCostCenterForm', params: {costCenterId: this.costCenterId} })
                this.workDetailCostCentersbyDetail.push({id: '' });
            },
            save(id, costcenter, unit_cost) {
                this.saveWorkDetailCostCenter(id, costcenter, unit_cost).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkDetailCostCenter(id, detail, unit_cost) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.invoiceDetailCostCenter.save( {id: id, detail: detail, unit_cost:unit_cost, costcenter: this.costCenterId});
                    } else {
                        res = await this.$api.invoiceDetailCostCenter.new({detail: detail, unit_cost:unit_cost, costcenter: this.costCenterId});
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.invoiceDetailCostCenter.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workDetailCostCentersbyDetail, index)
                }
            },
            getAdditionalData() {
			    this.getDetailList().then((res) => {this.workDetailList = res;});
                this.getWorkHeadings().then((res) => {
                    this.headingList = res;
                });
                this.headingLoaded = true;
            },
            changeHeading(e) {
                this.detailSelected = e;
                this.availableDetail = [];
                this.getDetailFromHeading(this.detailSelected[0]).then((res) => {
                    this.availableDetail = res;
                });
            },
            async getDetailFromHeading(id) {
                try {
                    const res = await this.$api.invoiceHeading.get(id);
                    return res.data.detail_set;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getDetailList() {
                try {
                    const res = await this.$api.invoiceDetail.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getWorkHeadings() {
                try {
                    const res = await this.$api.invoiceHeading.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeDetailValue({ value, detailIndex }) {
                Vue.set(this.workDetailCostCentersbyDetail[detailIndex],'detail', value);
                //Vue.set(this.workDetailCostCentersbyDetail[detailIndex],'detailLabel', res.heading_path);
            },
            getDetailCode(detail_id) {
                if ( this.workDetailList.length !== 0 && detail_id ) {
                    return this.workDetailList.find(detail => detail.id == detail_id).code;
                }
            },
            getDetailFullName(detail_id) {
                if ( this.workDetailList.length !== 0 && detail_id ) {
                    var currentDetail;
                    currentDetail = this.workDetailList.find(detail => detail.id == detail_id)
                    return currentDetail.code + ': ' + currentDetail.heading_path + ' ' + currentDetail.name;
                }
            },
            getDetailDesc(detail_id) {
                if ( this.workDetailList.length !== 0 && detail_id ) {
                    var currentDetail;
                    currentDetail = this.workDetailList.find(detail => detail.id == detail_id)
                    return currentDetail.description;
                }
            }
        },
        mounted(){
            this.list();
        },
        watch: {
            chosenDetail (val) {
                this.$emit('chosen', {value: val, detailIndex: this.detailIndex})
            },
        }
    }
</script>