import Vue from 'vue'
import Router from 'vue-router'
import home from '@/pages/dashboard/home'
import login from '@/pages/login'

// Asset
import assetSystemList from '@/pages/asset/system-list'
import assetSystemForm from '@/pages/asset/system-form'
import assetAssetList from '@/pages/asset/asset-list'
import assetAssetForm from '@/pages/asset/asset-form'
import assetGenericList from '@/pages/asset/generic-list'
import assetGenericForm from '@/pages/asset/generic-form'
// Unit
import unitMeasurementList from '@/pages/unit/measurement-list'
import unitMeasurementForm from '@/pages/unit/measurement-form'
import unitUnitList from '@/pages/unit/unit-list'
import unitUnitForm from '@/pages/unit/unit-form'

// Inspection

// Recurrence
import recurrenceList from '@/pages/recurrence-list'
import recurrenceForm from '@/pages/recurrence-form'

import checklistList from '@/pages/checklist-list'
import checklistForm from '@/pages/checklist-form'
import masterComponentList from '@/pages/master-component-list'
import masterComponentForm from '@/pages/master-component-form'
import componentList from '@/pages/component-list'
import componentForm from '@/pages/component-form'
import locationList from '@/pages/location-list'
import locationForm from '@/pages/location-form'
import teamList from '@/pages/team-list'
import teamForm from '@/pages/team-form'
import masterParameterList from '@/pages/master-parameter-list'
import masterParameterForm from '@/pages/master-parameter-form'
import userPreference from '@/pages/user-preference'
import globalPreference from '@/pages/global-preference'
import userList from '@/pages/user-list'
import userForm from '@/pages/user-form'
import assetPlanForm from '@/pages/asset-plan-form'
import checkplanList from '@/pages/checkplan-list'
import checkplanForm from '@/pages/checkplan-form'
// Work
import workOrderList from '@/pages/work/work-order-list'
import workOrderForm from '@/pages/work/work-order-form'
import workPlanList from '@/pages/work/work-plan-list'
import workPlanForm from '@/pages/work/work-plan-form'
import workTemplateList from '@/pages/work/work-template-list'
import workTemplateForm from '@/pages/work/work-template-form'
import workRecordList from '@/pages/work/work-record-list'
import workRecordForm from '@/pages/work/work-record-form'
import workScopeList from '@/pages/work/scope-list'
import workScopeForm from '@/pages/work/scope-form'
import workHeadingList from '@/pages/work/heading-list'
import workHeadingForm from '@/pages/work/heading-form'
import workCostCenterList from '@/pages/work/cost-center-list'
import workCostCenterForm from '@/pages/work/cost-center-form'
import workDetailList from '@/pages/work/detail-list'
import workDetailForm from '@/pages/work/detail-form'
import complaintComplaintList from '@/pages/work/complaint-list'
import complaintComplaintForm from '@/pages/work/complaint-form'

// Claim
import invoiceList from '@/pages/invoice/invoice-list'
import invoiceForm from '@/pages/invoice/invoice-form'
import makeInvoiceList from '@/pages/invoice/make-invoice-list'

import checkVerifyList from '@/pages/checking/check-verify-list'
import checkVerifyForm from '@/pages/checking/check-verify-form'

// Account
import profileForm from '@/pages/account/profile'
import verifyEmail from '@/pages/account/verify-email'
import register from '@/pages/account/register'
import verifyUser from '@/pages/account/verify-user'
import resetPassword from '@/pages/account/reset-password'

// Statistic
import orderStatistic from '@/pages/statistic/order-statistic'
import report from '@/pages/report/report'
import timelineOrder from '@/pages/dashboard/timeline'
import timelineAsset from '@/pages/dashboard/asset'

Vue.use(Router)

export default new Router({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect:'index.html',
      name: 'home',
      components: home,
      meta: {
        title: 'Home',
        layout: 'withSubmenu',
        category: 'dashboard'
      }
    },
    {
      path: '/index.html',
      name: 'home',
      component: home,
      meta: {
        title: 'Home',
        layout: 'withSubmenu',
        category: 'dashboard'
      }
    },
    {
      path: '/timeline-order',
      name: 'timeline-order',
      component: timelineOrder,
      meta: {
        title: 'Order Dashboard',
        layout: 'withSubmenu',
        category: 'dashboard'
      }
    },
    {
      path: '/timeline-asset',
      name: 'timeline-asset',
      component: timelineAsset,
      meta: {
        title: 'Asset Dashboard',
        layout: 'withSubmenu',
        category: 'dashboard'
      }
    },
    {
      path: '/report',
      name: 'report',
      component: report,
      meta: {
        title: 'Report',
        layout: 'noSubmenu',
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        title: 'Login',
        layout: 'empty'
      }
    },
    {
      path: '/verify-email',
      name: 'verifyEmail',
      component: verifyEmail,
      meta: {
        title: 'Verify Email',
        layout: 'empty'
      }
    },
    {
      path: '/register',
      name: 'register',
      component: register,
      meta: {
        title: 'Register',
        layout: 'empty'
      }
    },
    {
      path: '/verify-user',
      name: 'verifyUser',
      component: verifyUser,
      meta: {
        title: 'Verify User',
        layout: 'empty'
      }
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: resetPassword,
      meta: {
        title: 'Reset Password',
        layout: 'empty'
      }
    },
    {
      path: '/users',
      name: 'userList',
      component: userList,
      meta: {
        title: 'User',
        layout: 'withSubmenu',
        category: 'user',
      }
    },
    {
      path: '/user/:id?',
      name: 'userForm',
      component: userForm,
      props: castRouteParams,
      meta: {
        title: 'User',
        layout: 'withSubmenu',
        category: 'user',
      }
    },
    {
      path: '/teams',
      name: 'teamList',
      component: teamList,
      meta: {
        title: 'Team',
        layout: 'withSubmenu',
        category: 'user',
      }
    },
    {
      path: '/team/:id?',
      name: 'teamForm',
      props: castRouteParams,
      component: teamForm,
      meta: {
        title: 'Team',
        layout: 'withSubmenu',
        category: 'user',
      }
    },
    {
      path: '/locations',
      name: 'locationList',
      props: true,
      component: locationList,
      params: {
        lvl: ':lvl'
      },
      meta: {
        title: 'Level',
        layout: 'withSubmenu',
        category: 'location',
      }
    },
    {
      path: '/location/:id?',
      name: 'locationForm',
      props: castRouteParams,
      component: locationForm,
      params: {
        lvl: ':lvl'
      },
      meta: {
        title: 'Level',
        layout: 'withSubmenu',
        category: 'location',
      }
    },
    {
      path: '/systems',
      name: 'assetSystemList',
      component: assetSystemList,
      meta: {
        title: 'System',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/system/:id?',
      name: 'assetSystemForm',
      props: castRouteParams,
      component: assetSystemForm,
      meta: {
        title: 'System',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/generics',
      name: 'assetGenericList',
      component: assetGenericList,
      meta: {
        title: 'System >> Generic',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/generic/:id?',
      name: 'assetGenericForm',
      props: castRouteParams,
      component: assetGenericForm,
      meta: {
        title: 'System >> Generic',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/assets',
      name: 'assetAssetList',
      component: assetAssetList,
      meta: {
        title: 'Asset',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/asset/:id?',
      name: 'assetAssetForm',
      props: castRouteParams,
      component: assetAssetForm,
      meta: {
        title: 'Asset',
        layout: 'withSubmenu',
        category: 'asset',
      }
    },
    {
      path: '/checklists',
      name: 'checklistList',
      component: checklistList,
      meta: {
        title: 'Checklist',
        layout: 'withSubmenu',
        category: 'check',
      }
    },
    {
      path: '/checklist/:id?',
      name: 'checklistForm',
      props: castRouteParams,
      component: checklistForm,
      meta: {
        title: 'CheckList',
        layout: 'withSubmenu',
        category: 'check',
      }
    },
    {
      path: '/checkplans',
      name: 'checkplanList',
      component: checkplanList,
      meta: {
        title: 'Checkplan',
        layout: 'withSubmenu',
        category: 'check',
      }
    },
    {
      path: '/checkplan/:id?',
      name: 'checkplanForm',
      props: castRouteParams,
      component: checkplanForm,
      meta: {
        title: 'Checkplan',
        layout: 'withSubmenu',
        category: 'check',
      }
    },
    {
      path: '/checklist-checks',
      name: 'checkVerifyList',
      component: checkVerifyList,
      meta: {
        title: 'My Checklist',
        layout: 'simple',
        category: 'check',
      }
    },
    {
      path: '/checklist-check/:id?',
      name: 'checkVerifyForm',
      props: castRouteParams,
      component: checkVerifyForm,
      meta: {
        title: 'My Checklist',
        layout: 'simple',
        category: 'check',
      }
    },
    {
      path: '/work/complaints',
      name: 'complaintComplaintList',
      component: complaintComplaintList,
      meta: {
        title: 'Complaint',
        layout: 'withSubmenu',
        category: 'complaint',
      }
    },
    {
      path: '/work/complaint/:id?',
      name: 'complaintComplaintForm',
      props: castRouteParams,
      component: complaintComplaintForm,
      meta: {
        title: 'Complaint',
        layout: 'withSubmenu',
        category: 'complaint',
      }
    },
    {
      path: '/work/orders',
      name: 'workOrderList',
      component: workOrderList,
      meta: {
        title: 'Work Order',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/order/:id?',
      name: 'workOrderForm',
      props: castRouteParams,
      component: workOrderForm,
      meta: {
        title: 'Work Order',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/plans',
      name: 'workPlanList',
      component: workPlanList,
      meta: {
        title: 'Work Plan',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/plan/:id?',
      name: 'workPlanForm',
      props: castRouteParams,
      component: workPlanForm,
      meta: {
        title: 'Work Plan',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/templates',
      name: 'workTemplateList',
      component: workTemplateList,
      meta: {
        title: 'Work Template',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/template/:id?',
      name: 'workTemplateForm',
      props: castRouteParams,
      component: workTemplateForm,
      meta: {
        title: 'Work Template',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/records',
      name: 'workRecordList',
      component: workRecordList,
      meta: {
        title: 'Record',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/record/:id?',
      name: 'workRecordForm',
      props: castRouteParams,
      component: workRecordForm,
      meta: {
        title: 'Record',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/scopes',
      name: 'workScopeList',
      component: workScopeList,
      meta: {
        title: 'Scope',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/scope/:id?',
      name: 'workScopeForm',
      props: castRouteParams,
      component: workScopeForm,
      meta: {
        title: 'Scope',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/headings',
      name: 'workHeadingList',
      component: workHeadingList,
      meta: {
        title: 'Heading',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/heading/:id?',
      name: 'workHeadingForm',
      props: castRouteParams,
      component: workHeadingForm,
      meta: {
        title: 'Heading',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/cost-centers',
      name: 'workCostCenterList',
      component: workCostCenterList,
      meta: {
        title: 'Cost Center',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/cost-center/:id?',
      name: 'workCostCenterForm',
      props: castRouteParams,
      component: workCostCenterForm,
      meta: {
        title: 'Cost Center',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/details',
      name: 'workDetailList',
      component: workDetailList,
      meta: {
        title: 'Detail',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/work/detail/:id?',
      name: 'workDetailForm',
      props: castRouteParams,
      component: workDetailForm,
      meta: {
        title: 'Detail',
        layout: 'withSubmenu',
        category: 'work',
      }
    },
    {
      path: '/invoices',
      name: 'invoiceList',
      component: invoiceList,
      meta: {
        title: 'Invoice',
        layout: 'withSubmenu',
        category: 'invoice',
      }
    },
    {
      path: '/invoice/:id?',
      name: 'invoiceForm',
      props: castRouteParams,
      component: invoiceForm,
      meta: {
        title: 'Invoice',
        layout: 'withSubmenu',
        category: 'invoice',
      }
    },
    {
      path: '/make-invoices',
      name: 'makeInvoiceList',
      component: makeInvoiceList,
      meta: {
        title: 'Invoice Compiling',
        layout: 'withSubmenu',
        category: 'invoice',
      }
    },
    {
      path: '/recurrences',
      name: 'recurrenceList',
      component: recurrenceList,
      meta: {
        title: 'Recurrence',
        layout: 'withSubmenu',
        category: 'recurrence',
      }
    },
    {
      path: '/recurrence/:id?',
      name: 'recurrenceForm',
      props: castRouteParams,
      component: recurrenceForm,
      meta: {
        title: 'Recurrence',
        layout: 'withSubmenu',
        category: 'recurrence',
      }
    },
    {
      path: '/units',
      name: 'unitUnitList',
      component: unitUnitList,
      meta: {
        title: 'Unit',
        layout: 'withSubmenu',
        category: 'unit',
      }
    },
    {
      path: '/unit/:id?',
      name: 'unitUnitForm',
      component: unitUnitForm,
      props: castRouteParams,
      meta: {
        title: 'Unit',
        layout: 'withSubmenu',
        category: 'unit',
      }
    },
    {
      path: '/measurements',
      name: 'unitMeasurementList',
      component: unitMeasurementList,
      meta: {
        title: 'Measurement',
        layout: 'withSubmenu',
        category: 'unit',
      }
    },
    {
      path: '/measurement/:id?',
      name: 'unitMeasurementForm',
      props: castRouteParams,
      component: unitMeasurementForm,
      meta: {
        title: 'Measurement',
        layout: 'withSubmenu',
        category: 'unit',
      }
    },
    {
      path: '/master-components',
      name: 'masterComponentList',
      component: masterComponentList,
      meta: {
        title: 'Master Component',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/master-component/:id?',
      name: 'masterComponentForm',
      props: castRouteParams,
      component: masterComponentForm,
      meta: {
        title: 'Master Component',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/components',
      name: 'componentList',
      component: componentList,
      meta: {
        title: 'Component',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/component/:id?',
      name: 'componentForm',
      props: castRouteParams,
      component: componentForm,
      meta: {
        title: 'Component',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/master-parameters',
      name: 'masterParameterList',
      component: masterParameterList,
      meta: {
        title: 'Master Parameter',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/master-parameter/:id?',
      name: 'masterParameterForm',
      props: true,
      component: masterParameterForm,
      meta: {
        title: 'Master Parameter',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/user-preference',
      name: 'user-preference',
      component: userPreference,
      meta: {
        title: 'User Preference',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/global-preference',
      name: 'global-preference',
      component: globalPreference,
      meta: {
        title: 'Global Preference',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/asset-plan/:id?',
      name: 'assetPlanForm',
      props: castRouteParams,
      component: assetPlanForm,
      meta: {
        title: 'Asset Plan',
        layout: 'withSubmenu',
      }
    },
    {
      path: '/profile/:id?',
      name: 'profileForm',
      props: castRouteParams,
      component: profileForm,
      meta: {
        title: 'My Profile',
        layout: 'noSubmenu',
      }
    },
    {
      path: '/order-statistic',
      name: 'orderStatistic',
      component: orderStatistic,
      meta: {
        title: 'Order Statistic',
        layout: 'withSubmenu',
        category: 'statistic',
      }
    },
    
  ],
  duplicateNavigationPolicy: 'reload',
  mode: 'history'
})

function castRouteParams(route) {
  return {
    id: Number(route.params.id),
    complaint: Number(route.params.complaint),
    assetId: Number(route.params.assetId),
    locationId: Number(route.params.locationId),
    checklistId: Number(route.params.checklistId),
    checkplanId: Number(route.params.checkplanId),
    recurranceTimeList: Number(route.params.recurranceTimeList),
    assetPlanList: Number(route.params.assetPlanList),
  };
}