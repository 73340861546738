<template>
    <div class="pa-0">
        <v-row>
            <v-col>
                <v-btn-toggle
                v-model="dataBar"
                mandatory
                >
                    <v-btn value="all">All</v-btn>
                    <v-btn value="costcenter">Cost Center</v-btn>
                    <v-btn value="scope">Scope</v-btn>
                </v-btn-toggle>
            </v-col>
            <v-col v-if="dataBar == 'scope'">
                <v-autocomplete
                :items="costCenterList" 
                item-value="id"
                name="item"
                item-text="name"
                label="Cost Center" 
                outlined
                v-model="costCenterSelected"
                hide-details="auto"
                >
                    <v-btn v-for="(costcenter, index) in costCenterList" v-bind:key="index" :value="costcenter.id">{{costcenter.name}}</v-btn>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="dataBar == 'all'">
            <v-col>
                <v-row>
                    <v-col>
                        <v-autocomplete
                        :items="assetList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Asset" 
                        outlined
                        :loading="assetLoading"
                        v-model="assetSelected"
                        hide-details="auto"
                        :filter="assetFilter"
                        >
                            <template slot="selection" slot-scope="data">
                                {{ data.item.name }} <span style="margin-left:5px" v-if="data.item.id">(</span><span v-if="data.item.id">ID: {{ data.item.id }}</span><span v-if="data.item.manual_tag">, Tag: {{ data.item.manual_tag }} </span><span v-if="data.item.id">)</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                {{ data.item.name }} <span style="margin-left:5px" v-if="data.item.id">(</span><span v-if="data.item.id">ID: {{ data.item.id }}</span><span v-if="data.item.manual_tag">, Tag: {{ data.item.manual_tag }} </span><span v-if="data.item.id">)</span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col>
                        <v-select
                        :items="userList" 
                        item-value="id"
                        name="item"
                        outlined
                        item-text="username"
                        :label="userStatus ? userStatus[0].toUpperCase() + userStatus.slice(1) : 'User'" 
                        v-model="userSelected"
                        hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-btn-toggle
                        v-model="userStatus"
                        >
                            <v-btn value="worker">
                                Worker
                            </v-btn>
                            <v-btn value="supervisor">
                                Supervisor
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="border-middle" cols="12" md="4">
                <v-card>
                    <v-card-text>
                    <div class="display-1 text-center pt-4">Today</div>
                        <p class="text-center">(Total Cost: {{todayStat.cost_sum}})</p>
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionType"
                            :options="chartOptionsType" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionUrgency"
                            :options="chartOptionsUrgency" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionStatus"
                            :options="chartOptionsStatus" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'costcenter'"
                            :chartdata="datacollectionCost"
                            :options="chartOptionsCost" />
                        <PieChart
                        class="pa-4"
                            v-if="loaded && dataBar == 'scope'"
                            :chartdata="datacollectionScope"
                            :options="chartOptionsScope" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="border-middle" cols="12" md="4">
                <v-card>
                    <v-card-text>
                        <div class="display-1 text-center pt-4">This Week</div>
                        <p class="text-center">(Total Cost: {{weekStat.cost_sum}})</p>
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionTypeWeek"
                            :options="chartOptionsType" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionUrgencyWeek"
                            :options="chartOptionsUrgency" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionStatusWeek"
                            :options="chartOptionsStatus" />
                        <!--<PieChart
                        class="pa-4"
                        v-if="loaded"
                        :chartdata="datacollectionSourceWeek"
                        :options="chartOptionsSource" />-->
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'costcenter'"
                            :chartdata="datacollectionCostWeek"
                            :options="chartOptionsCost" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'scope'"
                            :chartdata="datacollectionScopeWeek"
                            :options="chartOptionsScope" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="border-middle" cols="12" md="4">
                <v-card>
                    <v-card-text>
                        <div class="display-1 text-center pt-4">This Month</div>
                        <p class="text-center">(Total Cost: {{monthStat.cost_sum}})</p>
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionTypeMonth"
                            :options="chartOptionsType" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionUrgencyMonth"
                            :options="chartOptionsUrgency" />
                        <PieChart
                        class="pa-4"
                            v-if="loaded && dataBar == 'all'"
                            :chartdata="datacollectionStatusMonth"
                            :options="chartOptionsStatus" />
                        <!--<PieChart
                        class="pa-4"
                        v-if="loaded"
                        :chartdata="datacollectionSourceMonth"
                        :options="chartOptionsSource" />-->
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'costcenter'"
                            :chartdata="datacollectionCostMonth"
                            :options="chartOptionsCost" />
                        <PieChart
                            class="pa-4"
                            v-if="loaded && dataBar == 'scope'"
                            :chartdata="datacollectionScopeMonth"
                            :options="chartOptionsScope" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import PieChart from '@/components/chart/pie'
import { months } from 'moment';
	export default {
        components: {
            PieChart
        },
        
		data() {
            return {
                scopeList: [],
                assetList: [],
                userList: [],
                costCenterList: [],
                costCenterSelected: null,
                userStatus: null,
                assetSelected: null,
                userSelected: null,
                assetLoading: false,
                dataBar: 'all',
                selection: null,
                loaded: false,
                // datacollection: null,
                // chartData: [],
                filterDataChart: {
                    frequency: 'month', 
                },
                chartOptionsType: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Type',
                        fontSize: 14
                    },
                },
                chartOptionsUrgency: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Urgency',
                        fontSize: 14
                    }
                },
                chartOptionsStatus: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Status',
                        fontSize: 14
                    }
                },
                chartOptionsSource: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Source',
                        fontSize: 14
                    }
                },
                chartOptionsCost: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Cost Center',
                        fontSize: 14
                    },
                },
                chartOptionsScope: {
                    responsive: true,
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Scope',
                        fontSize: 14
                    },
                },
                todayStat: {
                    cost_sum: 0,
                    type: [],
                    urgency: [],
                    status: [],
                    source: [],
                    cost_center:[],
                    scope: [],
                },
                weekStat: {
                    cost_sum: 0,
                    type: [],
                    urgency: [],
                    status: [],
                    source: [],
                    cost_center:[],
                    scope: [],
                },
                monthStat: {
                    cost_sum: 0,
                    type: [],
                    urgency: [],
                    status: [],
                    source: [],
                    cost_center:[],
                    scope: [],
                },
                chartTime: 'day',
                placeholderData: {
                    labels: ["No data"],
                    datasets: [
                        {
                            label: ["Test"],
                            data: [1],
                            backgroundColor: "#F2EEE8"
                        }
                    ]
                },
            }
        },
        computed: {
            // filterData: function() {

            // }
            chartDataOpen: function() {
                if (this.orderChart) {
                    var newArray = []
                    if (this.filterDataChart.frequency == 'month'){
                        for (var i=0; i<12;i++) {
                            newArray.push(0);
                            for (var j=0; j<this.orderChart.length;j++) {
                                newArray[this.$moment(this.orderChart[j].date).format('M')-1] = this.orderChart[j].status__open;
                            }
                        }
                    } else {
                        for (var i=0; i<4;i++) {
                            newArray.push(0);
                            for (var j=0; j<this.orderChart.length;j++) {
                                newArray[j] = this.orderChart[j].status__open;
                            }
                        }
                    }
                    return newArray
                }
            },
            chartDataRecorded: function() {
                if (this.orderChart) {
                    var newArray = []
                    if (this.filterDataChart.frequency == 'month'){
                        for (var i=0; i<12;i++) {
                            newArray.push(0);
                            for (var j=0; j<this.orderChart.length;j++) {
                                newArray[this.$moment(this.orderChart[j].date).format('M')-1] = this.orderChart[j].status__recorded;
                            }
                        }
                    } else {
                        for (var i=0; i<4;i++) {
                            newArray.push(0);
                            for (var j=0; j<this.orderChart.length;j++) {
                                newArray[j] = this.orderChart[j].status__recorded;
                            }
                        }
                    }
                    return newArray
                }
            },
            chartDataDone: function() {
                if (this.orderChart) {
                    var newArray = []
                    if (this.filterDataChart.frequency == 'month'){
                    for (var i=0; i<12;i++) {
                        newArray.push(0);
                        for (var j=0; j<this.orderChart.length;j++) {
                            newArray[this.$moment(this.orderChart[j].date).format('M')-1] = this.orderChart[j].status__done;
                        }
                    }
                    } else {
                        for (var i=0; i<4;i++) {
                            newArray.push(0);
                            for (var j=0; j<this.orderChart.length;j++) {
                                newArray[j] = this.orderChart[j].status__done;
                            }
                        }
                    }
                    return newArray
                }
            },
            datacollectionType: function() {
                var newObj = {}
                if (this.todayStat.type.some(item => item !== 0)){
                    newObj = {
                        labels: ['Corrective', 'Preventive', 'Reactive'],
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Type',
                                data: this.todayStat.type,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionUrgency: function() {
                var newObj = {}
                if (this.todayStat.urgency.some(item => item !== 0)){
                    newObj = {
                        labels: ['One', 'Two', 'Three'],
                        datasets: [
                            {
                                label: 'Urgency',
                                data: this.todayStat.urgency,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionStatus: function() {
                var newObj = {}
                if (this.todayStat.status.some(item => item !== 0)){
                    newObj = {
                        labels: ['Draft', 'Open', 'WIP', 'Recorded', 'Redo', 'Done'],
                        datasets: [
                            {
                                label: 'Status',
                                data: this.todayStat.status,
                                backgroundColor: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionTypeWeek: function() {
                var newObj = {}
                if (this.weekStat.type.some(item => item !== 0)){
                    newObj = {
                        labels: ['Corrective', 'Preventive', 'Reactive'],
                        datasets: [
                            {
                                label: 'Type',
                                data: this.weekStat.type,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionUrgencyWeek: function() {
                var newObj = {}
                if (this.weekStat.urgency.some(item => item !== 0)){
                    newObj = {
                        labels: ['One', 'Two', 'Three'],
                        datasets: [
                            {
                                label: 'Urgency',
                                data: this.weekStat.urgency,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionStatusWeek: function() {
                var newObj = {}
                if (this.weekStat.status.some(item => item !== 0)){
                    newObj = {
                        labels: ['Draft', 'Open', 'WIP', 'Recorded', 'Redo', 'Done'],
                        datasets: [
                            {
                                label: 'Status',
                                data: this.weekStat.status,
                                backgroundColor: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionTypeMonth: function() {
                var newObj = {}
                if (this.monthStat.type.some(item => item !== 0)){
                    newObj = {
                        labels: ['Corrective', 'Preventive', 'Reactive'],
                        datasets: [
                            {
                                label: 'Type',
                                data: this.monthStat.type,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionUrgencyMonth: function() {
                var newObj = {}
                if (this.monthStat.urgency.some(item => item !== 0)){
                    newObj = {
                        labels: ['One', 'Two', 'Three'],
                        datasets: [
                            {
                                label: 'Urgency',
                                data: this.monthStat.urgency,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionStatusMonth: function() {
                var newObj = {}
                if (this.monthStat.status.some(item => item !== 0)){
                    newObj = {
                        labels: ['Draft', 'Open', 'WIP', 'Recorded', 'Redo', 'Done'],
                        datasets: [
                            {
                                label: 'Status',
                                data: this.monthStat.status,
                                backgroundColor: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionCost: function() {
                var newObj = {}
                if (this.todayStat.cost_center && this.todayStat.cost_center.length > 0 && this.todayStat.cost_center.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.todayStat.cost_center.length; i++) {
                        label.push(this.todayStat.cost_center[i].costcenter__name)
                    }
                    var data = []
                    for(var i = 0; i < this.todayStat.cost_center.length; i++) {
                        data.push(this.todayStat.cost_center[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Cost Center',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = {
                        labels: ['No data'],
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Cost Center',
                                data: [1],
                                backgroundColor: ['#F2EEE8'],
                            },
                        ]
                    }
                }
                return newObj;
            },
            datacollectionCostWeek: function() {
                var newObj = {}
                if (this.weekStat.cost_center.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.weekStat.cost_center.length; i++) {
                        label.push(this.weekStat.cost_center[i].costcenter__name)
                    }
                    var data = []
                    for(var i = 0; i < this.weekStat.cost_center.length; i++) {
                        data.push(this.weekStat.cost_center[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Cost Center',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionCostMonth: function() {
                var newObj = {}
                if (this.monthStat.cost_center.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.monthStat.cost_center.length; i++) {
                        label.push(this.monthStat.cost_center[i].costcenter__name)
                    }
                    var data = []
                    for(var i = 0; i < this.monthStat.cost_center.length; i++) {
                        data.push(this.monthStat.cost_center[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Cost Center',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            // Scope
            
            datacollectionScope: function() {
                var newObj = {}
                if (this.todayStat.scope && this.todayStat.scope.length > 0 && this.todayStat.scope.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.todayStat.scope.length; i++) {
                        label.push(this.todayStat.scope[i].scope__name)
                    }
                    var data = []
                    for(var i = 0; i < this.todayStat.scope.length; i++) {
                        data.push(this.todayStat.scope[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Scope',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = {
                        labels: ['No data'],
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Scope',
                                data: [1],
                                backgroundColor: ['#F2EEE8'],
                            },
                        ]
                    }
                }
                return newObj;
            },
            datacollectionScopeWeek: function() {
                var newObj = {}
                if (this.weekStat.scope && this.weekStat.scope.length > 0 && this.weekStat.scope.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.weekStat.scope.length; i++) {
                        label.push(this.weekStat.scope[i].scope__name)
                    }
                    var data = []
                    for(var i = 0; i < this.weekStat.scope.length; i++) {
                        data.push(this.weekStat.scope[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Scope',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
            datacollectionScopeMonth: function() {
                var newObj = {}
                if (this.monthStat.scope && this.monthStat.scope.length > 0 && this.monthStat.scope.some(item => item !== 0)){
                    var label = []
                    for(var i = 0; i < this.monthStat.scope.length; i++) {
                        label.push(this.monthStat.scope[i].scope__name)
                    }
                    var data = []
                    for(var i = 0; i < this.monthStat.scope.length; i++) {
                        data.push(this.monthStat.scope[i].net__sum)
                    }
                    newObj = {
                        labels: label,
                        backgroundColor: '#ff0000',
                        datasets: [
                            {
                                label: 'Scope',
                                data: data,
                                backgroundColor: ['#003f5c', '#bc5090', '#ffa600'],
                            },
                        ]
                    }
                } else {
                    newObj = this.placeholderData;
                }
                return newObj;
            },
        },
        methods: {
            load() {
                this.getAssetList();
                this.getUserList();
                this.getcostCenterList();
                this.getWorkScope().then((res) => {
                    this.scopeList = res;
                    this.loadChart();
                });
            },
            getcostCenterList() {
			    this.getCostCentes().then((res) => {
                    this.costCenterList = res
                    this.costCenterList.unshift({ name: 'All', id: null})
                });
            },
            getAssetList() {
			    this.getAssets().then((res) => {
                    this.assetList = res
                    this.assetList.unshift({ name: 'All', id: null})
                });
            },
            getUserList() {
			    this.getUsers().then((res) => {
                    this.userList = res;
                    this.userList.unshift({ username: 'All', role: '', id: null})
                });
            },
			async getCostCentes() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    this.assetLoading = true
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.assetLoading = false
                }
            },
			async getUsers() {
                try {
					const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            loadChart() {
                this.getStat('day').then((res) => {
                    // console.log(res);
                    this.todayStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                    this.todayStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                    this.todayStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                    this.todayStat.cost_sum = res.net__sum ? res.net__sum : 0;
                });
                this.getStat('week').then((res) => {
                    // console.log(res);
                    this.weekStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                    this.weekStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                    this.weekStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                    this.weekStat.cost_sum = res.net__sum ? res.net__sum : 0
                });
                this.getStat('month').then((res) => {
                    // console.log(res);
                    this.monthStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                    this.monthStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                    this.monthStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                    this.monthStat.cost_sum = res.net__sum ? res.net__sum : 0
                });
                this.loaded = true;
            },
			async getWorkScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getStat(time) {
                try {
                    this.loading = true
                    let res;
                    if (this.userStatus == 'worker') {
					    res = await this.$api.statistics.avgOrderUser({current: time, asset: this.assetSelected, worker: this.userSelected });
                    } else if (this.userStatus == 'supervisor') {
					    res = await this.$api.statistics.avgOrderUser({current: time, asset: this.assetSelected, supervisor: this.userSelected });
                    } else {
					    res = await this.$api.statistics.avgOrderUser({current: time, asset: this.assetSelected });
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
			},
			async getSubStat(type, time) {
                try {
                    this.loading = true
                    let res;
                    if (type == 'costcenter') {
                        if (this.userStatus == 'worker') {
                            res = await this.$api.statistics.orderCost({current: time, asset: this.assetSelected, worker: this.userSelected });
                        } else if (this.userStatus == 'supervisor') {
                            res = await this.$api.statistics.orderCost({current: time, asset: this.assetSelected, supervisor: this.userSelected });
                        } else {
                            res = await this.$api.statistics.orderCost({current: time, asset: this.assetSelected });
                        }
                    } else {
                        if (this.userStatus == 'worker') {
                            res = await this.$api.statistics.orderScope({current: time, asset: this.assetSelected, worker: this.userSelected, costcenter: this.costCenterSelected });
                        } else if (this.userStatus == 'supervisor') {
                            res = await this.$api.statistics.orderScope({current: time, asset: this.assetSelected, supervisor: this.userSelected, costcenter: this.costCenterSelected });
                        } else {
                            res = await this.$api.statistics.orderScope({current: time, asset: this.assetSelected, costcenter: this.costCenterSelected });
                        }
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
			},
            assetFilter (item, queryText, itemText) {
                if (item.name !== 'All') {
                    var data = item.name.toLowerCase() + ' ' + item.manual_tag.toLowerCase() + ' ' + item.id
                    var searchText = queryText.toLowerCase()
                    return data.indexOf(searchText) > -1 
                }
            }
        },
        mounted(){
            this.load();
        },
        watch: {
            'dataBar': function(newVal) {
                if (newVal !== 'all') {
                    this.getSubStat(newVal, 'day').then((res) => {
                        if (newVal == 'costcenter') {
                            this.todayStat.cost_center = res;
                        } else {
                            this.todayStat.scope = res;
                        }
                    });
                    this.getSubStat(newVal, 'week').then((res) => {
                        if (newVal == 'costcenter') {
                            this.weekStat.cost_center = res;
                        } else {
                            this.weekStat.scope = res;
                        }
                    });
                    this.getSubStat(newVal, 'month').then((res) => {
                        if (newVal == 'costcenter') {
                            this.monthStat.cost_center = res;
                        } else {
                            this.monthStat.scope = res;
                        }
                    });
                } 
            },
            'chartTime': function(newVal) {
                this.loadChart();
                // this.getStat(newVal).then((res) => {
                //     // console.log(res);
                //     this.todayStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                //     this.todayStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                //     this.todayStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                // });
            },
            'assetSelected': function(newVal) {
                this.loadChart();
                // this.getStat(this.chartTime).then((res) => {
                //     // console.log(res);
                //     this.todayStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                //     this.todayStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                //     this.todayStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                // });
            },
            'userSelected': function(newVal) {
                if (newVal == null) {
                    this.userStatus = null; 
                } else {
                    if (this.userStatus == null ) {
                        this.userStatus = 'worker'
                    }
                }
                this.loadChart();
                // this.getStat(this.chartTime).then((res) => {
                //     // console.log(res);
                //     this.todayStat.type = [ res.type__corrective, res.type__preventive, res.type__reactive];
                //     this.todayStat.urgency = [ res.urgency__one, res.urgency__two, res.urgency__three];
                //     this.todayStat.status = [ res.status__draft, res.status__open, res.status__wip, res.status__recorded, res.status__redo, res.status__done, res.status__verified, res.status__audited, res.status__claimed, res.status__vetted, res.status__compiled];
                // });
            },
            'costCenterSelected': function(newVal) {
                this.getSubStat('scope', 'day').then((res) => {
                    this.todayStat.scope = res;
                });
                this.getSubStat('scope', 'week').then((res) => {
                    this.weekStat.scope = res;
                });
                this.getSubStat('scope', 'month').then((res) => {
                    this.monthStat.scope = res;
                });
            },
        }
    }
</script>