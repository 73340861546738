<template>
    <v-container fluid class="list-workOrder">
        <v-row v-if="!editMode">
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-row>
                    <v-col>
                        <v-autocomplete
                        :items="userList" 
                        item-value="id"
                        name="item"
                        item-text="username"
                        label="Worker" 
                        v-model="filterData.worker"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="wip_ts_date_after_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filterData.wip_ts_date_after"
                                label="From Date"
                                prepend-icon="mdi-clock-start"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="filterData.wip_ts_date_after" @input="wip_ts_date_after_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="wip_ts_date_before_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filterData.wip_ts_date_before"
                                label="To Date"
                                prepend-icon="mdi-clock-end"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="filterData.wip_ts_date_before" @input="wip_ts_date_before_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                        :items="costCenterList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Cost Center" 
                        v-model="filterData.costcenter"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                        :items="scopeList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Scope" 
                        v-model="filterData.scope"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                        :items="workTypeOption" 
                        item-value="value"
                        name="item"
                        item-text="name"
                        label="Work Type" 
                        v-model="filterData.work_type"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn :disabled="!searchValid" color="primary" @click="search()" >
                        <v-icon>mdi-magnify</v-icon> Search
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            :disabled="orderSelectedPost.length == 0"
                            label="Invoice Name ( will auto generate if this is empty )" 
                            v-model="claimName"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn class="ml-2" :disabled="orderSelectedPost.length == 0" color="secondary" @click="claim()" >
                        <v-icon>mdi-currency-usd-off</v-icon> Create Invoice
                        </v-btn>
                    </v-col>
                </v-row>
                <v-progress-circular
                v-if="progressLoad"
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </div>
            <div v-if="!filter"><strong>Worker</strong>, <strong>From Date</strong>, <strong>To Date</strong> is required to filter orders to be claimed.</div>
            <v-data-table
            show-select
            v-if="this.workOrder && filter"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="this.sortedWorkOrder"
            :sort-by="['id']"
            :items-per-page="20"
            class="elevation-1"
            v-model="orderSelected"
            
            >
            <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)"></v-simple-checkbox>
            </template>
            <template v-slot:item.id="{item}">
                <span>{{ item.id }} </span><v-btn small icon @click="openOrder(item.id)"><v-icon>mdi-eye</v-icon></v-btn>
            </template>
            <template v-slot:item.scope="{item}">
                <span>{{getScopeName(item.scope)}}</span>
            </template>
            <template v-slot:item.worker="{item}">
                <span>{{getUserName(item.worker)}}</span>
            </template>
            <template v-slot:item.costcenter="{item}">
                <span>{{getCostCenterName(item.costcenter)}}</span>
            </template>
            <!-- <template v-slot:item="row">
              <tr>
                <td><v-simple-checkbox v-model="orderSelected" :ripple="false" :value="row.item.id" ></v-simple-checkbox></td>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">
                    <v-badge
                    class="animate-fading"
                    v-if="row.item.urgency"
                    :color="urgencyColor(row.item.urgency)"
                    offset-x="-5"
                    offset-y="-1"
                    left
                    dot
                    >
                    </v-badge>
                    {{row.item.name}} 
                </td>
                <td class="text-left"><span v-if="row.item.open_dt">{{ row.item.open_dt | moment_dtstamp }}</span></td>
                <td class="text-left"><span v-if="row.item.due_dt">{{ row.item.due_dt | moment_dtstamp }}</span></td>
                <td class="text-left">{{row.item.status}}</td>
              </tr>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Vue from 'vue'
	export default {
		data: () => ({
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingWorkPrograms: [],
            yearMonthSelected: '',
            programSelected: '',
            otherWorkOrder : [],
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Scope', value: 'scope' },
            { text: 'Worker', value: 'worker' },
            { text: 'Cost Center', value: 'costcenter' },
            { text: 'Work Type', value: 'work_type' },
            { text: 'Cost', value: 'net' },
            { text: 'Status', value: 'status' },
            { text: 'WIP Datetime', value: 'wip_ts' },
            // { text: 'Activity', value: 'activity' },
            { text: '', sortable: false,}
            ],
			success: false,
			workOrder: [],
			loading: true,
            workOrderId: 0,
            userList: [],
            filter: false,
            filterData: {
                phase: 'CLAIM', 
                status: 'VERIFIED',
                costcenter: null,
                scope: null,
                work_type: null,
                wip_ts_date_after: null,
                wip_ts_date_before: null,
                worker: null,
            },
            claimName: '',
            wip_ts_date_after_menu: null,
            wip_ts_date_before_menu: null,
            costCenterList: [],
            scopeList: [],
            workTypeOption:[
                { value: "CORRECTIVE", name: "corrective (self-initiate)" },
                { value: "PREVENTIVE", name: "preventive (scheduled)" },
                { value: "REACTIVE", name: "reactive (respond to complaint)" }
            ],
            orderSelected: [],
            progressLoad: false,
		}),
        computed: {
            searchValid() {
                if (this.filterData.worker !== null && this.filterData.wip_ts_date_after !== null && this.filterData.wip_ts_date_before !== null && this.filterData.costcenter !== null && this.filterData.scope !== null) {
                    return true;
                }
            },
            sortedWorkOrder () {
                return this.workOrder;
            },
            orderSelectedPost() {
                return this.orderSelected.map(a => a.id);
            }
        },
		methods: {
            // Main Function
            search() {
                this.orderSelected = [];
			    this.getWorkOrder().then((res) => {
                    this.workOrder = res;
                    res.forEach((item) => { 
                        Vue.set(item,'selected', false);
                        Vue.set(item,'wip_ts', this.$moment(new Date(item.wip_ts)).format('MMMM Do YYYY, h:mm:ss a'));
                    })
                });
            },
			async getWorkOrder() {
                try {
                    const res = await this.$api.workOrder.search( this.filterData );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.filter = true
                    this.loading = false
                }
            },
            getAdditionalData() {
                this.getUsers().then((res) => {
                    this.userList = res;
                });
                this.getCostCenter().then((res) => {
                    this.costCenterList = res;
                });
                this.getScope().then((res) => {
                    this.scopeList = res;
                });
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getCostCenter() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    this.filterData = value;
			        this.$api.workOrder.search(value).then((res) => {
                        this.filteredWorkOrder = res.data;
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredWorkOrder = [];
                this.filter = false;
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.workOrder.delete(id).then(res => this.list() );
                    }
                })
            },
            urgencyColor(urgency) {
                if (urgency == 1) {
                    return 'red dark-3'
                }
                if (urgency == 2) {
                    return 'red'
                }
                if (urgency == 3) {
                    return 'amber'
                }
                if (urgency == 4) {
                    return 'blue'
                }
            },
            claim() {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you going to create this invoice?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
                        this.progressLoad = true;
                        this.addClaim().then((res) => {
                            if (res.status == 200 || res.status == 201 ) {
                                this.changeOrderClaimedStatus().then((res) => {
                                    if (res.status == 'Done') {
                                        this.progressLoad = false;
                                        this.search();
                                        this.$swal({
                                            icon: 'success',
                                            title: 'Invoice Created',
                                            reverseButtons: true,
                                            confirmButtonText: 'Ok',
                                        }).then((result) => {
                                        })
                                    }
                                });
                            }
                        })
                    }
                })
            },
			async addClaim() {
                try {
                    let res;
                    this.saving = true
                    if (this.claimName) {
                        if (this.filterData.work_type !== null) {
                            res = await this.$api.invoice.new({ order_set: this.orderSelectedPost, worker: this.filterData.worker, from_date: this.filterData.wip_ts_date_after, to_date: this.filterData.wip_ts_date_before, name: this.claimName, costcenter: this.filterData.costcenter, scope: this.filterData.scope, work_type: this.filterData.work_type});
                        } else {
                            res = await this.$api.invoice.new({ order_set: this.orderSelectedPost, worker: this.filterData.worker, from_date: this.filterData.wip_ts_date_after, to_date: this.filterData.wip_ts_date_before, name: this.claimName, costcenter: this.filterData.costcenter, scope: this.filterData.scope});
                        }
                    } else {
                        if (this.filterData.work_type !== null) {
                            res = await this.$api.invoice.new({ order_set: this.orderSelectedPost, worker: this.filterData.worker, from_date: this.filterData.wip_ts_date_after, to_date: this.filterData.wip_ts_date_before, costcenter: this.filterData.costcenter, scope: this.filterData.scope, work_type: this.filterData.work_type});
                        } else {
                            res = await this.$api.invoice.new({ order_set: this.orderSelectedPost, worker: this.filterData.worker, from_date: this.filterData.wip_ts_date_after, to_date: this.filterData.wip_ts_date_before, costcenter: this.filterData.costcenter, scope: this.filterData.scope});
                        }
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async changeOrderClaimedStatus() {
                try {
                    let res;
                    for (var i = 0; i < this.orderSelected.length; i++) {
                        await this.$api.workOrder.save( {id: this.orderSelected[i].id, status: 'COMPILED' });  
                    }
                    return { status:'Done' };
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getScopeName(id) {
                if ( this.scopeList.length > 0 && id ) {
                    return this.scopeList.find(scope => scope.id === id).name;
                }
            },
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getCostCenterName(costcenter_id) {
                if ( this.costCenterList.length !== 0 && costcenter_id ) {
                    return this.costCenterList.find(costCenter => costCenter.id === costcenter_id).name;
                }
            }, 
			openOrder(id) {
                // this.$router.push({ name: 'workOrderForm', params: { id } })
                let routeData = this.$router.resolve({name: 'workOrderForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
		},
		mounted () {
            // this.list();
            this.filterData.wip_ts_date_after = this.$moment().format('YYYY-MM-DD')
            this.filterData.wip_ts_date_before = this.$moment().format('YYYY-MM-DD')
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
        },
	}
</script>