<template>
	<v-row style="background-color:#fff" class="vh-100" align="center" justify="center">
		<v-col cols="12" sm="12" md="12" lg="8" align="center">
			<div v-if="status == 'ready'">
                <v-container>
                    <v-text-field v-model="verifyObj.password" id="password" label="Enter your new password" name="password" type="password" />
                    <v-btn class="mt-2" color="primary" @click="resetPass"><v-icon class="mr-2">mdi-login-variant</v-icon>Reset Pass</v-btn><br/>
                </v-container>
            </div>
			<div v-if="status !== 'ready'">
                <v-progress-circular
                v-if="status == 'spin'"
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
                <v-icon v-if="status == 'success'">mdi-check</v-icon>
                <v-icon v-if="status == 'fail'">mdi-close-circle-outline</v-icon>
                <div class="mt-2">{{progressText}}</div>
            </div>
		</v-col>
	</v-row>
</template>
<style scoped>
.v-icon {
	font-size: 30px;
}
</style>
<script>
	export default {
		data() {
			return {
				progressText: 'Verifying your email now...',
                status: 'ready',
                resetText: '',
				verifyObj: {}
			}
		},
		computed: {

		},
		methods: {
            resetPass() {
                this.resetPassword().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
						this.status = 'success';
                    	this.progressText = 'Verified! Redirecting you to login...';
						setTimeout(() => this.goToLogin(), 2000);
                    }
                })
            },
			async resetPassword() {
                try {
                    let res;
                    this.saving = true
					res = await this.$api.accountProfile.resetPassword( this.verifyObj );
                    return res;
                } catch (error) {
					this.status = 'ready';
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			goToLogin() {
                this.$router.push({ name: 'login' })
			}
		},
		mounted() {
			this.verifyObj = this.$route.query;
		}
	}
</script>