<template>
    <v-form v-if="loaded">
        <v-row>
            <v-col cols="12">
                <table class="table table-bordered w-100" v-if="dataChart">
                    <tr>
                        <th width="20%">Location</th>
                        <th width="20%">Scope</th>
                        <!-- <th width="20%" rowspan="2">Report</th> -->
                        <th width="8%">Total Orders</th>
                        <th v-for="(costcenter, index) in dataChart.data.datasets" v-bind:key="index" width="8%">{{costcenter.label}}</th>
                        <th width="8%">Month</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex justify-space-between align-center">
                            <div>{{filterLocName}}</div>
                            <v-dialog
                            v-model="dialogLoc"
                            width="500"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    elevation="1"
                                    dark color="secondary"
                                    icon
                                    v-on="on"
                                    >
                                    <v-icon>mdi-selection-marker</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>Select Location</v-card-title>
                                    <v-treeview
                                    :items="locationOption"
                                    :active="filterDataChart.location"
                                    v-model="filterDataChart.location"
                                    
                                    hoverable
                                    activatable
                                    dense
                                    selection-type="independent"
                                    expand-icon="mdi-chevron-down"
                                    on-icon="mdi-bookmark"
                                    off-icon="mdi-bookmark-outline"
                                    :return-object="true"
                                    @update:active="changeLoc"
                                    ></v-treeview>
                                </v-card>
                            </v-dialog></div>
                        </td>
                        <td>
                            <v-select
                            v-model="filterDataChart.scope"
                            :items="scopeOption"
                            item-text="name"
                            item-value="id"
                            label="Scope"
                            outlined
                            hide-details="auto"
                            ></v-select>
                        </td>
                        <td><span>{{ chartTotalOrderCount || 0}}</span></td>
                        <td v-for="(costcenter, index) in dataChart.data.datasets" v-bind:key="index" width="8%">
                            {{costcenter.data.reduce((a, b) => a + b, 0) }}
                        </td>
                        <td>
                            <v-select
                            v-model="selectedMonth"
                            :items="frequencyOption"
                            item-text="label"
                            item-value="value"
                            label="Month"
                            outlined
                            hide-details="auto"
                            ></v-select>
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <BarChart
                v-if="loaded"
                :height="200"
                :chartdata="typeChart"
                :options="chartOptions" />
            </v-col>
        </v-row>
        <v-row  v-if="dataChart">
            <v-col cols="12">
                <table class="table table-bordered w-100">
                    <tr>
                        <td>Date</td>
                        <td v-for="(day, index) in daysInMonth" v-bind:key="index">
                            {{day}}
                        </td>
                    </tr>
                    <tr v-for="(costcenter, index) in dataChart.data.datasets" v-bind:key="index">
                        <td>{{costcenter.label}}</td>
                        <td v-for="(data, index2) in costcenter.data" v-bind:key="index2">{{data}}</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                :headers="headers"
                :items="workOrderList"
                class="elevation-1"
                >
                <template v-slot:item="row">
                    <tr>
                        <td class="text-left">{{row.item.due_dt | moment_dtstamp}}</td>
                        <td class="text-left">{{row.item.name}}</td>
                        <td class="text-left"><span v-if="row.item.supervisor">{{ getUserName(row.item.supervisor) }}</span></td>
                        <td class="text-left"><span v-if="row.item.worker">{{ getUserName(row.item.worker) }}</span></td>
                        <td class="text-left">{{row.item.status}}</td>
                    </tr>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import BarChart from '@/components/chart/bar'
	export default {
        components: {
            BarChart
        },
        
		data() {
            return {
                loadedChart: false,
                loadedTable: false,
                dataBar: 'costcenter',
                workOrderList: [],
                orderChart:[],
                filterDataChart: {
                    frequency: 'daily',
                    'start-date': '2021-01-01',
                    'end-date':'0000-00-00',
                    location: null,
                    scope: null,
                },
                selectedMonth: '',
                headers: [
                    { text: 'Due Date', value: 'due_dt', width: 200 },
                    { text: 'Title', value: 'name' },
                    { text: 'Supervisor', value: 'supervisor' },
                    { text: 'Worker', value: 'worker' },
                    { text: 'Status', value: 'status' },
                ],
                daysInMonth: 31,
                chartOptions: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Urgency'
                    },
                    responsive: true,
                    scales: {
                    }
                },
                dataChart: {},
                locationOption: [
                    { name: 'All', id: null}
                ],
                scopeOption: [
                    { name: 'All', id: null}
                ],
                filterLocName: 'All',
                dialogLoc: false
            }
        },
        computed: {
            loaded:function() {
                if (this.loadedChart && this.loadedTable)
                    return true;
            },
            typeChart:function() {
                return this.dataChart;
            },
            chartTotalOrderCount:function() {
                return this.dataChart.data.grand_total;
            },
            filterDataTable:function() {
                var obj = {}
                obj.location = this.filterDataChart.location;
                obj.due_dt_date_after = this.$moment(this.filterDataChart['start-date']).format("YYYY-MM-DD");
                obj.due_dt_date_before = this.$moment(this.filterDataChart['end-date']).format("YYYY-MM-DD");
                obj.scope = this.filterDataChart.scope;
                return obj;
            },
            frequencyOption: function() {
                var arr = []
                var year = [2021,2022,2023,2024];
                var months = this.$moment.monthsShort();

                year.forEach((year) => {
                    months.forEach((month) => {
                        arr.push ( { label: month + ' ' +year, value: '01 '+ month + ' ' + year });
                    }) 
                })
                return arr;
            },
            
        },
        methods: {
            load() {
				this.getUsers().then(res => this.userList = res);
                this.filterDataChart['start-date'] = this.$moment().startOf('month').format('YYYY-MM-DD');
                this.filterDataChart['end-date'] = this.$moment().endOf('month').format('YYYY-MM-DD');
                this.selectedMonth = this.$moment().format('01 MMM YYYY');
                this.daysInMonth = this.$moment().daysInMonth();
                this.loadWorkOrder();
				this.getScope().then((res) => {
                    res.forEach((scope) => {
                        this.scopeOption.push(scope)
                    })
                });
                this.getLocations().then((res) => { 
                    res.forEach((loc) => {
                        this.locationOption.push(loc)
                    })
                });
            },
            loadWorkOrder() {
                this.getJsonChart(this.dataBar, this.filterDataChart.frequency, this.filterDataChart['end-date'], this.filterDataChart['start-date'], this.filterDataChart.location, this.filterDataChart.scope).then((res) => {
                    this.dataChart = res;
                });
                this.getWorkOrder().then((res) => {
                    this.workOrderList = res;
                });
            },
			async getOrderChart() {
                try {
                    const res = await this.$api.statistics.orderRange( this.filterDataChart );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getWorkOrder() {
                try {
                    this.loadedTable = false
                    const res = await this.$api.workOrder.search( this.filterDataTable );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loadedTable = true
                }
            },
			async getJsonChart(category, frequency, end_date, start_date, location, scope) {
                try {
                    this.loadedChart = false
                    let res;
                    res = await this.$api.statistics.jsonChart({ format:'json', category: category, frequency: frequency, 'end-date': end_date, 'start-date': start_date,  'location': location, 'scope': scope });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loadedChart = true
                }
			},
            async getLocations() {
                try {
                    const res = await this.$api.location.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.userList && this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            },
			async getUsers() {
				try {
					this.loading = true
					const res = await this.$api.user.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
			async getScope() {
				try {
					this.loading = true
					const res = await this.$api.workScope.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            changeLoc(e) {
                this.filterDataChart.location = e[0].id
                this.filterLocName = e[0].name
                this.dialogLoc = false
            },
        },
        mounted(){
            this.load();
        },
        watch: {
            'dataBar': function(newVal) {
                this.chartOptions.title.text = newVal.charAt(0).toUpperCase() + newVal.slice(1);
                this.loadWorkOrder();
            },
            filterDataChart: {
                handler(val){
                    this.loadWorkOrder();
                },
                deep: true
            },
            'selectedMonth': function(newVal) {
                this.filterDataChart['start-date'] = this.$moment(newVal).startOf('month').format('YYYY-MM-DD');
                this.filterDataChart['end-date'] = this.$moment(newVal).endOf('month').format('YYYY-MM-DD');
            },
        }
    }
</script>