<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="this.mastCompList"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left">{{row.item.abbreviation }}</td>
                <td class="text-left">{{  getSystemName(row.item.system) }}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		data: () => ({
            loading: true,
			success: false,
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', },
            { text: 'Name', value: 'name' },
            { text: 'Abbreviation', value: 'abbreviation' },
            { text: 'System', value: 'system' },
            { text: ''}
            ],
            mastCompList: [],
            systemList: [],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
        }),
		methods: {
            // Main Function
            list() {
			    this.getMastComps(1).then((res) => {
                    this.mastCompList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getMastComps(page) {
                try {
                    this.loading = true
                    const res = await this.$api.mastComp.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'masterComponentForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.mastComp.delete(id).then(res => this.list() );
                    }
                })
            },
            // Additional Infos
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getSystemName(id) {
                if ( this.systemList.length !== 0 && id ) {
                    return this.systemList.find(system => system.id === id).name;
                }
            },
		},
		mounted () {
            this.list();
            this.getSystemList();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getMastComps(this.pagination.current).then((res) => {
                        this.mastCompList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>