<template>
    <div>
        <v-card outlined class="mb-4" v-for="(location,i) in indexedLocation" :key="i">
            <v-card-text>
                <v-row align="center">
                    <v-col cols="12" sm="3" md="6">
                        <v-text-field
                            label="Name"
                            v-model="location.name"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="3" md="2" class="text-right">
                        <v-btn-toggle>
                            <v-btn small fab icon color="grey"  @click="edit(location.id)">
                            <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey"  @click="save(location.id, location.name)">
                            <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" @click="remove(location.id)" >
                            <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newLocation()">Add Child Location</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            parentLocationId: {
                type: Number
            },
        },
		data() {
            return {
                id: [],
                loading: true,
                locationsbyLocation: [],
                additionalDataLoaded: false,
            }
        },
        computed: {
            indexedLocation () {
                return this.locationsbyLocation.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.parentLocationId) {
                    this.getLocationsbyChecklist().then((res) => {
                        this.locationsbyLocation = res;
                    });
                }
            },
			async getLocationsbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.location.search({ parent: this.parentLocationId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newLocation() {
                // this.$router.push({ name: 'locationForm', params: {parentLocationId: this.parentLocationId } })
                this.locationsbyLocation.push({id: '', name: '', own_code: 0, parent: this.parentLocationId });
            },
            save(id, name ) {
                this.saveLocation(id, name).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveLocation(id, name, own_code, is_active) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.location.save( {id: id, name: name, parent: this.parentLocationId});
                    } else {
                        res = await this.$api.location.new({ name: name, parent: this.parentLocationId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            edit(id) {
                this.$emit('editAnotherLocation', id)
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.location.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.locationsbyLocation, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        },
        watch: {
            'parentLocationId': function(newVal) {
                if (this.parentLocationId) {
                    this.getLocationsbyChecklist().then((res) => {
                        this.locationsbyLocation = res;
                    });
                }
            },
        }
    }
</script>