<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
        <v-card class="pa-3 mb-3 mx-auto">
        <v-form lazy-validation
            ref="form"
            v-model="valid">
            <v-container fluid >
                <v-row>
                    <v-col cols="12" sm="7" md="10">
                        <v-text-field
                            :label="input.id ? 'Name' : 'Name will be generated automatically'" 
                            v-model="input.name"
                            :rules="nameRules"
                            :counter="50"
                            readonly
                        ></v-text-field>
                        <div class="caption">Status</div>
                         <v-switch inset v-model="input.is_active" label="Status" readonly></v-switch>
                    </v-col>
                    <v-col cols="12" sm="5" md="2">
                        <v-img class="ba-1" contain v-if="info.qr_code" :src="info.qr_code" width="180" height="180"></v-img>
                        <v-switch inset v-model="input.qr_scan_required" label="Qr Scan Required"></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <wysiwyg
                        :content.sync="input.description"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                            :readonly="!editMode"
                            :items="systemList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="System" 
                            v-model="info.system"
                            @change="getGeneric()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete ref="internal" 
                            :readonly="!editMode"
                            v-model="input.generic"
                            hide-no-data
                            no-filter
                            :items="genericList" 
                            name="item"
                            item-value="id"
                            label="Generic" 
                            item-text="name"
                            @change="getUnitNamebyGeneric()"
                            >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="input.purchase_date"
                                label="Purchase Date"
                                prepend-icon="mdi-calendar-check"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="input.purchase_date" no-title>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        
                        <v-menu
                            ref="menuWarranty"
                            v-model="menuWarranty"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="input.warranty_date"
                                label="Warranty Expiry Date"
                                prepend-icon="mdi-calendar-check"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="input.warranty_date" no-title>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Purchase Cost" 
                            v-model="input.purchase_cost"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Manufacturer / Supplier" 
                            v-model="input.manufacturer"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Manufacturer Serial Number" 
                            v-model="input.manufacturer_tag"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Asset Tag" 
                            v-model="input.manual_tag"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Model" 
                            v-model="input.model"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Person in charge" 
                            v-model="input.person_in_charge"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            :label="'Capacity - '+ info.capacity_unit" 
                            v-model="input.capacity"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            :label="'Consumption - '+ info.consumption_unit" 
                            v-model="input.consumption"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider light class="mt-3 mb-3"></v-divider>
                <div class="subtitle text-left mb-3">Location</div>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <div v-if="!editMode && input.location">{{getLocName(input.location)}}</div>
                        <v-card outlined class="scroll" max-height="300" 
                        v-if="editMode">
                            <v-sheet class="pa-4 secondary">
                                <v-text-field
                                    v-model="search"
                                    label="Search Location"
                                    flat
                                    solo
                                    hide-details
                                    clearable
                                    clear-icon="mdi-close-circle-outline"
                                ></v-text-field>
                            </v-sheet>
                            <v-card-text >
                                <v-treeview
                                    v-if="locationLoaded"
                                    :items="locationListFull"
                                    :active="locationSelected"
                                    v-model="locationSelected"
                                    :search="search"
                                    :filter="locFilter"
                                    hoverable
                                    activatable
                                    :open-all="hasLoc"
                                    dense
                                    selection-type="independent"
                                    expand-icon="mdi-chevron-down"
                                    on-icon="mdi-bookmark"
                                    off-icon="mdi-bookmark-outline"
                                    @update:active="changeLoc"
                                ></v-treeview>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="3">
                        <v-autocomplete ref="internal" 
                            :readonly="!editMode"
                            v-model="input.level_two"
                            cache-items
                            hide-no-data
                            :items="level2List" 
                            name="item"
                            item-value="id"
                            :label="level2Label" 
                            item-text="name">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        :items="level3List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level3Label" 
                        v-model="input.level_three"
                        ></v-select>
                    </v-col> -->
                </v-row>
                <!-- <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        :items="level4List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level4Label" 
                        v-model="input.level_four"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        :items="level5List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level5Label" 
                        v-model="input.location"
                        ></v-select>
                    </v-col>
                </v-row> -->
                <v-row class="text-right">
                    <v-col cols="12">
                        <v-btn class="mr-4" v-if="!editMode && id" @click="editMode = true"><v-icon class="mr-2">mdi-pencil</v-icon> Edit</v-btn>
                        <v-btn class="mr-4" v-if="editMode" @click="editMode = false"><v-icon class="mr-2">mdi-close-box-multiple</v-icon> Cancel Edit</v-btn>
                        <v-btn class="mr-4" v-if="editMode || !id" :disabled="!valid" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                    </v-col>
                </v-row><v-divider light></v-divider>
                <v-row v-if="paramsbyAsset.length > 0">
                    <v-col cols="12">
                        <div class="subtitle text-left mb-3">Parameters in this Asset</div>
                        <v-data-table 
                        :loading="this.loadingParams" loading-text="Loading... Please wait"
                        :headers="headersParams"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items="this.paramsbyAsset"
                        :items-per-page="20"
                        :footer-props="{
                            itemsPerPageOptions: [20,40,60],
                        }" 
                        class="full-width">
                        <template v-slot:item="row">
                            <tr>
                            <td class="text-left" v-if="row.item.id">{{ row.item.name}}</td>
                            <td class="text-left">
                                {{row.item.status_mode}}
                            </td>
                            <td class="text-left">
                                {{row.item.lower_limit}}
                            </td>
                            <td class="text-left">
                                {{row.item.upper_limit}}
                            </td>
                            <td class="text-left">
                                <v-text-field
                                    v-if="row.item.status_mode == 'ABOVE_LIMIT' || row.item.status_mode == 'BELOW_LIMIT' || row.item.status_mode == 'WITHIN_LIMIT'"
                                    label="Number Reading"
                                    v-model="row.item.number_reading" 
                                ></v-text-field>
                            </td>
                            <td class="text-left">
                                <div v-if="row.item.status_mode !== 'MANUAL'">{{row.item.status}}</div>
                                <v-select
                                v-if="row.item.status_mode == 'MANUAL'"
                                :items="statusOptions" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Status" 
                                v-model="row.item.status"
                                ></v-select>
                            </td>
                            <td>
                                <v-btn-toggle>
                                    <v-btn v-if="row.item.status_mode !== 'MANUAL'" small fab  @click="saveParam(row.item.id, row.item.number_reading, 'UNKNOWN')">
                                    <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                    <v-btn v-if="row.item.status_mode == 'MANUAL'" small fab  @click="saveParam(row.item.id, null, row.item.status)">
                                    <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                                <!-- <v-btn text class="mr-4" @click="delComponent(index, row.item.id, row.item.name, row.item.base_component)"><v-icon>mdi-delete</v-icon></v-btn> -->
                            </td>
                            </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                </v-row>
                <v-divider light></v-divider>
                <v-row>
                    <v-col>
                        <div class="subtitle text-left mb-3">Orders Related to Asset</div>
                        <v-data-table 
                            :headers="headersOrder"
                            :sort-by="['id']"
                            :sort-desc="[true]"
                            :items="this.relatedOrderList"
                            :items-per-page="20"
                            :footer-props="{
                                itemsPerPageOptions: [20,40,60],
                            }" 
                            class="full-width">
                            <template v-slot:item="row">
                                <tr>
                                <td class="text-left" v-if="row.item.id">{{ row.item.name}}</td>
                                <td class="text-left">
                                    {{row.item.due_dt | datetimestamp}}
                                </td>
                                <td class="text-left">
                                    {{row.item.status}}
                                </td>
                                <td class="text-left">
                                    {{getUserName(row.item.supervisor)}}
                                </td>
                                <td class="text-left">
                                    {{getUserName(row.item.worker)}}
                                </td>
                                <td>
                                    <v-btn-toggle>
                                        <v-btn small fab  @click="goToOrder(row.item.id)">
                                        <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
        components: {
            wysiwyg
        },
		data: () => ({
            headersParams: [
            { text: 'Name', align: 'left', sortable: true, value: 'name', },
            { text: 'Status Mode', value: 'status_mode' },
            { text: 'Lower Limit', value: 'lower_limit' },
            { text: 'Upper Limit', value: 'upper_limit' },
            { text: 'Reading', value: 'number_reading' },
            { text: 'Status', value: 'status' },
            { text: ''}
            ],
            headersComps: [
            { text: 'Base Component', align: 'left', sortable: true, value: 'id', },
            { text: 'Component', value: 'name' },
            { text: 'Condition', value: 'condition' },
            { text: ''}
            ],
            headersOrder: [
            { text: 'Name', align: 'left', sortable: true, value: 'name', },
            { text: 'Due', value: 'due_dt'},
            { text: 'Status', value: 'status' },
            { text: 'Supervisor', value: 'supervisor' },
            { text: 'Worker', value: 'worker'},
            { text: ''}
            ],
            statusOptions: [
                { name: 'unknown', value:'UNKNOWN' }, 
                { name: 'good', value:'GOOD' }, 
                { name: 'bad', value:'BAD' }, 
                { name: 'other', value:'OTHER' }
            ],
            systemList:[],
            loading: true,
            editMode: false,
            menu: false,
            menuWarranty: false,
            input: {
                id: '',
                name: '',
                condition: '',
                generic: '',
                location: '',
                is_active: false,
                description: '',
                purchase_cost: 0,
                purchase_date: null,
                manufacturer: '',
                manufacturer_tag: '',
                manual_tag: '',
                model: '',
                warranty_date: null,
                person_in_charge: '',
                capacity: 0,
                consumption: 0,
                qr_scan_required: false,
            },
            info: {
                system: {},
                qr_code: false,
                capacity_unit: '',
                consumption_unit: '',
            },
            genericList: [],
            baseCompList: [],
            locationList: [],
            userList: [],
            locationListFull: [],
            genericParameterList:[],
            loadingComps: true,
            paramsbyAsset: [],
            loadingParams:true,
            valid: true,
            nameRules: [
                v => (v.length <= 50) || 'Name must be less than 50 characters',
            ],
            locationRules: [
                v => !!v || 'Location is required',
            ],
            locationSelected: null,
            locationLoaded:false,
            hasLoc: false,
            search: null,
            caseSensitive: false,
            relatedOrderList: [],
        }),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
            id: Number   
        },
        computed: {
            locFilter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            },
        },
		methods: {
			quitEdit() {
                this.$router.push({ name: 'assetAssetList' })
            },
            form() {
                if (this.id) {
                    this.getAsset().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.generic = res.generic;
                        this.input.condition = res.condition;
                        this.input.is_active = res.is_active;
                        this.input.qr_scan_required = res.qr_scan_required;
                        this.info.qr_code = res.qr_code;

                        this.info.system = res.system;

                        this.input.location = res.location;
                        if (res.location) {
                            this.locationSelected = JSON.parse(JSON.stringify(res.location.toString().split().map(Number)));
                        }
                        this.input.purchase_cost = res.purchase_cost;
                        this.input.purchase_date = res.purchase_date;
                        this.input.manufacturer = res.manufacturer;
                        this.input.manufacturer_tag = res.manufacturer_tag;
                        this.input.manual_tag = res.manual_tag;
                        this.input.model = res.model;
                        this.input.warranty_date = res.warranty_date;
                        this.input.person_in_charge = res.person_in_charge;
                        this.input.capacity = res.capacity;
                        this.input.consumption = res.consumption;
                        this.getUnitNamebyGeneric();
                    });
                } else {
                    this.editMode = true;
                }
            },
			async getAsset() {
                try {
                    this.loading = true
                    const res = await this.$api.assetAsset.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            save() {
                this.saveAsset().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'assetAssetList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveAsset() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.assetAsset.save( this.input );
                    } else {
                        res = await this.$api.assetAsset.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            // Additional Infos
            getAdditionalData() {
			    this.getSystems().then(res => this.systemList = res);
                this.getGeneric();
                this.getBaseParameters().then(res => this.genericParameterList = res);
                this.getLocation().then(res => this.locationList = res);
				this.getUsers().then(res => this.userList = res);
                if (this.id) {
                    this.getParamList();
                    this.getRelatedOrder().then((res) => {
                        this.relatedOrderList = res;
                    });
                }
                this.getAllLoc().then((res) => {
                    this.locationListFull = res;
                    if (this.input.location) {
                        this.search = this.getLocName(this.input.location)
                        this.hasLoc = true;
                    }
                    this.locationLoaded = true;
                });
                
            },
			async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getGeneric() {
                this.getGenericbySystem().then((res) => {
                    this.genericList = res;
                });
            },
			async getGenericbySystem() {
                try {
                    const res = await this.$api.assetGeneric.search({ system: this.info.system });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseParameters() {
                try {
                    const res = await this.$api.assetGenericParameter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRelatedOrder() {
                try {
                    const res = await this.$api.workOrder.search({ asset: this.id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
				try {
					this.loading = true
					const res = await this.$api.user.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            goToOrder(id) {
                this.$router.push({ name: 'workOrderForm', params: { id } })
            },
            getParamList() {
                this.getParamsbyAsset().then((res) => {
                    this.paramsbyAsset = res;
                    this.loadingParams = false;
                });
            },
			async getParamsbyAsset() {
                try {
                    const res = await this.$api.assetAssetParameter.search({ asset: this.id });
                    console.log(res)
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            saveParam(id, number_reading, status) {
                this.saveParameter(id, number_reading, status).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.getParamList();
                        })
                    }
                });
			},
			async saveParameter(id, number_reading, status) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.assetAssetParameter.save( {id: id, number_reading: number_reading, status: status, asset: this.input.id } );
                    } else {
                        res = await this.$api.assetAssetParameter.new( {number_reading: number_reading, status: status, asset: this.input.id } );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async getLocation() {
                try {
                    this.loading = true
                    const res = await this.$api.location.list({ depth: 0 });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getLocName(id) {
                if ( this.locationList.length !== 0 && id ) {
                    return this.locationList.find(loc => loc.id === id).name;
                } else {
                    return;
                }
            },
			async getAllLoc() {
                try {
                    const res = await this.$api.location.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeLoc(e) {
                this.locationSelected = e;
                this.input.location = this.locationSelected[0];
            },
            getUnitNamebyGeneric() {
                this.getUnitDatabyGeneric().then((res) => {
                    this.info.capacity_unit = res.capacity_unit_data.symbol;
                    this.info.consumption_unit = res.consumption_unit_data.symbol;
                });
            },
			async getUnitDatabyGeneric() {
                try {
                    const res = await this.$api.assetGeneric.get(this.input.generic);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.success = true;
		},
	}
</script>