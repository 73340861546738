<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto">
                <v-form lazy-validation
                ref="form"
                v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :readonly="input.status == 'AUDITED' || input.status == 'COMPLETED'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Status" 
                                v-model="input.status"
                                hide-details="auto"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="2" sm="2" md="2">
                            <v-select
                            :items="statusOptions" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Status" 
                            v-model="input.status"
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="2" sm="2" md="2">
                            <v-switch v-model="input.is_active" label="Active"></v-switch>
                        </v-col> -->
                    </v-row>
                    <v-row>
                        <v-col>
                            <wysiwyg
                            :content.sync="input.description"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="text-left">
                            <v-btn color="primary" v-if="input.status !== 'AUDITED' &&  input.status !== 'COMPLETED'" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            <!-- <v-btn color="primary" v-if="input.status == 'AUDITED' || input.status == 'COMPLETED'" class="ml-2" :disabled="!valid" @click="getInvoice('web')"><v-icon class="mr-2">mdi-file-pdf</v-icon>Web Invoice</v-btn> -->
                            <v-btn color="primary" v-if="input.status == 'AUDITED' || input.status == 'COMPLETED'" class="ml-2" :disabled="!valid" @click="getInvoice('pdf')"><v-icon class="mr-2">mdi-printer</v-icon>PDF</v-btn>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <v-btn color="secondary" v-if="input.status == 'CREATED'" :disabled="!valid" @click="bulkStatus('VETTED')" class="mr-2"><v-icon class="mr-2">mdi-eye-check</v-icon>Vetted</v-btn>
                            <v-btn color="secondary" v-if="input.status == 'VETTED'" :disabled="!valid" @click="bulkStatus('AUDITED')" class="mr-2"><v-icon class="mr-2">mdi-check-decagram</v-icon>Audited</v-btn>
                            <v-btn color="secondary" v-if="input.status == 'AUDITED'" :disabled="!valid" @click="bulkStatus('COMPLETED')" class="mr-2"><v-icon class="mr-2">mdi-check-all</v-icon>Completed</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="this.input.id">
                        <v-col>
                            <v-data-table
                                :loading="invoiceOrderLoading"
                                :headers="headerOrderSet"
                                v-if="order_set_loaded"
                                :items="order_set_table"
                                :items-per-page="200"
                                :expanded.sync="expanded"
                                :single-expand="singleExpand"
                                @item-expanded="loadHistory"
                                hide-default-footer
                                class="elevation-1"
                            >
                            <template v-slot:item="{ item, expand, isExpanded }">
                                <tr>
                                    <td class="text-left">{{ order_set_table.indexOf(item)+1 }}</td>
                                    <td class="text-left">{{item.name}} (ID: {{item.id}})</td>
                                    <!-- <td class="text-left">{{item.status}}</td> -->
                                    <td class="text-left" v-if="item">{{ item.net }}</td>
                                    <td class="text-left" v-if="item">{{ getUserName(item.supervisor) }}</td>
                                    <td class="text-left" v-if="item">{{ getUserName(item.worker)}}</td>
                                    <td class="text-left" v-if="item">{{ item.claimed_ts | moment_dtstamp }}</td>
                                    <td style="width:150px" class="text-xs-center">
                                        <v-btn-toggle>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small fab icon @click="expand(!isExpanded)"
                                                        v-bind="attrs"
                                                        v-on="on">
                                                        <v-icon>mdi-history</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Order History</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small fab icon color="grey"  @click="editOrder(item.id)"
                                                        v-bind="attrs"
                                                        v-on="on">
                                                        <v-icon  v-if="input.status == 'AUDITED' || input.status == 'COMPLETED'" >mdi-eye</v-icon>
                                                        <v-icon  v-if="input.status !== 'AUDITED' &&  input.status !== 'COMPLETED'" >mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span v-if="input.status == 'AUDITED' || input.status == 'COMPLETED'" >View Order</span>
                                                <span v-if="input.status !== 'AUDITED' && input.status !== 'COMPLETED'" >Edit Order</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small v-if="item.status == 'COMPILED'" fab icon color="grey"  @click="bulkOrderStatus(item.id, 'VETTED')"
                                                        v-bind="attrs"
                                                        v-on="on">
                                                        <v-icon>mdi-eye-check</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vet Order</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small v-if="item.status == 'VETTED'" fab icon color="grey"  @click="bulkOrderStatus(item.id, 'AUDITED')"
                                                        v-bind="attrs"
                                                        v-on="on">
                                                        <v-icon>mdi-check-decagram</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Audit Order</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small fab icon color="grey"  @click="generatePDF('workorder', item.id)"
                                                        v-bind="attrs"
                                                        v-on="on">
                                                        <v-icon>mdi-printer</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Print Order</span>
                                            </v-tooltip>
                                        </v-btn-toggle>
                                    </td>
                                </tr>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length" style="border-bottom:1px solid #ddd">
                                        <div class="pa-4" v-for="(order_detail,orderDetailIndex) in item.order_detail_set" v-bind:key="orderDetailIndex">
                                            <strong>{{getDetailName(order_detail.detail)}} ({{order_detail.unit_data.symbol}}) History</strong><br/>
                                            <ol>
                                                <li v-for="(history, orderHistoryIndex) in order_detail.history" v-bind:key="orderHistoryIndex">
                                                    <div v-if="history.history_type == '+'">
                                                        Quantity set to {{history.quantity}} by {{getUserName(history.created_by)}} on {{history.modified_ts | moment_dtstamp }}
                                                    </div>
                                                    <div v-if="history.history_type == '~'">
                                                        Quantity adjusted to {{history.quantity}} by {{getUserName(history.modified_by)}} on {{history.modified_ts | moment_dtstamp }}
                                                    </div>
                                                    <div v-if="history.history_type == '-'">
                                                        Quantity removed by {{getUserName(history.created_by)}} on {{history.modified_ts | moment_dtstamp }}
                                                    </div>
                                                </li>
                                            </ol>
                                        </div>
                                    </td>
                                </template>
                            </v-data-table>
                            <div class="pa-4">Total Cost: {{info.cost_formatted}}</div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
            <v-card outlined class="pa-3 mb-3 mx-auto text-left">
                <v-card-title>Time Reports</v-card-title>
                <v-card-text>
                Vetted on: {{info.vetted_ts}}<br/>
                Audited on: {{info.audited_ts}}<br/><br/>
                Time taken to Vet from Created: {{info.created_to_vetted_du | formatTime}}<br/>
                Time taken to Audit from Vetted : {{info.vetted_to_audited_du | formatTime}}<br/>
                Time taken to Audit from Created: {{info.created_to_audited_du | formatTime}}<br/>
                </v-card-text>
            </v-card>
            <v-card v-if="generatedPDF" class="pa-3 mb-3 pdf-wrapper" id="pdfWrap">
                <vue-html2pdf
                    style="margin: 0 auto" 
                    :show-layout="false"
                    :float-layout="false"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="3000"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"
                    ref="html2Pdf"
                    :html-to-pdf-options="{scale: 1, margin:15, filename: this.pdfFilename}"
                    @hasDownloaded="hasDownloaded($event)"
                >
                    <section slot="pdf-content">
                        <section class="pdf-item" v-if="this.pdfMode !== 'workorder'">
                            <table class="text-center" width="100%" v-if="pdfData.worker">
                                <tr v-if="pdfData.worker.full_name">
                                    <td>{{pdfData.worker.full_name}}</td>
                                </tr>
                                <tr v-if="pdfData.worker.address_1">
                                    <td>{{ pdfData.worker.address_1 }}</td>
                                </tr>
                                <tr v-if="pdfData.worker.address_2">
                                    <td>{{ pdfData.worker.address_2 }}</td>
                                </tr>
                                <tr v-if="pdfData.worker.address_3">
                                    <td>{{ pdfData.worker.address_3 }}</td>
                                </tr>
                                <tr>
                                    <td>Tel: {{pdfData.worker.phone}} &nbsp;&nbsp;&nbsp; Email: {{pdfData.worker.email}}</td>
                                </tr>
                                <tr>
                                    <td>{{pdfData.worker.tax_reference}}</td>
                                </tr>
                            </table>
                            <br/>
                            <br/>
                            <h2 class="text-center">INVOIS</h2>
                            <br/>
                            <br/>
                            <table class="text-left" width="100%">
                                <tr>
                                    <td valign="top" >
                                        <div v-html="pdfData.to" style="white-space: pre-line;"></div>
                                        <!-- {{getUserName(claimInfo.vetted_by)}}<br/>
                                        {{core__organization_name}}<br/>
                                        {{pdfData.costcenter}}<br/> -->
                                    </td>
                                    <td valign="top" >
                                        No. Invois: {{pdfData.id}}<br/>
                                        Tarikh: {{claimInfo.audited_ts | moment_dtstamp}}<br/>
                                        No. Indent:<br/>
                                    </td>
                                </tr>
                            </table>
                            <br/>
                            <h3 v-if="pdfMode !== 'summary'">{{pdfData.name}}</h3>
                            <p v-if="claimInfo.description" v-html="claimInfo.description"></p>
                            <br/>
                            <table class="table table-flexible table-bordered text-left" width="100%">
                                <thead>
                                    <tr>
                                        <th width="100">NO</th>
                                        <th width="70%">KETERANGAN</th>
                                        <th width="20%" class="text-right">JUMLAH (RM)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="pdfMode !== 'summary'">
                                    <tr v-for="(order, index) in order_set_table" v-bind:key="index">
                                        <td>{{index+1}}</td>
                                        <td>
                                            Order ID:{{order.id}}<br/>
                                            Ext Ref ID: {{order.ext_ref_id}}<br/>
                                            Name: {{order.name}}
                                        </td>
                                        <td class="text-right">{{order.net_formatted}}</td>
                                    </tr>
                                    </template>
                                    <template v-if="pdfMode == 'summary'">
                                    <tr>
                                        <td>1</td>
                                        <td>{{pdfData.name}}</td>
                                        <td class="text-right">{{pdfData.cost_formatted}}</td>
                                    </tr>
                                    </template>
                                </tbody>
                                <tfoot>
                                    <tr class="text-left">
                                        <td></td>
                                        <td>
                                            BANK: {{bank_name}}<br/>
                                            NO AKAUN: {{bank_account}}
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr class="text-right">
                                        <td colspan="2">Jumlah Besar:</td>
                                        <td class="text-right">{{pdfData.cost_formatted}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <!-- <br/> -->
                            <!-- <div v-if="pdfData.cost"><strong>RINGGIT MALAYSIA: {{inWords(parseFloat( pdfData.cost.replace(/,/g, '')).toFixed(2))}} {{centsWords(parseFloat( pdfData.cost.replace(/,/g, '')).toFixed(2))}}</strong></div>
                            <br/>
                            <br/> -->
                            <!-- <br/>
                            <br/> -->
                            <!-- <table width="100%">
                                <tr>
                                    <td>Disediakan Oleh: {{getUserName(claimInfo.vetted_by)}}</td>
                                    <td>Disemak Oleh: {{getUserName(claimInfo.audited_by)}}</td>
                                </tr>
                            </table> -->
                            <template v-if="pdfMode == 'full'">
                            <table class="table table-bordered text-left mb-4 mt-4" width="100%">
                                <thead>
                                    <tr>
                                        <th width="150">
                                            
                                        </th>
                                        <th width="30%" style="height:40px">
                                        </th>
                                        <th width="30%">
                                        </th>
                                        <th width="30%">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="height:100px">
                                            Stamp
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:100px">
                                            Signature
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Name
                                        </td>
                                        <td>
                                            <span v-if="pdfData.worker.full_name || pdfData.worker.short_name">{{pdfData.worker.full_name || pdfData.worker.short_name}}</span>
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <span v-if="pdfData.created_by.full_name || pdfData.created_by.short_name">{{pdfData.created_by.full_name || pdfData.created_by.short_name }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Title
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Date
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </template>
                        </section>
                        <!-- <hr v-if="pdfMode !== 'workorder'" /> -->
                        <template v-if="pdfMode == 'full'">
                            <div class="html2pdf__page-break"/>
                        <section  class="pdf-item tablebreakbefore py-4"  v-for="(order_set, orderSetIndex) in pdfData.order_set" v-bind:key="orderSetIndex">
                            <table class="table table-bordered table-enforce text-left" width="100%">
                                <thead>
                                    <tr>
                                        <th width="120" colspan="1">No. {{orderSetIndex+1}}</th>
                                        <th width="100" colspan="5">
                                            Order ID:{{order_set.id}}<br/>
                                            Ext Ref ID: {{order_set.ext_ref_id}}<br/>
                                            Name: {{order_set.name}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(order_heading, orderHeadingIndex) in order_set.order_heading_set" v-bind:key="orderHeadingIndex">
                                    <tr>
                                        <td colspan="6" style="background:#eee">{{order_heading.heading}} </td>
                                    </tr>
                                    <tr>
                                        <th width="120">#</th>
                                        <th width="60%">Detail</th>
                                        <th width="120" class="text-right">Qty</th>
                                        <th width="40px" class="text-right">Unit</th>
                                        <th width="100" class="text-right">Cost/unit</th>
                                        <th width="100" class="text-right">Cost</th>
                                    </tr>
                                    <tr v-for="(order_detail, orderDetailIndex) in order_heading.order_detail_set" v-bind:key="orderDetailIndex">
                                        <td>{{orderDetailIndex+1}}</td>
                                        <td>{{order_detail.code}}: {{order_detail.path}} </td>
                                        <td class="text-right">{{order_detail.quantity_formatted}}</td>
                                        <td class="text-right">{{order_detail.unit}}</td>
                                        <td class="text-right">{{order_detail.unit_cost_formatted}}</td>
                                        <td class="text-right">{{order_detail.cost_formatted}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Gross:</td>
                                        <td class="text-right">
                                            {{  order_heading.gross_formatted }}
                                        </td>
                                    </tr><tr>
                                        <th width="120">#</th>
                                        <th width="60%" colspan="3">Addendum</th>
                                        <th width="120" class="text-right">%</th>
                                        <th width="100" class="text-right">Cost</th>
                                    </tr>
                                    <tr v-for="(order_addendum, orderAddendumIndex) in order_heading.order_addendum_set" v-bind:key="orderAddendumIndex">
                                        <td>{{orderAddendumIndex+1}}</td>
                                        <td colspan="3">{{order_addendum.addendum}} </td>
                                        <td class="text-right">{{order_addendum.percentage}}</td>
                                        <td class="text-right">{{order_addendum.cost_formatted}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Net:</td>
                                        <td class="text-right">
                                            {{  order_heading.net_formatted }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5"><strong>Order Gross:</strong></td>
                                        <td class="text-right">
                                            <strong>{{  order_set.gross_formatted }}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="background:#ccc"><strong>Order Net:</strong></td>
                                        <td class="text-right" style="background:#ccc">
                                            <strong>{{  order_set.net_formatted }}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </section>
                    </template>
                    <template v-if="pdfMode == 'workorder'">
                        <section  class="pdf-item tablebreakbefore py-4"  v-for="(order_set, orderSetIndex) in pdfData.order_set.filter(order => (order.id == workorderPdfId))" v-bind:key="orderSetIndex">
                            <table class="table table-bordered table-enforce text-left" width="100%">
                                <thead>
                                    <tr>
                                        <th width="120" colspan="1">Invoice ID</th>
                                        <th width="100" colspan="5">
                                            {{ id }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th width="120" colspan="1">No. {{orderSetIndex+1}}</th>
                                        <th width="100" colspan="5" style="padding:0;">
                                            <table class="table table-bordered table-enforce text-left" style="margin:0; width: 100%; max-width: 100%; border:0;">
                                                <tr>
                                                    <td style="border-left:0; border-top:0;"><span style="font-weight:normal">Order ID:</span> {{order_set.id}}</td>
                                                    <td style="border-top:0;"><span style="font-weight:normal">Ext Ref ID:</span> {{order_set.ext_ref_id}} </td>
                                                    <td style="border-left:0; border-top:0;"><span style="font-weight:normal">WIP Datetime:</span> {{ order_set.wip_ts | moment_dtstamp  }}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="text-center" style="border-left:0; border-right:0; border-bottom:0;"><span style="font-weight:normal">Name:</span> {{order_set.name}}  </td>
                                                </tr>
                                            </table>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(order_heading, orderHeadingIndex) in order_set.order_heading_set" v-bind:key="orderHeadingIndex">
                                    <tr>
                                        <td colspan="6" style="background:#eee">{{order_heading.heading}} </td>
                                    </tr>
                                    <tr>
                                        <th width="120">#</th>
                                        <th width="60%">Detail</th>
                                        <th width="120" class="text-right">Qty</th>
                                        <th width="40px" class="text-right">Unit</th>
                                        <th width="100" class="text-right">Cost/unit</th>
                                        <th width="100" class="text-right">Cost</th>
                                    </tr>
                                    <tr v-for="(order_detail, orderDetailIndex) in order_heading.order_detail_set" v-bind:key="orderDetailIndex">
                                        <td>{{orderDetailIndex+1}}</td>
                                        <td>{{order_detail.code}}: {{order_detail.path}} </td>
                                        <td class="text-right">{{order_detail.quantity_formatted}}</td>
                                        <td class="text-right">{{order_detail.unit}}</td>
                                        <td class="text-right">{{order_detail.unit_cost_formatted}}</td>
                                        <td class="text-right">{{order_detail.cost_formatted}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Gross:</td>
                                        <td class="text-right">
                                            {{  order_heading.gross_formatted }}
                                        </td>
                                    </tr><tr>
                                        <th width="120">#</th>
                                        <th width="60%" colspan="3">Addendum</th>
                                        <th width="120" class="text-right">%</th>
                                        <th width="100" class="text-right">Cost</th>
                                    </tr>
                                    <tr v-for="(order_addendum, orderAddendumIndex) in order_heading.order_addendum_set" v-bind:key="orderAddendumIndex">
                                        <td>{{orderAddendumIndex+1}}</td>
                                        <td colspan="3">{{order_addendum.addendum}} </td>
                                        <td class="text-right">{{order_addendum.percentage}}</td>
                                        <td class="text-right">{{order_addendum.cost_formatted}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Net:</td>
                                        <td class="text-right">
                                            {{  order_heading.net_formatted }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5"><strong>Order Gross:</strong></td>
                                        <td class="text-right">
                                            <strong>{{  order_set.gross_formatted }}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="background:#ccc"><strong>Order Net:</strong></td>
                                        <td class="text-right" style="background:#ccc">
                                            <strong>{{  order_set.net_formatted }}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </section>
                    </template>
                    <template v-if="pdfMode !== 'workorder'">
                        <section class="pdf-item py-4" >
                            <table class="table table-bordered text-left" width="100%">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="text-center">Invoice Heading</th>
                                    </tr>
                                    <tr>
                                        <th width="100">#</th>
                                        <th width="60%">Name</th>
                                        <th class="text-right" width="100">Gross</th>
                                        <th class="text-right" width="40px">Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(invoice_heading, invoiceHeadingIndex) in pdfData.invoice_heading_set" v-bind:key="invoiceHeadingIndex">
                                        <td>{{invoiceHeadingIndex+1}}</td>
                                        <td>{{invoice_heading.name}}</td>
                                        <td class="text-right">{{invoice_heading.gross_formatted}}</td>
                                        <td class="text-right">{{invoice_heading.net_formatted}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" style="background:#ccc"><strong>Net Total:</strong></td>
                                        <td class="text-right" style="background:#ccc">
                                            <strong>{{  pdfData.cost_formatted }}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <!-- <div v-if="pdfData.cost"><strong>RINGGIT MALAYSIA: {{inWords(parseFloat( pdfData.cost.replace(/,/g, '')).toFixed(2))}} {{centsWords(parseFloat( pdfData.cost.replace(/,/g, '')).toFixed(2))}}</strong></div> -->
                            <table class="table table-bordered text-left mb-4 mt-4" width="100%">
                                <thead>
                                    <tr>
                                        <th width="150">
                                            
                                        </th>
                                        <th width="30%" style="height:40px">
                                        </th>
                                        <th width="30%">
                                        </th>
                                        <th width="30%">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="height:100px">
                                            Stamp
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:100px">
                                            Signature
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Name
                                        </td>
                                        <td>
                                            <span v-if="pdfData.worker.full_name || pdfData.worker.short_name">{{pdfData.worker.full_name || pdfData.worker.short_name}}</span>
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <span v-if="pdfData.created_by.full_name || pdfData.created_by.short_name">{{pdfData.created_by.full_name || pdfData.created_by.short_name }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Title
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Date
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        </template>
                    </section>
                </vue-html2pdf>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<style lang="scss">
    .pdf-wrapper {
        // position: fixed;
        top:0;
        left:0;
        max-width: 740px;
        // max-height:90vh;
    }
</style>
<script>
import jsPDF from 'jspdf';
import Vue from 'vue'
import html2canvas from 'html2canvas';
import VueHtml2pdf from 'vue-html2pdf'
import pdf from '@/components/export/invoice-report.vue';
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
		  components: {
            pdf,
            wysiwyg,
            VueHtml2pdf
		  },
          props: {
                id: Number,
                globalPref: {
                    type: Array,
                    required: false
                },
          },
		data: () => ({
            headerOrderSet: [
            { text: '#', value: 'index', width: 80 },
            { text: 'Name', value: 'name' },
            { text: 'Net', value: 'net', sortable: false,},
            { text: 'Supervisor', value: 'supervisor' },
            { text: 'Worker', value: 'worker' },
            { text: 'Claimed ts', value: 'claimed_ts' },
            { text: ''}
            ],
            first: ['','SATU ','DUA ','TIGA ','EMPAT ', 'LIMA ','ENAM ','TUJUH ','LAPAN ','SEMBILAN ','SEPULUH ','SEBELAS ',' DUA BELAS ','TIGA BELAS ','EMPAT BELAS ','LIMA BELAS ','ENAM BELAS ','TUJUH BELAS ','LAPAN BELAS ','SEMBILAN BELAS '],
            tens: ['', '', 'DUA PULUH','TIGA PULUH','EMPAT PULUH','LIMA PULUH', 'ENAM PULUH','TUJUH PULUH','LAPAN PULUH','SEMBILAN PULUH'],
            mad: ['', 'RIBU,','JUTA,','BILION,','TRILION,'],
            statusOptions: [
                {
                    "value": "CREATED",
                    "name": "created"
                },
                {
                    "value": "VETTED",
                    "name": "vetted"
                },
                {
                    "value": "AUDITED",
                    "name": "audited"
                }
            ],
            expanded: [],
            singleExpand: false,
            success: false,
            loading: false,
            saving: false,
            invoiceOrderLoading: true,
            input:{
                id: '',
                name: '',
                description: '',
                from_date: '',
                to_date: '',
                worker: '',
                status: '',
            },
            info: {
                cost:'',
                cost_formatted:'',
                order_count:'',
                order_cost_sum :'',
                order_cost_max:'',
                order_cost_min:'',
                order_cost_avg:'',
                order_cost_var:'',
                order_cost_std_dev :'',
                vetted_ts: null,
                audited_ts: null,
                created_to_vetted_du: null,
                vetted_to_audited_du: null,
                created_to_audited_du: null,
            },
            order_set_table: [],
            order_set_loaded: false,
            detailList: [],
            userList: [],
            costCenterList: [],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
            generatedPDF: false,
            pdfMode: 'full',
            pdfFilename: '',
            workorderPdfId: '',
            workerInfo:{},
            claimInfo: {},
            pdfData: {},
		}),
        computed:{
            address_1: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_1;
                }
            },
            address_2: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_2;
                }
            },
            address_3: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.address_3;
                }
            },
            phone: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.phone;
                }
            },
            email: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.email;
                }
            },
            bank_name: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.bank_name;
                }
            },
            bank_account: function() {
                if (this.workerInfo.profile_data) {
                    return this.workerInfo.profile_data.bank_account;
                }
            },
            core__organization_name: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'core__organization_name').value;
                }
            }
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getClaim().then((res) => {
                        this.claimInfo = res;
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.is_active = res.is_active;
                        this.input.status = res.status;
                        this.input.description = res.description;
                        this.input.from_date = res.from_date;
                        this.input.to_date = res.to_date;
                        this.input.worker = res.worker;

                        this.info.order_count = res.order_count;
                        this.info.cost = res.cost;
                        this.info.cost_formatted = res.cost_formatted;
                        this.info.order_cost_sum = res.order_cost_sum;
                        this.info.order_cost_max = res.order_cost_max;
                        this.info.order_cost_min = res.order_cost_min;
                        this.info.order_cost_avg = res.order_cost_avg;
                        this.info.order_cost_var = res.order_cost_var;
                        this.info.order_cost_std_dev = res.order_cost_std_dev;

                        this.info.order_set = res.order_set;
                        if (res.vetted_ts) {
                            this.info.vetted_ts =  this.$moment(res.vetted_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.audited_ts) {
                            this.info.audited_ts = this.$moment(res.audited_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        this.info.created_to_vetted_du = res.created_to_vetted_du;
                        this.info.vetted_to_audited_du = res.vetted_to_audited_du;
                        this.info.created_to_audited_du = res.created_to_audited_du;
                        this.getOrderSet();
                    });
                } else {
                    this.input.status = 'CREATED'
                }
            },
			async getClaim() {
                try {
                    this.loading = true
                    const res = await this.$api.invoice.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveClaim().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'invoiceList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveClaim() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoice.save( this.input );
                    } else {
                        res = await this.$api.invoice.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            bulkStatus(status) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
                        this.saveClaim().then((res) => {
                            if (res.status == 200 || res.status == 201 ) {
                                this.setStatus(status).then((res) => {
                                    if (res.status == 200 || res.status == 201 ) {
                                        this.claimChangeOrder(status).then((res) => {
                                            this.$swal({
                                                icon: 'success',
                                                title: 'Saved',
                                                showCancelButton: true,
                                                reverseButtons: true,
                                                confirmButtonText: 'Return',
                                                cancelButtonText: 'Cancel',
                                            }).then((result) => {
                                                if (result.value) {
                                                    this.$router.push({ name: 'invoiceList' })
                                                } else {
                                                    this.input.id = res.data.id;
                                                    this.form();
                                                }
                                            })
                                        })
                                    }
                                });
                            }
                        });
                    }
                })
			},
			async setStatus(status) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoice.save({id: this.input.id, status: status });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async claimChangeOrder(status) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoice.changeAllStatus({id: this.input.id, status: status });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            bulkOrderStatus(id, status){
                this.setOrderStatus(id, status).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.input.id = res.data.id;
                            this.form();
                        })
                    }
                });
			},
			async setOrderStatus(id, status) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workOrder.save({id: id, status: status });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'invoiceList' })
            },
            getInvoice(type) {
                if (type == 'web') {
                    window.open(process.env.VUE_APP_API+'/experimental/invoice/'+  this.id +'/summary/', '_blank');
                } else {
                    this.getInvoiceFile(type,this.id).then((res) => {
                        const url = window.URL.createObjectURL(new Blob([res], {type:"application/pdf"}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'invoice_summary_' + this.id + '.pdf');
                        document.body.appendChild(link);
                        link.click();
                    });
                }
            },
			async getInvoiceFile(type, id) {
                try {
                    let res;
                    if (type == 'web') {
                        res = await this.$api.invoice.invoiceWeb({id: id, headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
                    } else {
                        res = await this.$api.invoice.invoicePdf({id: id, responseType: 'arraybuffer'});
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getOrderSet() {
                this.getUsers().then((res) => {
                    this.userList = res;
                });
                this.getUser().then((res) => {
                    this.workerInfo = res;
                });
			    this.getCostCenterList().then((res) => {this.costCenterList = res;});
                this.info.order_set.forEach(item => {
                        this.order_set_table = [];
                        this.getWorkOrder(item).then((res) => {
                            // res.order_detail_set.forEach(order_detail => {
                            //     this.getWorkOrderDetailHistory(order_detail.id).then((detail_history) => {
                            //         order_detail.history = detail_history;
                            //     });
                            // });
                            this.order_set_table.push(res);
                        });
                    this.order_set_loaded = true;
                });
                this.invoiceOrderLoading = false;
            },
            loadHistory({item}){
                if (this.detailList.length == 0) {
                    this.getDetails().then((res) => {
                        this.detailList = res;
                    });
                }
                item.order_detail_set.forEach(order_detail => {
                    if (!order_detail.history) {
                        this.getWorkOrderDetailHistory(order_detail.id).then((detail_history) => {
                            Vue.set(order_detail,'history', detail_history);
                        });
                    }
                })
            },
			async getWorkOrder(id) {
                try {
                    const res = await this.$api.workOrder.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getWorkOrderDetailHistory(id) {
                try {
                    const res = await this.$api.workOrderDetail.history(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
			async getDetails() {
                try {
                    const res = await this.$api.invoiceDetail.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getDetailName(detail_id){
                if ( this.detailList.length !== 0 && detail_id ) {
                    return this.detailList.find(detail => detail.id === detail_id).name;
                }
            },
            editOrder(id) {
                this.$router.push({ name: 'workOrderForm', params: { id } })
            },
            closePDF() {
                this.generatedPDF = false;
            },
            generatePDF(mode, order_id) {
                this.pdfMode = mode;
                if (this.pdfMode == 'summary') {
                    this.pdfFilename = 'invoice_summary_' + this.id
                }
                if (this.pdfMode == 'workorder') {
                    this.pdfFilename = 'invoice_'+this.id+'_workorder_' + order_id
                    this.workorderPdfId = order_id;
                }
                this.getPdf().then((res) => {
                    this.pdfData = res;
                    this.generatedPDF = true;
                    if (mode == 'full') {
                        setTimeout(() => {
                            this.$refs.html2Pdf.generatePdf()
                        }, 100*this.order_set);
                    } else {
                        setTimeout(() => {
                            this.$refs.html2Pdf.generatePdf()
                        }, 1000);
                    }
                });
                
            },
			async getUser() {
                try {
                    this.loading = true
                    const res = await this.$api.user.get(this.input.worker);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getCostCenterList() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getPdf() {
                try {
                    const res = await this.$api.invoice.pdf(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getCostCenter(cost_center_id) {
                if ( this.costCenterList.length !== 0 && cost_center_id ) {
                    return this.costCenterList.find(costcenter => costcenter.id === cost_center_id).name;
                }
            },
            calculateSum(val){
                var sum = 0;
                for(let value in val){
                    sum += val[value]['order_detail_set.cost']
                }
                return sum;
            },
            inWords (number) {
                let word = '';
                for (let i = 0; i < this.mad.length; i++) {
                let tempNumber = number%(100*Math.pow(1000,i));
                if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
                if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
                    word = this.first[Math.floor(tempNumber/Math.pow(1000,i))] + this.mad[i] + ' ' + word;
                } else {
                    word = this.tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + ' ' + this.first[Math.floor(tempNumber/Math.pow(1000,i))%10] + this.mad[i] + ' ' + word;
                }
                }

                tempNumber = number%(Math.pow(1000,i+1));
                if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = this.first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'RATUS ' + word;
            }
                return word;
            },
            centsWords(number){
                console.log(number.toString().split(".")[1]);
                if (number.toString().split(".")[1] && number.toString().split(".")[1] !== '00') {
                    return ' DAN ' +this.inWords(number.toString().split(".")[1]) + ' SEN';
                }
            },
            async hasDownloaded() {
                this.generatedPDF = false;
            }
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>