<template>
	<v-row style="background-color:#313a3a" class="vh-100" align="center" justify="center">
		<v-col cols="12" sm="12" md="12" lg="8">
			<v-card class="elevation-12">
				<v-card
				>
					<div class="d-flex flex-sm-column flex-md-row flex-no-wrap justify-space-between">
						<v-img class="d-none d-sm-block w-100 w-md-50" src="img/login-image.jpg"></v-img>
						<v-card-text>
							<v-form @submit.stop.prevent="login()" class="text-left pt-8 pb-8 pl-4 pr-4 vh-md-50 vh-50 h-auto">
								<v-alert v-if="alertText" type="warning">
									{{alertText}}
								</v-alert>
      							<v-expand-transition>
									<div v-if="loginStatus == 'not'">
										<div class="display-1 mb-6">
											Login
										</div>
										<v-text-field v-model="username" label="Login" name="login" type="text" />
										<v-text-field v-model="password" id="password" label="Password" name="password" type="password" />
										<v-spacer />
										<v-btn class="mt-2" color="primary" type="submit"><v-icon class="mr-2">mdi-login-variant</v-icon>Login</v-btn><br/>
										<a class="d-inline-block mt-4" style="text-decoration:none" v-on:click.stop="loginStatus = 'resetPass'">Forgot your password?</a>
										<br>
										<router-link to="register" class="d-inline-block mt-2" style="text-decoration:none">Don't have an account? Register here.</router-link>
									</div>
									<div v-if="loginStatus == 'resetPass'">
										<div class="display-1 mb-6">
											Reset your password
										</div>
										<v-text-field v-model="resetUser" label="Username" name="resetUser" type="text" />
										<v-spacer />
										<v-btn class="mt-2" color="primary" @click="resetPass"><v-icon class="mr-2">mdi-login-variant</v-icon>Reset your password</v-btn><br/>
									</div>
								</v-expand-transition>
								<div v-if="loginStatus == 'loging'">
									<v-progress-circular
									class="ml-2"
									:size="60"
									:width="4"
									indeterminate
									color="primary"
									></v-progress-circular>
								</div>
							</v-form>
						</v-card-text>
					</div>
				</v-card>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				password: '',
				alertText: '',
				redirect: false,
				loginStatus: 'not',
				resetUser:'',
			}
		},
		computed: {
			expiredLocation: function() {
				return this.$store.getters.expiredLocation
			},
		},
		methods: {
			login() {
				this.loginStatus = 'loging';
				this.$store.dispatch('obtainToken', [this.username, this.password]).then((response) => {
					this.loginStatus = 'done';
					if (response.statusText == "OK") {
						this.$api.accountProfile.get().then((res) => {
							this.getGlobalPreferences().then((res2) => {
								if (!this.$store.getters.osKey) {
									this.$store.dispatch('saveOneSignal').then((data) => {
										window.OneSignal = window.OneSignal || [];
										OneSignal.push(() => {
											OneSignal.init({
												appId: res2.find(pref => pref.identifier === 'one_signal__web_id').value,
											});
										});
										OneSignal.showNativePrompt();
										OneSignal.push(() => {
											OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
												if (isEnabled) {
													OneSignal.getUserId().then((userId) => {
														this.$api.accountProfile.regDeviceOneSignal({ player_id: userId, user: res.data.id }).then((res3) => { console.log(res3) });
													});
												} else {
													OneSignal.on('subscriptionChange', function (isSubscribed) {
														OneSignal.getUserId().then((userId) => {
															this.$api.accountProfile.regDeviceOneSignal({ player_id: userId, user: res.data.id }).then((res3) => { console.log(res3) });
														});
													});    
												} 
											});
										});
									});
								}
							});

							this.$store.dispatch('setProfileId', res.data.id).then((res) => {
							})
							this.$store.dispatch('setProfileRole', res.data.role).then((res) => {
							})
							if (res.data.role == 'CUSTOMER') {
								this.$router.push({ name: 'complaintComplaintList' })
							} else if (res.data.role == 'VENDOR') {
								this.$store.dispatch('logout');
							} else {
								if (this.expiredLocation) {
									this.$router.push({ name: this.expiredLocation.name, params: this.expiredLocation.params })
								} else {
									this.$router.push({ name: 'home' })
								}
							}
						});
					} else {
						this.loginStatus = 'not';
						this.alertText = response.data.detail
					}
				})
			},
			resetPass() {
				this.$api.accountProfile.sendPassword({login: this.resetUser}).then((res) => {
					console.log(res);
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
							title: 'Sent!',
							text: 'Check your email for the reset link',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
							this.loginStatus = 'not';
                        })
                    } else {
						this.alertText = res.detail
					}
				})
			},
			async getGlobalPreferences() {
                try {
                    const res = await this.$api.preference.global();
                    return res.data;
                } catch (error) {
                } finally {
                }
            },
			goToRegister() {
                this.$router.push({ name: 'register' })
			}
		},
		mounted() {
			if (this.$router.currentRoute.name == 'home') {
				this.redirect = true;
			}
		}
	}
</script>