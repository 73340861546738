<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-row>
                <v-col cols="12" md="12" class="py-0">
                    <v-text-field
                        v-model="filterFields.id"
                        label="ID"
                        prepend-icon="mdi-tag"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-select
                    :items="costCenterList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="Cost Center" 
                    v-model="filterFields.costcenter"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-select
                    :items="scopeList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="Scope" 
                    v-model="filterFields.scope"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-select
                    :items="userList" 
                    item-value="id"
                    name="item"
                    item-text="username"
                    label="Worker" 
                    v-model="filterFields.worker"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-select
                    :items="userList" 
                    item-value="id"
                    name="item"
                    item-text="username"
                    label="Supervisor" 
                    v-model="filterFields.supervisor"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                    <v-text-field
                        v-model="filterFields.ext_ref_id"
                        label="Ext Ref ID"
                        prepend-icon="mdi-barcode"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="open_dt_date_afterMenu"
                        v-model="open_dt_date_afterMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.open_dt_date_after"
                            label="From Open Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.open_dt_date_after"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="open_dt_date_beforeMenu"
                        v-model="open_dt_date_beforeMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.open_dt_date_before"
                            label="To Open Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.open_dt_date_before"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="due_dt_date_afterMenu"
                        v-model="due_dt_date_afterMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.due_dt_date_after"
                            label="From Due Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.due_dt_date_after"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="due_dt_date_beforeMenu"
                        v-model="due_dt_date_beforeMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.due_dt_date_before"
                            label="To Due Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.due_dt_date_before"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="done_dt_date_afterMenu"
                        v-model="done_dt_date_afterMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.done_dt_date_after"
                            label="From Done Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.done_dt_date_after"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="done_dt_date_beforeMenu"
                        v-model="done_dt_date_beforeMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.done_dt_date_before"
                            label="To Done Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.done_dt_date_before"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                     <v-menu
                        ref="audited_dt_date_afterMenu"
                        v-model="audited_dt_date_afterMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.audited_dt_date_after"
                            label="From Audited Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.audited_dt_date_after"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-menu
                        ref="audited_dt_date_beforeMenu"
                        v-model="audited_dt_date_beforeMenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFields.audited_dt_date_before"
                            label="To Audited Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="filterFields.audited_dt_date_before"
                        no-title
                        scrollable
                        show-current
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
            scopeList: [],
            costCenterListSelect: [],
            scopeListSelect: [],
            userListSelect: [],
            open_dt_date_beforeMenu: false,
            open_dt_date_afterMenu: false,
            due_dt_date_beforeMenu: false,
            due_dt_date_afterMenu: false,
            done_dt_date_beforeMenu: false,
            done_dt_date_afterMenu: false,
            audited_dt_date_beforeMenu: false,
            audited_dt_date_afterMenu: false,
        }),
        props: {
            tableType: {
                type: String,
            },
            filterFields: {
                type: Object,
            },
            userList: {
                type: Array,
            },
            costCenterList: {
                type: Array,
            }
        },
        computed: {
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            },
			getFilterList() {
				this.getScope().then(res => this.scopeList = res);
			},
			async getScope() {
				try {
					this.loading = true
					const res = await this.$api.workScope.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
        },
        mounted() {
            this.getFilterList();
            this.costCenterListSelect = this.costCenterList
            this.costCenterListSelect.unshift({
                id:'',
                name: 'All Cost Center',
            });
            this.scopeListSelect = this.scopeList
            this.scopeListSelect.unshift({
                id:'',
                name: 'All Scope',
            });
            this.userListSelect = this.userList
            this.userListSelect.unshift({
                id:'',
                username: 'All Users',
            });
        }
    }
</script>