<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                <v-icon>mdi-window-close</v-icon>
                Remove filter
            </v-btn>
            <v-dialog v-model="filterDialog" max-width="400">
                <template v-slot:activator="{on}">
                    <v-btn color="secondary" dark v-on="on" class="mr-2">
                        <v-icon>mdi-filter</v-icon>
                        Filter
                    </v-btn>
                </template>
                <v-card>
                     <tableFilter :filterFields="filterData" :systemList="systemList" v-on:filteredData="tableFilter"></tableFilter>
                </v-card>
            </v-dialog>
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            v-if="this.genericList"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="this.baseAssetTable"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left" v-if="row.item">{{  getSystemName(row.item.system) }}</td>
                <td class="text-left" v-if="row.item">{{  getCrititcalityName(row.item.criticality) }}</td>
                <td class="text-left" v-if="row.item">{{  getQRScanRequired(row.item.qr_scan_required) }}</td>
                <td class="text-left" v-if="row.item">{{  statusName(row.item.is_active) }}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterBaseAsset.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
			success: false,
            loading: true,
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width:100 },
            { text: 'Name', value: 'name' },
            { text: 'System', value: 'system' },
            { text: 'Criticality', value: 'criticality' },
            { text: 'QR Scan Required', value: 'qr_scan_required' },
            { text: 'Status', value: 'is_active' },
            { text: ''}
            ],
			genericList: [],
            systemList: [],

            filter: false,
            filterDialog: false,
            filterData: {},
            filteredBaseAssets: [],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
        }),
        computed: {
            baseAssetTable: function() {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredBaseAssets
                } else {
                    return this.genericList;
                }
            }
        },
		methods: {
            // Main Function
            list() {
			    this.getGeneric(1).then((res) => {
                    this.genericList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getGeneric(page) {
                try {
                    this.loading = true
                    const res = await this.$api.assetGeneric.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'assetGenericForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.assetGeneric.delete(id).then(res => this.list() );
                    }
                })
            },
            // Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
			        this.$api.assetGeneric.search(value).then((res) => {
                        this.filteredBaseAssets = res.data;
                        if (this.filterData.name) {
                            this.filteredBaseAssets = this.filteredBaseAssets.filter(baseAsset => 
                                baseAsset.name.toLowerCase().includes(this.filterData.name.toLowerCase())
                            )
                        }
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
            },
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getSystemName(id) {
                if ( this.systemList.length !== 0 && id ) {
                    return this.systemList.find(system => system.id === id).name;
                }
            },
            getCrititcalityName (val) {
                if (val == 1) {
                    return "C1";
                } else if (val == 2) {
                    return "C2"
                } else {
                    return "C3"
                }
            },
            getQRScanRequired (val) {
                if (val) {
                    return "Yes";
                } else {
                    return "No"
                }
            },
            statusName (val) {
                if (val == true) {
                    return "Active";
                } else {
                    return "Inactive"
                }
            },
		},
		mounted () {
            this.list();
            this.getSystemList();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getGeneric(this.pagination.current).then((res) => {
                        this.genericList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>