<template>
    <v-container fluid>
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right" v-if="this.profileRole == 'SYSADMIN'">
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="userList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="userList"
            :items-per-page="perPage"
            hide-default-footer
            class="elevation-1"
            :options.sync="options"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.username}}</td>
                <td class="text-left">{{row.item.email}}</td>
                <!-- <td class="text-left">
                    <span class="comma-separated" v-for="(team ,index) in row.item.team_set" v-bind:key="index">{{ getTeamName(team) }}</span>
                </td> -->
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="profileRole == 'SYSADMIN' && row.item.is_active == true" small fab icon color="grey" @click="deactivate(row.item.id)" >
                        <v-icon>mdi-account-cancel</v-icon>
                        </v-btn>
                        <v-btn v-if="profileRole == 'SYSADMIN' && row.item.is_active == false" small fab icon color="grey" @click="activate(row.item.id)" >
                        <v-icon>mdi-account-check</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		data: () => ({
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width:100},
            { text: 'Username', value: 'username' },
            { text: 'Email', value: 'email' },
            // { text: 'Team', value: 'team_set' },
            { text: '', sortable: false,}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			userList: [],
            loading: true,
            options:{},
            filterData: {},
		}),
        computed: {
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            filterDataWithPagination: function() {
                var newObj = this.filterData;
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                return newObj;
            },
            datatableOrdering: function() {
                if (this.options.sortBy.length > 0) {
                    if ( this.options.sortDesc[0] == false) {
                        return this.options.sortBy[0];
                    } else {
                        return '-' + this.options.sortBy[0];
                    }   
                } else {
                    return '-id';
                }
            }
        },
		methods: {
            // Main Function
            init() {
			    this.loadList();
            },
            loadList() {
			    this.getUsers().then((res) => {
                    this.userList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getUsers(page) {
                try {
                    this.loading = true
                    const res = await this.$api.user.search( this.filterDataWithPagination );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'userForm', params: { id } })
            },
            deactivate(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to deactivate this user?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.user.save({id: id, is_active: false}).then(res => this.loadList() );
                    }
                })
            },
            activate(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to activate this user?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.user.save({id: id, is_active: true}).then(res => this.loadList() );
                    }
                })
            },
            getTeamList() {
			    this.getTeams().then(res => this.teamList = res);
            },
			async getTeams() {
                try {
                    this.loading = true
                    const res = await this.$api.team.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getTeamName(id) {
                if ( this.teamList.length !== 0 && id ) {
                    return this.teamList.find(team => team.id === id).name;
                }
            }, 
		},
		mounted () {
            this.init();
            this.getTeamList();
            this.success = true;
		},
        watch: {
            pagination: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            filterData: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            options: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
        },
	}
</script>