var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('v-app-bar',{attrs:{"app":"","color":"white"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex text-left",attrs:{"cols":"4"}},[(this.profile.role == 'SYSADMIN')?_c('v-btn',{staticStyle:{"height":"36px"},attrs:{"color":"secondary","small":"","to":"/"}},[_c('span',{staticClass:"d-inline d-sm-none"},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Back to CRUD")])]):_vm._e(),(this.profile.role == 'SYSADMIN')?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-account-edit")]),_vm._v(" "),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(_vm._s(_vm.profileName))])],1)]}}],null,false,3889994419)},[_c('v-list',{staticClass:"text-left",attrs:{"rounded":"","dense":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":false,"hide-details":"auto","label":"Choose a profile"},model:{value:(_vm.profileId),callback:function ($$v) {_vm.profileId=$$v},expression:"profileId"}},[_c('v-radio',{attrs:{"label":"Super Admin","value":"0"}}),_vm._l((_vm.userList),function(user,index){return _c('v-radio',{key:index,attrs:{"label":user.username,"value":user.id}})})],2)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.currentRouteName)+" "+_vm._s(_vm.currentRoutePageId)+" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.tokenCheck)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":16,"width":2,"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.system_mode == 'set_up')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-wrench")])]}}],null,false,1562253079)},[_c('span',[_vm._v("Currently in set up mode")])]):_vm._e(),(_vm.system_mode == 'production')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-cog")])]}}],null,false,3971394489)},[_c('span',[_vm._v("Currently in production mode")])]):_vm._e(),(_vm.system_mode == 'file_import')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-database-import")])]}}],null,false,1308165799)},[_c('span',[_vm._v("Currently in file import mode")])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-avatar',{attrs:{"color":"indigo","size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.profileFirstLetter))])])],1)]}}])},[_c('v-list',{staticClass:"text-left",attrs:{"rounded":"","dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.profile.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.email))])],1)],1),_vm._l((_vm.userLinks),function(link,index){return _c('v-list-item',{key:index,attrs:{"disabled":link.disabled},on:{"click":function($event){return _vm.userLink(link.link)}}},[(link.icon)?_c('v-list-item-icon',{attrs:{"size":"12"}},[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(link.title))])],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }