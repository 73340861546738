<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="mb-3">Complaint ( ID: {{complaint.id}} ) <v-btn fab small text :to="{ name: 'complaintComplaintForm', params: { id: complaint.id } }"><v-icon>mdi-link-variant</v-icon></v-btn></h3>
                <strong>Name:</strong> {{complaint.name}}<br/>
                <strong>Ext Ref ID:</strong> {{complaint.ext_ref_id}}<br/>
                <strong>Description:</strong><br/>
                <div v-html="complaint.description"></div>
                <strong>Landmark:</strong> {{complaint.landmark}}<br/>
                <div v-if="complaint.asset_data">
                    <label><strong>System:</strong></label> {{complaint.asset_data.system__name}}<br/>
                    <label><strong>Generic:</strong></label> {{complaint.asset_data.generic__name}}<br/>
                    <label><strong>Asset:</strong></label> {{complaint.asset_data.name}}<br/>
                    <label><strong>Location:</strong></label> {{complaint.asset_data.location__name}}
                </div>
            </v-col>
            <v-col>
                <v-card outlined class="mb-4"  v-if="complainer">
                    <v-card-text>
                        <h3 class="mb-3">Complainer</h3>
                        <table>
                            <tr>
                                <td class="pr-3">Name:</td>
                                <td>{{complainer.fullname}}</td>
                            </tr>
                            <tr>
                                <td class="pr-3">Username:</td>
                                <td>{{complainer.username}}</td>
                            </tr>
                            <tr>
                                <td class="pr-3">E-mail:</td>
                                <td>{{complainer.email}}</td>
                            </tr>
                            <tr v-if="complainer.profile_data">
                                <td class="pr-3">Phone:</td>
                                <td>{{complainer.profile_data.phone}}</td>
                            </tr>
                        </table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div v-if="complaint.latitude">Lat:{{complaint.latitude}}, Long: {{complaint.longitude}}</div>
                <iframe v-if="complaint.latitude" class="location-map" :src="'https://maps.google.com/maps?q='+complaint.latitude+','+complaint.longitude+'&hl=es;z=14&amp;output=embed'" width="100%" height="400"></iframe>
            </v-col>
        </v-row>
        <v-row v-if="locLoaded">
            <v-col>
                <div v-if="!complaint.asset && complaint.location_data"><label><strong>Location: </strong></label>{{complaint.location_data.name}} ({{complaint.location_data.parent__name}})</div>
                
                <v-row>
                    <v-col cols="12">
                        <v-select
                        v-if="location[0]"
                        :items="locationList0" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Location 1" 
                        v-model="location[0]"
                            hide-details="auto"
                            readonly
                        ></v-select>
                        <v-select
                        v-if="location[1]"
                        :items="locationList1" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Location 2" 
                        v-model="location[1]"
                            hide-details="auto"
                            readonly
                        ></v-select>
                        <v-select
                        v-if="location[2]"
                        :items="locationList2" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Location 3" 
                        v-model="location[2]"
                            hide-details="auto"
                            readonly
                        ></v-select>
                        <v-select
                        v-if="location[3]"
                        :items="locationList3" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Location 4" 
                        v-model="location[3]"
                            hide-details="auto"
                            readonly
                        ></v-select>
                        <v-select
                        v-if="location[4]"
                        :items="locationList4" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Location 5" 
                        v-model="location[4]"
                            hide-details="auto"
                            readonly
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <label>Images</label><br/>
                <v-row>
                    <v-col class="md-20">
                        <enlargeable-image class="img-wrapper" v-if="complaint.image_1" :src="complaint.image_1" :src_large="complaint.image_1" />
                        <v-img class="ba-1" contain v-if="!complaint.image_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                    </v-col>
                    <v-col class="md-20">
                        <enlargeable-image class="img-wrapper" v-if="complaint.image_2" :src="complaint.image_2" :src_large="complaint.image_2" />
                        <v-img class="ba-1" contain v-if="!complaint.image_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                    </v-col>
                    <v-col class="md-20">
                        <enlargeable-image class="img-wrapper" v-if="complaint.image_3" :src="complaint.image_3" :src_large="complaint.image_3" />
                        <v-img class="ba-1" contain v-if="!complaint.image_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                    </v-col>
                    <v-col class="md-20">
                        <enlargeable-image class="img-wrapper" v-if="complaint.image_4" :src="complaint.image_4" :src_large="complaint.image_4" />
                        <v-img class="ba-1" contain v-if="!complaint.image_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                    </v-col>
                    <v-col class="md-20">
                        <enlargeable-image class="img-wrapper" v-if="complaint.image_5" :src="complaint.image_5" :src_large="complaint.image_5" />
                        <v-img class="ba-1" contain v-if="!complaint.image_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="complaint.status == 'RESOLVED'">
            <v-col>
                Rating:
                <v-rating
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                hover
                v-model="complaint.complainer_rating"
                readonly
                length="5"
                size="36"
                value="3"
                ></v-rating>
            </v-col>
        </v-row>
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
        props: {
            complaintId: {
                type: Number
            },
            locationList0: {
                type: Array
            },
            locationList1: {
                type: Array
            },
            locationList2: {
                type: Array
            },
            locationList3: {
                type: Array
            },
            locationList4: {
                type: Array
            },
        },
		  components: {
            EnlargeableImage
          },
		data() {
            return {
                id: [],
                loading: true,
                complaint: [],
                complainer: {},
                location:[],
                locLoaded: false,
            }
        },
        methods: {
            list() {
                if (this.complaintId) {
                    this.getComplaint().then((res) => {
                        this.complaint = res;
                        this.getLoc(res.location_data.id).then((res) => { 
                            this.location[res.depth] = res.id;
                            if (res.depth > 0) {
                                this.getLoc(res.parent).then((res) => { 
                                    this.location[res.depth] = res.id;
                                    if (res.depth > 0) {
                                        this.getLoc(res.parent).then((res) => { 
                                            this.location[res.depth] = res.id;
                                            if (res.depth > 0) {
                                                this.getLoc(res.parent).then((res) => { 
                                                    this.location[res.depth] = res.id;
                                                })
                                            }   
                                        })
                                    }
                                })
                            }
                        });
                        this.getComplainer().then((res) => {
                            this.complainer = res;
                        }); 
                        setTimeout(() => {
                            this.locLoaded = true;
                        }, 1000)
                    });
                }
                this.getAdditionalData();
            },
			async getComplaint() {
                try {
                    this.loading = true
                    const res = await this.$api.complaintComplaint.get( this.complaintId );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getComplainer() {
                try {
                    const res = await this.$api.user.get( this.complaint.complainer );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAdditionalData() {
                // this.getLocsbyDepth(0).then((res) => { 
                //     this.locationList0 = res;
                // });
                // this.getLocsbyDepth(1).then((res) => { 
                //     this.locationList1 = res;
                // });
                // this.getLocsbyDepth(2).then((res) => { 
                //     this.locationList2 = res;
                // });
                // this.getLocsbyDepth(3).then((res) => { 
                //     this.locationList3 = res;
                // });
                // this.getLocsbyDepth(4).then((res) => { 
                //     this.locationList4 = res;
                // });
            },
            async getLoc(location_id) {
                try {
                    const res = await this.$api.location.get( location_id );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>