<template>
    <v-snackbar
    right
    v-model="snackbar"
    :timeout="timeout"
    >
    {{ item }} is loading..
    </v-snackbar>
</template>
<script>
export default {
    data() {
        return {
            snackbar: true,
            timeout: 8000,
        }
    },
    props:{
        item: String
    }
}
</script>