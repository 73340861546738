<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
                <v-btn class="ml-2" v-if="this.info.order_data && this.info.order_data !== undefined" :to="{ name: 'workOrderForm', params: { id: this.info.order_data.id } }" dark color="gray">
                    <v-icon class="pr-2">mdi-vector-link</v-icon>
                    Work Order
                </v-btn>
            </div>
            <v-card outlined class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Complaint</v-tab>
                    <v-tab>Related Complaints</v-tab>
                    <v-tab-item>
                        <v-row>
                            <!-- <v-col cols="12" sm="12" md="12">
                                <div class="caption">Status</div>
                                <v-switch inset v-model="inputStatus" label="Status"></v-switch>
                            </v-col> -->
                            <v-col cols="12" sm="8" md="8">
                                <v-text-field
                                    label="Title *" 
                                    v-model="input.name"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                    label="Status" 
                                    v-model="input.status"
                                    hide-details="auto"
                                    readonly
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Ext ref id" 
                                    v-model="input.ext_ref_id"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Landmark" 
                                    v-model="input.landmark"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete
                                :class="profileRole == 'CUSTOMER' ? 'd-none' : ''"
                                :items="userList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Complainer" 
                                v-model="input.complainer"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row v-if="!editAsset">
                            <v-col>
                                <v-col>
                                    <div v-if="info.asset_data">
                                        <label><strong>System:</strong></label> {{info.asset_data.system__name}}<br/>
                                        <label><strong>Generic:</strong></label> {{info.asset_data.generic__name}}<br/>
                                        <label><strong>Asset:</strong></label> {{info.asset_data.name}}<br/>
                                        <label><strong>Location:</strong></label> {{info.asset_data.location__name}}
                                    </div>
                                    <div v-if="!input.asset && info.location_data"><label><strong>Location: </strong></label>{{info.location_data.name}} ({{info.location_data.parent__name}})</div>
                                    <v-btn color="primary" @click="editAsset = !editAsset"><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-btn>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row v-if="editAsset"> 
                            <v-col cols="12" sm="4" md="4">
                                <label>Filter by </label><br/>
                                <v-btn-toggle v-model="assetSearchSwitch">
                                    <v-btn value="system">
                                        <v-icon left>mdi-shape</v-icon>
                                        <span class="hidden-sm-and-down">System</span>
                                    </v-btn>
                                    <v-btn value="location">
                                        <v-icon left>mdi-map-marker</v-icon>
                                        <span class="hidden-sm-and-down">Location</span>
                                    </v-btn>
                                </v-btn-toggle>
                                <v-row>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'system'">
                                        <v-select
                                        :items="systemList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="System" 
                                        v-model="info.system"
                                            hide-details="auto"
                                            @change="filterBySystem()"
                                        ></v-select>
                                        <v-select
                                        :items="genericList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Generic" 
                                        v-model="info.generic"
                                            hide-details="auto"
                                            @change="filterByGeneric()"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'location'">
                                        <v-select
                                        :items="locationList0" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 1" 
                                        v-model="info.location[0]"
                                            hide-details="auto"
                                            @change="filterByLoc(0, info.location[0])"
                                        ></v-select>
                                        <v-select
                                        v-if="info.location[0]"
                                        :items="locationList1" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 2" 
                                        v-model="info.location[1]"
                                            hide-details="auto"
                                            @change="filterByLoc(1, info.location[1])"
                                        ></v-select>
                                        <v-select
                                        v-if="info.location[1]"
                                        :items="locationList2" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 3" 
                                        v-model="info.location[2]"
                                            hide-details="auto"
                                            @change="filterByLoc(2, info.location[2])"
                                        ></v-select>
                                        <v-select
                                        v-if="info.location[2]"
                                        :items="locationList3" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 4" 
                                        v-model="info.location[3]"
                                            hide-details="auto"
                                            @change="filterByLoc(3, info.location[3])"
                                        ></v-select>
                                        <v-select
                                        v-if="info.location[3]"
                                        :items="locationList4" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Location 5" 
                                        v-model="info.location[4]"
                                            hide-details="auto"
                                            @change="filterByLoc(4, info.location[4])"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-card outlined>
                                    <v-card-text>
                                        <div v-if="info.asset_data"><label><strong>Location: </strong></label>{{info.asset_data.location__name}}</div>
                                        <v-autocomplete
                                        :items="assetList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Asset" 
                                        v-model="input.asset"
                                            hide-details="auto"
                                            @change="getAssetDescription"
                                        >
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.name }} (ID: {{ data.item.id }}) <span v-if="data.item.manual_tag">(</span>{{ data.item.manual_tag }} <span v-if="data.item.manual_tag">)</span>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.name }} (ID: {{ data.item.id }}) {{ data.item.manual_tag }} <span v-if="data.item.manual_tag">(</span>{{ data.item.manual_tag }} <span v-if="data.item.manual_tag">)</span>
                                        </template>
                                        </v-autocomplete>
                                        <br/>
                                        
                                        <p v-if="assetDescription">Selected Asset Description:</p>
                                        <p v-html="assetDescription"></p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div v-if="!input.id">{{this.errorStr}}</div>
                                <div v-if="!mapLat" @click="grabLoc()">No latitude / longitude available for this complaint. Click here to generate.</div>
                                <div v-if="mapLat && !errorStr">Lat:{{mapLat}}, Long: {{mapLong}}</div>
                                <iframe v-if="mapLat && !errorStr" class="location-map" :src="'https://maps.google.com/maps?q='+mapLat+','+mapLong+'&hl=es;z=14&amp;output=embed'" width="100%" height="400"></iframe>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Images</label><br/>
                                <v-row>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_1 && !newimage_1" :src="input.image_1" :src_large="input.image_1" />
                                        <v-img class="ba-1" contain v-if="!input.image_1 && !newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_1" :src="newimage_1Preview" :src_large="newimage_1Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_1Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_1_label"
                                            v-model="newimage_1"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_2 && !newimage_2" :src="input.image_2" :src_large="input.image_2" />
                                        <v-img class="ba-1" contain v-if="!input.image_2 && !newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_2" :src="newimage_2Preview" :src_large="newimage_2Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_2Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_2_label"
                                            v-model="newimage_2"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_3 && !newimage_3" :src="input.image_3" :src_large="input.image_3" />
                                        <v-img class="ba-1" contain v-if="!input.image_3 && !newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_3" :src="newimage_3Preview" :src_large="newimage_3Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_3Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_3_label"
                                            v-model="newimage_3"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_4 && !newimage_4" :src="input.image_4" :src_large="input.image_4" />
                                        <v-img class="ba-1" contain v-if="!input.image_4 && !newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_4" :src="newimage_4Preview" :src_large="newimage_4Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_4Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_4_label"
                                            v-model="newimage_4"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_5 && !newimage_5" :src="input.image_5" :src_large="input.image_5" />
                                        <v-img class="ba-1" contain v-if="!input.image_5 && !newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_5" :src="newimage_5Preview" :src_large="newimage_5Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_5Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_5_label"
                                            v-model="newimage_5"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_6 && !newimage_6" :src="input.image_6" :src_large="input.image_6" />
                                        <v-img class="ba-1" contain v-if="!input.image_6 && !newimage_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_6" :src="newimage_6Preview" :src_large="newimage_6Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_6Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="complaint_image_6_label"
                                            v-model="newimage_6"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row> 
                        <v-row>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_1_label"
                                    v-model="input.newfile_1"
                                ></v-file-input>
                                <small v-if="info.file_1">Uploaded: <a class="noline" :href="info.file_1" target="_blank">{{complaint_file_1_label}}</a></small>
                                <small v-if="!info.file_1">No files uploaded</small>
                            </v-col>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_2_label"
                                    v-model="input.newfile_2"
                                ></v-file-input>
                                 <small v-if="info.file_2">Uploaded: <a class="noline" :href="info.file_2" target="_blank"> {{complaint_file_2_label}}></a></small>
                                <small v-if="!info.file_2">No files uploaded</small>
                            </v-col>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_3_label"
                                    v-model="input.newfile_3"
                                ></v-file-input>
                                <small v-if="info.file_3">Uploaded: <a class="noline" :href="info.file_3" target="_blank"> {{complaint_file_3_label}}</a></small>
                                <small v-if="!info.file_3">No files uploaded</small>
                            </v-col>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_4_label"
                                    v-model="input.newfile_4"
                                ></v-file-input>
                                <small v-if="info.file_4">Uploaded: <a class="noline" :href="info.file_4" target="_blank">{{complaint_file_4_label}}</a></small>
                                <small v-if="!info.file_4">No files uploaded</small>
                            </v-col>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_5_label"
                                    v-model="input.newfile_5"
                                ></v-file-input>
                                <small v-if="info.file_5">Uploaded: <a class="noline" :href="info.file_5" target="_blank">{{complaint_file_5_label}}</a></small>
                                <small v-if="!info.file_5">No files uploaded</small>
                            </v-col>
                            <v-col md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="complaint_file_6_label"
                                    v-model="input.newfile_6"
                                ></v-file-input>
                                <small v-if="info.file_6">Uploaded: <a class="noline" :href="info.file_6" target="_blank">{{complaint_file_6_label}}</a></small>
                                <small v-if="!info.file_6">No files uploaded</small>
                            </v-col>
                        </v-row>
                        <v-row v-if="input.status !== 'ACCEPTED'">
                            <v-col cols="6" class="text-left">
                                <!-- <v-btn class="mr-4" v-if="profileId == input.complainer && input.status !== 'SUBMITTED'" color="primary" @click="save('DRAFT')"><v-icon class="mr-2">mdi-content-save</v-icon>Save as Draft</v-btn> -->
                                <v-btn v-if="(profileRole == 'STAFF' || profileRole == 'MANAGER' ) && input.status == 'SUBMITTED'" class="mr-4" color="error" @click="bulkStatus('REJECTED')"><v-icon class="mr-2">mdi-delete</v-icon>Not Applicable</v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn class="mr-4" v-if="!input.id" color="secondary" @click="save()">
                                    <span><v-icon class="mr-2">mdi-send</v-icon>Submit</span>
                                </v-btn>
                                <v-btn class="mr-4" v-if="input.id && input.status == 'SUBMITTED'" color="secondary" @click="save()">
                                    <span><v-icon class="mr-2">mdi-content-save</v-icon>Save</span>
                                </v-btn>
                                <v-btn v-if="(profileRole == 'STAFF' || profileRole == 'MANAGER' ) && input.status == 'SUBMITTED'" class="mr-4" color="primary" @click="bulkStatus('PROCESSED')"><v-icon class="mr-2">mdi-check</v-icon>Applicable</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="input.status == 'RESOLVED'">
                            <v-col>
                                Rating:
                               <v-rating
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                hover
                                v-model="input.complainer_rating"
                                :readonly="profileId !== info.complainer"
                                length="5"
                                size="36"
                                value="3"
                                ></v-rating>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="pa-5">
                            <strong>Parent: </strong><br>
                            <a text class="pa-0" v-if="info.complaintParent.id" @click="edit(info.complaintParent.id)">{{info.complaintParent.name}} (ID:{{info.complaintParent.id}})</a><br><br>
                            <strong>Siblings: </strong><br>
                            <ul>
                                <li v-for="(complaint, index) in info.complaintChild" v-bind:key="index">
                                <a text class="pa-0" @click="edit(complaint.id)">{{complaint.name}} (ID:{{complaint.id}})</a>
                                </li>
                            </ul>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </v-card>
            <v-card outlined class="pa-3 mb-3 mx-auto text-left" v-if="this.timeline[0].data !== ''">
                <v-card-title>Timeline</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col xs="12" sm="12" md="6">
                            <v-timeline dense >
                                <v-timeline-item
                                    class="mb-6"
                                    hide-dot
                                >
                                    <span>START</span>
                                </v-timeline-item>

                                <v-timeline-item
                                v-for="(item, i) in timeline.filter(x => x.data !== '')"
                                :key="i"
                                :color="item.color"
                                :icon="item.icon"
                                fill-dot
                                >
                                <v-card width="400">
                                    <v-card-text class="d-flex justify-space-between" :class="item.color+'--text'">
                                            <strong>{{item.title}}</strong>
                                            <div class="caption">
                                                {{item.data}}
                                            </div>
                                    </v-card-text>
                                </v-card>
                                </v-timeline-item>
                                <v-timeline-item
                                    class="mt-6"
                                    hide-dot
                                >
                                    <span>END</span>
                                </v-timeline-item>
                            </v-timeline>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                            <v-simple-table
                            >
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Time taken to Process from Submit:</td>
                                            <td>{{info.submit_to_process_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Resolve from Process Created:</td>
                                            <td>{{info.process_to_resolve_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Resolve from Submit:</td>
                                            <td>{{info.submit_to_resolve_du | formatTime}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<style>
.location-map{
    border:0;    
}
</style>
<script>
import Vue from 'vue'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
		  components: {
            EnlargeableImage,
            wysiwyg
          },
          props: {
            id: Number,
            globalPref: Array,
          },
		data: () => ({
			success: false,
			loading: true,
            gettingLocation: false,
            errorStr: '',
            editAsset: false,
            input:{
                id: '',
                name: '',
                description: '',
                image_1: null,
                image_2: null,
                image_3: null,
                image_4: null,
                image_5: null,
                image_6: null,
                latitude: null,
                longitude: null,
                complainer_rating: null,
                complainer:'', 
                landmark: '',
                cost: '',
                ext_ref_id: '',
                newfile_1: null,
                newfile_2: null,
                newfile_3: null,
                newfile_4: null,
                newfile_5: null,
                newfile_6: null,
            },
            info: {
                status: null,
                asset_data: {},
                system:0,
                generic:0,
                location:[null, null, null, null],
                latitude: null,
                longitude:null,
                created_ts: null,
                submit_ts: null,
                process_ts: null,
                resolve_ts: null,
                submit_to_process_du: null,
                process_to_resolve_du: null,
                submit_to_resolve_du: null,
                complaintParent: {
                    id: null,
                    name: '',
                },
                complaintChild: [],
                order_data: undefined,
                file_1: null,
                file_2: null,
                file_3: null,
                file_4: null,
                file_5: null,
                file_6: null,
            },
            timeline: [
                {
                    color: 'grey',
                    icon: 'mdi-email-send-outline',
                    title: 'Submitted',
                    data: '',
                },
                {
                    color: 'green',
                    icon: 'mdi-refresh',
                    title: 'Processed',
                    data: '',
                },
                {
                    color: 'teal',
                    icon: 'mdi-check-all',
                    title: 'Resolved',
                    data: '',
                },
            ],
            newimage_1: null,
            newimage_2: null,
            newimage_3: null,
            newimage_4: null,
            newimage_5: null,
            newimage_6: null,
            newimage_1Preview: null,
            newimage_2Preview: null,
            newimage_3Preview: null,
            newimage_4Preview: null,
            newimage_5Preview: null,
            newimage_6Preview: null,

            assetList: [],
            userList: [],
            locationList: [],
            locationList0: [],
            locationList1: [],
            locationList2: [],
            locationList3: [],
            locationList4: [],
            systemList: [],
            genericList: [],
            locationFilteredList: [],
            genericFilteredList: [],
            assetFilteredList: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            assetSearchSwitch: 'system',
            assetSelected: 0,
            assetDescription: '',
        }),
        computed: {
            objOpenDateTime: {
                get(){
                    return (new Date(this.input.open_dt))
                },
                set(value) {
                    this.input.open_dt = value
                }
            },
            objDueDateTime: {
                get(){
                    return (new Date(this.input.due_dt))
                },
                set(value) {
                    this.input.due_dt = value
                }
            },
            mapLat: {
                get(){
                    return this.input.latitude || this.info.latitude;
                },
            },
            mapLong: {
                get(){
                    return this.input.longitude || this.info.longitude;
                },
            },
			profileId: function() {
				return this.$store.getters.profileId;
			},
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            selectedLocation: function() {
                if ( !this.info.location[3] ) {
                    if ( !this.info.location[2] ) {
                        if ( !this.info.location[1] ) {
                            if ( this.info.location[0] ) {
                                return this.info.location[0]
                            }
                        } else {
                            return this.info.location[1]
                        }
                    } else {
                        return this.info.location[2]
                    }
                } else {
                    return this.info.location[3]
                }
            },
            complaint_image_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_1_label').value;
                }
            },
            complaint_image_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_2_label').value;
                }
            },
            complaint_image_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_3_label').value;
                }
            },
            complaint_image_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_4_label').value;
                }
            },
            complaint_image_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_5_label').value;
                }
            },
            complaint_image_6_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__image_6_label').value;
                }
            },
            complaint_file_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_1_label').value;
                }
            },
            complaint_file_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_2_label').value;
                }
            },
            complaint_file_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_3_label').value;
                }
            },
            complaint_file_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_4_label').value;
                }
            },
            complaint_file_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_5_label').value;
                }
            },
            complaint_file_6_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'complaint__file_6_label').value;
                }
            }
            // userVerifier: function() {
            //     if (this.users.length > 0) {
            //         if (this.input.checker == null) {
            //             return (this.users);
            //         } else {
            //             return (this.users.filter(users => users.id !== this.input.checker));
            //         }
            //     }
            // },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getComplaint().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.complainer = res.complainer;
                        this.input.location = res.location;

                        this.input.image_1 = res.image_1;
                        this.input.image_2 = res.image_2;
                        this.input.image_3 = res.image_3;
                        this.input.image_4 = res.image_4;
                        this.input.image_5 = res.image_5;
                        this.input.image_6 = res.image_6;

                        this.input.ext_ref_id = res.ext_ref_id;
                        this.input.landmark = res.landmark;
                        this.input.asset = res.asset;
                        if (res.asset) {
                            this.getAssetDescription(res.asset);
                        }
                        this.input.status = res.status;
                        this.input.complainer_rating = res.complainer_rating;
                        this.info.location_data = res.location_data;
                        this.info.asset_data = res.asset_data;
                        this.info.complainer_data = res.complainer_data;
                        this.info.latitude = res.latitude;
                        this.info.longitude = res.longitude;
                        this.input.complainer = res.complainer;
                        this.info.order_data = res.order_data;
                        if (res.asset_data) {
                            this.info.generic = res.asset_data.generic__id;
                            this.info.system = res.asset_data.system__id;
                        }
                        if (res.submit_ts) {
                            this.timeline[0].data = this.$moment(res.submit_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.process_ts) {
                            this.timeline[1].data = this.$moment(res.process_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.resolve_ts) {
                            this.timeline[2].data = this.$moment(res.resolve_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        this.info.submit_to_process_du = res.submit_to_process_du;
                        this.info.process_to_resolve_du = res.process_to_resolve_du;
                        this.info.submit_to_resolve_du = res.submit_to_resolve_du;

                        this.info.complaintParent.id = res.parent;
                        if (res.parent) {
                            this.getComplaintName(res.parent).then(res => this.info.complaintParent.name = res );
                        }
                        // this.info.complaintChild = res.child_set;

                        res.child_set.forEach((item, index) => {
                            this.getComplaintName(res.parent).then((res) =>{
                                this.info.complaintChild.push ({
                                    id: item.id, name: res
                                })
                                }
                            );
                        });


                        if (this.input.asset) {
                            this.getLoc(res.location_data.id).then((res) => { 
                                console.log(res);
                                this.info.location[res.depth] = res.id;
                                if (res.depth > 0) {
                                    this.getLoc(res.parent).then((res) => { 
                                        this.info.location[res.depth] = res.id;
                                        if (res.depth > 0) {
                                            this.getLoc(res.parent).then((res) => { 
                                                this.info.location[res.depth] = res.id;
                                                if (res.depth > 0) {
                                                    this.getLoc(res.parent).then((res) => { 
                                                        this.info.location[res.depth] = res.id;
                                                    })
                                                }       
                                            })
                                        }
                                    })
                                }
                            });
                        } else {
                            if (this.input.location) {
                                this.getLoc(this.input.location).then((res) => {
                                    this.info.location[res.depth] = res.id;
                                    if (res.depth > 0) {
                                        this.getLoc(res.parent).then((res) => { 
                                            this.info.location[res.depth] = res.id;
                                            if (res.depth > 0) {
                                                this.getLoc(res.parent).then((res) => { 
                                                    this.info.location[res.depth] = res.id;
                                                    if (res.depth > 0) {
                                                        this.getLoc(res.parent).then((res) => { 
                                                            this.info.location[res.depth] = res.id;
                                                        })
                                                    }       
                                                })
                                            }
                                        })
                                    }
                                });
                            }
                        }
                        if (this.profileId == this.info.complainer_data.id) {
                            this.editAsset = true;
                        }
                        if (res.location) {
                            this.filterByLocBasic();
                        }
                        this.info.file_1 = res.file_1;
                        this.info.file_2 = res.file_2;
                        this.info.file_3 = res.file_3;
                        this.info.file_4 = res.file_4;
                        this.info.file_5 = res.file_5;
                        this.info.file_6 = res.file_6;
                        
                    });
                } else {
                    this.input.status = ''
                    this.grabLoc();
                    this.editAsset = true;
                    this.input.complainer = this.profileId;
                }
                this.getAdditionalData();
            },
            grabLoc() {
                if(!("geolocation" in navigator)) {
                    this.errorStr = 'Geolocation is not available.';
                    return;
                }
                this.gettingLocation = true;
                // get position
                    navigator.geolocation.getCurrentPosition(pos => {
                    this.gettingLocation = false;
                    this.input.latitude = Math.floor(pos.coords.latitude*1000000+0.5)/1000000;
                    this.input.longitude = Math.floor(pos.coords.longitude*1000000+0.5)/1000000;
                }, err => {
                    this.gettingLocation = false;
                    this.errorStr = err.message;
                })
            },
			async getComplaint() {
                try {
                    const res = await this.$api.complaintComplaint.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            bulkStatus(status) {
                if (status !== "PROCESSED") {
                    this.saveComplaint(status).then((res) => {
                        if (res.status == 200 || res.status == 201 ) {
                            this.setStatus(status).then((res) => {
                                if (res.status == 200 || res.status == 201 ) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Saved',
                                        showCancelButton: true,
                                        reverseButtons: true,
                                        confirmButtonText: 'Return',
                                        cancelButtonText: 'Cancel',
                                    }).then((result) => {
                                        if (result.value) {
                                            this.$router.push({ name: 'complaintComplaintList' })
                                        } else {
                                            this.input.id = res.data.id;
                                            this.form();
                                        }
                                    })
                                }
                            });
                        }
                    })
                } else {
                    this.saveComplaint().then((res) => {
                        if (res.status == 200 || res.status == 201 ) {
                            if (this.selectedLocation && !this.input.asset) {
                                this.$router.push({ name: 'workOrderForm', params: { complaint: this.id, locationId: this.selectedLocation } })
                            } else {
                                this.$router.push({ name: 'workOrderForm', params: { complaint: this.id, assetId: this.input.asset } })
                            }
                        }
                    })
                }
			},
			async setStatus(status) {
                try {
                    let res;
                    this.saving = true
                    if (status == 'WIP') {
                        res = await this.$api.complaintComplaint.save({id: this.input.id, status: status });
                    } else {
                        res = await this.$api.complaintComplaint.save({id: this.input.id, status: status });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			save(status) {
                this.saveComplaint(status).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'complaintComplaintList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveComplaint(status) {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    if (status) {
                    formData.append("status", status);
                    }
                    if (this.input.ext_ref_id) {
                        formData.append("ext_ref_id", this.input.ext_ref_id);
                    }
                    if (this.input.landmark) {
                        formData.append("landmark", this.input.landmark);
                    }
                    if (this.selectedLocation) {
                        formData.append("location", this.selectedLocation);
                    }
                    if (this.input.latitude) {
                        formData.append("latitude", this.input.latitude);
                    }
                    if (this.input.longitude) {
                        formData.append("longitude", this.input.longitude);
                    }
                    if ( this.input.complainer_rating) {
                        formData.append("complainer_rating", this.input.complainer_rating);
                    }
                    formData.append("complainer", this.input.complainer);
                    if (this.input.asset) {
                        formData.append("asset", this.input.asset);
                    }
                    if (this.newimage_1) {
                        formData.append("image_1", this.newimage_1 );
                    }
                    if (this.newimage_2) {
                        formData.append("image_2", this.newimage_2 );
                    }
                    if (this.newimage_3) {
                        formData.append("image_3", this.newimage_3 );
                    }
                    if (this.newimage_4) {
                        formData.append("image_4", this.newimage_4 );
                    }
                    if (this.newimage_5) {
                        formData.append("image_5", this.newimage_5 );
                    }
                    if (this.newimage_6) {
                        formData.append("image_6", this.newimage_6 );
                    }
                    if (this.input.newfile_1) {
                        formData.append("file_1", this.input.newfile_1);
                    }
                    if (this.input.newfile_2) {
                        formData.append("file_2", this.input.newfile_2);
                    }
                    if (this.input.newfile_3) {
                        formData.append("file_3", this.input.newfile_3);
                    }
                    if (this.input.newfile_4) {
                        formData.append("file_4", this.input.newfile_4);
                    }
                    if (this.input.newfile_5) {
                        formData.append("file_5", this.input.newfile_5);
                    }
                    if (this.input.newfile_6) {
                        formData.append("file_6", this.input.newfile_6);
                    }
                    if (this.input.id) {
                        res = await this.$api.complaintComplaint.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.complaintComplaint.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'complaintComplaintList' })
            },
            previewImage(e, previewTarget) {
                if (e) {
                    this[previewTarget] = URL.createObjectURL(e);
                }
            },
            getAdditionalData() {
                this.getUsers().then(res => this.userList = res);
                this.getAssets().then(res => this.assetList = res);
                this.getSystems().then(res => this.systemList = res);
                this.getGenerics().then(res => this.genericList = res);
                this.getLocsbyDepth(0).then((res) => { 
                    this.locationList0 = res;
                });
                this.getLocsbyDepth(1).then((res) => { 
                    this.locationList1 = res;
                });
                this.getLocsbyDepth(2).then((res) => { 
                    this.locationList2 = res;
                });
                this.getLocsbyDepth(3).then((res) => { 
                    this.locationList3 = res;
                });
                this.getLocsbyDepth(4).then((res) => { 
                    this.locationList4 = res;
                });
            },
            async getAsset() {
                try {
                    const res = await this.$api.assetAsset.get( this.input.asset );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLoc(location_id) {
                try {
                    const res = await this.$api.location.get( location_id );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLocsbyDepth(depth) {
                try {
                    const res = await this.$api.location.search({depth: depth});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getUsers() {
                try {
                    const res = await this.$api.user.search({ is_active: true });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenerics() {
                try {
                    const res = await this.$api.assetGeneric.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            filterBySystem() {
                this.getGenericsbySystem().then((res) => { 
                    this.genericList = res;
                });
                this.getAssetsbyFilter(this.info.system, null, null).then(res => this.assetList = res );
            },
            filterByGeneric() {
                this.getAssetsbyFilter(this.info.system, this.info.generic, null).then((res) => { this.assetList = res } );
            },
            filterByLoc(locationfield, location_id) {
                this.$api.location.search({ depth: locationfield+1, parent: location_id }).then((res) => {
                    if (locationfield+1 == 1) {
                        Vue.set(this.info.location, 1, null);
                        Vue.set(this.info.location, 2, null);
                        Vue.set(this.info.location, 3, null);
                        Vue.set(this.info.location, 4, null);
                        this.info.location[1] = null;
                        this.locationList1 = res.data;
                    }
                    if (locationfield+1 == 2) {
                        Vue.set(this.info.location, 2, null);
                        Vue.set(this.info.location, 3, null);
                        Vue.set(this.info.location, 4, null);
                        this.info.location[2] = null;
                        this.locationList2 = res.data;
                    }
                    if (locationfield+1 == 3) {
                        Vue.set(this.info.location, 3, null);
                        Vue.set(this.info.location, 4, null);
                        this.info.location[3] = null;
                        this.locationList3 = res.data;
                    }
                    if (locationfield+1 == 4) {
                        Vue.set(this.info.location, 4, null);
                        this.info.location[4] = null;
                        this.locationList4 = res.data;
                    }
                } );
                this.getAssetsbyFilter(null, null, location_id).then((res) => { this.assetList = res } );
            },
            filterByLocBasic() {
                this.getAssetsbyFilter(null, null, this.input.location).then((res) => { this.assetList = res } );
            },
            async getChildLoc(depth, parent_id) {
                try {
                    const res = await this.$api.location.search({ depth: depth+1, parent: parent_id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenericsbySystem(system_id) {
                try {
                    const res = await this.$api.assetGeneric.search({ system: this.info.system });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAssetsbyFilter(system, generic, location) {
                try {
                    let res;
                    if (this.assetSearchSwitch == 'system') {
                        res = await this.$api.assetAsset.search({ system: system, generic: generic });
                    } else {
                        res = await this.$api.assetAsset.search({ location: location });
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            convertComplaintToName(complaint_id) {
                this.getComplaintName(complaint_id).then((res) => {
                    return res;  
                });
            },
            async getComplaintName(complaint_id) {
                try {
                    const res = await this.$api.complaintComplaint.get(complaint_id);
                    return res.data.name;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshComplaintTask() {
                this.form();
            },
			edit(id) {
                this.$router.push({ name: 'complaintComplaintForm', params: { id } })
            },
            getAssetDescription(asset_id) {
                if (asset_id) {
                    this.getAssetDesc(asset_id).then((res) => { 
                        this.assetDescription = res;
                    })
                }
            },
			async getAssetDesc(asset_id) {
                try {
                    const res = await this.$api.assetAsset.get(asset_id).description;
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.valid = true;
        },
        watch: {
            profileId: {
                handler(val){
                    if (this.info.complainer_data && this.profileId == this.info.complainer_data.id) {
                        this.editAsset = true;
                    } else {
                        this.editAsset = false;
                    }
                },
            }
        }
	}
</script>