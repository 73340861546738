<template>
    <v-container fluid class="list-complaintComplaint">
        <v-row v-if="!editMode">
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                    <v-icon>mdi-window-close</v-icon>
                    Remove filter
                </v-btn>
                <v-dialog v-model="filterDialog" max-width="400">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" dark v-on="on" class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </template>
                    <v-card>
                        <tableFilter :profileRole="profileRole" :profileId="profileId" :filterFields="filterData" v-on:filteredData="tableFilter" :userList="userList" :locationList="locationList"></tableFilter>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="mergeDialog" max-width="400">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" v-on="on" class="mr-2" :disabled="complaintSelected.length < 1">
                            <v-icon>mdi-merge</v-icon>
                            Merge
                            </v-btn>
                    </template>
                    <v-card class="pa-5">
                        <h3>Choose the parent:</h3>
                        <v-radio-group v-model="complaintSelectedParent">
                        <v-radio
                            v-for="(complaint, index) in complaintSelected"
                            v-bind:key="index"
                            :label="complaint.name + ' (ID:' + complaint.id +')'"
                            :value="complaint.id"
                        ></v-radio>
                        </v-radio-group>
                        <v-btn @click="merge()" color="primary" class="mr-2" :disabled="!complaintSelectedParent">
                            <v-icon>mdi-merge</v-icon>
                            Merge
                        </v-btn>
                    </v-card>
                </v-dialog>
                <v-btn @click="edit()" color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
                <!-- <v-select
                :items="existingYearMonths" 
                label="Year - Month" 
                v-model="yearMonthSelected"
                name="item"
                item-value="value"
                item-text="name"
                ></v-select>
                <v-row align-content="center">
                    <v-col sm="6">
                        <v-select
                        :items="programList" 
                        label="WorkProgram" 
                        v-model="programSelected"
                        name="item"
                        item-value="id"
                        item-text="title"
                        ></v-select>
                    </v-col>
                </v-row> -->
            </div>
            <v-data-table
            :ripple="false"
            show-select
            v-if="this.complaintComplaint"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="profileRole == 'CUSTOMER' ? headersComp : headers"
            :items-per-page="perPage"
            :items="this.complaintComplaint"
            hide-default-footer
            class="elevation-1"
            v-model="complaintSelected"
            :options.sync="options"
            >
            <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)"></v-simple-checkbox>
            </template>
            <template v-slot:item.name="{item}">
                <td class="text-left">
                    <v-badge
                    class="animate-fading"
                    v-if="item.urgency"
                    :color="urgencyColor(item.urgency)"
                    offset-x="-5"
                    offset-y="-1"
                    left
                    dot
                    >
                    </v-badge>
                    {{item.urgency}} 
                    {{item.name}} 
                </td>
            </template>
            <template v-slot:item.complainer="{item}">
                <td class="text-left" v-if="profileRole !== 'CUSTOMER'">{{getUserName(item.complainer)}}</td>
            </template>
            <template v-slot:item.location="{item}">
                <td class="text-left" v-if="profileRole !== 'CUSTOMER'">{{getLocationName(item.location)}}</td>
            </template>
            <template v-slot:item.submit_ts="{item}">
                <td class="text-left">{{ item.submit_ts | datetimestamp }}</td>
            </template>
            <template v-slot:item.buttons="{item}">
                <td style="width:150px" class="text-xs-right">
                    <v-btn-toggle>
                        <v-btn small fab @click="edit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab @click="remove(item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterComplaint.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
                locationList: [],
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingWorkPrograms: [],
            yearMonthSelected: '',
            programSelected: '',
            otherComplaintComplaint : [],
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Title', value: 'name' },
            { text: 'Complainer', value: 'complainer' },
            { text: 'Ext Ref ID', value: 'ext_ref_id' },
            { text: 'Location', value: 'location' },
            { text: 'Submitted On', value: 'submit_ts', width:200 },
            { text: 'Status', value: 'status', width:200 },
            { text: '', value: 'buttons', sortable: false, width: 100}
            ],
            headersComp: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Submitted On', value: 'submit_ts', width:200 },
            { text: 'Status', value: 'status', width:200 },
            { text: '', sortable: false, width: 100}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			complaintComplaint: [],
			loading: true,
            inputId: '',
            inputName: '',
            loading: true,
            complaintComplaintId: 0,
            userList: [],
            today: null,
            setDate: null,
            menu: false,
            programList: [],
            loadingComplaintComplaintCalendar: false,
            userList: [],
            filter: false,
            filterDialog: false,
            filterData: {},
            filteredComplaintList: [],
            mergeDialog: false,
            complaintSelected: [],
            complaintSelectedParent: null,
            options:{},
		}),
        computed: {
			profileId:{
                get: function () {
                    return this.$store.getters.profileId;
                },
            },
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            complaintSelectedPost() {
                return this.complaintSelected.map(a => a.id);
            },
            filterDataWithPagination: function() {
                var newObj = this.filterData;
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                newObj.open_date = this.today;
                return newObj;
            },
            filterDataWithPaginationCustomer: function() {
                var newObj = this.filterData;
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                newObj.complainer = this.profileId;
                newObj.open_date = this.today;
                return newObj;
            },
            datatableOrdering: function() {
                if (this.options.sortBy.length > 0) {
                    if ( this.options.sortDesc[0] == false) {
                        return this.options.sortBy[0];
                    } else {
                        return '-' + this.options.sortBy[0];
                    }   
                } else {
                    return '-submit_ts';
                }
            }
        },
		methods: {
            // Main Function
            init() {
                this.getLocation().then((res) => {
                    this.locationList = res;
                });
                this.loadList();
            },
            loadList() {
			    this.getComplaintComplaint().then((res) => {
                    this.complaintComplaint = res.results;
                    this.pagination.next = res.next;
                    this.pagination.count = res.count;
                    this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getComplaintComplaint() {
                try {
                    this.loading = true
                    let res;
                    if (this.profileRole == 'CUSTOMER') {
                        res = await this.$api.complaintComplaint.search( this.filterDataWithPaginationCustomer );
                    } else {
                        res = await this.$api.complaintComplaint.search( this.filterDataWithPagination );
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getUserList().then((res) => {this.userList = res;});
            },
			edit(id) {
                // this.$router.push({ name: 'complaintComplaintForm', params: { id } })
                let routeData = this.$router.resolve({name: 'complaintComplaintForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.complaintComplaint.delete(id).then(res => this.list() );
                    }
                })
            },
            urgencyColor(urgency) {
                if (urgency == 1) {
                    return 'red dark-3'
                }
                if (urgency == 2) {
                    return 'red'
                }
                if (urgency == 3) {
                    return 'amber'
                }
                if (urgency == 4) {
                    return 'blue'
                }
            } ,
			async getUserList() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.userList && this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getLocationName(location_id) {
                if ( this.locationList.length !== 0 && location_id ) {
                    return this.locationList.find(location => location.id === location_id).name;
                }
            }, 
            // Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filterDataVetted  = {};
                this.filterDataAudited = {};
                this.filteredClaimList = [];
                this.filteredClaimListVetted = [];
                this.filteredClaimListAudited = [];
                this.filter = false;
            },
            merge() {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to merge?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
                        this.mergeComplain().then((res) => {
                            console.log(res);
                            if (res.data.error) {
                                this.$swal({
                                    icon: 'error',
                                    title: res.data.error,
                                    reverseButtons: true,
                                    confirmButtonText: 'Ok',
                                }).then((result) => {
                                    this.list();
                                    this.mergeDialog = false;
                                    this.complaintSelected = [];
                                    this.omplaintSelectedParent = null;
                                })
                            } else {
                                this.mergeDialog = false;
                                this.loadList();
                                this.complaintSelected = [];
                                this.complaintSelectedParent = null;
                                this.$swal({
                                    icon: 'success',
                                    title: 'Successfuly Merged!',
                                    reverseButtons: true,
                                    confirmButtonText: 'Ok',
                                }).then((result) => {
                                })
                            }
                        })
                    }
                })
            },
			async mergeComplain() {
                try {
                    let res;
                    this.saving = true
                    res = await this.$api.complaintComplaint.merge({ child_set: this.complaintSelectedPost, parent: this.complaintSelectedParent});
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			async getLocation() {
                try {
                    const res = await this.$api.location.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.today = this.$moment().format('YYYY-MM-DD')
            this.init();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
            today: function() {
                this.init();
            },
            pagination: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            filterData: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            profileId: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            options: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
        },
	}
</script>