<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto">
                <v-form lazy-validation
                    ref="form"
                    v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-menu
                                v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="From"
                                    readonly
                                    :value="fromDateDisp"
                                    v-on="on"

                                ></v-text-field>
                                </template>
                                <v-date-picker
                                locale="en-in"
                                v-model="input.start"
                                no-title
                                @input="fromDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-menu
                                v-model="ToDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="To"
                                    readonly
                                    :value="ToDateDisp"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                locale="en-in"
                                v-model="input.until"
                                no-title
                                @input="ToDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            :items="optionFrequency" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Frequency" 
                            v-model="input.frequency"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                            :items="optionInterval" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Interval" 
                            v-model="input.interval"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'WEEKLY_NUMBER' || input.frequency == 'WEEKLY_INTERVAL'"
                            :items="optionDayInAWeek" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Week" 
                            multiple
                            chips
                            v-model="input.day_in_week"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'WEEKLY_NUMBER'"
                            :items="optionWeekNumber" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Week" 
                            multiple
                            chips
                            v-model="input.week_number"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'MONTHLY_DAY'"
                            :items="optionDayInAMonth" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Month"
                            multiple
                            chips 
                            v-model="input.day_in_month"
                            ></v-select>
                            <v-dialog
                            v-if="input.frequency == 'MONTHLY_DAY'"
                            v-model="dialog"
                            width="800"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="red lighten-2"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon class="mr-2">mdi-information-outline</v-icon> Important Information
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline grey lighten-2">
                                Important Note
                                </v-card-title>

                                <v-card-text>
                                    <div class="pt-2 pb-2">Cannot combine mixed these two groups of day in month:</div>
                                
                                <table class="table-bordered table" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Every Group:</th>
                                            <th>Other Group:</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td width="50%" valign="top">
                                            "MON", "Every Monday"<br/>
                                            "TUE", "Every Tuesday"<br/>
                                            "WED", "Every Wednesday"<br/>
                                            "THU", "Every Thursday"<br/>
                                            "FRI", "Every Friday"<br/>
                                            "SAT", "Every Saturday"<br/>
                                            "SUN", "Every Sunday"
                                        </td>
                                        <td width="50%" valign="top">
                                            "FIRST_MON", "First Monday"<br/>
                                            "FIRST_TUE", "First Tuesday"<br/>
                                            "FIRST_WED", "First Wednesday"<br/>
                                            "FIRST_THU", "First Thursday"<br/>
                                            "FIRST_FRI", "First Friday"<br/>
                                            "FIRST_SAT", "First Saturday"<br/>
                                            "FIRST_SUN", "First Sunday"<br/>
                                            "SECOND_MON", "Second Monday"<br/>
                                            "SECOND_TUE", "Second Tuesday"<br/>
                                            "SECOND_WED", "Second Wednesday"<br/>
                                            "SECOND_THU", "Second Thursday"<br/>
                                            "SECOND_FRI", "Second Friday"<br/>
                                            "SECOND_SAT", "Second Saturday"<br/>
                                            "SECOND_SUN", "Second Sunday"<br/>
                                            "THIRD_MON", "Third Monday"<br/>
                                            "THIRD_TUE", "Third Tuesday"<br/>
                                            "THIRD_WED", "Third Wednesday"<br/>
                                            "THIRD_THU", "Third Thursday"<br/>
                                            "THIRD_FRI", "Third Friday"<br/>
                                            "THIRD_SAT", "Third Saturday"<br/>
                                            "THIRD_SUN", "Third Sunday"<br/>
                                            "FOURTH_MON", "Fourth Monday"<br/>
                                            "FOURTH_TUE", "Fourth Tuesday"<br/>
                                            "FOURTH_WED", "Fourth Wednesday"<br/>
                                            "FOURTH_THU", "Fourth Thursday"<br/>
                                            "FOURTH_FRI", "Fourth Friday"<br/>
                                            "FOURTH_SAT", "Fourth Saturday"<br/>
                                            "FOURTH_SUN", "Fourth Sunday"<br/>
                                            "FIFTH_MON", "Fifth Monday"<br/>
                                            "FIFTH_TUE", "Fifth Tuesday"<br/>
                                            "FIFTH_WED", "Fifth Wednesday"<br/>
                                            "FIFTH_THU", "Fifth Thursday"<br/>
                                            "FIFTH_FRI", "Fifth Friday"<br/>
                                            "FIFTH_SAT", "Fifth Saturday"<br/>
                                            "FIFTH_SUN", "Fifth Sunday"<br/>
                                            "LAST_MON", "Last Monday"<br/>
                                            "LAST_TUE", "Last Tuesday"<br/>
                                            "LAST_WED", "Last Wednesday"<br/>
                                            "LAST_THU", "Last Thursday"<br/>
                                            "LAST_FRI", "Last Friday"<br/>
                                            "LAST_SAT", "Last Saturday"<br/>
                                            "LAST_SUN", "Last Sunday"<br/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                                
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                >
                                    I understand
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'MONTHLY_DATE'"
                            :items="optionDateInAMonth" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Date in a Month" 
                            multiple
                            chips
                            v-model="input.date_in_month"
                            ></v-select>
                        </v-col>
                       
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
		  components: {
                wysiwyg
          },
          props: ['id'],
		data: () => ({
            success: false,
            input: {
                id:'',
                name: '',
                description: '',
                frequency: '',
                interval: '',
                start: '',
                until: '',
                day_in_week: [],
                day_in_month: [],
                date_in_month: [],
                week_number: [],
            },
            dialog: false,
            loading: true,
            fromDateMenu: false,
            ToDateMenu: false,
            fromDateVal: '',
            ToDateVal: '',
            optionFrequency:[
                {
                value: "DAILY",
                name: "daily"
                },
                {
                value: "WEEKLY_INTERVAL",
                name: "weekly (interval)"
                },
                {
                value: "WEEKLY_NUMBER",
                name: "weekly (number)"
                },
                {
                value: "MONTHLY_DAY",
                name: "monthly (day)"
                },
                {
                value: "MONTHLY_DATE",
                name: "monthly (date)"
                },
            ],
            optionInterval:[
                {
                value: 1,
                name: "One"
                },
                {
                value: 2,
                name: "Two"
                },
                {
                value: 3,
                name: "Three"
                },
                {
                value: 4,
                name: "Four"
                },
                {
                value: 5,
                name: "Five"
                },
                {
                value: 6,
                name: "Six"
                },
            ],
            optionDayInAWeek:[
                {
                value: "MON",
                name: "Monday"
                },
                {
                value: "TUE",
                name: "Tuesday"
                },
                {
                value: "WED",
                name: "Wednesday"
                },
                {
                value: "THU",
                name: "Thursday"
                },
                {
                value: "FRI",
                name: "Friday"
                },
                {
                value: "SAT",
                name: "Saturday"
                },
                {
                value: "SUN",
                name: "Sunday"
                },
            ], 
            optionDayInAMonth:[
                { value:"MON", name:"Every Monday" },
                { value:"TUE", name:"Every Tuesday" },
                { value:"WED", name:"Every Wednesday" },
                { value:"THU", name:"Every Thursday" },
                { value:"FRI", name:"Every Friday" },
                { value:"SAT", name:"Every Saturday" },
                { value:"SUN", name:"Every Sunday" },
                { value:"FIRST_MON", name:"First Monday" },
                { value:"FIRST_TUE", name:"First Tuesday" },
                { value:"FIRST_WED", name:"First Wednesday" },
                { value:"FIRST_THU", name:"First Thursday" },
                { value:"FIRST_FRI", name:"First Friday" },
                { value:"FIRST_SAT", name:"First Saturday" },
                { value:"FIRST_SUN", name:"First Sunday" },
                { value:"SECOND_MON", name:"Second Monday" },
                { value:"SECOND_TUE", name:"Second Tuesday" },
                { value:"SECOND_WED", name:"Second Wednesday" },
                { value:"SECOND_THU", name:"Second Thursday" },
                { value:"SECOND_FRI", name:"Second Friday" },
                { value:"SECOND_SAT", name:"Second Saturday" },
                { value:"SECOND_SUN", name:"Second Sunday" },
                { value:"THIRD_MON", name:"Third Monday" },
                { value:"THIRD_TUE", name:"Third Tuesday" },
                { value:"THIRD_WED", name:"Third Wednesday" },
                { value:"THIRD_THU", name:"Third Thursday" },
                { value:"THIRD_FRI", name:"Third Friday" },
                { value:"THIRD_SAT", name:"Third Saturday" },
                { value:"THIRD_SUN", name:"Third Sunday" },
                { value:"FOURTH_MON", name:"Fourth Monday" },
                { value:"FOURTH_TUE", name:"Fourth Tuesday" },
                { value:"FOURTH_WED", name:"Fourth Wednesday" },
                { value:"FOURTH_THU", name:"Fourth Thursday" },
                { value:"FOURTH_FRI", name:"Fourth Friday" },
                { value:"FOURTH_SAT", name:"Fourth Saturday" },
                { value:"FOURTH_SUN", name:"Fourth Sunday" },
                { value:"FIFTH_MON", name:"Fifth Monday" },
                { value:"FIFTH_TUE", name:"Fifth Tuesday" },
                { value:"FIFTH_WED", name:"Fifth Wednesday" },
                { value:"FIFTH_THU", name:"Fifth Thursday" },
                { value:"FIFTH_FRI", name:"Fifth Friday" },
                { value:"FIFTH_SAT", name:"Fifth Saturday" },
                { value:"FIFTH_SUN", name:"Fifth Sunday" },
                { value:"LAST_MON", name:"Last Monday" },
                { value:"LAST_TUE", name:"Last Tuesday" },
                { value:"LAST_WED", name:"Last Wednesday" },
                { value:"LAST_THU", name:"Last Thursday" },
                { value:"LAST_FRI", name:"Last Friday" },
                { value:"LAST_SAT", name:"Last Saturday" },
                { value:"LAST_SUN", name:"Last Sunday" }
            ],
            optionDateInAMonth: [
                {
                    value: 1,
                    name: "1"
                },
                {
                    value: 2,
                    name: "2"
                },
                {
                    value: 3,
                    name: "3"
                },
                {
                    value: 4,
                    name: "4"
                },
                {
                    value: 5,
                    name: "5"
                },
                {
                    value: 6,
                    name: "6"
                },
                {
                    value: 7,
                    name: "7"
                },
                {
                    value: 8,
                    name: "8"
                },
                {
                    value: 9,
                    name: "9"
                },
                {
                    value: 10,
                    name: "10"
                },
                {
                    value: 11,
                    name: "11"
                },
                {
                    value: 12,
                    name: "12"
                },
                {
                    value: 13,
                    name: "13"
                },
                {
                    value: 14,
                    name: "14"
                },
                {
                    value: 15,
                    name: "15"
                },
                {
                    value: 16,
                    name: "16"
                },
                {
                    value: 17,
                    name: "17"
                },
                {
                    value: 18,
                    name: "18"
                },
                {
                    value: 19,
                    name: "19"
                },
                {
                    value: 20,
                    name: "20"
                },
                {
                    value: 21,
                    name: "21"
                },
                {
                    value: 22,
                    name: "22"
                },
                {
                    value: 23,
                    name: "23"
                },
                {
                    value: 24,
                    name: "24"
                },
                {
                    value: 25,
                    name: "25"
                },
                {
                    value: 26,
                    name: "26"
                },
                {
                    value: 27,
                    name: "27"
                },
                {
                    value: 28,
                    name: "28"
                },
                {
                    value: 29,
                    name: "29"
                },
                {
                    value: 30,
                    name: "30"
                },
                {
                    value: 31,
                    name: "31"
                }
            ],
            optionWeekNumber:[
                {
                    value: 1,
                    name: "W01"
                },
                {
                    value: 2,
                    name: "W02"
                },
                {
                    value: 3,
                    name: "W03"
                },
                {
                    value: 4,
                    name: "W04"
                },
                {
                    value: 5,
                    name: "W05"
                },
                {
                    value: 6,
                    name: "W06"
                },
                {
                    value: 7,
                    name: "W07"
                },
                {
                    value: 8,
                    name: "W08"
                },
                {
                    value: 9,
                    name: "W09"
                },
                {
                    value: 10,
                    name: "W10"
                },
                {
                    value: 11,
                    name: "W11"
                },
                {
                    value: 12,
                    name: "W12"
                },
                {
                    value: 13,
                    name: "W13"
                },
                {
                    value: 14,
                    name: "W14"
                },
                {
                    value: 15,
                    name: "W15"
                },
                {
                    value: 16,
                    name: "W16"
                },
                {
                    value: 17,
                    name: "W17"
                },
                {
                    value: 18,
                    name: "W18"
                },
                {
                    value: 19,
                    name: "W19"
                },
                {
                    value: 20,
                    name: "W20"
                },
                {
                    value: 21,
                    name: "W21"
                },
                {
                    value: 22,
                    name: "W22"
                },
                {
                    value: 23,
                    name: "W23"
                },
                {
                    value: 24,
                    name: "W24"
                },
                {
                    value: 25,
                    name: "W25"
                },
                {
                    value: 26,
                    name: "W26"
                },
                {
                    value: 27,
                    name: "W27"
                },
                {
                    value: 28,
                    name: "W28"
                },
                {
                    value: 29,
                    name: "W29"
                },
                {
                    value: 30,
                    name: "W30"
                },
                {
                    value: 31,
                    name: "W31"
                },
                {
                    value: 32,
                    name: "W32"
                },
                {
                    value: 33,
                    name: "W33"
                },
                {
                    value: 34,
                    name: "W34"
                },
                {
                    value: 35,
                    name: "W35"
                },
                {
                    value: 36,
                    name: "W36"
                },
                {
                    value: 37,
                    name: "W37"
                },
                {
                    value: 38,
                    name: "W38"
                },
                {
                    value: 39,
                    name: "W39"
                },
                {
                    value: 40,
                    name: "W40"
                },
                {
                    value: 41,
                    name: "W41"
                },
                {
                    value: 42,
                    name: "W42"
                },
                {
                    value: 43,
                    name: "W43"
                },
                {
                    value: 44,
                    name: "W44"
                },
                {
                    value: 45,
                    name: "W45"
                },
                {
                    value: 46,
                    name: "W46"
                },
                {
                    value: 47,
                    name: "W47"
                },
                {
                    value: 48,
                    name: "W48"
                },
                {
                    value: 49,
                    name: "W49"
                },
                {
                    value: 50,
                    name: "W50"
                },
                {
                    value: 51,
                    name: "W51"
                },
                {
                    value: 52,
                    name: "W52"
                },
                {
                    value: 53,
                    name: "W53"
                }
            ],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
        }),
        computed: {
            fromDateDisp() {
            return this.input.start;
            // format/do something with date
            },
            ToDateDisp() {
                return this.input.until;
            }
        },  
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getRecurrence().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.frequency = res.frequency;
                        this.input.interval = res.interval;
                        this.input.start = res.start;
                        this.input.until = res.until;
                        this.input.day_in_week = res.day_in_week;
                        this.input.day_in_month = res.day_in_month;
                        this.input.date_in_month = res.date_in_month;
                        this.input.week_number = res.week_number
                    });
                }
            },
			async getRecurrence() {
                try {
                    this.loading = true
                    const res = await this.$api.recurrence.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveRecurrence().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'recurrenceList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveRecurrence() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.recurrence.save( this.input );
                    } else {
                        res = await this.$api.recurrence.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'recurrenceList' })
            },
		},
		mounted () {
			this.form();
		},
	}
</script>