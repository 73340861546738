<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-select
            :items="userWorkerList" 
            item-value="id"
            name="item"
            item-text="username"
            label="Worker" 
            v-model="filterFields.worker"
            ></v-select>
            <v-select
            :items="costCenterListSelect" 
            item-value="id"
            name="item"
            item-text="name"
            label="Cost Center" 
            v-model="filterFields.costcenter"
            ></v-select>
            <v-select
            :items="scopeListSelect" 
            item-value="id"
            name="item"
            item-text="name"
            label="Scope" 
            v-model="filterFields.scope"
            ></v-select>
            <v-select
            :items="userListSelect" 
            item-value="id"
            name="item"
            item-text="username"
            label="Created By" 
            v-model="filterFields.created_by"
            ></v-select>
            <v-select
            :items="userListSelect" 
            item-value="id"
            name="item"
            item-text="username"
            label="Vetted By" 
            v-model="filterFields.vetted_by"
            ></v-select>
            <v-select
            :items="userListSelect" 
            item-value="id"
            name="item"
            item-text="username"
            label="Audited By" 
            v-model="filterFields.audited_by"
            ></v-select>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
            userWorkerList: [],
            userListSelect:[],
            costCenterListSelect:[],
            scopeListSelect: [],
        }),
        props: {
            tableType: {
                type: String,
            },
            
            filterFields: {
                type: Object,
            },
            costCenterList: {
                type: Array,
                required: false
            },
            userList: {
                type: Array,
                required: false
            },
            scopeList: {
                type: Array,
                required: false
            }
        },
        computed: {
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            },
            async getWorkers() {
                try {
                    const res = await this.$api.user.search({is_worker_candidate:true});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted() {
            this.getWorkers().then((res) => {
                this.userWorkerList = res
                this.userWorkerList.unshift({
                    id:'',
                    username: 'All Worker',
                });
            });
            this.costCenterListSelect = this.costCenterList
            this.costCenterListSelect.unshift({
                id:'',
                name: 'All Cost Center',
            });
            this.userListSelect = this.userList
            this.userListSelect.unshift({
                id:'',
                username: 'All User',
            });
            this.scopeListSelect = this.scopeList
            this.scopeListSelect.unshift({
                id:'',
                name: 'All Scope',
            });
        }
    }
</script>