<template>
    <v-container fluid class="list-workDetail">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Detail Info</v-tab>
                    <!-- <v-tab v-if="this.input.id">Child: Scope</v-tab> -->
                    <v-tab v-if="this.input.id">Child: Cost Center</v-tab>
                    <v-tab-item>
                        <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Code" 
                                        v-model="input.code"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                    <v-text-field
                                        label="Name" 
                                        v-model="input.name"
                                        :rules="nameRules"
                                        :counter="50"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <wysiwyg
                                    :content.sync="input.description"
                                    />
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select
                                    :items="categoryOptions" 
                                    item-value="value"
                                    name="item"
                                    item-text="name"
                                    label="Category" 
                                    v-model="input.category"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row> -->
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select
                                    :items="headingList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="Heading" 
                                    v-model="input.heading"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>    
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <detailScopeTable class="p-2" v-if="this.input.id" :workDetailId='this.input.id'></detailScopeTable>
                    </v-tab-item> -->
                    <v-tab-item>
                        <detailCostCenterTable class="p-2" v-if="this.input.id" :workDetailId='this.input.id'></detailCostCenterTable>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import detailScopeTable from '@/components/work/detail-scope-table.vue';
import detailCostCenterTable from '@/components/work/detail-cost-center-table.vue';
	export default {
		  components: {
              detailScopeTable, detailCostCenterTable,
            wysiwyg
		  },
          props: ['id'],
		data: () => ({
            success: false,
            loading: false,
            saving: false,

            input:{
                id: '',
                name: '',
                abbreviation: '',
            },
            categoryOptions: [
                {value: 'tool', name: 'tool'},
                {value: 'material', name: 'material'},
                {value: 'routine', name: 'routine'},
            ],
            headingList: [],
            unitList:[],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
		}),
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getWorkDetail().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.category = res.category;
                        this.input.code = res.code;
                        this.input.heading = res.heading;
                        this.input.unit = res.unit;
                    });
                }
                this.getAdditionalData();
            },
			async getWorkDetail() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceDetail.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveWorkDetail().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workDetailList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveWorkDetail() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoiceDetail.save( this.input );
                    } else {
                        res = await this.$api.invoiceDetail.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workDetailList' })
            },
            getAdditionalData() {
			    this.getHeadingList().then((res) => {this.headingList = res;});
            },
			async getHeadingList() {
                try {
                    const res = await this.$api.invoiceHeading.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>