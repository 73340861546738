<template>
    <div>
        <v-data-table 
            v-if="!this.loading"
            :headers="headersRecurranceTime"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedRecurrenceTime"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                            :items="recurrenceList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Recurrance Time" 
                            v-model="row.item.recurrence"
                            hide-details
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                :items="users" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Checker" 
                                v-model="row.item.checker"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                :items="users" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Verifier" 
                                v-model="row.item.verifier"
                                @change="verifierSelect(row.item.index, row.item.checker, row.item.verifier)"
                                ></v-select>
                            </v-col>
                        </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            :items="optionAction" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Action" 
                            v-model="row.item.action"
                            hide-details
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="openTimeMenu[row.item.id]"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="Open"
                                    readonly
                                    :value="row.item.open_time"
                                    v-on="on"

                                ></v-text-field>
                                </template>
                                <v-time-picker
                                locale="en-in"
                                v-model="row.item.open_time"
                                no-title
                                @input="openTimeMenu[row.item.id] = false"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="checkDueTimeMenu[row.item.id]"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="Check Due"
                                    readonly
                                    :value="row.item.check_due_time"
                                    v-on="on"

                                ></v-text-field>
                                </template>
                                <v-time-picker
                                locale="en-in"
                                v-model="row.item.check_due_time"
                                no-title
                                @input="checkDueTimeMenu[row.item.id] = false"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="verifyDueTimeMenu[row.item.id]"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="Verify Due"
                                    readonly
                                    :value="row.item.verify_due_time"
                                    v-on="on"

                                ></v-text-field>
                                </template>
                                <v-time-picker
                                locale="en-in"
                                v-model="row.item.verify_due_time"
                                no-title
                                @input="verifyDueTimeMenu[row.item.id] = false"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <v-row no-gutters>
                        <v-tooltip bottom v-if="row.item.id && row.item.recurrence && row.item.open_time && row.item.check_due_time && row.item.verify_due_time" class="mr-4" >
                            <template v-slot:activator="{ on }">
                                <v-icon class="mr-2" color="green darken-2" v-on="on">mdi-clipboard-check-outline</v-icon> 
                            </template>
                            <span>
                                This recurrence plan is valid.
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom class="mr-4" >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn v-if="row.item.id && !row.item.checklist_generated && row.item.checklist_set.length == 0 && row.item.action !== 'GENERATE'" small fab @click="save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, 'GENERATE', row.item.recurrence)" ><v-icon>mdi-reload</v-icon></v-btn>
                                </div>
                            </template>
                            <span>
                                Generate Checklist
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom class="mr-4" >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn class="mr-2" v-if="row.item.id && row.item.checklist_generated && row.item.checklist_set.length > 0" small fab @click="save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, 'GENERATE', row.item.recurrence)" ><v-icon>mdi-reload-alert</v-icon></v-btn>
                                </div>
                            </template>
                            <span>
                                Checklist for this recurrence are already created!
                            </span>
                        </v-tooltip>
                        <v-col cols="auto">
                            <v-btn-toggle>
                                <v-btn small fab  @click="save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, row.item.action, row.item.recurrence)"><v-icon>mdi-content-save</v-icon></v-btn>
                                <v-btn small fab @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newRecurranceTime()">Add Recurrence Time</v-btn></div>
        <v-dialog
        persistent
        v-model="alertCheckerVerifier"
        max-width="290"
        >
        <v-card>
            <v-card-title class="headline">Error!</v-card-title>
            <v-card-text>
            Verifier should not be the same with Checker!
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary darken-1"
                text
                @click="removeVerifier()"
            >
                Okay
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    
</template>
<script>
	export default {
        props: {
            checkplanId: {
                type: Number
            },
        },
		data() {
            return {
                headersRecurranceTime: [
                { text: 'Recurrence', align: 'left', sortable: true, value: 'recurrence', },
                { text: ''}
                ],
                id: [],
                loading: true,
                recurrenceList: [],
                recurrenceTimeList: [],
                checkplan: 0,
                users: [],
                openTimeMenu: {},
                checkDueTimeMenu: {},
                verifyDueTimeMenu: {},
                optionAction: [
                    {
                        value: "GENERATE",
                        name: "Generate"
                    },
                    {
                        value: "REMOVE",
                        name: "Remove"
                    },
                ],
                alertCheckerVerifier: false,
                faultyVerifier: null,
            }
        },
        computed: {
            indexedRecurrenceTime () {
                return this.recurrenceTimeList.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getRecurranceTimebyCheckplan().then(res => this.recurrenceTimeList = res);
            },
			async getRecurranceTimebyCheckplan() {
                try {
                    this.loading = true
                    const res = await this.$api.recurrenceTime.search({ checkplan: this.checkplanId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getRecurrences().then(res => this.recurrenceList = res);
			    this.getUsers().then((res) => {
                    this.users = res
                    this.users.unshift({id: null, username: 'None'})
                });
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            newRecurranceTime() {
                this.recurrenceTimeList.push({id: '', open_time: '', check_due_time: '', verify_due_time: '', checker: '', verifier: '', action: '', recurrence:''});
            },
            save(id, open_time, check_due_time, verify_due_time, checker, verifier, action, recurrence) {
                this.saveRecurranceTime(id, open_time, check_due_time, verify_due_time, checker, verifier, action, recurrence).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                            this.updateCheckplan();
                        })
                    }
                });
			},
			async saveRecurranceTime(id, open_time, check_due_time, verify_due_time, checker, verifier, action, recurrence) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.recurrenceTime.save( {id: id, open_time: open_time, check_due_time: check_due_time, checker: checker, verifier: verifier, action: action, recurrence: recurrence, checkplan: this.checkplanId });
                    } else {
                        res = await this.$api.recurrenceTime.new({open_time: open_time, check_due_time: check_due_time, checker: checker, verifier: verifier, action: action, recurrence: recurrence, checkplan: this.checkplanId } );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.recurrenceTime.delete(id).then((res) => {
                                this.list();
                                this.updateCheckplan();
                            });
                        }
                    })
                } else {
                    this.$delete(this.recurrenceTimeList, index)
                }
            },
            updateCheckplan(event){
                this.$emit('updateCheckplan')
            },
            verifierSelect(index, checker, verifier) {
                if (checker && verifier) {
                    if (checker == verifier) {
                        this.alertCheckerVerifier = true;
                        this.faultyVerifier = index;
                    }
                }
            },
            removeVerifier(index) {
                this.recurrenceTimeList[this.faultyVerifier].verifier = null;
                this.indexedRecurrenceTime[this.faultyVerifier].verifier = null;
                this.alertCheckerVerifier = false;
                this.faultyVerifier = null;
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
            this.loading = false;
        }
    }
</script>