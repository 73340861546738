<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            v-if="this.measurementList"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="this.measurementList"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab  icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import unitTable from '@/components/table/unit-table.vue';
	export default {
		  components: {
              unitTable
		  },
		data: () => ({
			success: false,
            loading: true,
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width:100},
            { text: 'Name', value: 'name' },
            { text: ''}
            ],
			measurementList: [],
			unitList: [],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
        }),
		methods: {
            // Main Function
			list() {
			    this.getMeasurement(1).then((res) => {
                    this.measurementList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getMeasurement(page) {
                try {
                    this.loading = true
                    const res = await this.$api.unitMeasurement.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'unitMeasurementForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.unitMeasurement.delete(id).then(res => this.list() );
                    }
                })
            },
			getUnitList() {
			    this.getUnits().then(res => this.unitList = res);
            },
			async getUnits() {
                try {
                    const res = await this.$api.unitUnit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUnitName(unit_id) {
                if ( this.unitList.length !== 0 && unit_id ) {
                    return this.unitList.find(unit => unit.id === unit_id).name;
                }
            }, 
		},
		mounted () {
            this.list();
            this.getUnitList();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getMeasurement(this.pagination.current).then((res) => {
                        this.measurementList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>