<template>
    <v-card class="moduleNavCard">
        <v-container fluid>
            <div class="row no-gutters justify-space-evenly">
                <v-btn v-for="(menu, index) in roleMenu" v-bind:key="index" text height="auto" class="col col-4 text-center module-link" :to="menu.link">
                    <v-icon>{{menu.icon}}</v-icon>
                    {{menu.name}}
                </v-btn>
            </div>
        </v-container>
    </v-card>
</template>
<script>
	export default {
		  components: {
		  },
        props:{
             globalPref: Array,
        },
		data: () => ({
            indexName: ['', 'one', 'two', 'three', 'four', 'five'],
            profile: [],
            originalProfile: [],
            userList: [],
            adminMenu:[
                {
                    name: 'Dashboard',
                    link: '/',
                    icon: 'mdi-home'
                },
                {
                    name: 'User',
                    link: '/users',
                    icon: 'mdi-account'
                },
                {
                    name: 'Location',
                    link: '/locations',
                    icon: 'mdi-map-marker'
                },
                {
                    name: 'Asset',
                    link: '/assets',
                    icon: 'mdi-application'
                },
                {
                    name: 'Check',
                    link: '/checklists',
                    icon: 'mdi-order-bool-ascending-variant'
                },
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Work',
                    link: '/work/orders',
                    icon: 'mdi-briefcase'
                },
                {
                    name: 'Invoice',
                    link: '/make-invoices',
                    icon: 'mdi-currency-usd-off'
                },
                {
                    name: 'Recurrence',
                    link: '/recurrences',
                    icon: 'mdi-update'
                },
                {
                    name: 'Unit',
                    link: '/units',
                    icon: 'mdi-tape-measure'
                },
                // {
                //     name: 'Statistic',
                //     link: '/order-statistic',
                //     icon: 'mdi-chart-areaspline-variant'
                // },
                {
                    name: 'Report',
                    link: '/report',
                    icon: 'mdi-chart-bell-curve-cumulative'
                },
            ],
            customerMenu: [
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
            ],
            vendorMenu: [
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Record',
                    link: '/work/records',
                    icon: 'mdi-account-voice'
                },
            ],
            staffMenu: [
                {
                    name: 'Dashboard',
                    link: '/',
                    icon: 'mdi-home'
                },
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Record',
                    link: '/work/records',
                    icon: 'mdi-account-voice'
                },
                {
                    name: 'Check',
                    link: '/checklists',
                    icon: 'mdi-order-bool-ascending-variant'
                },
                {
                    name: 'Report',
                    link: '/report',
                    icon: 'mdi-chart-bell-curve-cumulative'
                },
            ],
            managerMenu: [
                {
                    name: 'Dashboard',
                    link: '/',
                    icon: 'mdi-home'
                },
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Work',
                    link: '/work/orders',
                    icon: 'mdi-briefcase'
                },
                {
                    name: 'Invoice',
                    link: '/make-invoices',
                    icon: 'mdi-currency-usd-off'
                },
                {
                    name: 'Check',
                    link: '/checklists',
                    icon: 'mdi-order-bool-ascending-variant'
                },
                {
                    name: 'Recurrence',
                    link: '/recurrences',
                    icon: 'mdi-update'
                },
                {
                    name: 'Report',
                    link: '/report',
                    icon: 'mdi-chart-bell-curve-cumulative'
                },
            ]

        }),
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					// return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
			profileId:{
                get: function () {
                    return this.$store.getters.profileId;
                },
                // setter
                set: function (newValue) {
                    this.assignNewProfile(newValue)
                }
            },
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            profileName() {
				if (this.userList.length > 0) {
                    if (this.$store.getters.profileId) {
                        if (this.$store.getters.profileId !== '0') {
                            return this.userList.find(user => user.id === this.$store.getters.profileId).username;
                        } else {
                            return "Super Admin"
                        }
                    }
				}
            },
            roleMenu: function() {
                if (this.profileRole == 'SYSADMIN') {
                    return this.adminMenu;
                }
                if (this.profileRole == 'CUSTOMER') {
                    return this.customerMenu;
                }
                if (this.profileRole == 'VENDOR') {
                    return this.vendorMenu;
                }
                if (this.profileRole == 'STAFF') {
                    return this.staffMenu;
                }
                if (this.profileRole == 'MANAGER') {
                    return this.managerMenu;
                }
            }
        },
        methods: {
            getLevelName(levelLabel) {
                if ( this.globalPref.length > 0 && levelLabel !== undefined) {
                    return this.globalPref.find(pref => pref.name === levelLabel).value;
                }
            },
            getProfileId() {
                this.getProfile().then((res) => {
                    this.profile = res;
                    this.originalProfile = res;
                    });
            },
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserList() {
			    this.getUsers().then((res) => {
                    this.userList = res;
                    this.userList.unshift({ username: 'Super User', role: 'SYSADMIN', id: '0'})
                });
            },
			async getUsers() {
                try {
					const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUser(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id);
                }
            }, 
            assignNewProfile(user_id){
                console.log('asd');
                this.profile = this.getUser(user_id);
                this.$store.dispatch('setProfileId', this.profile.id).then((res) => {
                })
                this.$store.dispatch('setProfileRole', this.profile.role).then((res) => {
                })
            }
        },
		mounted: function () {
            this.getProfileId();
            this.getUserList();
        },
    }
</script>