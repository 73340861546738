<template>
    <div>
        <v-data-table 
            v-if="!this.loading"
            :headers="headersComponentCheck"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedComponents"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr style="vertical-align: top">
                <td>
                    <v-row>
                        <v-col cols="10">
                            <v-select
                            :items="componentList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Component" 
                            v-model="row.item.component" 
                            ></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Sort Order" 
                                v-model="row.item.sort_order" 
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <!-- TODO CHANGE CONDITION UPDATE checker_ts -->
                            <v-select
                            :items="condition" 
                            label="Checker Condition" 
                            v-model="row.item.checker_condition" 
                            name="item"
                            item-value="value"
                            item-text="text"
                            readonly
                            @change="checked_ts(row.item.id, row.item.checker_condition)"
                            ></v-select>
                            <v-text-field
                                label="Checker Note"
                                v-model="row.item.checker_note" 
                                required
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <!-- TODO CHANGE CONDITION UPDATE verifier_ts -->
                            <v-select
                            :items="condition" 
                            label="Verifier Condition" 
                            v-model="row.item.verifier_condition"  
                            name="item"
                            item-value="value"
                            item-text="text"
                            readonly
                            @change="verified_ts(row.item.id, row.item.verifier_condition)"
                            ></v-select>
                            <v-text-field
                                label="Verifier Note"
                                v-model="row.item.verifier_note" 
                                required
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn-toggle>
                                <v-btn small fab @click="save(row.item.id, row.item.component, row.item.checker_condition, row.item.verifier_condition, row.item.checker_note, row.item.verifier_note, row.item.checker_ts, row.item.verifier_ts, row.item.asset_check)">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn small fab @click="remove(row.item.index, row.item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div>
            <v-btn class="mr-4" @click="newComponentCheck()">Add Component Check</v-btn>
            <!-- <v-btn v-if="componentList.length > 0" color="secondary" class="mr-4" @click="multipleAddDialog = true">Add Multiple Component Check</v-btn> -->
        </div>
        <v-dialog v-model="multipleAddDialog" scrollable max-width="600px">
        <v-card>
            <v-card-title>Choose Components</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px;">
                <v-checkbox hide-details v-for="(component, index) in componentListFiltered" v-model="multipleAddComp" :label="component.name" :value="component.id" v-bind:key="index"></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-btn color="blue darken-1" text @click="multipleAddDialog = false">Close</v-btn>
            <v-btn color="blue darken-1" text @click="newMultipleComponentChecks()">Add</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <snackbar item="Component" v-if="componentSnackbar"></snackbar>
    </div>
    
</template>
<script>
import snackbar from '@/components/alerts/snackbar'
	export default {
        props: {
            assetCheckId: {
                type: Number
            },
            assetId: {
                type: Number
            }
        },
        components: {
          snackbar,
        },
		data() {
            return {
                headersComponentCheck: [
                { text: 'Components', align: 'left', sortable: true, value: 'components', },
                { text: ''}
                ],
                id: [],
                loading: true,
                componentChecksbyAssetCheck: [],
                users:[],
                componentList: [],
                componentIdsFromChecks: [],
                condition: [
                    { 
                        text: 'Unknown',
                        value: 'UNKNOWN'
                    },
                    { 
                        text: 'Good',
                        value: 'GOOD'
                    },
                    { 
                        text: 'Bad',
                        value: 'BAD'
                    },
                    { 
                        text: 'Other',
                        value: 'OTHER'
                    },
                ],
                expandable: {},
                assetCheck: 0,
                multipleAddDialog: false,
                multipleAddComp: [],
                componentSnackbar: false,
            }
        },
        computed: {
            indexedComponents () {
                return this.componentChecksbyAssetCheck.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
            componentListFiltered: function() {
                if (this.componentList) {
                    return ( this.componentList.filter(component => !this.componentIdsFromChecks.includes(component.id)) );
                }
            },
        },
        methods: {
            list() {
                if (this.assetCheckId) {
                    this.getComponentChecksbyAssetCheck().then((res) => {
                        this.componentChecksbyAssetCheck = res;
                        if (this.componentChecksbyAssetCheck) {
                            this.componentChecksbyAssetCheck.forEach((componentCheck) => {
                                this.componentIdsFromChecks.push(componentCheck.component);
                            });
                        }
                    });
                }
            },
			async getComponentChecksbyAssetCheck() {
                try {
                    this.loading = true
                    const res = await this.$api.compCheck.search({ asset_check: this.assetCheckId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getCompsbyAsset().then(res => this.componentList = res);
            },
			async getCompsbyAsset() {
                try {
                    console.log('wtf');
                    this.componentSnackbar = true;
                    const res = await this.$api.component.search({ asset: this.assetId });
                    console.log(res);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.componentSnackbar = false;
                }
            },
            newComponentCheck() {
                this.componentChecksbyAssetCheck.push({id: '', checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
            },
            newMultipleComponentChecks() {
                this.multipleAddComp.forEach((newComps) => {
                    this.componentChecksbyAssetCheck.push({id: '', component: newComps, checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
                    this.componentIdsFromChecks.push(newComps);
                });
                this.multipleAddComp = [];
                this.multipleAddDialog = false;
            },
            save(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                this.saveAssetCheck(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveAssetCheck(id, component,  checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.compCheck.save( {id: id, component: component, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    } else {
                        res = await this.$api.compCheck.new( {component: component, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id,) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.compCheck.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.componentChecksbyAssetCheck, index)
                    this.componentIdsFromChecks = this.componentIdsFromChecks.filter(item => item !== component) ;
                }
            },
            
            checked_ts(id, checker_condition) {
                var d = new Date();
				this.$api.compCheck.save({id:id, checker_ts: d, checker_condition: checker_condition}).then((res) => {
                    if (res.status == 201 ) {
                            console.log('success!');
							this.$swal({
								icon: 'success',
								title: 'Saved',
                                reverseButtons: true,
                                confirmButtonText: 'OK',
							}).then((result) => {
							})
						}
					})
            },
            verified_ts(id, verifier_condition) {
                var d = new Date();
				this.$api.compCheck.save({ id: id, verifier_ts: d, checker_condition: verifier_condition }).then((res) => {
                    if (res.status == 201 ) {
                            console.log('success!');
							this.$swal({
								icon: 'success',
								title: 'Saved',
                                reverseButtons: true,
                                confirmButtonText: 'OK',
							}).then((result) => {
							})
						}
					})
            },
        },
        mounted(){
            this.getAdditionalData();
            this.list();
        }
    }
</script>