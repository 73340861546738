<template>
  <div>
      <Header v-if="authed" :drawerStatus="drawer" @updateDrawer="toggleDrawer($event)" :globalPref="globalPref"></Header>
        <v-navigation-drawer v-if="authed" 
        :clipped="true"
        :mini-variant="!isMobileCheck ? mini : null"
        v-model="drawerSlide"
        app
        >
            <sideSubMenu :globalPref="globalPref"></sideSubMenu>
        </v-navigation-drawer>
        <v-main v-if="authed" >
            <v-container fluid class="fill-height py-0">
                <v-row>
                    <slot />
                </v-row>
            </v-container>
        </v-main>
  </div>
</template>
<script>
import login from '@/pages/login';
import Header from '@/components/common/header.vue';
import sideSubMenu from '@/components/common/sideSubMenu.vue';
export default {
    components: {
        Header,
        sideSubMenu,
        login,
    },
    props:{
        globalPref: Array,
    },
    data: () => ({
        drawer: false,
        windowWidth: 0,
        mini: false,
        drawerSlide: false,
    }),
    computed: {
        companyName: function() {
            if (this.authed && this.globalPref.length > 0) {
                return (this.globalPref.find(pref => pref.name === 'organization_name').value);
            }
        },
        authed: function() {
            return this.$store.getters.isLoggedIn
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        checkLogin() {
            if (!this.authed) {
			    this.$router.push('/login');
            }
        },
        toggleDrawer(drawer) {
            this.drawer = drawer;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

    },
    watch: {
        drawer: function(val) {
            if (window.innerWidth >= 1264) {
                this.mini = val;
                this.drawerSlide = true;
                console.log(val)
            } else if (window.innerWidth >= 768 && window.innerWidth <= 1263 ) {
                this.drawerSlide = val;
                this.mini = false;
            } else {
                this.drawerSlide = val;
                this.mini = false;
            }
        },
        drawerSlide: function(val) {
            this.drawer = val;
        }
    },
    mounted: function () {
        this.checkLogin();
        if (window.innerWidth >= 1264) {
            this.drawer = true;
            this.drawerSlide = true;
            this.mini = false;
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1263 ) {
            console.log('?');
            this.drawer = false;
            this.drawerSlide = false;
            this.mini = false;
        } else {
            this.drawer = false;
            this.mini = false;
        }
    }
};
</script>
