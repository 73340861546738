<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Program Info</v-tab>
                    <v-tab v-if="this.input.id">Child: Program Task</v-tab>
                    <v-tab v-if="this.input.id">Child: Program Plan</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Name" 
                                    v-model="input.title"
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="12">
                                <div class="caption">Status</div>
                                <v-switch inset v-model="inputStatus" label="Status"></v-switch>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field
                                    label="Cost" 
                                    v-model="input.cost"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <div class="v-text-field" style="position:relative">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot" style="width:100%">
                                            <label class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Time</label>
                                            <vue-timepicker v-model="input.time" format="HH:mm:ss" width="100%"></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="activity" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Activity" 
                                v-model="input.activity"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="category" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Category" 
                                v-model="input.category"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                :items="urgency" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Urgency" 
                                v-model="input.urgency"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workPlanTaskTable v-on:updateworkPlan="refreshworkPlanTask" :workPlanId="input.id"></workPlanTaskTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <workPlanPlanTable v-on:updateworkPlan="refreshworkPlanTask" :workPlanId="input.id"></workPlanPlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item> -->
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
// import workPlanTaskTable from '@/components/work/work-program-task-table.vue';
// import workPlanPlanTable from '@/components/work/work-program-plan-table.vue';
import VueTimepicker from 'vue2-timepicker/src'
	export default {
		  components: {
            //   workPlanTaskTable,
            //   workPlanPlanTable,
              VueTimepicker,
            wysiwyg
          },
          props: {
                id: Number,
          },
		data: () => ({
			success: false,
			loading: true,
            input:{
                id: '',
                name: '',
                description: '',
                cost: '',
                time: '',
                crew: null,
                activity: '',
                category: '',
                urgency: '',
            },
            activity: [
                { value: "clean",name: "clean" },
                { value: "inspect", name: "inspect" },
                { value: "modify", name: "modify" },
                { value: "repair", name: "repair" },
                { value: "replace", name: "replace" },
                { value: "service", name: "service" }
            ],
            category:[
                { value: "corrective", name: "corrective (self-initiate)" },
                { value: "preventive", name: "preventive (scheduled)" },
                { value: "reactive", name: "reactive (respond to complaint)" }
            ],
            urgency: [
                { value: 1, name: "1 : one" },
                { value: 2, name: "2 : two" },
                { value: 3, name: "3 : three" },
                { value: 4, name: "4 : four" }
            ],
            inputAssetPlan: [],
            inputRecurrence: [],
            recurranceTimeList:[],
            recurrenceLists: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
        computed: {
            userVerifier: function() {
                if (this.users.length > 0) {
                    if (this.input.checker == null) {
                        return (this.users);
                    } else {
                        return (this.users.filter(users => users.id !== this.input.checker));
                    }
                }
            },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getworkPlan().then((res) => {
                        this.input.id = res.id;
                        this.input.title = res.title;
                        this.input.description = res.description;
                        this.input.cost = res.cost;
                        this.input.time = res.time;
                        this.input.crew = res.crew;
                        this.input.activity = res.activity;
                        this.input.category = res.category;
                        this.input.urgency = res.urgency;

                        this.getAdditionalData();
                    });
                }
            },
			async getworkPlan() {
                try {
                    this.loading = true
                    const res = await this.$api.workPlan.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveworkPlan().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workPlanList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveworkPlan() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.workPlan.save( this.input );
                    } else {
                        res = await this.$api.workPlan.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workPlanList' })
            },
            getAdditionalData() {
			    // this.getRecurranceTimebyworkPlan().then(res => this.recurranceTimeList = res);
			    // this.getRecurrences().then(res => this.recurrenceLists = res);
            },
			async getRecurranceTimebyworkPlan() {
                try {
                    const res = await this.$api.recurrenceTime.search({ workPlan: this.input.id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getRecurName(id) {
                if ( this.recurrenceLists.length !== 0 && id ) {
                    return this.recurrenceLists.find(recurrance => recurrance.id === id).name;
                }
            },
            refreshworkPlanTask() {
                this.form();
                this.getAdditionalData();
            }
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.valid = true;
		},
	}
</script>