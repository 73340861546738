<template>
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name" 
                        v-model="input.name"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
	export default {
        props: {
            teamList: {
               type: Array
            },
            userList: {
               type: Array
            },
            teamId: {
                type: Number
            },
            userId: {
                type: Number
            }
        },
		data() {
            return {
                input: {
                    id: '',
                    name: '',
                },
            }
        },
        computed: {
            indexedTeam () {
                if (this.teamByUser) {
                    return this.teamByUser.map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }
            },
        },
        methods: {
            // Normal
            form() {
                if (this.teamId) {
                    this.getTeam().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                    });
                }
            },
			async getTeam() {
                try {
                    this.loading = true
                    const res = await this.$api.team.get(this.teamId);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveTeam().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'teamList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveTeam() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.team.save( this.input );
                    } else {
                        res = await this.$api.team.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
        },
        mounted(){
            this.form();
        }
    }
</script>