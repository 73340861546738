<template>
    <div>
        <v-data-table
        v-if="this.workOrder"
        :loading="this.loading" loading-text="Loading... Please wait"
        :headers="headers"
        :items="this.workOrder"
        hide-default-footer
        :items-per-page="perPage"
        :options.sync="options"
        class="elevation-1"
        >
            <template v-slot:item="row">
            <tr>
                <td style="width:150px" class="text-xs-right">
                    <v-btn-toggle>
                        <v-btn small fab @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab @click="remove(row.item.id)" v-if="row.item.status !== 'CLAIMED' && row.item.status !== 'VERIFIED' && row.item.status !== 'COMPILED' && row.item.status !== 'VETTED' && row.item.status !== 'AUDITED'">
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">
                    <!-- <v-badge
                    class="animate-fading"
                    v-if="!row.item.is_ontime"
                    color="red"
                    offset-x="-5"
                    offset-y="-1"
                    left
                    dot
                    >
                    </v-badge> -->
                    {{row.item.ext_ref_id}} 
                    <!-- {{row.item.name}}  -->
                </td>
                <td class="text-left"><span v-if="row.item.supervisor">{{ getUserName(row.item.worker) }}</span></td>
                <td class="text-left"><span v-if="row.item.supervisor">{{ getUserName(row.item.supervisor) }}</span></td>
                <td class="text-left"><span v-if="row.item.net">{{ row.item.net }}</span></td>
                <td class="text-left"><span v-if="row.item.open_dt">{{ row.item.open_dt | moment_dtstamp }}</span></td>
                <td class="text-left"><span v-if="row.item.due_dt">{{ row.item.due_dt | moment_dtstamp }}</span></td>
                <td class="text-left"><span v-if="row.item.costcenter">{{ getCostCenterName(row.item.costcenter) }}</span></td>
                <td class="text-left">{{getScopeName(row.item.scope)}}</td>
                <!-- <td class="text-left">{{row.item.activity}}</td> -->
            </tr>
            </template>
        </v-data-table>
        <div class="text-center">
            <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
            <div class="subtitle">Total: {{pagination.count}}</div>
        </div>
    </div>
</template>
<script>
import tableFilter from '@/components/filter/filterWorkOrder.vue'
	export default {
		data: () => ({
            headers: [
            { text: '', sortable: false,},
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            // { text: 'Name', value: 'name' },
            { text: 'Ext Ref ID', value:'ext_ref_id'},
            { text: 'Worker', value: 'worker',  width: 140  },
            { text: 'Supervisor', value: 'supervisor',  width: 140 },
            { text: 'Net', value: 'net',  width: 140  },
            { text: 'Open Datetime', value: 'open_dt',  width: 140  },
            { text: 'Due Datetime', value: 'open_dt' ,  width: 140 },
            { text: 'Cost Center', value: 'costcenter' ,  width: 140 },
            { text: 'Scope', value: 'scope' },
            // { text: 'Activity', value: 'activity' },
            ],
            pagination:{
                next: '',
                count:0,
                pages: 1,
                current:1,
            },
            options:{},
            perPage: 20,
            workOrder: [],
            loading: false,
		}),
        components: {
            tableFilter,
        },
        props: {
            filterData: Object, 
            selectedStatus: String,
            userList: Array, 
            costCenterList: Array, 
            scopeList: Array,
        },
        computed: {
            filterDataWithPagination: function() {
                var newObj = this.filterData;
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                return newObj;
            },
            datatableOrdering: function() {
                if (this.options.sortBy.length > 0) {
                    if ( this.options.sortDesc[0] == false) {
                        return this.options.sortBy[0];
                    } else {
                        return '-' + this.options.sortBy[0];
                    }   
                } else {
                    return '-due_dt';
                }
            }
        },
		methods: {
            // Main Function
            list() {
			    this.getWorkOrder(this.pagination.current).then((res) => {
                    this.workOrder = res.results;
                    this.pagination.next = res.next;
                    this.pagination.count = res.count;
                    this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getWorkOrder() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrder.search( this.filterDataWithPagination );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                // this.$router.push({ name: 'workOrderForm', params: { id } })
                let routeData = this.$router.resolve({name: 'workOrderForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.workOrder.delete(id).then(res => this.list() );
                    }
                })
            },
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getCostCenterName(costcenter_id) {
                if ( this.costCenterList.length !== 0 && costcenter_id ) {
                    return this.costCenterList.find(costCenter => costCenter.id === costcenter_id).name;
                }
            }, 
            urgencyColor(urgency) {
                if (urgency == 1) {
                    return 'red dark-3'
                }
                if (urgency == 2) {
                    return 'red'
                }
                if (urgency == 3) {
                    return 'amber'
                }
                if (urgency == 4) {
                    return 'blue'
                }
            },
            getScopeName(id) {
                if ( this.scopeList.length > 0 && id ) {
                    return this.scopeList.find(scope => scope.id === id).name;
                }
            },
		},
		mounted () {
            this.list();
		},
        watch: {
            filterData: function(newVal, oldVal) { // watch it
                this.list();
            },
            'pagination.current': {
                handler(val){
                    this.list();
                },
                deep: true
            },
            options: {
                handler(val){
                    this.list();
                },
                deep: true
            },
        },
	}
</script>