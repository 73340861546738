<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-select
            :class="profileRole == 'CUSTOMER' ? 'd-none' : ''"
            :items="userListSelect" 
            item-value="id"
            name="item"
            item-text="username"
            label="Complainer" 
            v-model="filterFields.complainer"
            ></v-select>
            <v-select
            :items="statusSelect" 
            item-value="value"
            name="item"
            item-text="text"
            label="Status" 
            v-model="filterFields.status"
            ></v-select>
            <v-text-field
                v-model="filterFields.ext_ref_id"
                label="Ext Ref ID"
                prepend-icon="mdi-barcode"
            ></v-text-field>
            <v-autocomplete
				v-model="filterFields.location"
				:items="locationListSelect"
				label="Location"
				item-text="name"
				item-value="id"
				>
				<template v-slot:selection="data">
					{{ data.item.name }}
				</template>
				<template v-slot:item="data">
					<template>
						<v-list-item-content>
						<v-list-item-title><div class="text-left">{{data.item.name}}</div></v-list-item-title>
						<v-list-item-subtitle><div class="text-left">{{getLocParentName(data.item.parent)}}</div></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</template>
			</v-autocomplete>
            <v-menu
                ref="submit_ts_date_afterMenu"
                v-model="submit_ts_date_afterMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filterFields.submit_ts_date_after"
                    label="From Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="filterFields.submit_ts_date_after"
                no-title
                scrollable
                show-current
                >
                </v-date-picker>
            </v-menu>
            <v-menu
                ref="submit_ts_date_beforeMenu"
                v-model="submit_ts_date_beforeMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filterFields.submit_ts_date_before"
                    label="To Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="filterFields.submit_ts_date_before"
                no-title
                scrollable
                show-current
                >
                </v-date-picker>
            </v-menu>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
			locationListSelect: [],
            userListSelect:[],
            submit_ts_date_beforeMenu: false,
            date: null,
            submit_ts_date_afterMenu: false,
            statusSelect:[
                {
                    "value": "",
                    "text": "All"
                },
                // {
                //     "value": "DRAFT",
                //     "text": "draft"
                // },
                {
                    "value": "SUBMITTED",
                    "text": "submitted"
                },
                {
                    "value": "REJECTED",
                    "text": "rejected"
                },
                {
                    "value": "PROCESSED",
                    "text": "processed"
                },
                {
                    "value": "ACCEPTED",
                    "text": "accepted"
                },
                {
                    "value": "RESOLVED",
                    "text": "resolved"
                },
            ],
        }),
        props: {
            tableType: {
                type: String,
            },
            filterFields: {
                type: Object,
            },
            userList: {
                type: Array,
                required: false
            },
            profileRole: {
                type: String,
            },
            profileId: {},
			locationList: {
				type: Array,
				required: false
			},
        },
        computed: {
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            },
            getLocParentName(id) {
                if ( this.locationList.length !== 0 && id ) {
                    return this.locationList.find(loc => loc.id === id).name;
                } else {
                    return;
                }
            },
        },
        mounted() {
            this.userListSelect = this.userList
            this.userListSelect.unshift({
                id:'',
                username: 'All User',
            });
            if (this.profileRole == 'CUSTOMER') {
                this.filterFields.complainer = this.profileId;
            }
			this.locationListSelect = this.locationList
			this.locationListSelect.unshift({
				id:'',
				name: 'All Location',
			});
        }
    }
</script>