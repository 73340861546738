<template>
    <div>
        <div>
            <v-data-table 
                :headers="headersBaseParameters"
                :sort-by="['id']"
                :sort-desc="[true]"
                :items="indexedBaseParameter"
                :items-per-page="20"
                hide-default-footer
                :footer-props="{
                    itemsPerPageOptions: [20,40,60],
                }" 
                class="full-width">
                <template v-slot:item="row">
                    <tr>
                    <td class="text-left">
                        <v-text-field
                            label="Name"
                            v-model="row.item.name" 
                            required
                        ></v-text-field>
                    </td>
                    <td class="text-left">
                        <v-select
                        item-value="id"
                        name="name"
                        item-text="name"
                        v-model="row.item.unit"
                        :items="unitList"
                        label="Unit"
                        ></v-select>
                    </td>
                    <td class="text-left">
                        <v-select
                        item-value="value"
                        name="text"
                        item-text="text"
                        v-model="row.item.status_mode"
                        :items="conditions"
                        label="Status Mode"
                        ></v-select>
                    </td>
                    <td class="text-left">
                        <div class="d-flex align-center">
                        <v-text-field
                            label="Lower Limit"
                            v-model="row.item.lower_limit" 
                            required
                        ></v-text-field>
                        <span class="ml-2 mr-2">-</span>
                        <v-text-field
                            label="Upper Limit"
                            v-model="row.item.upper_limit" 
                            required
                        ></v-text-field>
                        </div>
                    </td>
                    <td>
                        <v-switch v-model="row.item.is_active" label="Status" readonly></v-switch>
                    </td>
                    <td>

                        <v-btn-toggle>
                            <v-btn small fab @click="save(row.item.id, row.item.name, row.item.unit, row.item.status_mode, row.item.lower_limit, row.item.upper_limit, row.item.is_active)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-tooltip bottom v-if="row.item.parameter_set" >
                            <template v-slot:activator="{ on }">
                                <v-icon color="grey" dark v-on="on">mdi-delete</v-icon>
                            </template>
                            <span>These assets have to be removed first: 
                                <ul class="text-left">
                                    <li v-for="(asset, index) in row.item.parameter_set" v-bind:key="index">
                                        {{ getAssetName(asset) }} ( ID: {{ asset }} )
                                    </li>    
                                </ul> 
                            </span>
                            </v-tooltip>
                            <v-btn small fab v-if="!row.item.parameter_set" @click="remove(row.item.index, row.item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        
                        
                    </td>
                    </tr>
                </template>
            </v-data-table>
            <div><v-btn class="mr-4" @click="newBaseParameter()">Add Base Parameter</v-btn></div>
        </div>
    </div> 
</template>
<script>
	export default {
        props: {
            genericId: {
                type: Number
            },
        },
		data() {
            return {
                headersBaseParameters: [
                { text: 'Name', value: 'name' },
                { text: 'Unit', value: 'unit' },
                { text: 'Status Mode', value:'status_mode'},
                { text: 'Lower - Upper Limit', value:''},
                { text: 'Status', value:'is_active'},
                { text: '', value:''},
                ],
                conditions: [
                    {
                        "value": "MANUAL",
                        "text": "manual"
                    },
                    {
                        "value": "ABOVE_LIMIT",
                        "text": "above lower limit"
                    },
                    {
                        "value": "BELOW_LIMIT",
                        "text": "below upper limit"
                    },
                    {
                        "value": "WITHIN_LIMIT",
                        "text": "within lower and upper limit"
                    }
                ],
                genericParameter: [],
                masterParameterList: [],
                unitList: [],
                assetList: [],
            }
        },
        computed: {
            indexedBaseParameter () {
                return this.genericParameter.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getBaseParameterbyBaseAsset().then(res => this.genericParameter = res);
            },
			async getBaseParameterbyBaseAsset() {
                try {
                    const res = await this.$api.assetGenericParameter.search({ generic: this.genericId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            newBaseParameter() {
                this.genericParameter.push({id: '', generic: '', attribute: '', unit: ''});
            },
            save(id, name, unit, status_mode, lower_limit, upper_limit, is_active) {
                this.saveBaseParam(id, name, unit, status_mode, lower_limit, upper_limit, is_active).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveBaseParam(id, name, unit, status_mode, lower_limit, upper_limit, is_active) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        console.log(this.genericId)
                        res = await this.$api.assetGenericParameter.save( {id: id, name: name, unit: unit, status_mode:status_mode, lower_limit:lower_limit, upper_limit:upper_limit, is_active: is_active, generic: this.genericId });
                    } else {
                        res = await this.$api.assetGenericParameter.new({name: name, unit: unit, status_mode:status_mode, lower_limit:lower_limit, upper_limit:upper_limit, is_active: is_active, generic: this.genericId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.assetGenericParameter.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.genericParameter, index)
                }
            },
            // Additional
            getAdditionalData() {
			    this.getUnits().then(res => this.unitList = res);
			    this.getAssets().then(res => this.assetList = res);
            },
			async getUnits() {
                try {
                    const res = await this.$api.unitUnit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(id) {
                if ( this.assetList.length !== 0 && id ) {
                    return this.assetList.find(asset => asset.id === id).name;
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>