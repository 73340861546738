<template>
    <div>
        <v-expansion-panels
        v-if="!loading"
            accordion
            multiple
            hover
            >
            <v-expansion-panel
                v-for="(assetCheck,i) in indexedAssetCheck"
                :key="i"
            >
                <v-expansion-panel-header>
                    <table>
                        <tr>
                            <td width="40">{{assetCheck.sort_order}}</td>
                            <td width="300">
                                {{(assetCheck.asset_name)}}
                            </td>
                            <td width="100">
                                Checker: {{getUserName(assetCheck.checker)}} 
                                <v-tooltip v-if="!assetCheck.checker_data_complete" bottom class="mr-2" >
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="grey" dark v-on="on">mdi-alert-circle</v-icon>
                                </template>
                                <span>Data Not Complete</span>
                                </v-tooltip>
                                <v-tooltip v-if="assetCheck.checker_data_complete" bottom class="mr-2" >
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="primary" dark v-on="on">mdi-check-circle</v-icon>
                                </template>
                                <span>Data Complete</span>
                                </v-tooltip>
                            </td>
                            <td width="100">
                                Verifier: {{getUserName(assetCheck.verifier)}} 
                                <v-tooltip v-if="!assetCheck.verifier_data_complete" bottom class="mr-2" >
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="grey" dark v-on="on">mdi-alert-circle</v-icon>
                                </template>
                                <span>Data Not Complete</span>
                                </v-tooltip>
                                <v-tooltip v-if="assetCheck.verifier_data_complete" bottom class="mr-2" >
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="primary" dark v-on="on">mdi-check-circle</v-icon>
                                </template>
                                <span>Data Complete</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </table>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-autocomplete ref="internal"
                                label="Asset"  
                                v-model="assetCheck.asset"
                                cache-items
                                hide-no-data
                                :items="assetListFull"
                                name="item"
                                item-value="id"
                                item-text="name">
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                            :items="users" 
                            item-value="id"
                            name="item"
                            item-text="username"
                            label="Checker" 
                            v-model="assetCheck.checker"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                            :items="users" 
                            item-value="id"
                            name="item"
                            item-text="username"
                            label="Verifier" 
                            v-model="assetCheck.verifier"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                label="Sort Order" 
                                v-model="assetCheck.sort_order"
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn  text color="grey" @click="remove(assetCheck.index, assetCheck.id)"><v-icon class="mr-2">mdi-delete</v-icon></v-btn>
                            <v-btn class="mr-4" color="primary" @click="save(assetCheck.id, assetCheck.asset, assetCheck.checker, assetCheck.verifier, assetCheck.sort_order)"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                    <v-tabs >
                        <v-tabs-slider />
                        <v-tab v-if="assetCheck.id">Parameters</v-tab>
                        <v-tab v-if="assetCheck.id">Components</v-tab>
                        <v-tab-item>
                            <paramCheckTable v-if="assetCheck.id" :checkverifyStatus="checkverifyStatus" :assetCheckId="assetCheck.id" :assetId="assetCheck.asset"></paramCheckTable>
                        </v-tab-item>
                        <v-tab-item>
                            <compCheckTable v-if="assetCheck.id" :checkverifyStatus="checkverifyStatus" :assetCheckId="assetCheck.id" :assetId="assetCheck.asset"></compCheckTable>
                        </v-tab-item>
                    </v-tabs>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div>
            <v-btn class="mr-4 mt-2" @click="newAssetCheck()">Add Asset Check</v-btn>
        </div>
    </div>
    
</template>
<script>
import Vue from 'vue'
import compCheckTable from '@/components/table/component-check-table'
import paramCheckTable from '@/components/table/parameter-check-table'
	export default {
        props: {
            checklistId: {
                type: Number
            },
            checkverifyStatus: String
        },
        components: {
            compCheckTable,
            paramCheckTable
        },
		data() {
            return {
                headersAssetCheck: [
                {
                    text: 'Asset',
                    align: 'left',
                    sortable: true,
                    value: 'asset',
                },
                { text: 'Checker', value: 'checker', width: '200px' },
                { text: 'Verifier', value: 'verifier', width: '200px' },
                { text: 'Order', value: 'sort_order', width:'100px' },
                { text: ''}
                ],
                id: [],
                loading: true,
                assetChecksbyChecklist: [],
                users:[],
                assetListFull: [],
            }
        },
        computed: {
            indexedAssetCheck () {
                return this.assetChecksbyChecklist.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.checklistId) {
                    this.getAssetChecksbyChecklist().then((res) => {
                        console.log(res)
                        this.assetChecksbyChecklist = res;
                        res.forEach((item) => { 
                            this.getAssetName(item.asset).then((response) => {
                                Vue.set(item,'asset_name', response);
                            })
                        })
                    });
                }
            },
			async getAssetChecksbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.assetCheck.search({ checklist: this.checklistId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getAssetName(asset_id) {
                try {
                    this.loading = true
                    const res = await this.$api.assetAsset.get(asset_id);
                    return res.data.name;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            }, 
            getAdditionalData() {
			    this.getUsers().then(res => this.users = res);
			    this.getAssets().then(res => this.assetListFull = res);
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.users.length !== 0 && user_id ) {
                    return this.users.find(user => user.id === user_id).username;
                }
            }, 
            newAssetCheck() {
                // this.$router.push({ name: 'assetCheckForm', params: {checklistId: this.checklistId } })
                this.assetChecksbyChecklist.push({id: '', asset: '', checklist: this.checklistId, asset: '', checker: '', sort_order: '' });
            },
            edit (id) {
                this.$router.push({ name: 'assetCheckForm', params: { id: id, checklistId: this.checklistId } })
            },
            save(id, asset, checker, verifier, sort_order) {
                this.saveAssetCheck(id, asset, checker, verifier, sort_order).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveAssetCheck(id, asset, checker, verifier, sort_order) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.assetCheck.save( {id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checklist: this.checklistId });
                    } else {
                        res = await this.$api.assetCheck.new({id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checklist: this.checklistId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.assetCheck.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.assetChecksbyChecklist, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>