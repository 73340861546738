<template>
    <v-container fluid>
        <v-row>
        <v-col cols="12">
            <v-card class="pa-3 mb-3 mx-auto text-right">
            <v-form>
                <v-container fluid>
                    <v-row>
                        <v-col v-for="(pref, index) in globalPref" cols="12" sm="6" md="3" v-bind:key="index">
                            <v-text-field
                                readonly
                                :label="pref.verbose_name" 
                                v-model="pref.value"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card>

        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
			success: false,
			globalPref: [],
			loading: true,
		}),
		methods: {
            // Main Function
            form() {
                this.getGlobalPref().then((res) => {
                    this.globalPref = res;
                });
            },
			async getGlobalPref() {
                try {
                    this.loading = true
                    const res = await this.$api.preference.global();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
		},
		mounted () {
			this.form();
            this.success = true;
		},
	}
</script>