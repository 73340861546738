<template>
    <v-container fluid class="py-0 list-workOrder">
        <v-row>
        <v-col cols="12" md="2" lg="1" class="workOrderStatus pa-0">
            <v-list dense shaped v-if="!isMobileCheck">
                <v-subheader>Status</v-subheader>
                <v-list-item-group
                    v-model="selectedStatus"
                    color="primary"
                >
                    <v-list-item
                    v-for="(status, i) in statusOption"
                    :value="status.value"
                    :key="i"
                    >
                    <v-list-item-content>
                        <v-list-item-title v-text="status.name"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-tabs
            v-if="isMobileCheck"
            dark
            background-color="teal darken-3"
            show-arrows
            v-model="selectedStatusMobile"
            >
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

            <v-tab
                v-for="(status, i) in statusOption"
                :key="i"
                :value="status.value"
            >
                {{ status.name }}
            </v-tab>
            </v-tabs>
        </v-col>
        <v-col cols="12" md="10" lg="11">
            <v-row>
                <!-- <v-col cols="2">
                    <v-text-field
                        label="Go to:"
                        placeholder="ID"
                        append-icon="mdi-send"
                        outlined
                        dense
                        v-model="goToId"
                        @click:append="goTo()"
                        @keydown.enter="goTo()"
                    ></v-text-field>
                </v-col> -->
                <v-col cols="12">
                    <div class="mb-5 mx-auto text-right">
                        <v-btn @click="removeFilter()" v-if="filterStatus" color="secondary" text dark class="mr-2">
                            <v-icon>mdi-window-close</v-icon>
                            Remove filter
                        </v-btn>
                        <v-dialog v-model="filterDialog" max-width="800">
                            <template v-slot:activator="{on}">
                                <v-btn color="secondary" dark v-on="on" class="mr-2">
                                    <v-icon>mdi-filter</v-icon>
                                    Filter
                                </v-btn>
                            </template>
                            <v-card>
                                <tableFilter :filterFields="filterData"  v-on:filteredData="tableFilter" :costCenterList="costCenterList" :userList="userList"></tableFilter>
                            </v-card>
                        </v-dialog>
                        <v-btn @click="edit()" dark color="primary">
                            <v-icon>mdi-plus</v-icon>
                            New
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <workOrderTable :filterData="filterDataN" :userList="userList" :costCenterList="costCenterList" :scopeList="scopeList" />
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterWorkOrder.vue'
import workOrderTable from '@/components/work/order/table.vue'
	export default {
		data: () => ({
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingWorkPrograms: [],
            yearMonthSelected: '',
            programSelected: '',
            otherWorkOrder : [],
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Title', value: 'name' },
            { text: 'Worker', value: 'worker',  width: 140  },
            { text: 'Supervisor', value: 'supervisor',  width: 140 },
            { text: 'Open Datetime', value: 'open_dt',  width: 140  },
            { text: 'Due Datetime', value: 'open_dt' ,  width: 140 },
            { text: 'Cost Center', value: 'Cost Center' ,  width: 140 },
            { text: 'Scope', value: 'scope' },
            { text:'Ext Ref ID', value:'ext_ref_id'},
            // { text: 'Activity', value: 'activity' },
            { text: '', sortable: false,}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 200,
            success: false,
            statusOption: [
                { name: 'Draft', value: 'DRAFT' },
                { name: 'Open', value: 'OPEN' },
                { name: 'WIP', value: 'WIP' },
                { name: 'Recorded', value: 'RECORDED' },
                { name: 'Redo', value: 'REDO' },
                { name: 'Done', value: 'DONE' },
                { name: 'Claimed', value: 'CLAIMED' },
                { name: 'Verified', value: 'VERIFIED' },
                { name: 'Compiled', value: 'COMPILED' },
                { name: 'Vetted', value: 'VETTED' },
                { name: 'Audited', value: 'AUDITED' },
            ],
            selectedStatus: 'OPEN',
            selectedStatusMobile: 1,
            userList: [],
            costCenterList: [],
            scopeList: [],
            menu: false,
            filterDialog: false,
            filterData: {},
            goToId: null,
		}),
        components: {
            tableFilter,
            workOrderTable,
        },
        computed: {
            filterDataN: function() {
                var newObj = JSON.parse(JSON.stringify(this.filterData));
                newObj.status = this.selectedStatus;
                return newObj;
            },
            filterStatus:function() {
                if (Object.keys(this.filterData).length > 0) {
                    return true;
                }
            }
        },
		methods: {
			goTo() {
                if (this.goToId !== null) {
                    var id = this.goToId;
                    this.$router.push({ name: 'workOrderForm', params: { id } })
                }
            },
            edit(id) {
                // this.$router.push({ name: 'workOrderForm', params: { id } })
                let routeData = this.$router.resolve({name: 'workOrderForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
			getFilterList() {
				this.getCostCenter().then(res => this.costCenterList = res);
				this.getUsers().then(res => this.userList = res);
                this.getScope().then((res) => {
                    this.scopeList = res;
                });
            },
            async getCostCenter() {
				try {
					this.loading = true
					const res = await this.$api.invoiceCostCenter.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
			async getUsers() {
				try {
					this.loading = true
					const res = await this.$api.user.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            tableFilter(value) {
                if (Object.keys(value).length > 0) {
                    this.filterData = value;
                }
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
            },
			async getScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.getFilterList();
		},
        watch:{
            selectedStatusMobile:function(index) {
                this.selectedStatus = this.statusOption[index].value;
            }
        }
	}
</script>