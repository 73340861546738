<template>
    <v-container fluid class="list-claim">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                    <v-icon>mdi-window-close</v-icon>
                    Remove filter
                </v-btn>
                <v-dialog v-model="filterDialog" max-width="400">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" dark v-on="on" class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </template>
                    <v-card>
                        <tableFilter :filterFields="filterData" v-on:filteredData="tableFilter" :costCenterList="costCenterList" :userList="userList" :scopeList="scopeList"></tableFilter>
                    </v-card>
                </v-dialog>
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-tabs
                background-color="primary"
                show-arrows 
                center-active
                centered grow >
                <v-tabs-slider />
                <v-tab>Created</v-tab>
                <v-tab>Vetted</v-tab>
                <v-tab>Audited</v-tab>
                <v-tab>Completed</v-tab>
                <v-tab-item>
                    <v-data-table
                        v-if="this.sortedClaimList"
                        :loading="this.loading" loading-text="Loading... Please wait"
                        :headers="headersCreated"
                        :items="this.sortedClaimList"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items-per-page="20"
                        class="elevation-1"
                        >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-left">{{row.item.id}}</td>
                            <td class="text-left">{{row.item.name}}</td>
                            <td class="text-left">{{getUserName(row.item.worker)}}</td>
                            <td class="text-left">{{row.item.from_date}}</td>
                            <td class="text-left">{{row.item.to_date}}</td>
                            <td class="text-left">{{getCostCenterName(row.item.costcenter)}}</td>
                            <td class="text-left">{{getScopeName(row.item.scope)}}</td>
                            <td class="text-left">{{getUserName(row.item.created_by)}}</td>
                            <td class="text-left">{{row.item.cost_formatted}}</td>
                            <td style="width:150px" class="text-xs-center">
                                <v-btn-toggle>
                                    <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table
                        v-if="this.sortedClaimListVetted"
                        :loading="this.loading" loading-text="Loading... Please wait"
                        :headers="headersVetted"
                        :items="this.sortedClaimListVetted"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items-per-page="20"
                        class="elevation-1"
                        >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-left">{{row.item.id}}</td>
                            <td class="text-left">{{row.item.name}}</td>
                            <td class="text-left">{{getUserName(row.item.worker)}}</td>
                            <td class="text-left">{{row.item.from_date}}</td>
                            <td class="text-left">{{row.item.to_date}}</td>
                            <td class="text-left">{{getCostCenterName(row.item.costcenter)}}</td>
                            <td class="text-left">{{getScopeName(row.item.scope)}}</td>
                            <td class="text-left">{{getUserName(row.item.vetted_by)}}</td>
                            <td class="text-left">{{row.item.cost_formatted}}</td>
                            <td style="width:150px" class="text-xs-center">
                                <v-btn-toggle>
                                    <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table
                        v-if="this.sortedClaimListAudited"
                        :loading="this.loading" loading-text="Loading... Please wait"
                        :headers="headersAudited"
                        :items="this.sortedClaimListAudited"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items-per-page="20"
                        class="elevation-1"
                        >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-left">{{row.item.id}}</td>
                            <td class="text-left">{{row.item.name}}</td>
                            <td class="text-left">{{getUserName(row.item.worker)}}</td>
                            <td class="text-left">{{row.item.from_date}}</td>
                            <td class="text-left">{{row.item.to_date}}</td>
                            <td class="text-left">{{getCostCenterName(row.item.costcenter)}}</td>
                            <td class="text-left">{{getScopeName(row.item.scope)}}</td>
                            <td class="text-left">{{getUserName(row.item.audited_by)}}</td>
                            <td class="text-left">{{row.item.cost_formatted}}</td>
                            <td style="width:150px" class="text-xs-center">
                                <v-btn-toggle>
                                    <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table
                        v-if="this.sortedClaimListCompleted"
                        :loading="this.loading" loading-text="Loading... Please wait"
                        :headers="headersCompleted"
                        :items="this.sortedClaimListCompleted"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items-per-page="20"
                        class="elevation-1"
                        >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-left">{{row.item.id}}</td>
                            <td class="text-left">{{row.item.name}}</td>
                            <td class="text-left">{{getUserName(row.item.worker)}}</td>
                            <td class="text-left">{{row.item.from_date}}</td>
                            <td class="text-left">{{row.item.to_date}}</td>
                            <td class="text-left">{{getCostCenterName(row.item.costcenter)}}</td>
                            <td class="text-left">{{getScopeName(row.item.scope)}}</td>
                            <td class="text-left">{{row.item.cost_formatted}}</td>
                            <td style="width:150px" class="text-xs-center">
                                <v-btn-toggle>
                                    <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterClaim.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
            headersCreated: [
            { text: 'ID',  align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Worker', value: 'worker' },
            { text: 'From', value: 'from', width:120 },
            { text: 'To', value: 'to', width:120 },
            { text: 'Cost Center', value: 'cost_center' },
            { text: 'Scope', value: 'scope' },
            { text: 'Created By', value: 'created_by' },
            { text: 'Cost', value: 'cost' },
            { text: '', sortable: false,}
            ],
            headersVetted: [
            { text: 'ID',  align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Worker', value: 'worker' },
            { text: 'From', value: 'from', width:120 },
            { text: 'To', value: 'to', width:120 },
            { text: 'Cost Center', value: 'cost_center' },
            { text: 'Scope', value: 'scope' },
            { text: 'Vetted By', value: 'vetted_by' },
            { text: 'Cost', value: 'cost' },
            { text: '', sortable: false,}
            ],
            headersAudited: [
            { text: 'ID',  align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Worker', value: 'worker' },
            { text: 'From', value: 'from', width:120 },
            { text: 'To', value: 'to', width:120 },
            { text: 'Cost Center', value: 'cost_center' },
            { text: 'Scope', value: 'scope' },
            { text: 'Audited By', value: 'audited_by' },
            { text: 'Cost', value: 'cost' },
            { text: '', sortable: false,}
            ],
            headersCompleted: [
            { text: 'ID',  align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Worker', value: 'worker' },
            { text: 'From', value: 'from', width:120 },
            { text: 'To', value: 'to', width:120 },
            { text: 'Cost Center', value: 'cost_center' },
            { text: 'Scope', value: 'scope' },
            { text: 'Cost', value: 'cost'},
            { text: '', sortable: false,}
            ],
			success: false,
            loading: true,
            
			claimList: [],
			claimListVetted: [],
            claimListAudited: [],
            claimListCompleted: [],
            costCenterList: [],
            userList: [],
            scopeList: [],
            
            filter: false,
            filterDialog: false,
            filterData: {},
            filterDataVetted: {},
            filterDataAudited: {},
            filterDataCompleted: {},
            filteredClaimList: [],
            filteredClaimListVetted: [],
            filteredClaimListAudited: [],
            filteredClaimListCompleted: [],
		}),
        computed: {
            sortedClaimList () {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredClaimList;
                } else {
                    return this.claimList;
                }
            },
            sortedClaimListVetted () {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredClaimListVetted;
                } else {
                    return this.claimListVetted;
                }
            },
            sortedClaimListAudited () {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredClaimListAudited;
                } else {
                    return this.claimListAudited;
                }
            },
            sortedClaimListCompleted () {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredClaimListCompleted;
                } else {
                    return this.claimListCompleted;
                }
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getClaims().then((res) => {
                    this.claimList = res;
                });
			    this.getClaimsVetted().then((res) => {
                    this.claimListVetted = res;
                });
			    this.getClaimsAudited().then((res) => {
                    this.claimListAudited = res;
                });
			    this.getClaimsCompleted().then((res) => {
                    this.claimListCompleted = res;
                });
            },
			async getClaims() {
                try {
                    this.loading = true
                    const res = await this.$api.invoice.search({ status: 'CREATED'});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getClaimsVetted() {
                try {
                    this.loading = true
                    const res = await this.$api.invoice.search({ status: 'VETTED'});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getClaimsAudited() {
                try {
                    this.loading = true
                    const res = await this.$api.invoice.search({ status: 'AUDITED'});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getClaimsCompleted() {
                try {
                    this.loading = true
                    const res = await this.$api.invoice.search({ status: 'COMPLETED'});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                // this.$router.push({ name: 'invoiceForm', params: { id } })
                let routeData = this.$router.resolve({name: 'invoiceForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.invoice.delete(id).then(res => this.list() );
                    }
                })
            },
            statusName () {
                if (true) {
                    return "Active";
                } else {
                    return "Inactive"
                }
            },
            getAdditionalData() {
			    this.getCostCenterList().then((res) => {this.costCenterList = res;});
			    this.getUserList().then((res) => {this.userList = res;});
                this.getScope().then((res) => {
                    this.scopeList = res;
                });
            },
			async getCostCenterList() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getCostCenterName(id) {
                if ( this.costCenterList.length > 0 && id ) {
                    return this.costCenterList.find(costcenter => costcenter.id === id).name;
                }
            },
			async getScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getScopeName(id) {
                if ( this.scopeList.length > 0 && id ) {
                    return this.scopeList.find(scope => scope.id === id).name;
                }
            },
			async getUserList() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(id) {
                if ( this.userList.length > 0 && id ) {
                    return this.userList.find(user => user.id === id).username;
                }
            },
            // Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
                    this.filterData.status = "CREATED";
			        this.$api.invoice.search(this.filterData).then((res) => {
                        this.filteredClaimList = res.data;
                    });
                    this.filterDataVetted = JSON.parse(JSON.stringify(this.filterData));
                    this.filterDataVetted.status = "VETTED";
			        this.$api.invoice.search(this.filterDataVetted).then((res) => {
                        this.filteredClaimListVetted = res.data;
                    });
                    this.filterDataAudited = JSON.parse(JSON.stringify(this.filterData));
                    this.filterDataAudited.status = "AUDITED";
			        this.$api.invoice.search(this.filterDataAudited).then((res) => {
                        this.filteredClaimListAudited = res.data;
                    });
                    this.filterDataCompleted = JSON.parse(JSON.stringify(this.filterData));
                    this.filterDataCompleted.status = "COMPLETED";
			        this.$api.invoice.search(this.filterDataCompleted).then((res) => {
                        this.filteredClaimListCompleted = res.data;
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filterDataVetted  = {};
                this.filterDataAudited = {};
                this.filterDataCompleted = {};
                this.filteredClaimList = [];
                this.filteredClaimListVetted = [];
                this.filteredClaimListAudited = [];
                this.filteredClaimListCompleted = [];
                this.filter = false;
            },
		},
		mounted () {
            this.list();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
        },
	}
</script>