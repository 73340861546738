<template>
    <v-container fluid class="pa-0">
		<div v-if="authed == true" >
			<v-row>
				<v-container style="max-width:1200px">
					<DashboardPie />
				</v-container>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import DashboardPie from '@/components/report/dashboard-pie.vue'
	export default {
		components: {
			DashboardPie
		},
		data() {
			return {
				mode: process.env.VUE_APP_MODE,
				loading: false,
			}
		},
		computed: {
			authed: function () {
				return this.$store.getters.isLoggedIn
			}
		},
	}
</script>