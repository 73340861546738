<template>
    <div>
        <div class="subtitle-2 py-2" v-if="this.dataType !== 'complainCreator'">Average Order Time Taken</div>
        <v-tabs v-if="this.dataType !== 'complainCreator'">
            <v-tabs-slider />
            <v-tab v-if="userId">Work</v-tab>
            <v-tab v-if="userId">Claim</v-tab>
            <v-tab-item>
                <v-row>
                    <v-col
                        v-for="card in avgOrderStatWork"
                        :key="card.title"
                        :cols="12"
                        :sm="card.flex"
                    >
                        <v-card
                            :color="card.color"
                            dark
                            class="text-left"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                {{card.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-card-text>
                            <v-row align="center">
                                <v-col
                                class="display-1"
                                cols="12"
                                >
                                {{card.data | duToHour}}
                                </v-col>
                            </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row>
                    <v-col
                        v-for="card in avgOrderStatClaim"
                        :key="card.title"
                        :cols="12"
                        :sm="card.flex"
                    >
                        <v-card
                            :color="card.color"
                            dark
                            class="text-left"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                {{card.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-card-text>
                            <v-row align="center">
                                <v-col
                                class="display-3"
                                cols="6"
                                >
                                {{card.data}}
                                </v-col>
                            </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs>
        <!-- <v-row dense class="mb-2" v-if="this.dataType !== 'complainCreator'">
            <v-col
                v-for="card in avgOrderStat"
                :key="card.title"
                :cols="12"
                :sm="card.flex"
            >
            <v-card
                :color="card.color"
                dark
                class="text-left"
            >
                <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title class="headline">
                    {{card.title}}
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-card-text>
                <v-row align="center">
                    <v-col
                    class="display-3"
                    cols="6"
                    >
                    {{card.data}}
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row> -->
        <div class="subtitle-2 py-2" v-if="this.dataType !== 'complainCreator'">Order List</div>
        <v-data-table
        :headers="headers"
        :items="dataList"
        class="elevation-1"
        hide-default-footer
        :items-per-page="perPage"
        :options.sync="options"
        >
        <template v-slot:item="row">
            <tr>
                <td class="text-left">{{row.item.open_dt | moment_dtstamp}}</td>
                <td class="text-left"><span v-if="row.item.due_dt">{{ row.item.due_dt | moment_dtstamp }}</span></td>
                <td class="text-left"><span v-if="row.item.done_ts">{{row.item.done_ts | moment_dtstamp}}</span></td>
                <td class="text-left">{{row.item.name}}</td>
                <!-- <td class="text-left"><span v-if="row.item.worker">{{ getUserName(row.item.worker) }}</span></td> -->
                <td class="text-left">{{row.item.status}}</td>
                <td style="width:150px" class="text-xs-right">
                    <v-btn-toggle>
                        <v-btn small fab @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
            </tr>
        </template>
        </v-data-table>
        <div class="text-center">
            <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
            <div class="subtitle">Total: {{pagination.count}}</div>
        </div>
    </div>
</template>
<script>
import tableFilter from '@/components/filter/filterWorkOrder.vue'
	export default {
		data: () => ({
            headers: [
                { text: 'Open Date', value: 'open_dt', width: 200 },
                { text: 'Due Date', value: 'due_dt', width: 200 },
                { text: 'Resolved Date', value: 'done_ts', width: 200  },
                {
                    text: 'Name',
                    value: 'name',
                },
                { text: 'Status', value: 'status' },
                { text: '', value: '' },
            ],
            dataList: [],
            pagination:{
                next: '',
                count:0,
                pages: 1,
                current:1,
            },
            avgOrderStatWork: {
                openWipOrders: { title: 'Open to WIP', color: '#003f5c', flex: 3, data: 0 },
                wipDoneOrders: { title: 'WIP to Done', color: '#004f6b', flex: 3, data: 0 },
                openDoneOrders: { title: 'Open to Done', color: '#005f79', flex: 3, data: 0 },
            },
            avgOrderStatClaim: {
                claimedVerifiedOrders: { title: 'Claimed to Verified', color: '#00808d', flex: 3, data: 0 },
                verifiedVettedOrders: { title: 'Verified to Vetted', color: '#009194', flex: 3, data: 0 },
                vettedAuditedOrders: { title: 'Vetted to Audited', color: '#00a198', flex: 3, data: 0 },
                claimedAuditedOrders: { title: 'Claimed to Audited', color: '#2db29a', flex: 3, data: 0 },
            },
            options:{},
            perPage: 20,
            loading: false,
            filterData: {},
		}),
        components: {
            tableFilter,
        },
        props: {
            dataType: String, 
            userId: Number,
        },
        computed: {
            filterDataWithPagination: function() {
                var newObj = this.filterData;
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                return newObj;
            },
            datatableOrdering: function() {
                if (this.options.sortBy.length > 0) {
                    if ( this.options.sortDesc[0] == false) {
                        return this.options.sortBy[0];
                    } else {
                        return '-' + this.options.sortBy[0];
                    }   
                } else {
                    return '-due_dt';
                }
            }
        },
		methods: {
            // Main Function
            list() {
                if (this.dataType == 'complainCreator') {
                    this.filterData.complainer = this.userId;
                    this.getComplains(this.pagination.current).then((res) => {
                        this.dataList = res.results;
                        this.pagination.next = res.next;
                        this.pagination.count = res.count;
                        this.pagination.pages = Math.ceil(res.count / this.perPage);
                    });
                }
                if (this.dataType == 'orderSupervisor') {
                    this.filterData.supervisor = this.userId;
                    this.getWorkOrders(this.pagination.current).then((res) => {
                        this.dataList = res.results;
                        this.pagination.next = res.next;
                        this.pagination.count = res.count;
                        this.pagination.pages = Math.ceil(res.count / this.perPage);
                    });
                    this.getAvgStat({ supervisor: this.userId }).then((res) => {
                        this.avgOrderStatWork.openWipOrders.data = res.open_to_wip_du__avg;
                        this.avgOrderStatWork.wipDoneOrders.data = res.wip_to_done_du__avg;
                        this.avgOrderStatWork.openDoneOrders.data = res.open_to_done_du__avg;
                        this.avgOrderStatClaim.claimedVerifiedOrders.data = res.claimed_to_verified_du__avg;
                        this.avgOrderStatClaim.verifiedVettedOrders.data = res.verified_to_vetted_du__avg;
                        this.avgOrderStatClaim.vettedAuditedOrders.data = res.vetted_to_audited_du__avg;
                        this.avgOrderStatClaim.claimedAuditedOrders.data = res.claimed_to_audited_du__avg;
                    });
                }
                if (this.dataType == 'orderWorker') {
                    this.filterData.worker = this.userId;
                    this.getWorkOrders(this.pagination.current).then((res) => {
                        this.dataList = res.results;
                        this.pagination.next = res.next;
                        this.pagination.count = res.count;
                        this.pagination.pages = Math.ceil(res.count / this.perPage);
                    });
                    this.getAvgStat({ worker: this.userId }).then((res) => {
                        this.avgOrderStatWork.openWipOrders.data = res.open_to_wip_du__avg;
                        this.avgOrderStatWork.wipDoneOrders.data = res.wip_to_done_du__avg;
                        this.avgOrderStatWork.openDoneOrders.data = res.open_to_done_du__avg;
                        this.avgOrderStatClaim.claimedVerifiedOrders.data = res.claimed_to_verified_du__avg;
                        this.avgOrderStatClaim.verifiedVettedOrders.data = res.verified_to_vetted_du__avg;
                        this.avgOrderStatClaim.vettedAuditedOrders.data = res.vetted_to_audited_du__avg;
                        this.avgOrderStatClaim.claimedAuditedOrders.data = res.claimed_to_audited_du__avg;
                    });
                }
            },
			async getComplains() {
                try {
                    this.loading = true
                    const res = await this.$api.complaintComplaint.search( this.filterDataWithPagination );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getAvgStat(obj) {
                try {
                    this.loading = true
					const res = await this.$api.statistics.avgOrderUser(obj);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
			},
			async getWorkOrders() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrder.search( this.filterDataWithPagination );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                if (this.dataType == 'complainCreator') {
                    this.$router.push({ name: 'complaintComplaintForm', params: { id } })
                } else {
                    this.$router.push({ name: 'workOrderForm', params: { id } })
                }
            },
		},
		mounted () {
            this.list();
		},
        watch: {
            filterData: function(newVal, oldVal) { // watch it
                this.list();
            },
            'pagination.current': {
                handler(val){
                    this.list();
                },
                deep: true
            },
            options: {
                handler(val){
                    this.list();
                },
                deep: true
            },
        },
	}
</script>