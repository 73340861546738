<template>
    <div>
        <v-data-table 
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headersComponentPlan"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedComponents"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-row>
                        <v-col cols="10">
                            <v-select
                            :items="componentList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Component" 
                            v-model="row.item.component"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Sort Order" 
                                v-model="row.item.sort_order" 
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <v-row no-gutters>
                        <v-btn-toggle>
                            <v-btn small fab @click="save(row.item.id, row.item.component, row.item.sort_order, row.item.asset_plan)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab @click="remove(row.item.index, row.item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newComponentPlan()">Add Component Plan</v-btn></div>
    </div>
    
</template>
<script>
	export default {
        props: {
            assetPlanId: {
                type: Number
            },
            assetId: {
                type: Number
            }
        },
		data() {
            return {
                headersComponentPlan: [
                {
                    text: 'Components',
                    align: 'left',
                    sortable: true,
                    value: 'component',
                },
                { text: ''}
                ],
                id: [],
                loading: true,
                componentPlansbyAssetPlan: [],
                users:[],
                componentList: [],
                assetPlan: 0,
                masterParamList: [],
                baseParamList:[],
            }
        },
        computed: {
            indexedComponents () {
                return this.componentPlansbyAssetPlan.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getComponentPlansbyAssetPlan().then((res) => {this.componentPlansbyAssetPlan = res});
            },
			async getComponentPlansbyAssetPlan() {
                try {
                    this.loading = true
                    const res = await this.$api.compPlan.search( { asset_plan:this.assetPlanId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getCompsbyAsset().then(res => this.componentList = res);
            },
			async getCompsbyAsset() {
                try {
                    const res = await this.$api.component.search({ asset: this.assetId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getCompName(id) {
                if ( this.componentList.length !== 0 ) {
                    return (this.componentList.find(component => component.id === id).name)
                }
            },
            newComponentPlan() {
                this.componentPlansbyAssetPlan.push({id: '', component: '', sort_order: '', asset_plan: this.assetPlan});
            },
            save(id, component, sort_order) {
                this.saveCompPlan(id, component, sort_order).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveCompPlan(id, component, sort_order) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.compPlan.save( {id: id, component: component, sort_order: sort_order, asset_plan: this.assetPlanId });
                    } else {
                        res = await this.$api.compPlan.new({ component: component, sort_order: sort_order, asset_plan: this.assetPlanId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.compPlan.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.componentPlansbyAssetPlan, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>