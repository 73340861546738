<template>
  <div>
      <Header v-if="authed" :drawerStatus="drawer" @update="drawer = $event;" :mode="'simple'" :globalPref="globalPref"></Header>
        <!-- <v-navigation-drawer v-if="authed" 
        :clipped="$vuetify.breakpoint.lgAndUp"
        :mini-variant.sync="drawer"
        permanent
        app
        >
            <sideSubMenu></sideSubMenu>
        </v-navigation-drawer> -->
        <v-main v-if="authed" >
            <v-container fluid class="fill-height">
                <v-row>
                    <v-col cols="12">
                        <slot />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
  </div>
</template>
<script>
import login from '@/pages/login';
import Header from '@/components/common/header.vue';
export default {
    components: {
        Header,
        login,
    },
    data: () => ({
        drawer: false,
        windowWidth: 0,
        globalPref: [],
        mini: true,
    }),
    computed: {
        companyName: function() {
            if (this.authed && this.globalPref.length > 0) {
                return (this.globalPref.find(pref => pref.name === 'organization_name').value);
            }
        },
        authed: function() {
            return this.$store.getters.isLoggedIn
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        getGlobalPref() {
            if (this.authed) {
                this.getGlobalPreferences().then(res => this.globalPref = res);
            }
        },
        async getGlobalPreferences() {
            try {
                const res = await this.$api.preference.global();
                return res.data;
            } catch (error) {
            } finally {
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        checkLogin() {
            if (!this.authed) {
			    this.$router.push('/login');
            }
        }
    },
    watch: {
        authed: function(newAuth,oldAuth){
            this.getGlobalPref();
        },
        windowWidth(width) {
            if (width >= 1264) {
                this.drawer = true;
            } else {
                this.drawer = false;
            }
        }
    },
    mounted: function () {
        this.getGlobalPref();
        this.checkLogin();
    }
};
</script>
