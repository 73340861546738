<template>
    <div>
        <v-card outlined class="mb-4" >
            <v-card-text>
                <v-row v-for="(workDetailScope,i) in indexedWorkDetailScope" :key="i">
                    <v-col cols="8" sm="8" md="10">
                        <v-select
                        :items="workScopeList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Scope" 
                        v-model="workDetailScope.scope"
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workDetailScope.index, workDetailScope.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workDetailScope.id, workDetailScope.scope)"><v-icon>mdi-content-save</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkDetailScope()">Add Scope</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            workDetailId: {
                type: Number
            },
        },
		data() {
            return {
                id: [],
                loading: true,
                getWorkDetailCostCentersbyDetail: [],
                users:[],
                workScopeList: [],
            }
        },
        computed: {
            indexedWorkDetailScope () {
                return this.getWorkDetailCostCentersbyDetail.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workDetailId) {
                    this.getWorkDetailScopesbyDetail().then((res) => {
                        this.getWorkDetailCostCentersbyDetail = res;
                    });
                }
                this.getAdditionalData();
            },
			async getWorkDetailScopesbyDetail() {
                try {
                    this.loading = true
                    const res = await this.$api.workScope.search({ detail: this.workDetailId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkDetailScope() {
                // this.$router.push({ name: 'workDetailScopeForm', params: {workDetailId: this.workDetailId } })
                this.getWorkDetailCostCentersbyDetail.push({id: '', scope: '', detail: this.workDetailId});
            },
            save(id, scope) {
                this.saveWorkDetailScope(id, scope).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkDetailScope(id, scope) {
                try {
                    let res;
                    console.log(id)
                    console.log(scope)
                    this.saving = true
                    if (id) {
                        res = await this.$api.workScope.save( {id: id, scope: scope, detail: this.workDetailId });
                    } else {
                        res = await this.$api.workScope.new({scope: scope, detail: this.workDetailId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workScope.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.getWorkDetailCostCentersbyDetail, index)
                }
            },
            getAdditionalData() {
			    this.getScopeList().then((res) => {this.workScopeList = res;});
            },
			async getScopeList() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>