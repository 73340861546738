<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left d-flex">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                <v-tabs-slider/>
                <v-tab>Generic</v-tab>
                <v-tab :disabled="!this.input.id">Child: Generic Parameters</v-tab>

                <v-tab-item>
                    <v-container fluid >
                        <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                            <v-row>
                                <v-col cols="10" sm="10" md="10">
                                    <v-text-field
                                        label="Name" 
                                        v-model="input.name"
                                        :rules="nameRules"
                                        :counter="50"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-switch v-model="input.is_active" label="Status" readonly></v-switch>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                    :items="systemList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="System" 
                                    v-model="input.system"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-select
                                    :items="optionsCriticality" 
                                    item-value="value"
                                    name="item"
                                    item-text="text"
                                    label="Criticality" 
                                    v-model="input.criticality"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-switch v-model="input.qr_scan_required" label="QR Scan Required"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                    :items="unitList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="Capacity Unit" 
                                    v-model="input.capacity_unit"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                    :items="unitList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="Consumption Unit" 
                                    v-model="input.consumption_unit"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn class="mr-4" color="primary"  :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2">
                        <div class="subtitle text-left mb-3">Base Parameters in this Generic</div>
                        <genericParameterTable :genericId='this.id' :systemId='input.system'></genericParameterTable>
                    </div>
                </v-tab-item>
                </v-tabs>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import genericParameterTable from '@/components/table/generic-parameter-table.vue';
	export default {
		  components: {
              genericParameterTable,
		  },
        props: {
            id: Number   
        },
		data: () => ({
            baseCompHeaders: [
            { text: 'Base Components',  value: 'name',  },
            { text: 'Master Component', value: 'master_component' },
            { text: ''},
            { text: ''},
            ],
			success: false,
			baseAssets: [],
			loading: false,

            input:{
                id: '',
                name: '',
                system: '',
                is_active: '',
                criticality: null,
                qr_scan_required: null,
            },
            unitList: [],
            systemList: [],
            baseParameterList: [],
            optionsCriticality: [
            { text: 'c1', value: 1, },
            { text: 'c2', value: 2 },
            { text: 'c3', value: 3 },
            ],
            
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getGeneric().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.system = res.system;
                        this.input.criticality = res.criticality;
                        this.input.is_active = res.is_active;
                        this.input.qr_scan_required = res.qr_scan_required;
                        this.input.capacity_unit = res.capacity_unit;
                        this.input.consumption_unit = res.consumption_unit;
                    });
                }
            },
			async getGeneric() {
                try {
                    this.loading = true
                    const res = await this.$api.assetGeneric.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveGeneric().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'assetGenericList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                                if (this.id) {
                                    this.form();
                                } else {
                                }
                            }
                        })
                    }
                })
			},
			async saveGeneric() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.assetGeneric.save( this.input );
                    } else {
                        res = await this.$api.assetGeneric.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error);
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'assetGenericList' })
            },
            // Additional Infos
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUnitList() {
			    this.getUnits().then(res => this.unitList = res);
            },
			async getUnits() {
                try {
                    const res = await this.$api.unitUnit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            }
		},
		mounted () {
            this.getGeneric();
            this.getSystemList();
            this.getUnitList();
            this.form();
		},
	}
</script>