<template>
    <v-form lazy-validation v-if="!loading"
    ref="form"
    v-model="valid">
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name" 
                        v-model="input.name"
                        :rules="nameRules"
                        :counter="50"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Symbol" 
                        v-model="input.symbol"
                        :rules="abbreRules"
                        :counter="5"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-select
                    :items="baseUnitList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="Base Unit" 
                    v-model="input.base_unit"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        type="number"
                        label="Converter" 
                        v-model="input.converter"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
    
</template>
<script>
	export default {
        props: {
            unitId: {
                type: Number
            },
            baseUnitId: {
                type: Number
            }
        },
		data() {
            return {
                loading: false,
                baseUnitList: [],
                input:{
                    id: '',
                    name: '',
                    symbol: '',
                    base_unit: '',
                    converter: '',
                },
                valid: true,
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 50) || 'Name must be less than 50 characters',
                ],
                abbreRules: [
                    v => !!v || 'Abbreviation is required',
                    v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
                ],
            }
        },
        methods: {
            form() {
                if (this.unitId) {
                    this.getUnit().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.symbol = res.symbol;
                        this.input.base_unit = res.base_unit;
                        this.input.converter = res.converter;
                    });
                }
            },
			async getUnit() {
                try {
                    this.loading = true
                    const res = await this.$api.unitUnit.get(this.unitId);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveUnit().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Stay',
                        }).then((result) => {
                            if (result.value) {
                                this.$emit('return-form')
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
            },
			async saveUnit() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.unitUnit.save( this.input );
                    } else {
                        res = await this.$api.unitUnit.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getBaseUnitList() {
			    this.getBaseUnits().then(res => this.baseUnitList = res);
            },
			async getBaseUnits() {
                try {
                    const res = await this.$api.unitMeasurement.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.form();
            this.getBaseUnitList();
        }
    }
</script>