<template>
    <v-container fluid class="list-workOrder">
        <v-row dense>
            <v-col>
                 <v-tabs 
                background-color="primary"
                show-arrows 
                center-active>
                <v-tabs-slider />
                <v-tab>Order Count</v-tab>
                <v-tab>Chart</v-tab>
                <v-tab-item>
                    <v-row>
                        <v-col
                            v-for="card in dashboardElement"
                            :key="card.title"
                            :cols="12"
                            :sm="card.flex"
                        >
                        <v-card
                            :color="card.color"
                            dark
                            class="text-left"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                {{card.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-card-text class="pt-0">
                            <v-row align="center">
                                <v-col
                                class="display-1 pt-0 pb-0"
                                >
                                {{card.data}}
                                </v-col>
                            </v-row>
                            </v-card-text>
                        </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <div class="mb-5 mx-auto text-right">
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                :items="dataOptionList" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Data Option" 
                                v-model="dataSelected"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-autocomplete
                                :items="frequencyOptionList" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Frequency" 
                                v-model="filterData.frequency"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-menu
                                    v-model="from_date_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filterData.from_date"
                                        label="From Date"
                                        prepend-icon="mdi-clock-start"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filterData.from_date" @input="from_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    v-model="to_date_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filterData.to_date"
                                        label="To Date"
                                        prepend-icon="mdi-clock-end"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filterData.to_date" @input="to_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn :disabled="!searchValid" color="primary" @click="search()" >
                                <v-icon>mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                <line-chart v-if="datacollection" :chartData="datacollection" :options="chartOptions"></line-chart>
                </v-tab-item>
            </v-tabs>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from 'vue'
import LineChart from '@/components/chart/chart'
	export default {
        components: {
        LineChart
        },
		data: () => ({
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingWorkPrograms: [],
            yearMonthSelected: '',
            programSelected: '',
            otherWorkOrder : [],
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Status', value: 'status' },
            { text: 'Open Datetime', value: 'open_dt' },
            // { text: 'Activity', value: 'activity' },
            { text: '', sortable: false,}
            ],
			success: false,
			workOrder: [],
			loading: true,
            userList: [],
            filter: false,
            filterData: {
                from_date: null,
                to_date: null,
                frequency: null,
            },
            claimName: '',
            from_date_menu: null,
            to_date_menu: null,
            costCenterList: [],
            scopeList: [],
            dataOptionList:[
                { value: "status", name: "Status" },
                { value: "cost", name: "Cost" },
            ],
            frequencyOptionList:[
                { value: "month", name: "Monthly" },
                { value: "week", name: "Weekly" },
            ],
            statisticMode:[
                { value: "range", name: "Date Range" },
                { value: "current", name: "Current" },
            ],
            chartFieldMode:[
                { value: "range", name: "Date Range" },
                { value: "current", name: "Current" },
            ],
            modeSelected: 'range',
            frequencySelected: 'week',
            dataSelected: 'status',
            dashboardElement: {
                draftOrders: { title: 'Draft Orders', color: '#385F73', flex: 2, data: 0 },
                openOrders: { title: 'Open Orders', color: '#385F73', flex: 2, data: 0 },
                wipOrders: { title: 'Orders in WIP', color: '#952175', flex: 2, data: 0 },
                recordedOrders: { title: 'Recorded Orders', color: '#1F7087', flex: 2, data: 0 },
                redoOrders: { title: 'Redo Orders', color: '#952175', flex: 2, data: 0 },
                doneOrders: { title: 'Orders Done', color: '#1F7087', flex: 2, data: 0 },
                claimedOrders: { title: 'Claimed Orders', color: '#952175', flex: 2, data: 0 },
                verifiedOrders: { title: 'Verified Orders', color: '#1F7087', flex: 2, data: 0 },
                compiledOrders: { title: 'Compiled Orders', color: '#952175', flex: 2, data: 0 },
                auditedOrders: { title: 'Audited Orders', color: '#1F7087', flex: 2, data: 0 },
            },
            datacollection: null,
            chartOptions: {
                responsive: true
            },
            chartData: [],
		}),
        computed: {
            searchValid() {
                if (this.filterData.frequency !== null ) {
                    return true;
                }
            },
            sortedWorkOrder () {
                return this.workOrder;
            },
            orderSelectedPost() {
                return this.orderSelected.map(a => a.id);
            },
            chartDataArray() {
                var newArray = [];
                for (var index = 0; index < this.chartData.length; ++index) {
                    newArray.push(this.chartData[index]);
                }
                return newArray;
            }
        },
		methods: {
            // Main Function
            search() {
			    this.getOrderStatistic().then((res) => {
                    this.chartData = this.res;
                    this.datacollection = {
                        labels: ['1','2'],
                        datasets: this.chartData,
                    }
                    console.log(this.datacollection);
                });
            },
			async getOrderStatistic() {
                try {
                    let res;
                    res = await this.$api.statistics.orderRange({
                        'frequency': this.filterData.frequency,
                        'date-start': this.filterData.from_date,
                        'date-end': this.filterData.to_date,
                    });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.filter = true
                    this.loading = false
                }
            },
            getAdditionalData() {
                this.getUsers().then((res) => {
                    this.userList = res;
                });
                this.getCostCenter().then((res) => {
                    this.costCenterList = res;
                });
                this.getScope().then((res) => {
                    this.scopeList = res;
                });
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getCostCenter() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    this.filterData = value;
			        this.$api.workOrder.search(value).then((res) => {
                        this.filteredWorkOrder = res.data;
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredWorkOrder = [];
                this.filter = false;
            },
            urgencyColor(urgency) {
                if (urgency == 1) {
                    return 'red dark-3'
                }
                if (urgency == 2) {
                    return 'red'
                }
                if (urgency == 3) {
                    return 'amber'
                }
                if (urgency == 4) {
                    return 'blue'
                }
            },
			async getStat() {
                try {
                    this.loading = true
					const res = await this.$api.statistics.orderToday();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
		},
		mounted () {
            // this.list();
			this.getStat().then((res) => {
				// console.log(res);
				this.dashboardElement.openOrders.data = res.status__open;
				this.dashboardElement.wipOrders.data = res.status__wip;
				this.dashboardElement.recordedOrders.data = res.status__recorded;
			});
            this.filterData.from_date = this.$moment().format('YYYY-MM-DD')
            this.filterData.to_date = this.$moment().format('YYYY-MM-DD')
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
        },
	}
</script>