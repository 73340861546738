<template>
    <v-snackbar
    right
    v-model="snackbar"
    >
    Connecting..
      <v-btn
        color="primary"
        text
        @click="relog"
      >
        Close
      </v-btn>
    </v-snackbar>
    <!-- <v-row align="center" @click="relog">
        <v-col class="grow text-left">Reconnecting <span v-if="isRunning">in {{ timer }}</span>... <br/><small>If it doesn't work, please tap here! </small></v-col>
    </v-row> -->
</template>
<script>
	export default {
		data: () => ({
            snackbar: true,
            timer: 2,
            timerObject: '',
            isRunning: false,
        }),
        created () {
            this.startTimer();
        },
        methods: {
            startTimer () {
                this.timerObject = setInterval(() =>{
                    if (this.timer > 0) {
                        this.isRunning = true;
                        this.timer--;
                    } else {
                        this.isRunning = false;
                        clearInterval(this.timerObject)
                    }
                }, 1000)
            },
            relog() {
                this.$store.dispatch('refreshToken');
			}
        },
        beforeDestroy () {
            clearInterval(this.timerObject)
        }
    }
</script>