<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <mastCompForm v-if="!loading" :mastCompId="mastCompId" v-on:return-form="quitEdit"></mastCompForm>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import mastCompForm from '@/components/form/master-component-form.vue';
	export default {
		  components: {
              mastCompForm
		  },
		data: () => ({
            mastCompId: 0,
            loading: true,
        }),
        props: {
            id: Number   
        },
		methods: {
            // Main Function
			quitEdit() {
                this.$router.push({ name: 'masterComponentList' })
            },
		},
		mounted () {
            this.mastCompId = this.id;
            this.loading = false;
		},
	}
</script>