<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderAssetParameter,i) in orderBy(indexedWorkOrderDetail, 'sort_order')" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                        :items="assetParamList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Asset Parameter" 
                        v-model="workOrderAssetParameter.asset_parameter"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-text-field
                            v-if="workOrderAssetParameter.status_mode !== 'MANUAL'"
                            label="Reading" 
                            v-model="workOrderAssetParameter.number_reading"
                            hide-details="auto"
                        ></v-text-field>
                        <v-select
                            v-if="workOrderAssetParameter.status_mode == 'MANUAL'"
                            :items="optionStatus" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Status" 
                            v-model="workOrderAssetParameter.status"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="text-right">
                        <!-- <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workOrderAssetParameter.index, workOrderAssetParameter.id)">mdi-delete</v-icon></v-btn> -->
                        <v-btn color="primary" @click="save(workOrderAssetParameter.id, workOrderAssetParameter.asset_parameter, workOrderAssetParameter.status_mode, workOrderAssetParameter.number_reading, workOrderAssetParameter.status, workOrderAssetParameter.newimage_1, workOrderAssetParameter.newimage_2, workOrderAssetParameter.newimage_3, workOrderAssetParameter.newimage_4, workOrderAssetParameter.newimage_5)"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <label>Images <span v-if="workOrderAssetParameter.require_image">( required )</span></label><br/>
                        <v-row>
                            <v-col class="md-20">
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.image_1 && !workOrderAssetParameter.newimage_1" :src="workOrderAssetParameter.image_1" :src_large="workOrderAssetParameter.image_1" />
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_1 && !workOrderAssetParameter.newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.newimage_1" :src="workOrderAssetParameter.newimage_1Preview" :src_large="workOrderAssetParameter.newimage_1Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event,workOrderAssetParameter.id, 'newimage_1')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 1"
                                    v-model="workOrderAssetParameter.newimage_1"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-20">
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.image_2 && !workOrderAssetParameter.newimage_2" :src="workOrderAssetParameter.image_1" :src_large="workOrderAssetParameter.image_2" />
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_2 && !workOrderAssetParameter.newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.newimage_2" :src="workOrderAssetParameter.newimage_2Preview" :src_large="workOrderAssetParameter.newimage_2Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderAssetParameter.id, 'newimage_2')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 2"
                                    v-model="workOrderAssetParameter.newimage_2"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-20">
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.image_3 && !workOrderAssetParameter.newimage_3" :src="workOrderAssetParameter.image_1" :src_large="workOrderAssetParameter.image_3" />
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_3 && !workOrderAssetParameter.newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.newimage_3" :src="workOrderAssetParameter.newimage_3Preview" :src_large="workOrderAssetParameter.newimage_3Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderAssetParameter.id, 'newimage_3')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 3"
                                    v-model="workOrderAssetParameter.newimage_3"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-20">
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.image_4 && !workOrderAssetParameter.newimage_4" :src="workOrderAssetParameter.image_1" :src_large="workOrderAssetParameter.image_4" />
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_4 && !workOrderAssetParameter.newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.newimage_4" :src="workOrderAssetParameter.newimage_4Preview" :src_large="workOrderAssetParameter.newimage_4Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderAssetParameter.id, 'newimage_4')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 4"
                                    v-model="workOrderAssetParameter.newimage_4"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-20">
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.image_5 && !workOrderAssetParameter.newimage_5" :src="workOrderAssetParameter.image_1" :src_large="workOrderAssetParameter.image_5" />
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_5 && !workOrderAssetParameter.newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderAssetParameter.newimage_5" :src="workOrderAssetParameter.newimage_5Preview" :src_large="workOrderAssetParameter.newimage_5Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderAssetParameter.id, 'newimage_5')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 5"
                                    v-model="workOrderAssetParameter.newimage_5"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- <div>
            <v-btn class="mr-4 mt-2" @click="newWorkOrderDetail()">Add Detail</v-btn>
        </div> -->
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Vue2Filters from 'vue2-filters'
	export default {
        props: {
            workOrderId: {
                type: Number
            },
        },
        components: {
        EnlargeableImage
        },
        mixins: [Vue2Filters.mixin],
		data() {
            return {
                id: [],
                loading: true,
                workOrderAssetParametersbyChecklist: [],
                assetParamList: [],
                additionalDataLoaded: false,
                iconRules: [
                    v => !v || v.size < 2000000 || 'Image size should be less than 2 MB!',
                ],
                optionStatus: [
                    {
                        "value": "UNKNOWN",
                        "name": "unknown"
                    },
                    {
                        "value": "GOOD",
                        "name": "good"
                    },
                    {
                        "value": "BAD",
                        "name": "bad"
                    },
                    {
                        "value": "OTHER",
                        "name": "other"
                    }
                ]
            }
        },
        computed: {
            indexedWorkOrderDetail () {
                return this.workOrderAssetParametersbyChecklist.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderDetailsbyChecklist().then((res) => {
                        this.workOrderAssetParametersbyChecklist = res;
                        res.forEach((item) => { 
                            Vue.set(item,'newimage_1', null);
                            Vue.set(item,'newimage_2', null);
                            Vue.set(item,'newimage_3', null);
                            Vue.set(item,'newimage_4', null);
                            Vue.set(item,'newimage_5', null);
                            Vue.set(item,'newimage_1Preview', null);
                            Vue.set(item,'newimage_2Preview', null);
                            Vue.set(item,'newimage_3Preview', null);
                            Vue.set(item,'newimage_4Preview', null);
                            Vue.set(item,'newimage_5Preview', null);
                        })
                    });
                }
            },
			async getWorkOrderDetailsbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderAssetParameter.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderDetail() {
                // this.$router.push({ name: 'workOrderAssetParameterForm', params: {workOrderId: this.workOrderId } })
                this.workOrderAssetParametersbyChecklist.push({id: '', asset_parameter: '', order: this.workOrderId });
            },
            save(id, asset_parameter, status_mode, number_reading, status, image_1, image_2, image_3, image_4, image_5  ) {
                this.saveWorkOrderAssetParameter(id, asset_parameter, status_mode, number_reading, status, image_1, image_2, image_3, image_4, image_5 ).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderAssetParameter(id, asset_parameter, status_mode, number_reading, status, image_1, image_2, image_3, image_4, image_5 ) {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", id);
                    formData.append("asset_parameter", asset_parameter);
                    formData.append("status_mode", status_mode);
                    formData.append("number_reading", number_reading);
                    formData.append("status", status);
                    if (image_1) {
                        formData.append("image_1", image_1 );
                    }
                    if (image_2) {
                        formData.append("image_2", image_2 );
                    }
                    if (image_3) {
                        formData.append("image_3", image_3 );
                    }
                    if (image_4) {
                        formData.append("image_4", image_4 );
                    }
                    if (image_5) {
                        formData.append("image_5", image_5 );
                    }
                    if (id) {
                        res = await this.$api.workOrderAssetParameter.saveForm( id, formData );
                    } else {
                        res = await this.$api.workOrderAssetParameter.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderAssetParameter.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderAssetParametersbyChecklist, index)
                }
            },
            previewImage(e, parameterId, previewTarget) {
                if (e) {
                    this.workOrderAssetParametersbyChecklist.find(parameter => parameter.id == parameterId)[previewTarget] = URL.createObjectURL(e);
                }
            },
            getAdditionalData() {
                this.getAssetParam().then(res => this.assetParamList = res);
            },
            async getAssetParam() {
                try {
                    const res = await this.$api.assetAssetParameter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>