<template>
    <div>
                <v-data-table 
                :headers="headersTeam"
                :sort-by="['id']"
                :sort-desc="[true]"
                :items="indexedTeam"
                :items-per-page="20"
                :footer-props="{
                    itemsPerPageOptions: [20,40,60],
                }" 
                class="full-width">
                <template v-slot:item="row">
                    <tr>
                    <td class="text-left">
                        <span v-if="row.item.id && teamList">{{ getTeamName(row.item.team) }}</span>
                        <v-select v-if="!row.item.id"
                        item-value="id"
                        name="name"
                        item-text="name"
                        v-model="row.item.team"
                        :items="teamListAvailable"
                        label="Team"
                        ></v-select>
                        </td>
                    <td>
                        <v-btn v-if="!row.item.id" class="mr-4" @click="save(row.item.id,row.item.team)"><v-icon class="mr-2">mdi-content-save</v-icon> Save</v-btn>
                        <v-btn text class="mr-4" @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                    </td>
                    </tr>
                </template>
            </v-data-table>
            <v-btn class="mr-4" @click="addMember()">Add Team</v-btn>
        </div>
</template>
<script>
	export default {
        props: {
            userId: {
                type: Number
            }
        },
		data() {
            return {
                inputId: '',
                inputName: '',
                headersTeam: [
                {
                    text: 'Team',
                    align: 'left',
                    sortable: true,
                    value: 'id',
                },
                { text: ''}
                ],
                teamByUser: [],
                teamListAvailable: [],
                teamList:[],
            }
        },
        computed: {
            indexedTeam () {
                if (this.teamByUser) {
                    return this.teamByUser.map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }
            },
        },
        methods: {
            list() {
                this.getTeamByUser().then((res) =>{
                    this.teamByUser = res;
                    this.getTeamList();
                });
            },
			async getTeamByUser() {
                try {
                    this.loading = true
                    const res = await this.$api.member.search({ user: this.userId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getTeamsAvailable() {
                var index;
                for (index = 0; index < this.indexedTeam.length; ++index) {
                    var del = this.teamList.findIndex(team => team.id == this.indexedTeam[index].team)
                    // console.log(del);
                    this.$delete(this.teamListAvailable, del);
                }
            },
            getTeamName(id) {
                if ( this.teamList && id ) {
                    return this.teamList.find(team => team.id === id).name;
                }
            }, 
            addMember() {
                this.teamByUser.push({id: '', team: '', user: this.userId});
            },
            save(id, team) {
                this.saveMember(id, team, this.userId).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveMember(id, team, user) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.member.save( {id: id, user: user, team: team});
                    } else {
                        res = await this.$api.member.new({user: user, team: team });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id, team, user) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.member.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.teamByUser, index)
                }
            },
            getTeamList() {
			    this.getTeams().then((res) => {
                    this.teamList = res
                this.teamListAvailable = [...this.teamList];
                console.log(this.teamListAvailable)
                this.getTeamsAvailable();
                });
            },
			async getTeams() {
                try {
                    this.loading = true
                    const res = await this.$api.team.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>