<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                <v-icon>mdi-window-close</v-icon>
                Remove filter
            </v-btn>
            <v-dialog v-model="filterDialog" max-width="400">
                <template v-slot:activator="{on}">
                    <span v-on="on">
                        <v-btn v-if="loading == false" color="secondary" dark class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </span>
                </template>
                <v-card>
                     <tableFilter tableType="asset" :globalPref="globalPref" :filterFields="filterData" :locationList="locationList" :genericList="genericList" v-on:filteredData="tableFilter"></tableFilter>
                </v-card>
            </v-dialog>
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            v-if="assetList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="assetTable"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left">
                    <span v-if="row.item.location">{{  getLocName(row.item.location) }} 
                        <v-tooltip top open-on-click :open-on-hover="false" max-width="400" open-on-focus>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="getLocAncestor(row.item.location)">
                                <v-icon color="grey lighten-1">mdi-map-marker</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="tooltipLoc">{{tooltipLoc}}</span>
                        </v-tooltip>
                    </span>
                </td>
                <td class="text-left" v-if="row.item">{{  getCrititcalityName(row.item.criticality) }}</td>
                <td class="text-left" v-if="row.item">{{  getQRScanRequired(row.item.qr_scan_required) }}</td>
                <td class="text-left" v-if="row.item">{{  statusName(row.item.status) }}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center" v-if="!hidePagination">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterAsset.vue'
	export default {
		  components: {
              tableFilter,
		  },
		data: () => ({
            headers: [
            { text: 'ID', align: 'left',  sortable: true, value: 'id', width: 100 },
            { text: 'Name', value: 'name' },
            { text: 'Location', value: 'location', sortable: false,},
            { text: 'Criticality', value: 'criticality' },
            { text: 'QR Scan Required', value: 'qr_scan_required' },
            { text: 'Status', value: 'is_active' },
            { text: ''}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			assetList: [],
			loading: true,
            filter: false,
            filterDialog: false,
            filterData: {},
            filteredAsset: [],
            genericList: [],
            locationList: [],
            tooltipLoc: '',
            hidePagination: false,
        }),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
            assetTable: function() {
                if (Object.keys(this.filteredAsset).length >= 0 && this.filter == true) {
                    return this.filteredAsset
                } else {
                    return this.assetList;
                }
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getAssets(1).then((res) => {
                    this.assetList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getAssets(page) {
                try {
                    this.loading = true
                    const res = await this.$api.assetAsset.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'assetAssetForm', params: { id } })
            },
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    this.filterData = value;
			        this.$api.assetAsset.search(value).then((res) => {
                        console.log(res)
                        this.filteredAsset = res.data;
                        if (this.filterData.name) {
                            this.filteredAsset = this.filteredAsset.filter(asset => 
                                asset.name.toLowerCase().includes(this.filterData.name.toLowerCase())
                            )
                        }
                    });
                    this.hidePagination = true;
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
                this.hidePagination = false;
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.assetAsset.delete(id).then(res => this.list() );
                    }
                })
            },
            getAdditionalData() {
                this.getGenerics().then(res => this.genericList = res);
                this.getLocation().then(res => this.locationList = res);
            },
			async getGenerics() {
                try {
                    const res = await this.$api.assetGeneric.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getLocation() {
                try {
                    const res = await this.$api.location.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getLocName(id) {
                if ( this.locationList.length !== 0 && id ) {
                    return this.locationList.find(level1 => level1.id === id).name;
                } else {
                    return;
                }
            },
            getLocAncestor(id) {
                this.getLoc(id).then((res) => {
                    this.tooltipLoc = [];
                    for(var i in res.ancestor) {
                        var value = res.ancestor[i].name;
                        this.tooltipLoc.push(value)
                    }
                    this.tooltipLoc = this.tooltipLoc.join(' > ');
                });
            },
			async getLoc(id) {
                try {
                    const res = await this.$api.location.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getCrititcalityName (val) {
                if (val == 1) {
                    return "C1";
                } else if (val == 2) {
                    return "C2"
                } else {
                    return "C3"
                }
            },
            getQRScanRequired (val) {
                if (val) {
                    return "Yes";
                } else {
                    return "No"
                }
            },
            statusName () {
                if (true) {
                    return "Active";
                } else {
                    return "Inactive"
                }
            },
		},
		mounted () {
            this.list();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
            pagination: {
                handler(val){
                    this.getAssets(this.pagination.current).then((res) => {
                        this.assetList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>