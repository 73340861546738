<template>
    <v-container fluid class="list-level">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <div class="text-left">
                <v-btn v-if="currentDepth !== 0" small text color="grey" @click="loadPrevLoc(currentDepth-1)" >
                    <v-icon>mdi-chevron-up</v-icon> Up one level
                </v-btn>
            </div>
            <v-data-table
            v-if="locationList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="locationList"
            :sort-by="['id']"
            hide-default-footer
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}} 

                    <v-btn small fab icon color="grey" @click="loadNextLoc(row.item.depth+1, row.item.id, row.item.parent)" >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
              <tr v-if="row.item.child">

              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
        components: {
        },
		data: () => ({
            headers: [
            { text: 'ID',  align: 'left', sortable: true, value: 'id', width:100},
            { text: 'Name', value: 'name' },
            { text: '', sortable: false,}
            ],
            perPage: 20,
            success: false,
            levelSelected: 1,
            locationList: [],
            loading: true,
            loadingLevels: true,
            currentDepth: 0,
            parentCatId: 0,
            ancestor_id: 0,
		}),
        props: {
            globalPref: {
                type: Array,
                required: false
            }
        },
        computed: {
        },
		methods: {
            // Main Function
            list() {
			    this.getLocation().then((res) => {
                    this.locationList = res;
                });
            },
			async getLocation() {
                try {
                    this.loading = true
                    const res = await this.$api.location.search({depth: 0});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            loadNextLoc(depth, parent_id) {
			    this.getChildLocation(depth, parent_id).then((res) => {
                    this.currentDepth = depth;
                    this.parentCatId = parent_id; 
                    this.locationList = res;
                    this.getLoc(parent_id).then((res) => {
                        this.ancestor_id = res.parent;
                    })
                });
            },
            loadPrevLoc(depth) {
                this.getLoc(this.parentCatId).then((res) => {
                    this.ancestor_id = res.parent;
                    if (depth == 0) {
                        this.getChildLocation(depth).then((res) => {
                            this.currentDepth = depth;
                            this.locationList = res;
                        });
                    } else {
                        this.getChildLocation(depth, this.ancestor_id).then((res) => {
                            this.currentDepth = depth;
                            this.parentCatId = this.ancestor_id;
                            this.locationList = res;
                        });
                    }
                })
            },
			async getLoc(id) {
                try {
                    const res = await this.$api.location.get( id );
                    return res.data
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getChildLocation(depth, parent_id) {
                try {
                    this.loadingLevels = true
                    const res = await this.$api.location.search({depth: depth, parent: parent_id });
                    return res.data
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingLevels = false
                }
            },
			async getChildLocation(depth, parent_id, name) {
                try {
                    this.loadingLevels = true
                    const res = await this.$api.location.search({depth: depth, parent: parent_id });
                    return res.data
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingLevels = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'locationForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.location.delete(id).then(res => this.list() );
                    }
                })
            }
		},
		mounted () {
			this.list();
		},
	}
</script>