import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#2db29a', // #E53935
          secondary: '#1b7c88', // #FFCDD2
          accent: '#2db29a', // #3F51B5
          background: '#fff',
        },
        dark: {
          primary: '#2db29a', // #E53935
          secondary: '#1b7c88', // #FFCDD2
          accent: '#2db29a', // #3F51B5
          background: '#343a40',
          toolbar: '#3a444e'
        },
      },
    },
});
