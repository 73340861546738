<template>
    <transition name="fade">
        <div v-if="isVisible" class="loader-overlay">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="151px" height="151px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<path fill="none" stroke="#2db29a" stroke-width="8" stroke-dasharray="159.08513549804687 97.50379272460938" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style="transform:scale(1);transform-origin:50px 50px">
  <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="2.6315789473684212s" keyTimes="0;1" values="0;256.58892822265625"></animate>
</path></svg>
                <p class="text-center">Loading {{ current }} out of {{ total }}</p>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            isVisible: {type: Boolean, required: true},
            current: {type: Number, required: true},
            total: {type: Number, required: true},
            text: {type: String, required: false, default: ""},
        },
}
</script>

<style lang="scss">
    .loader-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        display: flex;
        background-color: rgba(0, 0, 0, 0.7);
        align-items: center;
        justify-content: center;
        .loader {
            height: 320px;
            width: 320px;
        }
    }
    #app.theme--light .loader-overlay {
        background-color: rgba(255,255, 255, 0.7);
    }
    #app.theme--dark .loader-overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
</style>