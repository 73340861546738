<template>
    <v-container fluid class="list-workDetail">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="this.workDetail"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="this.sortedWorkDetail"
            :sort-by="['id']"
            :server-items-length="pagination.count"
            hide-default-footer
            calculate-widths=""
            :sort-desc="[true]"
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr class="cursor-pointer">
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.code}}</td>
                <td class="text-left pt-2 pb-2">
                    {{row.item.name}} 
                    <div v-if="row.item.description">
                         <v-tooltip bottom max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="text-caption text--secondary d-inline-block text-truncate" style="max-width: 500px" v-html="row.item.description"></span>
                            </template>
                            <span v-html="row.item.description"></span>
                        </v-tooltip>
                    </div>
                </td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		data: () => ({
            headers: [
            { text: 'ID', value: 'id', width: 100 },
            { text: 'Code', value: 'code', width: 100 },
            { text: 'Name', value: 'name' },
            { text: ''}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			workDetail: [],
			loading: true,
		}),
        computed: {
            sortedWorkDetail () {
                return this.workDetail;
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getWorkDetail(1).then((res) => {
                    this.workDetail = res.results;
                    this.pagination.next = res.next;
                    this.pagination.count = res.count;
                    this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getWorkDetail(page) {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceDetail.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            edit(id) {
                this.$router.push({ name: 'workDetailForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.invoiceDetail.delete(id).then(res => this.list() );
                    }
                })
            },
		},
		mounted () {
            this.list();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getWorkDetail(this.pagination.current).then((res) => {
                        this.workDetail = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>