<template>
<v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        small
        text
        class="mt-2 pa-0"
        >
        <v-icon>mdi-magnify</v-icon> Search Detail
        </v-btn>
    </template>
    <v-card>
        <v-toolbar
        dark
        color="primary"
        >
        <v-btn
            icon
            dark
            @click="show = false"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Search for Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-btn
            dark
            text
            @click="show = false"
            >
            Select
            </v-btn>
        </v-toolbar-items>
        </v-toolbar>
        <v-row>
            <v-col>
                <v-sheet class="pa-4 secondary">
                    <v-text-field
                        v-model="search"
                        label="Search Heading"
                        flat
                        solo
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                </v-sheet>
                <v-treeview
                    v-if="headingLoaded"
                    :items="headingList"
                    :active="detailSelected"
                    v-model="detailSelected"
                    :search="search"
                    :filter="headingFilter"
                    :open-all="hasParent"
                    hoverable
                    activatable
                    dense
                    selection-type="independent"
                    expand-icon="mdi-chevron-down"
                    on-icon="mdi-bookmark"
                    off-icon="mdi-bookmark-outline"
                    @update:active="changeHeading"
                >
                    <template v-slot:label="{ item }">
                        {{item.code}}: {{item.name}} 
                    </template>
                </v-treeview> 
        </v-col>
        <v-col
            class="d-flex text-center"
        >
            <v-scroll-y-transition mode="out-in">
            <div
                v-if="!availableDetail"
                class="title grey--text text--lighten-1 font-weight-light"
                style="align-self: center;"
            >
                Select a Heading
            </div>
            <v-card
                v-else
                class="pt-6"
                flat
                max-width="400"
            >
                <v-radio-group v-model="chosenDetail">
                <v-radio
                    v-for="(detail,index) in availableDetail"
                    :key="index"
                    :label="detail.code+': '+detail.name"
                    :value="detail.id"
                ></v-radio>
                </v-radio-group>
            </v-card>
            </v-scroll-y-transition>
        </v-col>
        </v-row>
    </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        detailIndex: Number || String,
        headingList: Array
    },
    data() {
        return {
            headingLoaded:false,
            // headingList:[],
            availableDetail:null,
            chosenDetail: null,
            detailSelected: null,
            show:false,
            search: null,
            caseSensitive: false,
            hasParent: false,
        }
    },
    computed: {
        headingFilter () {
            return this.caseSensitive
            ? (item, search, textKey) => item[textKey].indexOf(search) > -1
            : undefined
        },
    },
    mounted() {
        // this.getWorkHeadings().then((res) => {
        //     this.headingList = res;
        //     this.headingLoaded = true;
        // });
        this.headingLoaded = true;
    },
    methods:{
        // async getWorkHeadings() {
        //     try {
        //         const res = await this.$api.invoiceHeading.listAll();
        //         return res.data;
        //     } catch (error) {
        //         // handle error
        //     } finally {
        //     }
        // },
        changeHeading(e) {
            this.detailSelected = e;
            this.availableDetail = [];
            this.getDetailFromHeading(this.detailSelected[0]).then((res) => {
                this.availableDetail = res;
            });
        },
        async getDetailFromHeading(id) {
            try {
                const res = await this.$api.invoiceHeading.get(id);
                return res.data.detail_set;
            } catch (error) {
                // handle error
            } finally {
            }
        },
    },
    watch: {
        chosenDetail (val) {
            this.$emit('chosen', {value: val, detailIndex: this.detailIndex})
        },
    }
}
</script>