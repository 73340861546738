<template>
    <v-container fluid>
        <v-row >
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="this.mastParamList"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="this.mastParamList"
            :sort-by="['id']"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
            editMode: false,
            headers: [
            {
                text: 'ID',
                align: 'left',
                sortable: true,
                value: 'id',
            },
            { text: 'Name', value: 'name' },
            { text: '', sortable: false,}
            ],
			success: false,
			mastParamList: [],
			loading: true,
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
		}),
		methods: {
            // Main Function
            list() {
			    this.getMastParams(1).then((res) => {
                    this.mastParamList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getMastParams(page) {
                try {
                    this.loading = true
                    const res = await this.$api.mastParam.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'masterParameterForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.mastParam.delete(id).then(res => this.list() );
                    }
                })
            },
		},
		mounted () {
            this.list();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getMastParams(this.pagination.current).then((res) => {
                        this.mastParamList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>