<template>
    <v-container fluid class="list-level">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto">
            <v-form>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Parent:</label>
                            <v-card outlined class="scroll" max-height="300">
                                <v-sheet class="pa-4 secondary">
                                    <v-text-field
                                        v-model="search"
                                        label="Search Location"
                                        flat
                                        solo
                                        hide-details
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-sheet>
                                <v-card-text >
                                    <v-treeview
                                        v-if="locationLoaded"
                                        :items="locationListFull"
                                        :active="locationSelected"
                                        v-model="locationSelected"
                                        :search="search"
                                        :filter="locFilter"
                                        :open.sync="open"
                                        hoverable
                                        activatable
                                        :open-all="hasParent"
                                        dense
                                        selection-type="independent"
                                        expand-icon="mdi-chevron-down"
                                        on-icon="mdi-bookmark"
                                        off-icon="mdi-bookmark-outline"
                                        @update:active="changeLoc"
                                    ></v-treeview>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <subLocationTable v-if="this.id" :parentLocationId='this.id' v-on:editAnotherLocation="formSub" ></subLocationTable>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import subLocationTable from '@/components/work/location-table.vue';
	export default {
        components: {
              subLocationTable,
        },
		data: () => ({
            success: false,
            levelSelected: '',
            locationList0: [],
            locationList1: [],
            locationList2: [],
            locationList3: [],
            locationList4: [],
            locationListFull: [],
            maxLevel: 5,
            loading: true,
            input: {
                id: '',
                name: '',
                parent: null,
            },
            info: {
                depth: '',
                parent: {},
            },
            loading: true,
            locationSelected: null,
            locationLoaded:false,
            hasParent: false,
            search: null,
            caseSensitive: false,
		}),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
            id: Number   
        },
        computed: {
            locFilter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            },
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getLevel().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.locationSelected = JSON.parse(JSON.stringify(res.parent.toString().split().map(Number)));
                        this.info.depth = res.depth;
                        this.info.parent = res.parent_data;
                        if (res.parent) {
                            this.search = res.parent_data.name
                            this.hasParent = true;
                        }
                    });
                }
            },
			async getLevel() {
                try {
                    this.loading = true
                    const res = await this.$api.location.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            quitEdit() {
                this.$router.push({ name: 'locationList' })
            },
			save() {
                this.saveLevel().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'locationList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveLevel() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.location.save(this.input );
                    } else {
                        res = await this.$api.location.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getLocations() {
                this.getAllLoc().then((res) => {
                    this.locationListFull = res;
                    this.locationLoaded = true;
                });
                this.getLocationsbyDepth(0).then((res) => {
                    this.locationList0 = res;
                });
                this.getLocationsbyDepth(1).then((res) => {
                    this.locationList1 = res;
                });
                this.getLocationsbyDepth(2).then((res) => {
                    this.locationList2 = res;
                });
                this.getLocationsbyDepth(3).then((res) => {
                    this.locationList3 = res;
                });
                this.getLocationsbyDepth(4).then((res) => {
                    this.locationList4 = res;
                });
            },
			async getLocationsbyDepth(depth) {
                try {
                    const res = await this.$api.location.search({depth:depth});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAllLoc() {
                try {
                    const res = await this.$api.location.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeLoc(e) {
                this.locationSelected = e;
                this.input.parent = this.locationSelected[0];
            },
            formSub(e) {
                this.$router.push({ name: 'locationForm', params: { id: e } })
                this.getSubLocation(e).then((res) => {
                    this.input.id = res.id;
                    this.input.name = res.name;
                    this.input.parent = res.parent;
                });
            },
			async getSubLocation(id) {
                try {
                    this.loading = true
                    const res = await this.$api.location.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
		},
		mounted () {
            this.form();
            this.getLocations();
            this.success = true;
		},
        watch: {
            'id': function(newVal) {
                this.formSub(newVal);
            },
        }
	}
</script>