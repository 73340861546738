var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"list-complaintComplaint",attrs:{"fluid":""}},[(!_vm.editMode)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5 mx-auto text-right"},[(_vm.filter)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","text":"","dark":""},on:{"click":function($event){return _vm.removeFilter()}}},[_c('v-icon',[_vm._v("mdi-window-close")]),_vm._v(" Remove filter ")],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"secondary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter ")],1)]}}],null,false,3000713893),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('tableFilter',{attrs:{"profileRole":_vm.profileRole,"profileId":_vm.profileId,"filterFields":_vm.filterData,"userList":_vm.userList,"locationList":_vm.locationList},on:{"filteredData":_vm.tableFilter}})],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"secondary","disabled":_vm.complaintSelected.length < 1}},on),[_c('v-icon',[_vm._v("mdi-merge")]),_vm._v(" Merge ")],1)]}}],null,false,3241151408),model:{value:(_vm.mergeDialog),callback:function ($$v) {_vm.mergeDialog=$$v},expression:"mergeDialog"}},[_c('v-card',{staticClass:"pa-5"},[_c('h3',[_vm._v("Choose the parent:")]),_c('v-radio-group',{model:{value:(_vm.complaintSelectedParent),callback:function ($$v) {_vm.complaintSelectedParent=$$v},expression:"complaintSelectedParent"}},_vm._l((_vm.complaintSelected),function(complaint,index){return _c('v-radio',{key:index,attrs:{"label":complaint.name + ' (ID:' + complaint.id +')',"value":complaint.id}})}),1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","disabled":!_vm.complaintSelectedParent},on:{"click":function($event){return _vm.merge()}}},[_c('v-icon',[_vm._v("mdi-merge")]),_vm._v(" Merge ")],1)],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ")],1)],1),(this.complaintComplaint)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"ripple":false,"show-select":"","loading":this.loading,"loading-text":"Loading... Please wait","headers":_vm.profileRole == 'CUSTOMER' ? _vm.headersComp : _vm.headers,"items-per-page":_vm.perPage,"items":this.complaintComplaint,"hide-default-footer":"","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[(item.urgency)?_c('v-badge',{staticClass:"animate-fading",attrs:{"color":_vm.urgencyColor(item.urgency),"offset-x":"-5","offset-y":"-1","left":"","dot":""}}):_vm._e(),_vm._v(" "+_vm._s(item.urgency)+" "+_vm._s(item.name)+" ")],1)]}},{key:"item.complainer",fn:function(ref){
var item = ref.item;
return [(_vm.profileRole !== 'CUSTOMER')?_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.getUserName(item.complainer)))]):_vm._e()]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [(_vm.profileRole !== 'CUSTOMER')?_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.getLocationName(item.location)))]):_vm._e()]}},{key:"item.submit_ts",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm._f("datetimestamp")(item.submit_ts)))])]}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-right",staticStyle:{"width":"150px"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)]}}],null,false,3792749908),model:{value:(_vm.complaintSelected),callback:function ($$v) {_vm.complaintSelected=$$v},expression:"complaintSelected"}}):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-pagination',{staticClass:"mt-2 mb-2",attrs:{"total-visible":"7","length":_vm.pagination.pages},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}),_c('div',{staticClass:"subtitle"},[_vm._v("Total: "+_vm._s(_vm.pagination.count))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }