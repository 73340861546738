<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workDetail,i) in workDetailbyHeading" :key="i">
            <v-card-text>
                <v-row align="top">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            label="Name"
                            v-model="workDetail.name"
                            hide-details="auto"
                            :rules="nameRules"
                            :counter="50"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            label="Own Code"
                            v-model="workDetail.own_code"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-select
                        :items="unitList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Unit"  
                        v-model="workDetail.unit"
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                        <v-text-field
                            label="Unit Cost"
                            v-model="workDetail.unit_cost"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                         <v-switch inset v-model="workDetail.is_active" label="Status" readonly></v-switch>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" class="text-right">
                        <v-btn-toggle>
                            <v-btn small fab icon color="grey"  @click="goToDetail(workDetail.id)">
                            <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" :disabled="workDetail.name.length > 50" @click="save(workDetail.id, workDetail.name, workDetail.own_code, workDetail.unit, workDetail.unit_cost, workDetail.is_active, workDetail.description)">
                            <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" @click="remove(workDetail.index, workDetail.id)">
                            <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <wysiwyg
                            :content.sync="workDetail.description"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkDetail()">Add Detail</v-btn>
        </div>
    </div>
    
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import Vue from 'vue'
	export default {
        props: {
            headingId: {
                type: Number
            },
        },
        components: {
            wysiwyg
        },
		data() {
            return {
                id: [],
                loading: true,
                workDetailbyHeading: [],
                users:[],
                workScopeList: [],
                unitList: [],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 50) || 'Name must be less than 50 characters',
                ],
            }
        },
        methods: {
            list() {
                if (this.headingId) {
                    this.getWorkDetailsbyDetail().then((res) => {
                        this.workDetailbyHeading = res;
                        this.workDetailbyHeading.forEach((item, index) => { 
                            Vue.set(item,'index', index);
                        })
                    });
                }
                this.getAdditionalData();
            },
			async getWorkDetailsbyDetail() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceDetail.search({ heading: this.headingId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkDetail() {
                // this.$router.push({ name: 'workDetailForm', params: {headingId: this.headingId } })
                this.workDetailbyHeading.push({id: '', name: '', own_code: '', unit: '', is_active: true , heading: this.headingId, description: '' });
            },
            save(id, name, own_code, unit, unit_cost, is_active, description) {
                this.saveWorkDetail(id, name, own_code, unit, unit_cost, is_active, description).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
            goToDetail(id) {
                this.$router.push({ name: 'workDetailForm', params: { id } })
            },
			async saveWorkDetail(id, name, own_code, unit, unit_cost, is_active, description) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.invoiceDetail.save( {id: id, name: name, own_code: own_code, unit: unit, unit_cost: unit_cost, is_active: is_active, heading: this.headingId, description: description });
                    } else {
                        res = await this.$api.invoiceDetail.new({name: name, own_code: own_code, unit: unit, unit_cost:unit_cost, is_active: is_active, heading: this.headingId, description: description });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.invoiceDetail.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workDetailbyHeading, index)
                }
            },
            getAdditionalData() {
			    this.getUnitList().then((res) => {this.unitList = res;});
            },
			async getUnitList() {
                try {
                    const res = await this.$api.unitUnit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>