var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!this.loading)?_c('v-data-table',{staticClass:"full-width",attrs:{"headers":_vm.headersRecurranceTime,"sort-by":['id'],"sort-desc":[true],"items":_vm.indexedRecurrenceTime,"items-per-page":20,"footer-props":{
            itemsPerPageOptions: [20,40,60],
        }},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.recurrenceList,"item-value":"id","name":"item","item-text":"name","label":"Recurrance Time","hide-details":""},model:{value:(row.item.recurrence),callback:function ($$v) {_vm.$set(row.item, "recurrence", $$v)},expression:"row.item.recurrence"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.users,"item-value":"id","name":"item","item-text":"username","label":"Checker"},model:{value:(row.item.checker),callback:function ($$v) {_vm.$set(row.item, "checker", $$v)},expression:"row.item.checker"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.users,"item-value":"id","name":"item","item-text":"username","label":"Verifier"},on:{"change":function($event){return _vm.verifierSelect(row.item.index, row.item.checker, row.item.verifier)}},model:{value:(row.item.verifier),callback:function ($$v) {_vm.$set(row.item, "verifier", $$v)},expression:"row.item.verifier"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.optionAction,"item-value":"value","name":"item","item-text":"name","label":"Action","hide-details":""},model:{value:(row.item.action),callback:function ($$v) {_vm.$set(row.item, "action", $$v)},expression:"row.item.action"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Open","readonly":"","value":row.item.open_time}},on))]}}],null,true),model:{value:(_vm.openTimeMenu[row.item.id]),callback:function ($$v) {_vm.$set(_vm.openTimeMenu, row.item.id, $$v)},expression:"openTimeMenu[row.item.id]"}},[_c('v-time-picker',{attrs:{"locale":"en-in","no-title":""},on:{"input":function($event){_vm.openTimeMenu[row.item.id] = false}},model:{value:(row.item.open_time),callback:function ($$v) {_vm.$set(row.item, "open_time", $$v)},expression:"row.item.open_time"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Check Due","readonly":"","value":row.item.check_due_time}},on))]}}],null,true),model:{value:(_vm.checkDueTimeMenu[row.item.id]),callback:function ($$v) {_vm.$set(_vm.checkDueTimeMenu, row.item.id, $$v)},expression:"checkDueTimeMenu[row.item.id]"}},[_c('v-time-picker',{attrs:{"locale":"en-in","no-title":""},on:{"input":function($event){_vm.checkDueTimeMenu[row.item.id] = false}},model:{value:(row.item.check_due_time),callback:function ($$v) {_vm.$set(row.item, "check_due_time", $$v)},expression:"row.item.check_due_time"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Verify Due","readonly":"","value":row.item.verify_due_time}},on))]}}],null,true),model:{value:(_vm.verifyDueTimeMenu[row.item.id]),callback:function ($$v) {_vm.$set(_vm.verifyDueTimeMenu, row.item.id, $$v)},expression:"verifyDueTimeMenu[row.item.id]"}},[_c('v-time-picker',{attrs:{"locale":"en-in","no-title":""},on:{"input":function($event){_vm.verifyDueTimeMenu[row.item.id] = false}},model:{value:(row.item.verify_due_time),callback:function ($$v) {_vm.$set(row.item, "verify_due_time", $$v)},expression:"row.item.verify_due_time"}})],1)],1)],1)],1),_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[(row.item.id && row.item.recurrence && row.item.open_time && row.item.check_due_time && row.item.verify_due_time)?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"green darken-2"}},on),[_vm._v("mdi-clipboard-check-outline")])]}}],null,true)},[_c('span',[_vm._v(" This recurrence plan is valid. ")])]):_vm._e(),_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[(row.item.id && !row.item.checklist_generated && row.item.checklist_set.length == 0 && row.item.action !== 'GENERATE')?_c('v-btn',{attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, 'GENERATE', row.item.recurrence)}}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" Generate Checklist ")])]),_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[(row.item.id && row.item.checklist_generated && row.item.checklist_set.length > 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, 'GENERATE', row.item.recurrence)}}},[_c('v-icon',[_vm._v("mdi-reload-alert")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" Checklist for this recurrence are already created! ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.save(row.item.id, row.item.open_time, row.item.check_due_time, row.item.verify_due_time, row.item.checker, row.item.verifier, row.item.action, row.item.recurrence)}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1),_c('v-btn',{attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.remove(row.item.index, row.item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)])]}}],null,false,1653008345)}):_vm._e(),_c('div',[_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){return _vm.newRecurranceTime()}}},[_vm._v("Add Recurrence Time")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.alertCheckerVerifier),callback:function ($$v) {_vm.alertCheckerVerifier=$$v},expression:"alertCheckerVerifier"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Error!")]),_c('v-card-text',[_vm._v(" Verifier should not be the same with Checker! ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.removeVerifier()}}},[_vm._v(" Okay ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }