<template>
    <v-container fluid class="list-workCostCenter">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Cost Center</v-tab>
                    <v-tab v-if="this.input.id">Child: Detail</v-tab>
                    <v-tab-item>
                        <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="6" md="12">
                                    <v-text-field
                                        label="Name" 
                                        v-model="input.name"
                                        :rules="nameRules"
                                        :counter="50"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <costCenterDetailTable class="p-2" v-if="this.input.id" :costCenterId='this.input.id'></costCenterDetailTable>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import costCenterDetailTable from '@/components/work/cost-center-detail-table.vue';
	export default {
		  components: {
              costCenterDetailTable,
            wysiwyg
		  },
          props: ['id'],
		data: () => ({
            success: false,
            loading: false,
            saving: false,

            input:{
                id: '',
                name: '',
                abbreviation: '',
            },
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
		}),
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getWorkCostCenter().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                    });
                }
            },
			async getWorkCostCenter() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceCostCenter.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveWorkCostCenter().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workCostCenterList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveWorkCostCenter() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoiceCostCenter.save( this.input );
                    } else {
                        res = await this.$api.invoiceCostCenter.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workCostCenterList' })
            },
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>