<template>
    <div>
        <v-card outlined class="mb-4" v-for="(scopeInstruction,i) in indexedScopeInstruction" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4" md="8">
                        <v-text-field
                            label="Instruction Name *" 
                            v-model="scopeInstruction.name"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Sort Order" 
                            v-model="scopeInstruction.sort_order"
                            type="number"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <div v-html="scopeInstruction.note"></div>
                    </v-col>
                </v-row>
                <div class="subtitle">REQUIREMENTS</div>
                <v-row>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="scopeInstruction.require_note"
                            label="Note"
                            hide-details="auto"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="4" md="2">
                        <v-switch
                            v-model="scopeInstruction.require_image"
                            label="Image"
                            hide-details="auto"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(scopeInstruction.index, scopeInstruction.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(scopeInstruction.id, scopeInstruction.name, scopeInstruction.sort_order, scopeInstruction.require_note, scopeInstruction.require_image )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newScopeInstruction()">Add Task</v-btn>
        </div>
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
        props: {
            scopeId: {
                type: Number
            },
        },
        components: {
        EnlargeableImage
        },
		data() {
            return {
                id: [],
                loading: true,
                scopeInstructionbyScope: [],
                additionalDataLoaded: false,
            }
        },
        computed: {
            indexedScopeInstruction () {
                return this.scopeInstructionbyScope.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.scopeId) {
                    this.getScopeInstructionbyScope().then((res) => {
                        this.scopeInstructionbyScope = res;
                    });
                }
            },
			async getScopeInstructionbyScope() {
                try {
                    this.loading = true
                    const res = await this.$api.workScopeInstruction.search({ scope: this.scopeId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newScopeInstruction() {
                // this.$router.push({ name: 'scopeInstructionForm', params: {scopeId: this.scopeId } })
                var newSortOrder = 1;
                if (this.scopeInstructionbyScope.length > 0) {
                    newSortOrder = (this.scopeInstructionbyScope.reduce((max, obj) => obj.sort_order > max ? obj.sort_order : max, 0))+1;
                }
                this.scopeInstructionbyScope.push({id: '', name: '', scope: this.scopeId, sort_order: newSortOrder, require_status: false, require_reading: false, require_image: false });
            },
            save(id, name, sort_order, require_note, require_image ) {
                this.saveScopeInstruction(id, name, sort_order, require_note, require_image).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveScopeInstruction(id, name, sort_order, require_note, require_image) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workScopeInstruction.save( {id: id, name: name, sort_order: sort_order, require_note: require_note, require_image:require_image, scope: this.scopeId });
                    } else {
                        res = await this.$api.workScopeInstruction.new({name: name, sort_order: sort_order, require_note: require_note, require_image:require_image, scope: this.scopeId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workScopeInstruction.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.scopeInstructionbyScope, index)
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>