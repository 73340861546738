<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderDetail,i) in indexedWorkOrderDetail" :key="i">
            <v-card-text>
                <div class="text-caption">{{workOrderDetail.detailLabel}}</div>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                        :items="detailList" 
                        item-value="id" 
                        name="item"
                        item-text="name"
                        label="Detail" 
                        v-model="workOrderDetail.detail"
                        hide-details="auto"
                        readonly
                        @change="refreshUnit(workOrderDetail.index, workOrderDetail.detail)"
                        ></v-autocomplete>
                        <HeadingDialog :headingList="headingList" :showProp="workOrderDetail.detailDialog" @chosen="changeDetailValue" :detailIndex="workOrderDetail.index"></HeadingDialog>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-text-field
                            label="Quantity"
                            v-model="workOrderDetail.quantity"
                            type="number"
                            hide-details="auto"
                            :suffix="workOrderDetail.unit"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-tooltip right v-if="workOrderDetail.detail">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="goToDetailForm(workOrderDetail.detail)"
                            >
                            <v-icon>mdi-text</v-icon>
                            </v-btn>
                        </template>
                        <span>Click to enter detail form, please save first.</span>
                        </v-tooltip>
                    </v-col>
                    <v-col  cols="12" sm="3" md="2" class="text-right">
                        <!-- <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workOrderDetail.index, workOrderDetail.id)">mdi-delete</v-icon></v-btn> -->
                        <v-btn color="primary" @click="save(workOrderDetail.id, workOrderDetail.detail, workOrderDetail.quantity, workOrderDetail.newimage_1, workOrderDetail.newimage_2, workOrderDetail.newimage_3, workOrderDetail.newimage_4, workOrderDetail.newimage_5, workOrderDetail.newimage_6 )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row><v-row>
                    <v-col cols="12" md="12">
                        <label>Images <span v-if="workOrderDetail.require_image">( required )</span></label><br/>
                        <v-row>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_1" :src="workOrderDetail.image_1" :src_large="workOrderDetail.image_1" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 1"
                                    v-model="workOrderDetail.newimage_1"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_2" :src="workOrderDetail.image_2" :src_large="workOrderDetail.image_2" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 2"
                                    v-model="workOrderDetail.newimage_2"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_3" :src="workOrderDetail.image_3" :src_large="workOrderDetail.image_3" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 3"
                                    v-model="workOrderDetail.newimage_3"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_4" :src="workOrderDetail.image_4" :src_large="workOrderDetail.image_4" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 4"
                                    v-model="workOrderDetail.newimage_4"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_5" :src="workOrderDetail.image_5" :src_large="workOrderDetail.image_5" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 5"
                                    v-model="workOrderDetail.newimage_5"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderDetail.image_6" :src="workOrderDetail.image_6" :src_large="workOrderDetail.image_6" />
                                <v-img class="ba-1" contain v-if="!workOrderDetail.image_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 6"
                                    v-model="workOrderDetail.image_6"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkOrderDetail()">Add Detail</v-btn>
        </div>
    </div>
    
</template>
<script>
import Vue from 'vue'
import HeadingDialog from '@/components/work/work-order-detail-dialog.vue'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
	export default {
        props: {
            workOrderId: {
                type: Number
            },
        },
        components:{
            HeadingDialog,
            EnlargeableImage
        },
		data() {
            return {
                id: [],
                loading: true,
        visible: false,
        index: 0 ,
                workOrderDetailsbyOrder: [],
                detailList: [],
                additionalDataLoaded: false,
                iconRules: [
                    v => !v || v.size < 2000000 || 'Image size should be less than 2 MB!',
                ],
                headingLoaded:false,
                hasParent: false,
                search: null,
                caseSensitive: false,
                headingList:[],
                availableDetail:null,
            }
        },
        computed: {
            indexedWorkOrderDetail () {
                return this.workOrderDetailsbyOrder.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderDetailsbyChecklist().then((res) => {
                        res.forEach((item, index) => { 
                            Vue.set(item,'detailDialog', false);
                            Vue.set(item,'newimage_1', null);
                            Vue.set(item,'newimage_2', null);
                            Vue.set(item,'newimage_3', null);
                            Vue.set(item,'newimage_4', null);
                            Vue.set(item,'newimage_5', null);
                            Vue.set(item,'newimage_6', null);
                            this.getDetailUnit(index, item.detail);
                        })
                        this.workOrderDetailsbyOrder = res;
                    });
                }
            },
			async getWorkOrderDetailsbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderDetail.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderDetail() {
                // this.$router.push({ name: 'workOrderDetailForm', params: {workOrderId: this.workOrderId } })
                this.workOrderDetailsbyOrder.push({id: '', detail: '', quantity: 0, order: this.workOrderId, detailDialog: false });
            },
            save(id, detail, quantity, image_1, image_2, image_3, image_4, image_5, image_6 ) {
                this.saveWorkOrderDetail(id, detail, quantity, image_1, image_2, image_3, image_4, image_5, image_6 ).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderDetail(id, detail, quantity, image_1, image_2, image_3, image_4, image_5, image_6 ) {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    if (id) {
                    formData.append("id", id);
                    }
                    formData.append("quantity", quantity);
                    formData.append("detail", detail);
                    if (image_1) {
                        formData.append("image_1", image_1 );
                    }
                    if (image_2) {
                        formData.append("image_2", image_2 );
                    }
                    if (image_3) {
                        formData.append("image_3", image_3 );
                    }
                    if (image_4) {
                        formData.append("image_4", image_4 );
                    }
                    if (image_5) {
                        formData.append("image_5", image_5 );
                    }
                    if (image_6) {
                        formData.append("image_6", image_6 );
                    }
                    formData.append("order", this.workOrderId);
                    if (id) {
                        res = await this.$api.workOrderDetail.saveForm( id, formData );
                    } else {
                        res = await this.$api.workOrderDetail.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderDetail.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderDetailsbyOrder, index)
                }
            },
            getAdditionalData() {
                this.getDetails().then((res) => {
                    this.detailList = res
                });
                this.getWorkHeadings().then((res) => {
                    this.headingList = res;
                });
            },
            async getWorkHeadings() {
                try {
                    const res = await this.$api.invoiceHeading.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getDetails() {
                try {
                    const res = await this.$api.invoiceDetail.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshUnit(index, detail) {
                this.getDetailUnit(index, detail);
            },
            getDetailUnit(index, id) {
                this.getDetailInfo(id).then((res) => {
                    Vue.set(this.workOrderDetailsbyOrder[index],'unit', res.unit_data.symbol);
                    Vue.set(this.workOrderDetailsbyOrder[index],'detailLabel', res.heading_path);
                });
            },
            async getDetailInfo(id) {
                try {
                    const res = await this.$api.invoiceDetail.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeDetailValue({ value, detailIndex }) {
                Vue.set(this.workOrderDetailsbyOrder[detailIndex],'detail', value);
                this.refreshUnit(detailIndex, value)
            },
            goToDetailForm(id) {
                this.$router.push({ name: 'workDetailForm', params: { id } })
            }
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>