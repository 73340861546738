<template>
	<v-row style="background-color:#fff" class="vh-100" align="center" justify="center">
		<v-col cols="12" sm="12" md="12" lg="8" align="center">
			<v-progress-circular
			v-if="status == 'spin'"
			:size="50"
			color="primary"
			indeterminate
			></v-progress-circular>
			<v-icon v-if="status == 'success'">mdi-check</v-icon>
			<v-icon v-if="status == 'fail'">mdi-close-circle-outline</v-icon>
			<div class="mt-2">{{progressText}}</div>
		</v-col>
	</v-row>
</template>
<style scoped>
.v-icon {
	font-size: 30px;
}
</style>
<script>
	export default {
		data() {
			return {
				progressText: 'Verifying your email now...',
				status: 'spin',
				verifyObj: {}
			}
		},
		computed: {

		},
		methods: {
            verify(){
                this.verifyEmail().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
						this.status = 'success';
                    	this.progressText = 'Verified! Redirecting you to login...';
						setTimeout(() => this.goToLogin(), 2000);
                    }
                })
            },
			async verifyEmail() {
                try {
                    let res;
                    this.saving = true
					res = await this.$api.accountProfile.verifyRegister( this.verifyObj );
                    return res;
                } catch (error) {
					this.status = 'fail';
                    this.progressText = 'One of the detail is wrong, please contact administrator or reset again.'
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			goToLogin() {
                this.$router.push({ name: 'login' })
			}
		},
		mounted() {
			this.verifyObj = this.$route.query;
			setTimeout(() => this.verify(), 2000);
		}
	}
</script>