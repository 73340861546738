<template>
    <div>
        <v-data-table 
            v-if="!this.loading"
            :headers="headersWorkPlan"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedRecurrenceTime"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                            :items="recurrenceList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Recurrance Time" 
                            v-model="row.item.recurrence"
                            hide-details="auto"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-select
                            :items="assetList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Asset" 
                            v-model="row.item.asset"
                            hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="3">
                            <v-text-field
                                label="Open Time"
                                v-model="row.item.open_timedelta"
                            ></v-text-field>
                            <v-dialog
                            v-model="dialogTimeHelp[row.item.id]"
                            width="800"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="red lighten-2"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon class="mr-2">mdi-information-outline</v-icon> Important Information
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline grey lighten-2">
                                Important Note
                                </v-card-title>

                                <v-card-text>
                                    <div class="pt-2 pb-2">Correct Format: D HH:MM:ss</div>
                                    Example: <br/>
                                    1 hour = 01:00:00<br/>
                                    6 hours = 06:00:00<br/>
                                    1 day = 1 00:00:00<br/>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialogTimeHelp[row.item.id] = false"
                                >
                                    I understand
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </v-col>
                        <v-col cols="6" sm="3">
                            <v-text-field
                                label="Due Time"
                                v-model="row.item.due_timedelta"
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <v-select
                            :items="optionAction" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Action" 
                            v-model="row.item.action"
                            hide-details="auto"
                            >
                            </v-select>
                        </v-col> -->
                    </v-row>
                </td>
                <td>
                    <v-row no-gutters>
                        <v-col cols="auto">
                            <v-btn-toggle>
                                <v-dialog
                                v-model="dialogOrder[row.item.id]"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    fab
                                    @click="openDialogOrder(row.item.id)"
                                    >
                                    <v-icon>mdi-clipboard-text-search-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar
                                    dark
                                    color="primary"
                                    >
                                    <v-btn
                                        icon
                                        dark
                                        @click="dialogOrder[row.item.id] = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>List of Work Orders Generated</v-toolbar-title>
                                    </v-toolbar>
                                    <v-container fluid>
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                :items="statusOptions" 
                                                item-value="value"
                                                name="item"
                                                item-text="name"
                                                label="Status" 
                                                v-model="filterFields.status"
                                                @change="orderFilter(row.item.id, row.item.orders)"
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                :items="userList" 
                                                item-value="id"
                                                name="item"
                                                item-text="username"
                                                label="Supervisor" 
                                                v-model="filterFields.supervisor"
                                                @change="orderFilter(row.item.id, row.item.orders)"
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                :items="userList" 
                                                item-value="id"
                                                name="item"
                                                item-text="username"
                                                label="Worker" 
                                                v-model="filterFields.worker"
                                                @change="orderFilter(row.item.id, row.item.orders)"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>


                                    <v-list
                                    three-line
                                    subheader v-if="row.item.orders"
                                    >
                                    <v-list-item v-for="(order, index) in row.item.orderFiltered" v-bind:key="index" style="border-bottom:1px solid #eee">
                                        <v-list-item-content>
                                        <v-list-item-title>Order ID: {{order.id}}</v-list-item-title>
                                        <v-list-item-subtitle>Status: {{order.status}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Open: {{order.open_dt | moment_dtstamp}}</v-list-item-subtitle>
                                        <v-list-item-subtitle> Due: {{order.due_dt | moment_dtstamp}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                        <v-btn icon @click="goToOrder(row.item.id)">
                                            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                                        </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                    </v-list>
                                </v-card>
                                </v-dialog>
                                <v-btn small fab @click="generateOrders(row.item.index, row.item.id)"><v-icon>mdi-alarm-plus</v-icon></v-btn>
                                <v-btn small fab @click="deleteOrders(row.item.index, row.item.id)"><v-icon>mdi-close-circle-multiple-outline</v-icon></v-btn>
                                <v-btn small fab  @click="save(row.item.id,row.item.asset, row.item.open_timedelta, row.item.due_timedelta, row.item.action, row.item.recurrence)"><v-icon>mdi-content-save</v-icon></v-btn>
                                <v-btn small fab @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newWorkPlan()">Add Recurrence Time</v-btn></div>
        <v-dialog
        persistent
        v-model="alertCheckerVerifier"
        max-width="290"
        >
        <v-card>
            <v-card-title class="headline">Error!</v-card-title>
            <v-card-text>
            Verifier should not be the same with Checker!
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary darken-1"
                text
                @click="removeVerifier()"
            >
                Okay
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    
</template>
<script>
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker/src'
	export default {
		  components: {
              VueTimepicker
          },
        props: {
            workTemplateId: {
                type: Number
            },
            globalPref: Array,
        },
		data() {
            return {
                headersWorkPlan: [
                { text: 'Recurrence', align: 'left', sortable: true, value: 'recurrence', },
                { text: ''}
                ],
                id: [],
                alertCheckerVerifier: false,
                loading: true,
                openTimeMenu: {},
                dueTimeMenu: {},
                dialogOrder: {},
                dialogTimeHelp: {},
                filterFields:{},
                userList: [],
                statusOptions: [
                    {
                        "value": "DRAFT",
                        "name": "draft",
                    },
                    {
                        "value": "OPEN",
                        "name": "open"
                    },
                    {
                        "value": "WIP",
                        "name": "work in progress"
                    },
                    {
                        "value": "RECORDED",
                        "name": "recorded",
                    },
                    {
                        "value": "CLAIMED",
                        "name": "claimed"
                    },
                    {
                        "value": "VERIFIED",
                        "name": "verified",
                    },
                    {
                        "value": "COMPILED",
                        "name": "compiled",
                    },
                    {
                        "value": "VETTED",
                        "name": "vetted",
                    },
                    {
                        "value": "AUDITED",
                        "name": "audited",
                    }
                ],
                recurrenceList: [],
                workPlanList: [],
                workTemplate: 0,
                assetList:[],
                optionAction: [
                    {
                        value: "generate",
                        name: "Generate"
                    },
                    {
                        value: "remove",
                        name: "Remove"
                    },
                ],
                openTime: null,
                dueTime: null,
            }
        },
        computed: {
            indexedRecurrenceTime () {
                return this.workPlanList
                .map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getWorkPlanbyCheckplan().then(res => this.workPlanList = res);
            },
			async getWorkPlanbyCheckplan() {
                try {
                    this.loading = true
                    const res = await this.$api.workPlan.search({ template: this.workTemplateId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getRecurrences().then(res => this.recurrenceList = res);
			    this.getAssets().then(res => this.assetList = res);
				this.getUsers().then(res => this.userList = res);
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
				try {
					this.loading = true
					const res = await this.$api.user.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            newWorkPlan() {
                if (Object.keys(this.globalPref).length > 0) {
                    this.openTime = this.$moment(this.globalPref.find(pref => pref.identifier == 'plan__open_timedelta').value, 'HH:mm:ss').format('HH:mm:ss');
                    this.dueTime = this.$moment(this.openTime, "HH:mm:ss").add(this.$moment.duration(this.globalPref.find(pref => pref.identifier == 'plan__due_timedelta').value)).format('HH:mm:ss');
                    console.log(this.globalPref);
                } else {
                    this.openTime = this.$moment().format("hh:mm A");
                    this.dueTime = this.$moment().format("hh:mm A");
                }
                this.workPlanList.push({
                    id: '', 
                    asset: '', 
                    open_timedelta: this.openTime, 
                    due_timedelta: this.dueTime, 
                    action: '', 
                    recurrence: ''
                });
            },
            save(id, asset, open_timedelta, due_timedelta, action, recurrence) {
                this.saveWorkPlan(id, asset, open_timedelta, due_timedelta, action, recurrence).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                            this.updateCheckplan();
                        })
                    }
                });
			},
			async saveWorkPlan(id, asset, open_timedelta, due_timedelta, action, recurrence) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workPlan.save( {id: id, asset: asset, open_timedelta: open_timedelta, due_timedelta: due_timedelta, action: action, recurrence: recurrence, template: this.workTemplateId });
                    } else {
                        res = await this.$api.workPlan.new({asset: asset, open_timedelta: open_timedelta, due_timedelta: due_timedelta, action: action, recurrence: recurrence, template: this.workTemplateId} );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workPlan.delete(id).then((res) => {
                                this.list();
                                this.updateCheckplan();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workPlanList, index)
                }
            },
            generateOrders(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Do you want to generate orders?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                        showLoaderOnConfirm: true,
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: () => {
                            return this.$api.workPlan.generateOrders(id)
                        },
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.$swal({
                                icon: 'success',
                                title: 'Operation Complete',
                                html:
                                result.value.data.created_count +' orders created!<br/>' +
                                result.value.data.updated_count +' orders updated!<br/>',
                                confirmButtonText: 'Ok',
                            }).then((result) => {
                                this.list();
                                this.updateCheckplan();
                            })
                        }
                    })
                }
            },
            deleteOrders(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Do you want to delete generated orders?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                        this.$swal.showLoading();
                            this.$api.workPlan.deleteOrders(id).then((res) => {
                                this.$swal.hideLoading()
                                this.list();
                                this.updateCheckplan();
                            });
                        }
                    })
                }
            },
            updateCheckplan(event){
                this.$emit('updateCheckplan')
            },
            verifierSelect(index, checker, verifier) {
                if (checker && verifier) {
                    if (checker == verifier) {
                        this.alertCheckerVerifier = true;
                        this.faultyVerifier = index;
                    }
                }
            },
            removeVerifier(index) {
                this.workPlanList[this.faultyVerifier].verifier = null;
                this.indexedRecurrenceTime[this.faultyVerifier].verifier = null;
                this.alertCheckerVerifier = false;
                this.faultyVerifier = null;
            },
            openDialogOrder(id, index) {
                this.$api.workPlan.getOrders(id).then((res) => {
                    Vue.set(this.workPlanList.find(workplan => workplan.id == id),'orders', res.data);
                    Vue.set(this.workPlanList.find(workplan => workplan.id == id),'orderFiltered', res.data);
                });
            },
            orderFilter(id, orders){
                var filteredOrders = orders.filter(
                    order => {
                        let keep = true;
                        if (this.filterFields.status) {
                            keep = keep && order.status == this.filterFields.status;
                        }
                        if (this.filterFields.worker) {
                            keep = keep && order.worker == this.ilterFields.worker;
                        }
                        if (this.filterFields.supervisor) {
                            keep = keep && order.supervisor == this.filterFields.supervisor;
                        }
                        return keep;
                    }
                )
                Vue.set(this.workPlanList.find(workplan => workplan.id == id),'orderFiltered', filteredOrders);
            },
            goToOrder(id) {
                this.$router.push({ name: 'workOrderForm', params: { id } })
            }
        },
        mounted(){
            this.getAdditionalData();
            this.list();
            this.loading = false;
        }
    }
</script>