<template>
    <v-container fluid class="list-workHeading">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto">
                <v-form lazy-validation
                ref="form"
                v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Code" 
                                v-model="input.own_code"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="8">
                            <label>Parent:</label>
                            <v-card outlined class="scroll" max-height="300">
                                <v-sheet class="pa-4 secondary">
                                    <v-text-field
                                        v-model="search"
                                        label="Search Heading"
                                        flat
                                        solo
                                        hide-details
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-sheet>
                                <v-card-text >
                                    <v-treeview
                                        v-if="headingLoaded"
                                        :items="headingListFull"
                                        :active="headingSelected"
                                        v-model="headingSelected"
                                        :search="search"
                                        :filter="headingFilter"
                                        hoverable
                                        activatable
                                        :open-all="hasParent"
                                        dense
                                        selection-type="independent"
                                        expand-icon="mdi-chevron-down"
                                        on-icon="mdi-bookmark"
                                        off-icon="mdi-bookmark-outline"
                                        @update:active="changeHeading"
                                    ></v-treeview>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <label>Code:</label><br/>
                            {{info.code}}
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <v-tabs >
                <v-tabs-slider/>
                <v-tab>Sub Heading</v-tab>
                <v-tab :disabled="!this.input.id">Detail</v-tab>
                <v-tab-item>
                    <subHeadingTable v-if="this.id" :parentHeadingId='this.id' v-on:editAnotherHeading="formSub" ></subHeadingTable>
                </v-tab-item>
                <v-tab-item>
                    <headingDetailTable v-if="this.id" :headingId='this.id' ></headingDetailTable>
                </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import wysiwyg from '@/components/ui/wysiwyg.vue';
import headingDetailTable  from '@/components/work/heading-detail-table.vue';
import subHeadingTable from '@/components/work/heading-table.vue';
	export default {
		  components: {
              subHeadingTable,
              headingDetailTable,
            wysiwyg
		  },
          props: ['id'],
		data: () => ({
            success: false,
            loading: false,
            saving: false,

            input:{
                id: '',
                name: '',
                own_code: '',
            },
            info: {
                code: ''
            },
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            headingList: [],
            headingListFull: [],
            headingLoaded: false,
            headingSelected: null,
            hasParent: false,
            search: null,
            caseSensitive: false,
		}),
        computed: {
            headingFilter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            },
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getWorkHeading().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.own_code = res.own_code;
                        this.info.code = res.code;
                        this.input.parent = res.parent;
                        this.input.description = res.description;
                        this.headingSelected = JSON.parse(JSON.stringify(res.parent.toString().split().map(Number)));
                        if (res.have_children) {

                        }
                    });
                }
            },
			async getWorkHeading() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceHeading.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            formSub(e) {
                this.$router.push({ name: 'workHeadingForm', params: { id: e } })
                this.getSubWorkHeading(e).then((res) => {
                    this.input.id = res.id;
                    this.input.name = res.name;
                    this.input.own_code = res.own_code;
                    this.info.code = res.code;
                    this.input.parent = res.parent;
                    this.input.description = res.description;
                    if (res.have_children) {

                    }
                });
            },
			async getSubWorkHeading(id) {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceHeading.get(id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveWorkHeading().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'workHeadingList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveWorkHeading() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.invoiceHeading.save( this.input );
                    } else {
                        res = await this.$api.invoiceHeading.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workHeadingList' })
            },
            getWorkHeadingList() {
			    // this.getWorkHeadings().then((res) => {
                //     this.headingList = res;
                // });
                this.getWorkHeadings().then((res) => {
                    this.headingListFull = res;
                    this.headingLoaded = true;
                });
            },
			// async getWorkHeadings() {
            //     try {
            //         const res = await this.$api.invoiceHeading.list();
            //         return res.data;
            //     } catch (error) {
            //         // handle error
            //     } finally {
            //     }
            // },
			async getWorkHeadings() {
                try {
                    const res = await this.$api.invoiceHeading.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            changeHeading(e) {
                this.headingSelected = e;
                this.input.parent = this.headingSelected[0];
            }
		},
		mounted () {
            this.form();
            this.getWorkHeadingList();
            this.success = true;
		},
        watch: {
            'id': function(newVal) {
                this.formSub(newVal);
            },
        }
	}
</script>