<script>
import { Line } from 'vue-chartjs'

export default {
	extends: Line,
	props: {
		chartdata: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			chartDatas: {},
			options: {},
		}
	},
	mounted () {
		this.setChartData();
	},
	methods: {
		setChartData() {
			this.chartDatas = this.chartdata.data;
			this.options = this.chartdata.options;
			this.renderChart(this.chartDatas, this.options)
		}
	},
	watch: {
		chartdata: function() {
			this.setChartData();
		}
	}
}
</script>