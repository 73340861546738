<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <userForm v-if="!loading" :userId="userId" v-on:return-form="quitEdit"></userForm>
            <v-divider light></v-divider>
            
            <v-tabs 
      dark
      background-color="teal primary">
                <v-tabs-slider />
                <v-tab v-if="id">Complaints</v-tab>
                <v-tab v-if="id">Work Orders as Supervisor</v-tab>
                <v-tab v-if="id">Work Orders as Worker</v-tab>
                <v-tab-item>
                    <userUtilityTable dataType="complainCreator" :userId="userId"></userUtilityTable>
                </v-tab-item>
                <v-tab-item>
                    <userUtilityTable dataType="orderSupervisor" :userId="userId"></userUtilityTable>
                </v-tab-item>
                <v-tab-item>
                    <userUtilityTable dataType="orderWorker" :userId="userId"></userUtilityTable>
                </v-tab-item>
            </v-tabs>
                <!-- <teamTable v-if="!loading" :userId="userId" v-on:return-form="quitEdit"></teamTable> -->
            </v-card>
            
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import teamTable from '@/components/table/team-table.vue';
import userForm from '@/components/form/user-form.vue';
import userUtilityTable from '@/components/table/user-utility-table.vue';

	export default {
		  components: {
              teamTable,
              userForm,
              userUtilityTable
		  },
        props: {
            id: Number   
        },
		data: () => ({
            loading: true,
            userId: 0
		}),
		methods: {
            // Main Function
			quitEdit() {
                // console.log(this.$router)
                this.$router.push({ name: 'userList' })
            },
		},
		mounted () {
            this.userId = this.id;
            this.loading = false;
		},
	}
</script>