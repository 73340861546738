<template>
    <v-container fluid class="list-workOrder">
        <v-row>
        <v-col cols="12">
            <div class="mx-auto text-right" v-if="workerMode == 'supervisor'">
                <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                    <v-icon>mdi-window-close</v-icon>
                    Remove filter
                </v-btn>
                <v-dialog v-model="filterDialog" max-width="800">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" dark v-on="on" class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </template>
                    <v-card>
                        <tableFilter :filterFields="filterData"  v-on:filteredData="tableFilter" :scopeList="scopeList" :costCenterList="costCenterList" :userList="userList"></tableFilter>
                    </v-card>
                </v-dialog>
            </div>
        </v-col>
        <v-col cols="12">
            <div>
                <v-tabs
                v-if="profileRole !== 'VENDOR'"
                v-model="workerModeIndex"
                background-color="primary"
                show-arrows 
                center-active
                >
                    <v-tab
                        v-for="(item, index) in workerModeTab"
                        :key="index"
                    >
                        {{ item.text }}
                    </v-tab>
                </v-tabs>
                 <v-tabs-items v-model="workerMode" >
                    <v-tab-item v-for="(item, index) in workerModeTab" :key="index" :value="item.value">
                    </v-tab-item>
                 </v-tabs-items>
                <!-- <v-btn-toggle
                    v-model="workerMode"
                    borderless
                >
                    <v-btn value="worker">
                        <span>Worker</span>
                    </v-btn>

                    <v-btn value="supervisor">
                        <span>Supervisor</span>
                    </v-btn>
                </v-btn-toggle> -->
            </div>
            <v-data-table
            v-if="this.workOrder"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="workerMode == 'worker' ? headers : headersSV"
            :items="this.workOrder"
            :items-per-page="perPage"
            :options.sync="options"
            hide-default-footer
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr class="cursor-pointer" @click="answerWorkOrder(row.item.id)">
                <td class="text-left pl-6">
                    <v-badge
                    class="animate-fading"
                    v-if="!row.item.is_ontime"
                    color="red"
                    offset-x="-5"
                    offset-y="-1"
                    left
                    dot
                    >
                    </v-badge>
                    {{row.item.id}} 
                </td>
                <td class="text-left">
                    {{row.item.name}} 
                </td>
                <td class="text-left"><span v-if="row.item.costcenter">{{ getCostCenterName(row.item.costcenter) }}</span></td>
                <td class="text-left"><span v-if="row.item.scope">{{ getScopeName(row.item.scope) }}</span></td>
                <td class="text-left" v-if="workerMode == 'worker'"><span v-if="row.item.supervisor">{{ getUserName(row.item.supervisor) }}</span></td>
                <td class="text-left" v-if="workerMode == 'supervisor'"><span v-if="row.item.supervisor">{{ getUserName(row.item.worker) }}</span></td>
                <td class="text-left"><span v-if="row.item.net">{{ row.item.net }}</span></td>
                <td class="text-left" v-if="workerMode == 'worker'"><span v-if="row.item.due_dt">{{ row.item.due_dt | moment_dtstamp }}</span></td>
                <td class="text-left"><span v-if="row.item.open_dt">{{ row.item.due_dt | moment_dtstamp }}</span></td>
                <td class="text-left" v-if="workerMode == 'supervisor'"><span v-if="row.item.done_ts">{{ row.item.done_ts | moment_dtstamp }}</span></td>
                <td class="text-left">{{row.item.status}}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterWorkRecord.vue'
	export default {
		data: () => ({
            editMode: false,
            months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
            existingYearMonths: [],
            existingWorkPrograms: [],
            yearMonthSelected: '',
            programSelected: '',
            otherWorkOrder : [],
            options:{},
            headers: [
            { text: 'ID', value: 'id', width: 100 },
            { text: 'Title', value: 'name' },
            { text: 'Cost Center', value: 'costcenter' },
            { text: 'Scope', value: 'scope' },
            { text: 'Supervisor', value: 'supervisor' },
            { text: 'Net', value: 'net' },
            { text: 'Open Datetime', value: 'open_dt', width:200 },
            { text: 'Due Datetime', value: 'due_dt', width:200  },
            { text: 'Status', value: 'status' },
            ],
            headersSV: [
            { text: 'ID', value: 'id', width: 100 },
            { text: 'Title', value: 'name' },
            { text: 'Cost Center', value: 'costcenter' },
            { text: 'Scope', value: 'scope' },
            { text: 'Worker', value: 'worker' },
            { text: 'Net', value: 'net' },
            { text: 'Due Datetime', value: 'due_dt', width:200  },
            { text: 'Done Datetime', value: 'done_dt', width:200 },
            { text: 'Status', value: 'status' },
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			workOrder: [],
			loading: true,
            inputId: '',
            inputName: '',
            loading: true,
            workOrderId: 0,
            userList: [],
            costCenterList: [],
            scopeList: [],
            today: null,
            setDate: null,
            menu: false,
            filter:false,
            filterDialog: false,
            filterData: {},
            programList: [],
            loadingWorkOrderCalendar: false,
            workerModeIndex: 0,
            workerModeTab: [
                {text: 'supervisor', value: 'supervisor'},
                {text: 'worker', value: 'worker'}, 
            ],
		}),
        components: {
            tableFilter,
        },
        computed: {
			profileId: function() {
				return this.$store.getters.profileId;
            },
			profileRole: function() {
				return this.$store.getters.profileRole;
            },
            workerMode: function() {
                if (this.workerModeIndex == 1) {
                    return 'worker'
                }
                if (this.workerModeIndex == 0) {
                    return 'supervisor'
                }
            },
            datatableOrdering: function() {
                if (this.options.sortBy.length > 0) {
                    if ( this.options.sortDesc[0] == false) {
                        return this.options.sortBy[0];
                    } else {
                        return '-' + this.options.sortBy[0];
                    }   
                } else {
                    return '-due_dt';
                }
            },
            filterDataWithPaginationAll: function() {
                var newObj = Object.entries(this.filterData).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                return newObj;
            },
            filterDataWithPagination: function() {
                var newObj = Object.entries(this.filterData).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                newObj.supervisor = this.profileId;
                return newObj;
            },
            filterDataWithPaginationWorker: function() {
                var newObj = Object.entries(this.filterData).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
                newObj.limit = this.perPage;
                newObj.offset = this.perPage*(this.pagination.current - 1);
                newObj.ordering = this.datatableOrdering;
                newObj.worker = this.profileId;
                newObj.status = ['OPEN', 'WIP', 'REDO', 'DONE', 'CLAIMED'];
                return newObj;
            },
        },
		methods: {
            // Main Function
            list() {
                this.loadList();
            },
            loadList() {
			    this.getWorkOrder().then((res) => {
                    this.workOrder = res.results;
                    this.pagination.next = res.next;
                    this.pagination.count = res.count;
                    this.pagination.pages = Math.ceil(res.count / this.perPage);
                });
            },
			async getWorkOrder() {
                try {
                    this.loading = true
                    let res;
                    if (this.profileId != 0) {
                        if (this.workerMode == 'worker') {
                            res = await this.$api.workOrder.search( this.filterDataWithPaginationWorker );
                        } else {
                            res = await this.$api.workOrder.search( this.filterDataWithPagination );
                        }
                    } else {
                        res = await this.$api.workOrder.search( this.filterDataWithPaginationAll );
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
				this.getCostCenter().then(res => this.costCenterList = res);
				this.getUsers().then(res => this.userList = res);
                this.getScope().then((res) => {
                    this.scopeList = res;
                });
            },
			async getWorkProgram() {
                try {
                    const res = await this.$api.workProgram.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			answerWorkOrder(id) {
                // this.$router.push({ name: 'workRecordForm', params: { id } })
                let routeData = this.$router.resolve({name: 'workRecordForm', params: { id }});
                window.open(routeData.href, '_blank');
            },
            async getCostCenter() {
				try {
					this.loading = true
					const res = await this.$api.invoiceCostCenter.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
			async getUsers() {
				try {
					this.loading = true
					const res = await this.$api.user.list();
					return res.data;
				} catch (error) {
					// handle error
				} finally {
					this.loading = false
				}
			},
            getUserName(user_id) {
                if ( this.userList.length !== 0 && user_id ) {
                    return this.userList.find(user => user.id === user_id).username;
                }
            }, 
            getCostCenterName(costcenter_id) {
                if ( this.costCenterList.length !== 0 && costcenter_id ) {
                    return this.costCenterList.find(costCenter => costCenter.id === costcenter_id).name;
                }
            }, 
			async getScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getScopeName(id) {
                if ( this.scopeList.length > 0 && id ) {
                    return this.scopeList.find(scope => scope.id === id).name;
                }
            },
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            
            removeFilter() {
                this.filterData = {};
                this.filter = false;
            },
		},
		mounted () {
            if (this.profileRole == 'VENDOR') {
                this.workerModeIndex = 1;
            }
            this.today = this.$moment().format('YYYY-MM-DD')
            this.list();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
            pagination: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            workerMode: {
                handler(val){
                    this.filterData = {};
                    this.filter = false;
                },
                deep: true
            },
            profileId: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
            profileRole: {
                handler(val){
                    if (val == 'VENDOR') {
                        this.workerModeIndex = 1
                    }
                },
                deep: true
            },
            filterData: {
                handler(val){
                    this.loadList();
                },
                deep: true
            },
        },
	}
</script>