<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto text-right">
                <v-form lazy-validation
                ref="form"
                v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                label="Base Name" 
                                v-model="input.base_name"
                                :rules="nameRules"
                                :counter="5"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                label="Base Symbol" 
                                v-model="input.base_symbol"
                                :rules="abbreRules"
                                :counter="5"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="input.id">
                        <v-col cols="12">
                            <v-divider light></v-divider>
                            <div class="subtitle text-left mb-3">Units in this Base Unit</div>
                            <unitTable :parentSymbol='input.symbol' :baseUnitId='input.id'></unitTable>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import unitTable from '@/components/table/unit-table.vue';
	export default {
		  components: {
              unitTable
		  },
          props: {
           id: Number   
          },
		data: () => ({
            success: false,
            loading: false,
            input:{
                id: '',
                name: '',
                symbol: '',
            },
            unitList: [],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
        }),
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getBaseUnit().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.base_name = res.base_name;
                        this.input.base_symbol = res.base_symbol;
                    });
                }
            },
			async getBaseUnit() {
                try {
                    this.loading = true
                    const res = await this.$api.unitMeasurement.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'unitMeasurementList' })
            },
			save() {
                this.saveBaseUnit().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Stay',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'unitMeasurementList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
            },
			async saveBaseUnit() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.unitMeasurement.save( this.input );
                    } else {
                        res = await this.$api.unitMeasurement.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
		},
		async mounted () {
            this.form();
		},
	}
</script>