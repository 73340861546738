<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card outlined class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Order</v-tab>
                    <v-tab v-if="this.input.id">Parameter</v-tab>
                    <v-tab v-if="this.input.id">Instruction</v-tab>
                    <v-tab v-if="this.input.id">Detail</v-tab>
                    <v-tab v-if="this.input.id">Heading</v-tab>
                    <v-tab v-if="this.info.complaint">Complaint</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="6" md="8">
                                <v-text-field
                                    label="Title *" 
                                    v-model="input.name"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    label="Status" 
                                    v-model="input.status"
                                    hide-details="auto"
                                    readonly
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Ext Ref ID" 
                                    v-model="info.ext_ref_id"
                                    hide-details="auto"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-autocomplete
                                :items="userSupervisorList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Supervisor" 
                                v-model="input.supervisor"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-autocomplete
                                :items="userWorkerList" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Worker" 
                                v-model="input.worker"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <wysiwyg
                                :content.sync="input.description"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete
                                :items="costCenterList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Cost Center *" 
                                v-model="input.costcenter"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-datetime-picker label="Open Datetime *" v-model="objOpenDateTime"> 
                                    <template v-slot:dateIcon>
                                        <v-icon>mdi-calendar-month-outline</v-icon>
                                    </template>
                                    <template v-slot:timeIcon>
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-datetime-picker label="Due Datetime *" v-model="objDueDateTime"> 
                                    <template v-slot:dateIcon>
                                        <v-icon>mdi-calendar-month-outline</v-icon>
                                    </template>
                                    <template v-slot:timeIcon>
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row v-if="!editAsset">
                            <v-col>
                                <v-col>
                                    <div v-if="info.asset_data">
                                        <label><strong>System:</strong></label> {{info.asset_data.system__name}}<br/>
                                        <label><strong>Generic:</strong></label> {{info.asset_data.generic__name}}<br/>
                                        <label><strong>Asset:</strong></label> {{info.asset_data.name}}<br/>
                                        <label><strong>Location:</strong></label> {{info.asset_data.location__name}}
                                    </div>
                                    <v-btn color="primary" @click="editAsset = !editAsset"><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-btn>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row v-if="editAsset"> 
                            <v-col cols="12" sm="4" md="4">
                                <label>Filter by </label><br/>
                                <v-btn-toggle v-model="assetSearchSwitch">
                                    <v-btn value="system">
                                        <v-icon left>mdi-shape</v-icon>
                                        <span class="hidden-sm-and-down">System</span>
                                    </v-btn>
                                    <v-btn value="location">
                                        <v-icon left>mdi-map-marker</v-icon>
                                        <span class="hidden-sm-and-down">Location</span>
                                    </v-btn>
                                </v-btn-toggle>
                                <v-row>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'system'">
                                        <v-select
                                        :items="systemList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="System" 
                                        v-model="info.system"
                                            hide-details="auto"
                                            @change="filterBySystem()"
                                        ></v-select>
                                        <v-select
                                        :items="genericList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Generic" 
                                        v-model="info.generic"
                                            hide-details="auto"
                                            @change="filterByGeneric()"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="assetSearchSwitch == 'location'">
                                        <v-select
                                        v-if="locationLoad"
                                        :items="locationList0" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        :label="$store.getters.textLabel.location__depth_0_label || 'Location 1'" 
                                        v-model="info.location[0]"
                                            hide-details="auto"
                                            @change="filterByLoc(0, info.location[0])"
                                        ></v-select>
                                        <v-select
                                        v-if="locationLoad"
                                        :items="locationList1" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        :label="$store.getters.textLabel.location__depth_1_label || 'Location 2'" 
                                        v-model="info.location[1]"
                                            hide-details="auto"
                                            @change="filterByLoc(1, info.location[1])"
                                        ></v-select>
                                        <v-select
                                        v-if="locationLoad"
                                        :items="locationList2" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        :label="$store.getters.textLabel.location__depth_2_label || 'Location 3'" 
                                        v-model="info.location[2]"
                                            hide-details="auto"
                                            @change="filterByLoc(2, info.location[2])"
                                        ></v-select>
                                        <v-select
                                        v-if="locationLoad"
                                        :items="locationList3" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        :label="$store.getters.textLabel.location__depth_3_label || 'Location 4'" 
                                        v-model="info.location[3]"
                                            hide-details="auto"
                                            @change="filterByLoc(3, info.location[3])"
                                        ></v-select>
                                        <v-select
                                        v-if="locationLoad"
                                        :items="locationList4" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        :label="$store.getters.textLabel.location__depth_4_label || 'Location 5'" 
                                        v-model="info.location[4]"
                                            hide-details="auto"
                                            @change="filterByLoc(4, info.location[4])"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-autocomplete
                                        :items="assetList" 
                                        item-value="id"
                                        name="item"
                                        item-text="name"
                                        label="Asset *" 
                                        v-model="input.asset"
                                        :rules="[v => !!v || 'Asset is required']"
                                            hide-details="auto"
                                            @change="getAssetDescription"
                                        >
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.name }} (ID: {{ data.item.id }}) <span v-if="data.item.manual_tag">(</span>{{ data.item.manual_tag }} <span v-if="data.item.manual_tag">)</span>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.name }} (ID: {{ data.item.id }}) <span v-if="data.item.manual_tag">(</span>{{ data.item.manual_tag }} <span v-if="data.item.manual_tag">)</span>
                                        </template>
                                        </v-autocomplete>
                                        <br/>
                                        <p v-if="assetDescription">Selected Asset Description:</p>
                                        <p v-html="assetDescription"></p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="input.status !== 'DRAFT' && input.status !== 'OPEN'">
                            <v-col>
                                <label class="font-weight-bold">Check-in Location</label><br/>
                                <div v-if="!info.latitude">No latitude / longitude available for this work order.</div>
                                Lat:{{info.latitude}}, Long: {{info.longitude}}
                                <iframe class="location-map" :src="'https://maps.google.com/maps?q='+info.latitude+','+info.longitude+'&hl=es;z=14&amp;output=embed'" width="100%" height="400"></iframe>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :items="scopeList" 
                                item-value="id"
                                name="item"
                                item-text="name"
                                label="Scope *" 
                                v-model="input.scope"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :items="work_type" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Work Type" 
                                v-model="input.work_type"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                :items="urgencyList" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Urgency" 
                                v-model="input.urgency"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <label class="font-weight-bold">Images</label><br/>
                                <v-row>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_1 && !newimage_1" :src="input.image_1" :src_large="input.image_1" />
                                        <v-img class="ba-1" contain v-if="!input.image_1 && !newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_1" :src="newimage_1Preview" :src_large="newimage_1Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            v-on:change="previewImage($event, 'newimage_1Preview')"
                                            accept="image/png, image/jpeg"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_1_label"
                                            v-model="newimage_1"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_2 && !newimage_2" :src="input.image_2" :src_large="input.image_2" />
                                        <v-img class="ba-1" contain v-if="!input.image_2 && !newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_2" :src="newimage_2Preview" :src_large="newimage_2Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_2Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_2_label"
                                            v-model="newimage_2"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_3 && !newimage_3" :src="input.image_3" :src_large="input.image_3" />
                                        <v-img class="ba-1" contain v-if="!input.image_3 && !newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_3" :src="newimage_3Preview" :src_large="newimage_3Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_3Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_3_label"
                                            v-model="newimage_3"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_4 && !newimage_4" :src="input.image_4" :src_large="input.image_4" />
                                        <v-img class="ba-1" contain v-if="!input.image_4 && !newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_4" :src="newimage_4Preview" :src_large="newimage_4Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_4Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_4_label"
                                            v-model="newimage_4"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_5 && !newimage_5" :src="input.image_5" :src_large="input.image_5" />
                                        <v-img class="ba-1" contain v-if="!input.image_5 && !newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_5" :src="newimage_5Preview" :src_large="newimage_5Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_5Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_5_label"
                                            v-model="newimage_5"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col  md="2" sm="4">
                                        <enlargeable-image class="img-wrapper" v-if="input.image_6 && !newimage_6" :src="input.image_6" :src_large="input.image_6" />
                                        <v-img class="ba-1" contain v-if="!input.image_6 && !newimage_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                        <enlargeable-image class="img-wrapper" v-if="newimage_6" :src="newimage_6Preview" :src_large="newimage_6Preview" />
                                        <v-file-input
                                            ref="file"
                                            :rules="iconRules"
                                            accept="image/png, image/jpeg"
                                            v-on:change="previewImage($event, 'newimage_6Preview')"
                                            placeholder="Upload"
                                            prepend-icon="mdi-camera"
                                            :label="order_image_6_label"
                                            v-model="newimage_6"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_1_label"
                                    v-model="input.newfile_1"
                                ></v-file-input>
                                <small v-if="info.file_1">Uploaded: <a class="noline" :href="info.file_1" target="_blank">{{order_file_1_label}}</a></small>
                                <small v-if="!info.file_1">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_2_label"
                                    v-model="input.newfile_2"
                                ></v-file-input>
                                 <small v-if="info.file_2">Uploaded: <a class="noline" :href="info.file_2" target="_blank"> {{order_file_2_label}}></a></small>
                                <small v-if="!info.file_2">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_3_label"
                                    v-model="input.newfile_3"
                                ></v-file-input>
                                <small v-if="info.file_3">Uploaded: <a class="noline" :href="info.file_3" target="_blank"> {{order_file_3_label}}</a></small>
                                <small v-if="!info.file_3">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_4_label"
                                    v-model="input.newfile_4"
                                ></v-file-input>
                                <small v-if="info.file_4">Uploaded: <a class="noline" :href="info.file_4" target="_blank">{{order_file_4_label}}</a></small>
                                <small v-if="!info.file_4">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_5_label"
                                    v-model="input.newfile_5"
                                ></v-file-input>
                                <small v-if="info.file_5">Uploaded: <a class="noline" :href="info.file_5" target="_blank">{{order_file_5_label}}</a></small>
                                <small v-if="!info.file_5">No files uploaded</small>
                            </v-col>
                            <v-col  md="2" sm="4">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    :label="order_file_6_label"
                                    v-model="input.newfile_6"
                                ></v-file-input>
                                <small v-if="info.file_6">Uploaded: <a class="noline" :href="info.file_6" target="_blank">{{order_file_6_label}}</a></small>
                                <small v-if="!info.file_6">No files uploaded</small>
                            </v-col>
                        </v-row>
                        <v-row v-if="this.complaint">
                            <v-col class="text-left">
                                This order will be created relatively with the complaint accepted ( {{complaint}} ). Please make sure to save.
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <v-btn v-if="!invoiceClaimed" class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                <v-text-field
                                class="mb-2"
                                v-if="isManager && input.status == 'RECORDED'"
                                    label="Redo Reason" 
                                    v-model="input.redo_reason"
                                    hide-details="auto"
                                ></v-text-field>
                                <v-btn v-if="isManager && input.status == 'RECORDED'" class="mr-4" color="error" @click="bulkStatus('REDO')"><v-icon class="mr-2">mdi-delete</v-icon>Redo Work</v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn v-if="isManager && input.status == 'DRAFT' || input.status == 'OPEN'" class="mr-4" color="primary" @click="save('OPEN')"><v-icon class="mr-2">mdi-content-save</v-icon>Open</v-btn>
                                <v-btn v-if="isManager && input.status == 'RECORDED'" class="mr-4" color="primary" @click="bulkStatus('DONE')"><v-icon class="mr-2">mdi-check</v-icon>Done</v-btn>
                                <v-btn v-if="isManager && info.status == 'VERIFIED'" class="mr-4" color="primary" @click="bulkStatus('VETTED')"><v-icon class="mr-2">mdi-check-decagram</v-icon>Vetted</v-btn>
                                <v-btn v-if="isManager && info.status == 'VETTED'" class="mr-4" color="primary" @click="bulkStatus('AUDITED')"><v-icon class="mr-2">mdi-check-decagram</v-icon>Audited</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="this.input.id">
                        <v-row>
                            <v-col cols="12">
                            <workOrderAssetParameterTable v-on:updateWorkProgram="refreshWorkProgramTask" :workOrderId="input.id" :disableEdit="invoiceClaimed" :workOrderAssetId="input.asset"></workOrderAssetParameterTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="this.input.id">
                        <v-row>
                            <v-col cols="12">
                            <workOrderInstructionTable v-on:updateWorkProgram="refreshWorkProgramTask" :disableEdit="invoiceClaimed" :workOrderId="input.id"></workOrderInstructionTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="this.input.id">
                        <v-row>
                            <v-col cols="12">
                            <workOrderDetailTable v-on:updateWorkProgram="refreshWorkProgramTask" :disableEdit="invoiceClaimed" :workOrderId="input.id"></workOrderDetailTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="this.input.id">
                        <v-row>
                            <v-col cols="12">
                            <workOrderHeadingTable :disableEdit="invoiceClaimed" :workOrderId="input.id" />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="info.complaint">
                        <v-row>
                            <v-col cols="12">
                            <workOrderComplaint :complaintId="info.complaint" :locationList0="locationList0" :locationList1="locationList1" :locationList2="locationList2" :locationList3="locationList3" :locationList4="locationList4"></workOrderComplaint>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card>
            <v-card outlined class="pa-3 mb-3 mx-auto text-left" v-if="this.timeline[0].data !== ''">
                <v-card-title>Timeline</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col xs="12" sm="12" md="6">
                            <v-timeline dense >
                                <v-timeline-item
                                    class="mb-6"
                                    hide-dot
                                >
                                    <span>START</span>
                                </v-timeline-item>

                                <v-timeline-item
                                v-for="(item, i) in timeline.filter(x => x.data !== '')"
                                :key="i"
                                :color="item.color"
                                :icon="item.icon"
                                fill-dot
                                >
                                <v-card width="400">
                                    <v-card-text class="d-flex justify-space-between" :class="item.color+'--text'">
                                            <strong>{{item.title}}</strong>
                                            <div class="caption">
                                                {{item.data}}
                                            </div>
                                    </v-card-text>
                                </v-card>
                                </v-timeline-item>
                                <v-timeline-item
                                    class="mt-6"
                                    hide-dot
                                >
                                    <span>END</span>
                                </v-timeline-item>
                            </v-timeline>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                            <v-simple-table
                            >
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Time taken to WIP from Open:</td>
                                            <td>{{info.open_to_wip_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to WIP from Recorded:</td>
                                            <td v-if="info.wip_to_recorded_du !== null">{{info.wip_to_recorded_du | formatTime}}</td>
                                            <td v-if="info.wip_to_recorded_du == null">n/a</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Done from WIP:</td>
                                            <td>{{info.wip_to_done_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Done from Recorded:</td>
                                            <td v-if="info.recorded_to_done_du !== null">{{info.recorded_to_done_du | formatTime}}</td>
                                            <td v-if="info.recorded_to_done_du == null">n/a</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Done from Open:</td>
                                            <td>{{info.open_to_done_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Process from Submit</td>
                                            <td>{{info.done_to_claimed_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Resolve from Process Created</td>
                                            <td>{{info.verified_to_vetted_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Resolve from Submit</td>
                                            <td>{{info.vetted_to_audited_du | formatTime}}</td>
                                        </tr>
                                        <tr>
                                            <td>Time taken to Audited from Claim:</td>
                                            <td>{{info.claimed_to_audited_du | formatTime}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>// import the component and the necessary extensions
import workOrderAssetParameterTable from '@/components/work/work-order-asset-parameter-table.vue';
import workOrderInstructionTable from '@/components/work/work-order-instruction-table.vue';
import workOrderDetailTable from '@/components/work/work-order-detail-table.vue';
import workOrderComplaint from '@/components/work/work-order-complaint.vue';
import workOrderHeadingTable from '@/components/work/work-order-heading-table.vue';
import VueTimepicker from 'vue2-timepicker/src'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import wysiwyg from '@/components/ui/wysiwyg.vue';
	export default {
		  components: {
              workOrderInstructionTable,
              VueTimepicker,
              workOrderDetailTable,
              workOrderAssetParameterTable,
              workOrderComplaint,
              workOrderHeadingTable,
            EnlargeableImage,
            wysiwyg
          },
          props: {
                id: Number,
                complaint: Number,
                assetId: Number,
                locationId: Number,
                globalPref: Array,
          },
		data: () => ({
			success: false,
			loading: true,
            editAsset: false,
            input:{
                id: '',
                name: '',
                description: '',
                cost: '',
                time: '',
                crew: null,
                activity: '',
                category: '',
                work_type: 'REACTIVE',
                urgency: 2,
                supervisor: '',
                worker: '',
                image_1: null,
                image_2: null,
                image_3: null,
                image_4: null,
                image_5: null,
                image_6: null,
                due_dt: null,
                open_dt: null,
                is_active: true,
                costcenter: null,
                complaint: null,
                newfile_1: null,
                newfile_2: null,
                newfile_3: null,
                newfile_4: null,
                newfile_5: null,
                newfile_6: null,
                status: '',
            },
            info: {
                asset_data: {},
                system:0,
                generic:0,
                location:[null, null, null, null],
                latitude: null,
                longitude: null,
                open_dt: null,
                recorded_ts: null,
                wip_ts: null,
                done_ts: null,
                claimed_ts: null,
                verified_ts: null,
                vetted_ts: null,
                audited_ts: null,
                open_to_wip_du: null,
                wip_to_recorded_du: null,
                recorded_to_done_du: null,
                wip_to_done_du: null,
                open_to_done_du: null,
                done_to_claimed_du: null,
                verified_to_vetted_du: null,
                vetted_to_audited_du: null,
                claimed_to_audited_du: null,
                file_1: null,
                file_2: null,
                file_3: null,
                file_4: null,
                file_5: null,
                file_6: null,
                ext_ref_id: '',
            },
            orderHeading: null,
            timeline: [
                {
                    color: 'grey',
                    icon: 'mdi-new-box',
                    title: 'Opened',
                    data: '',
                },
                {
                    color: 'grey',
                    icon: 'mdi-progress-clock',
                    title: 'WIP',
                    data: '',
                },
                {
                    color: 'grey',
                    icon: 'mdi-progress-clock',
                    title: 'Recorded',
                    data: '',
                },
                {
                    color: 'green',
                    icon: 'mdi-progress-check',
                    title: 'Done',
                    data: '',
                },
                {
                    color: 'teal',
                    icon: 'mdi-currency-usd-off',
                    title: 'Claimed',
                    data: '',
                },
                {
                    color: 'teal',
                    icon: 'mdi-eye-check',
                    title: 'Verified',
                    data: '',
                },
                {
                    color: 'teal',
                    icon: 'mdi-check-all',
                    title: 'Vetted',
                    data: '',
                },
                {
                    color: 'teal',
                    icon: 'mdi-notebook-check',
                    title: 'Audited',
                    data: '',
                },
            ],
            newimage_1: null,
            newimage_2: null,
            newimage_3: null,
            newimage_4: null,
            newimage_5: null,
            newimage_6: null,
            newimage_1Preview: null,
            newimage_2Preview: null,
            newimage_3Preview: null,
            newimage_4Preview: null,
            newimage_5Preview: null,
            newimage_6Preview: null,
            work_type:[
                { value: "CORRECTIVE", name: "corrective (self-initiate)" },
                { value: "PREVENTIVE", name: "preventive (scheduled)" },
                { value: "REACTIVE", name: "reactive (respond to complaint)" }
            ],
            optionStatusStaff:[
                {
                    "value": "DRAFT",
                    "name": "draft",
                },
                {
                    "value": "OPEN",
                    "name": "open"
                },
                {
                    "value": "WIP",
                    "name": "work in progress"
                },
                {
                    "value": "RECORDED",
                    "name": "recorded",
                },
                {
                    "value": "CLAIMED",
                    "name": "claimed"
                },
                {
                    "value": "VERIFIED",
                    "name": "verified",
                    "disabled": "true"
                },
                {
                    "value": "COMPILED",
                    "name": "compiled",
                    "disabled": "true"
                },
                {
                    "value": "VETTED",
                    "name": "vetted",
                    "disabled": "true"
                },
                {
                    "value": "AUDITED",
                    "name": "audited",
                    "disabled": "true"
                }
            ],
            optionStatusSV: [
                {
                    "value": "DRAFT",
                    "name": "draft",
                },
                {
                    "value": "OPEN",
                    "name": "open"
                },
                {
                    "value": "WIP",
                    "name": "work in progress"
                },
                {
                    "value": "RECORDED",
                    "name": "recorded",
                },
                {
                    "value": "CLAIMED",
                    "name": "claimed"
                },
                {
                    "value": "VERIFIED",
                    "name": "verified",
                    "disabledRole": "STAFF"
                },
                {
                    "value": "COMPILED",
                    "name": "compiled",
                    "disabledRole": "STAFF"
                },
                {
                    "value": "VETTED",
                    "name": "vetted",
                    "disabledRole": "STAFF"
                },
                {
                    "value": "AUDITED",
                    "name": "audited",
                    "disabledRole": "STAFF"
                }
            ],
            urgency: [
                { value: 1, name: "1 : one" },
                { value: 2, name: "2 : two" },
                { value: 3, name: "3 : three" },
            ],
            userSupervisorList: [],
            userWorkerList: [],
            scopeList: [],
            assetList: [],
            costCenterList: [],
            locationList: [],
            locationList0: [],
            locationList1: [],
            locationList2: [],
            locationList3: [],
            locationList4: [],
            systemList: [],
            genericList: [],
            locationFilteredList: [],
            genericFilteredList: [],
            assetFilteredList: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            openDtMenu: false,
            dueDtMenu: false,
            assetSearchSwitch: 'system',
            assetSelected: 0,
            assetDescription: '',
            locationLoad: false,
        }),
        computed: {
            objOpenDateTime: {
                get(){
                    return (new Date(this.input.open_dt))
                },
                set(value) {
                    this.input.open_dt = value
                }
            },
            objDueDateTime: {
                get(){
                    return (new Date(this.input.due_dt))
                },
                set(value) {
                    this.input.due_dt = value
                }
            },
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            isManager() {
                return (this.profileRole == 'MANAGER' || this.profileRole == 'SYSADMIN') ? true: false
            },
            isStaff() {
                return (this.profileRole == 'STAFF' || this.profileRole == 'SYSADMIN') ? true: false
            },
            isCustomer() {
                return (this.profileRole == 'CUSTOMER' || this.profileRole == 'SYSADMIN') ? true: false
            },
			urgencyList: function () {
                if(this.$store.getters.urgencyList) {
                    return this.$store.getters.urgencyList;
                } else {
                    return this.urgency;
                }
            },
            order_image_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_1_label').value;
                }
            },
            order_image_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_2_label').value;
                }
            },
            order_image_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_3_label').value;
                }
            },
            order_image_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_4_label').value;
                }
            },
            order_image_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_5_label').value;
                }
            },
            order_image_6_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__image_6_label').value;
                }
            },
            order_file_1_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_1_label').value;
                }
            },
            order_file_2_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_2_label').value;
                }
            },
            order_file_3_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_3_label').value;
                }
            },
            order_file_4_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_4_label').value;
                }
            },
            order_file_5_label: function(){
                if (this.globalPref.length > 0) {
                    return this.globalPref.find(pref => pref.identifier == 'order__file_5_label').value;
                }
            },
            invoiceClaimed() {
                return (this.isStaff || this.isManager) && (this.input.status == 'AUDITED' || this.input.status == 'COMPLETED') ? true :false
            },
			// locationLabel: function () {
            //     if(this.$store.getters.textLabel.urgencyList) {
            //         return this.$store.getters.textLabel.urgencyList;
            //     } else {
            //         return this.urgency;
            //     }
            // },
            // userVerifier: function() {
            //     if (this.users.length > 0) {
            //         if (this.input.checker == null) {
            //             return (this.users);
            //         } else {
            //             return (this.users.filter(users => users.id !== this.input.checker));
            //         }
            //     }
            // },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getWorkOrder().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.status = res.status;
                        this.input.description = res.description;
                        this.input.scope = res.scope;
                        this.input.asset = res.asset;
                        if (res.asset) {
                            this.getAssetDescription(res.asset);
                        }
                        this.input.time = res.time;
                        this.input.crew = res.crew;
                        this.input.activity = res.activity;
                        this.input.work_type = res.work_type;
                        this.input.urgency = res.urgency;
                        this.input.open_dt = res.open_dt;
                        this.input.due_dt = res.due_dt;

                        this.input.supervisor = res.supervisor;
                        this.input.worker = res.worker;

                        this.input.image_1 = res.image_1;
                        this.input.image_2 = res.image_2;
                        this.input.image_3 = res.image_3;
                        this.input.image_4 = res.image_4;
                        this.input.image_5 = res.image_5;
                        this.input.image_6 = res.image_6;
                        this.info.file_1 = res.file_1;
                        this.info.file_2 = res.file_2;
                        this.info.file_3 = res.file_3;
                        this.info.file_4 = res.file_4;
                        this.info.file_5 = res.file_5;
                        this.info.file_6 = res.file_6;
                        this.info.ext_ref_id = res.ext_ref_id;
                        this.input.due_dt = res.due_dt;
                        this.input.open_dt = res.open_dt;
                        this.input.costcenter = res.costcenter;
                        this.input.redo_reason = res.redo_reason;

                        this.info.asset_data = res.asset_data;
                        this.info.latitude = res.check_in_latitude;
                        this.info.longitude = res.check_in_longitude;
                        this.input.source = res.source;

                        this.info.complaint = res.complaint;

                        if (res.open_dt) {
                            this.timeline[0].data =  this.$moment(res.open_dt).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.wip_ts) {
                            this.timeline[1].data = this.$moment(res.wip_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.recorded_ts) {
                            this.timeline[2].data = res.recorded_ts == null ? 'n/a' : this.$moment(res.recorded_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.done_ts) {
                            this.timeline[3].data = this.$moment(res.done_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.claimed_ts) {
                            this.timeline[4].data = this.$moment(res.claimed_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.verified_ts) {
                            this.timeline[5].data = this.$moment(res.verified_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.vetted_ts) {
                            this.timeline[6].data = this.$moment(res.vetted_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (res.audited_ts) {
                            this.timeline[7].data = this.$moment(res.audited_ts).format('YYYY-MM-DD HH:mm:ss');
                        }
                        this.info.done_to_claimed_du = res.done_to_claimed_du;
                        this.info.verified_to_vetted_du = res.verified_to_vetted_du;
                        this.info.vetted_to_audited_du = res.vetted_to_audited_du;
                        this.info.open_to_wip_du = res.open_to_wip_du;
                        this.info.wip_to_done_du = res.wip_to_done_du;
                        this.info.open_to_done_du = res.open_to_done_du;
                        this.info.claimed_to_audited_du = res.claimed_to_audited_du;
                        this.info.wip_to_recorded_du  = res.wip_to_recorded_du;
                        this.info.recorded_to_done_du  = res.recorded_to_done_du;
                        this.info.generic = res.asset_data.generic__id;
                        this.info.system = res.asset_data.system__id;

                        this.getOrderHeading().then(res => this.orderHeading = res);
                            
                        this.getLoc(res.asset_data.location__id).then((res2) => {
                            this.info.location[res2.depth] = res2.id;
                            if (res2.depth > 0) {
                                this.getLoc(res2.parent).then((res3) => { 
                                    this.info.location[res3.depth] = res3.id;
                                    if (res3.depth > 0) {
                                        this.getLoc(res3.parent).then((res4) => { 
                                            this.info.location[res4.depth] = res4.id;
                                            if (res.depth > 0) {
                                                this.getLoc(res4.parent).then((res5) => { 
                                                    this.info.location[res5.depth] = res5.id;
                                                })
                                            }       
                                        })
                                    }
                                })
                            }
                            this.locationLoad = true;
                        });
                    });
                } else {
                    if (this.assetId) {
                        this.input.asset = this.assetId;
                        if (this.assetId) {
                            this.getAssetDescription(this.assetId);
                        }
                        this.getAssetLoc(this.input.asset).then((res) => {
                            this.getLoc(res.location).then((res2) => {
                                this.info.location[res2.depth] = res2.id;
                                if (res2.depth > 0) {
                                    this.getLoc(res2.parent).then((res3) => { 
                                        this.info.location[res3.depth] = res3.id;
                                        if (res3.depth > 0) {
                                            this.getLoc(res3.parent).then((res4) => { 
                                                this.info.location[res4.depth] = res4.id;
                                                if (res.depth > 0) {
                                                    this.getLoc(res4.parent).then((res5) => { 
                                                        this.info.location[res5.depth] = res5.id;
                                                    })
                                                }       
                                            })
                                        }
                                    })
                                }
                                this.locationLoad = true;
                            });
                        });
                    } else {
                        this.locationLoad = true;
                        this.input.location = this.locationId;
                        this.getComplaint();
                        this.getAssetsbyFilter(null, null, this.locationId).then((res) => { this.assetList = res } );
                    }
                    this.editAsset = true;
                    this.input.status = 'DRAFT';
                    if (Object.keys(this.globalPref).length > 0) {
                        this.input.open_dt = this.$moment().add(this.$moment.duration(this.globalPref.find(pref => pref.identifier == 'order__open_timedelta').value)).format('YYYY-MM-DD HH:mm:ss');
                        this.input.due_dt = this.$moment(this.input.open_dt).add(this.$moment.duration(this.globalPref.find(pref => pref.identifier == 'order__due_timedelta').value)).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        this.input.open_dt = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                        this.input.due_dt = this.$moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                this.getAdditionalData();
            },
			async getWorkOrder() {
                try {
                    const res = await this.$api.workOrder.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            bulkStatus(status) {
                this.saveWorkOrder(status).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.setStatus(status).then((res) => {
                            if (res.status == 200 || res.status == 201 ) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Saved',
                                    showCancelButton: true,
                                    reverseButtons: true,
                                    confirmButtonText: 'Return',
                                    cancelButtonText: 'Cancel',
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'workOrderList' })
                                    } else {
                                        this.input.id = res.data.id;
                                        this.form();
                                    }
                                })
                            }
                        });
                    }
                })
			},
			async setStatus(status) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        if (status != 'REDO') {
                            res = await this.$api.workOrder.save({id: this.input.id, status: status });
                        } else {
                            res = await this.$api.workOrder.save({id: this.input.id, status: status, redo_reason: this.input.redo_reason });
                        }
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			save(status) {
                this.saveWorkOrder(status).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (!this.input.id) {
                                // this.$api.complaintComplaint.save({id: this.complaint, status: 'ACCEPTED' })
                            }
                            if (result.value) {
                                this.$router.push({ name: 'workOrderList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveWorkOrder(status) {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    if (this.input.scope) {
                    formData.append("scope", this.input.scope);
                    }
                    formData.append("work_type", this.input.work_type);
                    formData.append("urgency", this.input.urgency);
                    formData.append("description", this.input.description);
                    if (this.input.costcenter) {
                    formData.append("costcenter", this.input.costcenter);
                    }
                    formData.append("is_active", this.input.is_active);
                    if (status) {
                        formData.append("status", status);
                    }
                    if (this.complaint) {
                        formData.append("complaint", this.complaint);
                    }

                    formData.append("supervisor", this.input.supervisor);
                    formData.append("worker", this.input.worker);
                    if (this.input.asset) {
                    formData.append("asset", this.input.asset);
                    }
                    formData.append("open_dt", this.$moment(this.input.open_dt).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append("due_dt", this.$moment(this.input.due_dt).format('YYYY-MM-DD HH:mm:ss'));
                    if (this.newimage_1) {
                        formData.append("image_1", this.newimage_1 );
                    }
                    if (this.newimage_2) {
                        formData.append("image_2", this.newimage_2 );
                    }
                    if (this.newimage_3) {
                        formData.append("image_3", this.newimage_3 );
                    }
                    if (this.newimage_4) {
                        formData.append("image_4", this.newimage_4 );
                    }
                    if (this.newimage_5) {
                        formData.append("image_5", this.newimage_5 );
                    }
                    if (this.newimage_6) {
                        formData.append("image_6", this.newimage_6 );
                    }
                    if (this.input.newfile_1) {
                        formData.append("file_1", this.input.newfile_1);
                    }
                    if (this.input.newfile_2) {
                        formData.append("file_2", this.input.newfile_2);
                    }
                    if (this.input.newfile_3) {
                        formData.append("file_3", this.input.newfile_3);
                    }
                    if (this.input.newfile_4) {
                        formData.append("file_4", this.input.newfile_4);
                    }
                    if (this.input.newfile_5) {
                        formData.append("file_5", this.input.newfile_5);
                    }
                    if (this.input.newfile_6) {
                        formData.append("file_6", this.input.newfile_6);
                    }
                    if (this.input.id) {
                        res = await this.$api.workOrder.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.workOrder.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'workOrderList' })
            },
            previewImage(e, previewTarget) {
                if (e) {
                    this[previewTarget] = URL.createObjectURL(e);
                }
            },
            getAdditionalData() {
			    // this.getRecurranceTimebyWorkProgram().then(res => this.recurranceTimeList = res);
                this.getSupervisors().then(res => this.userSupervisorList = res);
                this.getWorkers().then(res => this.userWorkerList = res);
                this.getWorkScope().then(res => this.scopeList = res);
                this.getCostCenterList().then(res => this.costCenterList = res);
                if (!this.input.location) {
                    this.getAssets().then(res => this.assetList = res);
                }
                this.getSystems().then(res => this.systemList = res);
                this.getGenerics().then(res => this.genericList = res);
                this.getLocsbyDepth(0).then((res) => { 
                    this.locationList0 = res;
                });
                this.getLocsbyDepth(1).then((res) => { 
                    this.locationList1 = res;
                });
                this.getLocsbyDepth(2).then((res) => { 
                    this.locationList2 = res;
                });
                this.getLocsbyDepth(3).then((res) => { 
                    this.locationList3 = res;
                });
                this.getLocsbyDepth(4).then((res) => { 
                    this.locationList4 = res;
                });
            },
			async getCostCenterList() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAsset() {
                try {
                    const res = await this.$api.assetAsset.get( this.input.asset );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLoc(location_id) {
                try {
                    const res = await this.$api.location.get( location_id );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAssetLoc(asset_id) {
                try {
                    const res = await this.$api.assetAsset.get( asset_id );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getLocsbyDepth(depth) {
                try {
                    const res = await this.$api.location.search({depth: depth});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getSystems() {
                try {
                    const res = await this.$api.assetSystem.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenerics() {
                try {
                    const res = await this.$api.assetGeneric.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getSupervisors() {
                try {
                    const res = await this.$api.user.search({is_supervisor_candidate:true, is_active: true});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getWorkers() {
                try {
                    const res = await this.$api.user.search({is_worker_candidate:true, is_active: true});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getWorkScope() {
                try {
                    const res = await this.$api.workScope.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            filterBySystem() {
                this.getGenericsbySystem().then((res) => { 
                    this.genericList = res;
                });
                this.getAssetsbyFilter(this.info.system, null, null).then(res => this.assetList = res );
            },
            filterByGeneric() {
                this.getAssetsbyFilter(this.info.system, this.info.generic, null).then((res) => { this.assetList = res } );
            },
            filterByLoc(locationfield, location_id) {
                this.$api.location.search({ depth: locationfield+1, parent: location_id }).then((res) => {
                    if (locationfield+1 == 1) {
                        this.locationList1 = res.data;
                    }
                    if (locationfield+1 == 2) {
                        this.locationList2 = res.data;
                    }
                    if (locationfield+1 == 3) {
                        this.locationList3 = res.data;
                    }
                    if (locationfield+1 == 4) {
                        this.locationList4 = res.data;
                    }
                } );
                this.getAssetsbyFilter(null, null, location_id).then((res) => { this.assetList = res } );
            },
            async getChildLoc(depth, parent_id) {
                try {
                    const res = await this.$api.location.search({ depth: depth+1, parent: parent_id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getGenericsbySystem(system_id) {
                try {
                    const res = await this.$api.assetGeneric.search({ system: this.info.system });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            async getAssetsbyFilter(system, generic, location) {
                try {
                    let res;
                    if (!location) {
                        res = await this.$api.assetAsset.search({ system: system, generic: generic });
                    } else {
                        res = await this.$api.assetAsset.search({ location: location });
                    }
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            refreshWorkProgramTask() {
                this.form();
            },
            getComplaint () {
                this.getComplaintInfo().then((res) => {
                    if (res.location) {
                        this.getLoc(res.location).then((res2) => {
                            this.info.location[res2.depth] = res2.id;
                            if (res2.depth > 0) {
                                this.getLoc(res2.parent).then((res3) => { 
                                    this.info.location[res3.depth] = res3.id;
                                    if (res3.depth > 0) {
                                        this.getLoc(res3.parent).then((res4) => { 
                                            this.info.location[res4.depth] = res4.id;
                                            if (res.depth > 0) {
                                                this.getLoc(res4.parent).then((res5) => { 
                                                    this.info.location[res5.depth] = res5.id;
                                                })
                                            }       
                                        })
                                    }
                                })
                            }
                        });
                    }
                    setTimeout(() => {
                        console.log('are u here')
                        this.assetSearchSwitch = 'location';
                        this.locationLoad = true;
                    }, 1000)
                });
                
            },
			async getComplaintInfo() {
                try {
                    const res = await this.$api.complaintComplaint.get(this.complaint);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetDescription(asset_id) {
                if (asset_id) {
                    this.getAssetDesc(asset_id).then((res) => { 
                        this.assetDescription = res.description;
                    })
                }
            },
			async getAssetDesc(asset_id) {
                try {
                    const res = await this.$api.assetAsset.get(asset_id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getOrderHeading() {
                try {
                    const res = await this.$api.workOrderHeading.search({ order: this.id });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.valid = true;
		},
        watch: { 
            'this.info.location': {
                handler(newVal, oldVal) {
                    // do something with the object
                },
                deep: true,
            },
        },
	}
</script>