<template>
	<div id="app">
		<v-app>
    		<component :is="layout" :globalPref="globalPref">
				<transition name="fade">
					<router-view :profileId='profileId' :globalPref='globalPref' />
				</transition>
				
				<loader :is-visible="isLoading" :current="loadingIndicatorCurrent" :total="loadingIndicatorTotal"></loader>
				<alertDisconnect v-if="tokenError" ></alertDisconnect>
				<v-snackbar
				:vertical="true"
				v-model="apiError"
				:timeout="-1"
				>
				<div v-html="apiErrorText"></div>
				<!-- <span>{{apiErrorText}}</span> -->
					<template v-slot:action="{ attrs }">
						<v-btn
					color="white"
					text
						v-bind="attrs"
					@click="$store.commit('apiError', '')"
						>
					Ok
						</v-btn>
					</template>
				</v-snackbar>
    		</component>
			<div class="text-center caption pb-2 text--disabled">Build Version:{{gitHash}}</div>
		</v-app>
	</div>
</template>

<script>
	import alertDisconnect from '@/components/alerts/alertDisconnect.vue';
	import loader from '@/components/ui/loader.vue';
	const default_layout = "withSubmenu";

	export default {
		components: {
			alertDisconnect,
			loader
			// Footer,
		},
		name: 'App',
		data: () => ({
			apiRetry: false,
			apiRefresh: true,
			globalPref: [],
			windowWidth: 0,
		}),
		computed: {
			gitHash: function(){
				return process.env.VUE_APP_GIT_HASH
			},
			layout: function () {
				return (this.$route.meta.layout || default_layout) + "-layout";
			},
			isLoading: function () {
				return this.$store.getters.isLoading;
			},
			loadingIndicatorCurrent: function () {
				return this.$store.getters.loadingIndicatorCurrent;
			},
			loadingIndicatorTotal: function () {
				return this.$store.getters.loadingIndicatorTotal;
			},
			tokenError: function () {
				return this.$store.getters.tokenError;
			},
			apiError: function () {
				return this.$store.getters.apiError;
			},
			apiErrorText: function () {
				return this.$store.getters.apiErrorText;
			},
			authed: function() {
				return this.$store.getters.isLoggedIn;
			},
			profileId: function() {
				return this.$store.getters.profileId;
			},
		},
		mounted: function () {
			this.apiRefresh = false;
			
            this.$store.dispatch('inspectToken');
			if (this.authed == false) {
				// if (this.$route.name !== 'home') {
				// 	this.$router.push('/');
				// } 
			} else {
				this.getGlobalPref();
				this.getProfileId();
			}
		},
		methods: {
            getGlobalPref() {
				if (this.authed == true) {
					this.getGlobalPreferences().then((res) => {
						this.globalPref = res;
						this.$store.dispatch('setGlobalPref', res).then((res) => {
						})
					});
				}
            },
			async getGlobalPreferences() {
                try {
                    const res = await this.$api.preference.global();
                    return res.data;
                } catch (error) {
                } finally {
                }
            },
            getProfileId() {
			    this.getProfile().then((res) => {
					this.$store.dispatch('setProfileId', res.id).then((res) => {
					})
					this.$store.dispatch('setProfileRole', res.role).then((res) => {
					})
				});
            },
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			checkTokenPeriodic() {
				this.tokenCheck = true;
				this.$store.dispatch('inspectToken').then((res) => {
				if (res === false) {
					setTimeout(() => this.tokenCheck = false, 1000 );
				} else {
				};
				})
			}
		},
		watch: {
            '$route' (to, from) {
				this.getGlobalPref();
				this.$store.commit('apiError', '')
			},
		}
	};
</script>