var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('v-app-bar',{attrs:{"app":"","clipped-left":true,"outlined":"","flat":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[(_vm.mode !== 'simple')?_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleDrawer}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"primary","dark":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"small":"","color":"grey","dark":""}},[_vm._v("mdi-arrow-left")])],1),_c('div',{staticClass:"d-none d-sm-inline-block"},[_vm._v(_vm._s(_vm.companyName))])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-toolbar-title',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.currentRouteName)+" "+_vm._s(_vm.currentRoutePageId)+" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.tokenCheck)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":16,"width":2,"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.system_mode == 'set_up')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-wrench")])]}}],null,false,1562253079)},[_c('span',[_vm._v("Currently in set up mode")])]):_vm._e(),(_vm.system_mode == 'production')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-cog")])]}}],null,false,3971394489)},[_c('span',[_vm._v("Currently in production mode")])]):_vm._e(),(_vm.system_mode == 'file_import')?_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey","dark":""}},on),[_vm._v("mdi-database-import")])]}}],null,false,1308165799)},[_c('span',[_vm._v("Currently in file import mode")])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}}])},[_c('moduleNavigation',{staticStyle:{"width":"300px","height":"300px"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-avatar',{attrs:{"color":"indigo","size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.profileFirstLetter))])])],1)]}}])},[_c('v-list',{staticClass:"text-left",attrs:{"rounded":"","dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.profile.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.role))])],1)],1),_c('v-list-item',[(this.originalProfile.role == 'SYSADMIN')?_c('v-menu',{attrs:{"close-on-content-click":false,"max-height":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"primary","dark":""}},on),[_c('small',[_vm._v("Current User:"),_c('br'),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(_vm._s(_vm.profileName))])])])]}}],null,false,2753725231)},[_c('v-list',{staticClass:"text-left",attrs:{"rounded":"","dense":""}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":false,"hide-details":"auto","label":"Choose a profile"},model:{value:(_vm.profileId),callback:function ($$v) {_vm.profileId=$$v},expression:"profileId"}},_vm._l((_vm.userList),function(user,index){return _c('v-radio',{key:index,attrs:{"label":user.username,"value":user.id}})}),1)],1)],1):_vm._e()],1),_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"px-2"},[_c('v-switch',{attrs:{"inset":"","label":"Toggle Theme","persistent-hint":""},model:{value:(_vm.themeColor),callback:function ($$v) {_vm.themeColor=$$v},expression:"themeColor"}})],1)])],1),_vm._l((_vm.userLinks),function(link,index){return _c('v-list-item',{key:index,attrs:{"disabled":link.disabled},on:{"click":function($event){return _vm.userLink(link.link)}}},[(link.icon)?_c('v-list-item-icon',{attrs:{"size":"12"}},[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(link.title))])],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }