<template>
    <v-card
        class="mx-auto"
        outlined
    >
        <v-data-table 
            v-if="!this.loading"
            :headers="headersParameterCheck"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedParameters"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr style="vertical-align: top">
                <td>
                    <v-row>
                        <v-col cols="10">
                            <v-select
                            :items="parameterList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Parameter" 
                            v-model="row.item.parameter"
                            >
                            <template slot='selection' slot-scope='{ item }'>
                                <span v-if="item.base_parameter">{{ getParamName(item.base_parameter) }}</span>  (<span v-if="item.asset">{{ getAssetName(item.asset) }}</span>)
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                                <span v-if="item.base_parameter">{{ getParamName(item.base_parameter) }}</span>  (<span v-if="item.asset">{{getAssetName(item.asset) }}</span>)
                            </template>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Sort Order" 
                                v-model="row.item.sort_order" 
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Checker Reading"
                                        v-model="row.item.checker_reading" 
                                        required
                                        readonly
                                        @change="changeCheckConditionIfAuto(row.item.index, row.item.condition_mode, row.item.automatic_mode, row.item.lower_limit, row.item.upper_limit, row.item.id)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                    :items="condition" 
                                    readonly
                                    label="Checker Condition" 
                                    item-value="value"
                                    name="item"
                                    item-text="name"
                                    v-model="row.item.checker_condition" 
                                    @change="checked_ts(row.item.id, row.item.checker_reading, row.item.checker_condition)"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-text-field
                                label="Checker Note"
                                readonly
                                v-model="row.item.checker_note" 
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Verifier Reading"
                                        v-model="row.item.verifier_reading" 
                                        required
                                        readonly
                                        @change="changeVerifyConditionIfAuto(row.item.index, row.item.condition_mode, row.item.automatic_mode, row.item.lower_limit, row.item.upper_limit, row.item.id)"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                    readonly
                                    :items="condition" 
                                    item-value="value"
                                    name="item"
                                    item-text="name"
                                    label="Verifier Condition" 
                                    v-model="row.item.verifier_condition"
                                    @change="verified_ts(row.item.id, row.item.verifier_reading, row.item.verifier_condition)"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-text-field
                                label="Verifier Note"
                                readonly
                                v-model="row.item.verifier_note" 
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <v-row >
                        <v-col cols="auto">
                            <v-btn-toggle>
                                <v-btn small fab  @click="save(row.item.id, row.item.checker_reading, row.item.verifier_reading, row.item.checker_condition, row.item.verifier_condition, row.item.checker_note, row.item.verifier_note, row.item.checker_ts, row.item.verifier_ts, row.item.asset_check)">
                                    <v-icon >mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn small fab  @click="remove(row.item.index, row.item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
    <v-card-actions>
        <v-btn class="mr-4" @click="newParameterCheck()">Add Parameter Check</v-btn>
    </v-card-actions>
  </v-card>
    
</template>
<script>
	export default {
        props: {
            assetCheckId: {
                type: Number
            },
            assetId: {
                type: Number
            }
        },
		data() {
            return {
                headersParameterCheck: [
                { text: 'Parameters', align: 'left',  sortable: true, value: 'parameter', },
                { text: ''}
                ],
                id: [],
                loading: true,
                parameterChecksbyAssetCheck: [],
                users:[],
                parameterList: [],
                condition: [
                    {
                        name: 'unknown',
                        value: 'UNKNOWN'
                    },
                    {
                        name: 'good',
                        value: 'GOOD'
                    },
                    {
                        name: 'bad',
                        value: 'BAD'
                    },
                    {
                        name: 'other',
                        value: 'OTHER'
                    }
                ],
                expandable: {},
                assetCheck: 0,
                assetListFull: [],
                masterParamList: [],
                baseParamList:[],
            }
        },
        computed: {
            indexedParameters () {
                return this.parameterChecksbyAssetCheck.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.assetCheckId) {
                    this.getParamChecksbyAssetCheck().then((res) => {
                        this.parameterChecksbyAssetCheck = res;
                    });
                }
            },
			async getParamChecksbyAssetCheck() {
                try {
                    this.loading = true
                    const res = await this.$api.paramCheck.search({ asset_check: this.assetCheckId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getParamsbyAsset().then(res => this.parameterList = res);
			    this.getAssets().then(res => this.assetListFull = res);
			    this.getMastParam().then(res => this.masterParamList = res);
			    this.getBaseParameters().then(res => this.baseParamList = res);
            },
			async getParamsbyAsset() {
                try {
                    this.loading = true
                    const res = await this.$api.assetAssetParameter.search({ asset: this.assetId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getMastParam() {
                try {
                    const res = await this.$api.mastParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseParameters() {
                try {
                    const res = await this.$api.assetGenericParameter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getParamName(param_id) {
                if ( this.baseParamList.length !== 0 && param_id ) {
                    return (this.masterParamList.find(baseParam => baseParam.id === (this.baseParamList.find(baseParam => baseParam.id === param_id).master_parameter)).name)
                }
            },
            getAssetName(asset_id) {
                if ( this.assetListFull.length !== 0 && asset_id ) {
                    return this.assetListFull.find(asset => asset.id === asset_id).name;
                }
            }, 
            changeCheckConditionIfAuto(index, condition_mode, automatic_mode, lower_limit, upper_limit, id ) {
                if (condition_mode == 'AUTOMATIC') {
                    if (automatic_mode == 'LOWER') {
                        if (this.indexedParameters[index].checker_reading && this.indexedParameters[index].checker_reading > lower_limit) {
                            this.indexedParameters[index].checker_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].checker_condition = 'BAD';
                        }
                    }
                    if (automatic_mode == 'UPPER') {
                        if (this.indexedParameters[index].checker_reading && this.indexedParameters[index].checker_reading < upper_limit) {
                            this.indexedParameters[index].checker_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].checker_condition = 'BAD';
                        }
                    }
                    if (automatic_mode == 'RANGE') {
                        if (this.indexedParameters[index].checker_reading && parseFloat(this.indexedParameters[index].checker_reading) < parseFloat(upper_limit) && parseFloat(this.indexedParameters[index].checker_reading) > parseFloat(lower_limit)) {
                            this.indexedParameters[index].checker_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].checker_condition = 'BAD';
                        }
                    }
                } else {
                    // do nothing
                }
                this.checked_ts(id, this.indexedParameters[index].checker_reading, this.indexedParameters[index].checker_condition);
            },
            changeVerifyConditionIfAuto(index, condition_mode, automatic_mode, lower_limit, upper_limit, id ) {
                if (condition_mode == 'AUTOMATIC') {
                    if (automatic_mode == 'LOWER') {
                        if (this.indexedParameters[index].verifier_reading && this.indexedParameters[index].verifier_reading > lower_limit) {
                            this.indexedParameters[index].verifier_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].verifier_condition = 'BAD';
                        }
                    }
                    if (automatic_mode == 'UPPER') {
                        if (this.indexedParameters[index].verifier_reading && this.indexedParameters[index].verifier_reading < upper_limit) {
                            this.indexedParameters[index].verifier_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].verifier_condition = 'BAD';
                        }
                    }
                    if (automatic_mode == 'RANGE') {
                        if (this.indexedParameters[index].verifier_reading && parseFloat(this.indexedParameters[index].verifier_reading) < parseFloat(upper_limit) && parseFloat(this.indexedParameters[index].verifier_reading) > parseFloat(lower_limit)) {
                            this.indexedParameters[index].verifier_condition = 'GOOD';
                        } else {
                            this.indexedParameters[index].verifier_condition = 'BAD';
                        }
                    }
                } else {
                    // do nothing
                }
                this.verified_ts(id, this.indexedParameters[index].verifier_reading, this.indexedParameters[index].verifier_condition);
            },
            checked_ts(id, checker_reading, checker_condition) {
                var d = new Date();
                this.$api.paramCheck.save({id:id, checker_ts: d, checker_reading: checker_reading, checker_condition: checker_condition}).then((res) => {
                    if (res.status == 201 ) {
                            console.log('success!');
							this.$swal({
								icon: 'success',
								title: 'Saved',
                                reverseButtons: true,
                                confirmButtonText: 'OK',
							}).then((result) => {
							})
						}
					})
            },
            verified_ts(id, verifier_reading, verifier_condition) {
                var d = new Date();
				this.$api.paramCheck.save({ id: id, verifier_ts: d, verifier_reading:verifier_reading, verifier_condition: verifier_condition }).then((res) => {
                    if (res.status == 201 ) {
                            console.log('success!');
							this.$swal({
								icon: 'success',
								title: 'Saved',
                                reverseButtons: true,
                                confirmButtonText: 'OK',
							}).then((result) => {
							})
						}
					})
            },
            newParameterCheck() {
                this.parameterChecksbyAssetCheck.push({id: '', checker_reading: null, verifier_reading: null, checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
            },
            save(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                this.saveParamCheck(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveParamCheck(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.paramCheck.save( {id: id, checker_reading: checker_reading, verifier_reading: verifier_reading, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    } else {
                        res = await this.$api.paramCheck.new( {checker_reading: checker_reading, verifier_reading: verifier_reading, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check  });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.paramCheck.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.parameterChecksbyAssetCheck, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>