<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
        <v-card class="pa-3 mb-3 mx-auto">
        <v-form>
            <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Asset Plan</v-tab>
                    <v-tab v-if="this.input.id">Assign Parameters</v-tab>
                    <v-tab v-if="this.input.id">Assign Components</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete ref="internal"
                                    label="Checkplan"  
                                    v-model="input.checkplan"
                                    cache-items
                                    hide-no-data
                                    :items="checkplanList"
                                    name="item"
                                    item-value="id"
                                    item-text="name">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete ref="internal"
                                    label="Asset"  
                                    v-model="input.asset"
                                    cache-items
                                    hide-no-data
                                    :items="assetListFull"
                                    name="item"
                                    item-value="id"
                                    item-text="name">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select
                                :items="users" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Checker" 
                                v-model="input.checker"
                                ></v-select>
                                <v-select
                                :items="userVerifier" 
                                item-value="id"
                                name="item"
                                item-text="username"
                                label="Verifier" 
                                v-model="input.verifier"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="text-right">
                            <v-col cols="12">
                                <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                                <paramPlanTable v-if="this.input.id" :assetPlanId="this.input.id" :assetId="this.input.asset"></paramPlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                                <compPlanTable v-if="this.input.id" :assetPlanId="this.input.id" :assetId="this.input.asset"></compPlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
        </v-form>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import compPlanTable from '@/components/table/component-plan-table'
import paramPlanTable from '@/components/table/parameter-plan-table'
	export default {
		data: () => ({
            loading: true,
            input: {
                id: '',
                asset: '',
                checker: '',
                verifier: '',
                sort_order: 1,
            },
            users: [],
            assetListFull: [],
            checkplanList:[],
        }),
        components: {
            compPlanTable,
            paramPlanTable
        },
        props: {
            id: Number,
            checkplanId: Number,
        },
        computed: {
            userVerifier: function() {
                if (this.users.length > 0) {
                    return (this.users.filter(users => users.id !== this.input.checker));
                }
            },
        },
		methods: {
            form() {
                if (this.id) {
                    this.getAssetPlan().then((res) => {
                        this.input.id = res.id;
                        this.input.asset = res.asset
                        this.input.checker = res.checker;
                        this.input.verifier = res.verifier;
                        this.input.sort_order = res.sort_order;
                        this.input.checkplan = res.checkplan;
                    });
                } else {
                    this.input.checkplan = this.checkplanId;
                }
            },
			async getAssetPlan() {
                try {
                    this.loading = true
                    const res = await this.$api.assetPlan.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'checkplanForm', params: { id: this.input.checkplan }})
            },
			save() {
                this.saveAssetPlan().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.input.id = res.data.id;
                            //this.form();
                            this.$router.push({ name: 'checkplanForm', params: { id: this.input.checkplan }})
                        })
                    }
                });
			},
			async saveAssetPlan() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.assetPlan.save( this.input );
                    } else {
                        res = await this.$api.assetPlan.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getAdditionalData() {
			    this.getCheckplans().then(res => this.checkplanList = res);
			    this.getAssets().then(res => this.assetListFull = res);
			    this.getUsers().then((res) => {
                    this.users = res
                    this.users.unshift({id: null, username: 'None'})
                });
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getCheckplans() {
                try {
                    const res = await this.$api.checkplan.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(asset_id) {
                if ( this.assetListFull.length !== 0 && asset_id ) {
                    return this.assetListFull.find(asset => asset.id === asset_id).name;
                }
            }, 
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.success = true;
		},
	}
</script>