<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <!-- <div class="mb-3 text-right">
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div> -->
            <v-data-table
            v-if="componentList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="componentList"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left" v-if="row.item.id">{{ getAssetName(row.item.asset) }}</td>
                <td class="text-left" v-if="row.item.id">{{ getBaseCompName(row.item.base_component)}}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id',  },
            { text: 'Name', value: 'name' },
            { text: 'Asset', value: 'asset'},
            { text: 'Base Component', value: 'base_component'},
            { text: ''}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			componentList: [],
			loading: true,
            assetList: [],
            baseCompList: [],
        }),
		methods: {
            list() {
			    this.getComps(1).then((res) => {
                    this.componentList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getComps(page) {
                try {
                    this.loading = true
                    const res = await this.$api.component.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'componentForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.component.delete(id).then(res => this.list() );
                    }
                })
            },
            // Additional Infos
            getAdditionalData() {
			    this.getBaseComps().then(res => this.baseCompList = res);
			    this.getAssets().then(res => this.assetList = res);
            },
			async getBaseComps() {
                try {
                    const res = await this.$api.baseComp.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(id) {
                if ( this.assetList.length !== 0 && id ) {
                    return this.assetList.find(asset => asset.id === id).name;
                }
            },
            getBaseCompName(id) {
                if ( this.baseCompList.length !== 0 && id ) {
                    return this.baseCompList.find(baseComp => baseComp.id === id).name;
                }
            },
		},
		mounted () {
            this.list();
            this.getAdditionalData();
            this.success = true;
		},
        watch: {
            pagination: {
                handler(val){
                    this.getComps(this.pagination.current).then((res) => {
                        this.componentList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>