<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon class="mr-1">mdi-keyboard-return</v-icon>
                    Back to List
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto text-right">
                <v-form lazy-validation
                ref="form"
                v-model="valid">
                    <v-row>
                        <v-col cols="10" sm="10" md="10">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" md="2">
                            <v-switch v-model="input.is_active" label="Status" readonly></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                                <wysiwyg
                                :content.sync="input.description"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab v-if="this.input.id">Generic Set</v-tab>
                    <v-tab-item>
                        <GenericTable class="p-2" v-if="this.input.id" :systemId='this.input.id'></GenericTable>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
// import mastParamTable from '@/components/asset/master-parameter-table.vue';
import wysiwyg from '@/components/ui/wysiwyg.vue';
import GenericTable from '@/components/asset/generic-table.vue';
	export default {
		  components: {
              GenericTable,
                wysiwyg
		  },
          props: ['id'],
		data: () => ({
            success: false,
            loading: false,
            saving: false,
            input:{
                id: '',
                name: '',
                description: '',
            },
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
		}),
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getSystem().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.is_active = res.is_active;
                        this.input.description = res.description;
                    });
                }
            },
			async getSystem() {
                try {
                    this.loading = true
                    const res = await this.$api.assetSystem.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveSystem().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'assetSystemList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveSystem() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.assetSystem.save( this.input );
                    } else {
                        res = await this.$api.assetSystem.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'assetSystemList' })
            },
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>