<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderAssetParameter,i) in indexedWorkOrderDetail" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4" md="4">
                        <v-autocomplete
                        :items="assetParamList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Asset Parameter" 
                        v-model="workOrderAssetParameter.asset_parameter"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <label>Status Mode</label><br/>
                        {{workOrderAssetParameter.status_mode}}
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <label>Lower Limit</label><br/>
                        {{workOrderAssetParameter.lower_limit}}
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <label>Upper Limit</label><br/>
                        {{workOrderAssetParameter.upper_limit}}
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <label>Number Reading</label><br/>
                        {{workOrderAssetParameter.number_reading}}
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <label>Status</label><br/>
                        {{workOrderAssetParameter.status}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <label>Images <span v-if="workOrderAssetParameter.require_image">( required )</span></label><br/>
                        <v-row>
                            <v-col class="md-20">
                                <v-img class="ba-1" contain v-if="workOrderAssetParameter.image_1" :src="workOrderAssetParameter.image_1" width="180" height="180"></v-img>
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-20">
                                <v-img class="ba-1" contain v-if="workOrderAssetParameter.image_2" :src="workOrderAssetParameter.image_2" width="180" height="180"></v-img>
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-20">
                                <v-img class="ba-1" contain v-if="workOrderAssetParameter.image_3" :src="workOrderAssetParameter.image_3" width="180" height="180"></v-img>
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-20">
                                <v-img class="ba-1" contain v-if="workOrderAssetParameter.image_4" :src="workOrderAssetParameter.image_4" width="180" height="180"></v-img>
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col class="md-20">
                                <v-img class="ba-1" contain v-if="workOrderAssetParameter.image_5" :src="workOrderAssetParameter.image_5" width="180" height="180"></v-img>
                                <v-img class="ba-1" contain v-if="!workOrderAssetParameter.image_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row  v-if="!disableEdit">
                    <v-col cols="12" sm="4" md="3" class="text-right">
                        <v-btn-toggle>
                            <v-btn small fab icon color="grey" @click="save(workOrderAssetParameter.id, workOrderAssetParameter.asset_parameter)">
                            <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab icon color="grey" @click="remove(workOrderAssetParameter.index, workOrderAssetParameter.id)">
                            <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
        </v-card>
        <div v-if="!disableEdit">
            <v-btn class="mr-4 mt-2" @click="newWorkOrderDetail()">Add Parameter</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            disableEdit: {
                type: Boolean
            },
            workOrderId: {
                type: Number
            },
            workOrderAssetId: {
                type: Number
            },

        },
		data() {
            return {
                id: [],
                loading: true,
                workOrderAssetParametersbyChecklist: [],
                assetParamList: [],
                additionalDataLoaded: false,
            }
        },
        computed: {
            indexedWorkOrderDetail () {
                return this.workOrderAssetParametersbyChecklist.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderDetailsbyChecklist().then((res) => {
                        this.workOrderAssetParametersbyChecklist = res;
                    });
                }
            },
			async getWorkOrderDetailsbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderAssetParameter.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderDetail() {
                // this.$router.push({ name: 'workOrderAssetParameterForm', params: {workOrderId: this.workOrderId } })
                this.workOrderAssetParametersbyChecklist.push({id: '', asset_parameter: '', order: this.workOrderId });
            },
            save(id, asset_parameter ) {
                this.saveWorkOrderAssetParameter(id, asset_parameter).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderAssetParameter(id, asset_parameter) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.workOrderAssetParameter.save( {id: id, asset_parameter: asset_parameter, order: this.workOrderId});
                    } else {
                        res = await this.$api.workOrderAssetParameter.new({ asset_parameter: asset_parameter, order: this.workOrderId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderAssetParameter.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderAssetParametersbyChecklist, index)
                }
            },
            getAdditionalData() {
                this.getAssetParam().then(res => this.assetParamList = res);
            },
            async getAssetParam() {
                try {
                    const res = await this.$api.assetAssetParameter.search({asset: this.workOrderAssetId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>