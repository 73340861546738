<template>
    <div>
        <v-card outlined class="mb-4" >
            <v-card-text>
                <v-row v-for="(workDetailCostCenter,i) in indexedWorkDetailCostCenter" :key="i">
                    <v-col cols="5" sm="5" md="8">
                        <v-select
                        :items="workCostCenterList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Detail" 
                        v-model="workDetailCostCenter.costcenter"
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="3" sm="3" md="2">
                        <v-text-field
                            label="Cost" 
                            v-model="workDetailCostCenter.unit_cost"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" class="text-right">
                        <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workDetailCostCenter.index, workDetailCostCenter.id)">mdi-delete</v-icon></v-btn>
                        <v-btn color="primary" @click="save(workDetailCostCenter.id, workDetailCostCenter.costcenter, workDetailCostCenter.unit_cost)"><v-icon>mdi-content-save</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div>
            <v-btn class="mr-4 mt-2" @click="newWorkDetailCostCenter()">Add Cost</v-btn>
        </div>
    </div>
    
</template>
<script>
	export default {
        props: {
            workDetailId: {
                type: Number
            },
        },
		data() {
            return {
                id: [],
                loading: true,
                workDetailCostCentersbyDetail: [],
                users:[],
                workCostCenterList: [],
            }
        },
        computed: {
            indexedWorkDetailCostCenter () {
                return this.workDetailCostCentersbyDetail.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
                if (this.workDetailId) {
                    this.getWorkDetailCostCentersbyDetail().then((res) => {
                        this.workDetailCostCentersbyDetail = res;
                    });
                }
                this.getAdditionalData();
            },
			async getWorkDetailCostCentersbyDetail() {
                try {
                    this.loading = true
                    const res = await this.$api.invoiceDetailCostCenter.search({ detail: this.workDetailId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkDetailCostCenter() {
                // this.$router.push({ name: 'workDetailCostCenterForm', params: {workDetailId: this.workDetailId } })
                this.workDetailCostCentersbyDetail.push({id: '' });
            },
            save(id, costcenter, unit_cost) {
                this.saveWorkDetailCostCenter(id, costcenter, unit_cost).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkDetailCostCenter(id, costcenter, unit_cost) {
                try {
                    let res;
                    console.log(id)
                    console.log(costcenter)
                    this.saving = true
                    if (id) {
                        res = await this.$api.invoiceDetailCostCenter.save( {id: id, costcenter: costcenter, unit_cost:unit_cost, detail: this.workDetailId });
                    } else {
                        res = await this.$api.invoiceDetailCostCenter.new({costcenter: costcenter, unit_cost:unit_cost, detail: this.workDetailId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.invoiceDetailCostCenter.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workDetailCostCentersbyDetail, index)
                }
            },
            getAdditionalData() {
			    this.getCostCenterList().then((res) => {this.workCostCenterList = res;});
            },
			async getCostCenterList() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>