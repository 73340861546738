<template>
    <div>
        <v-card outlined class="mb-4" v-for="(workOrderTask,i) in orderBy(workOrderTasksbyChecklist, 'sort_order')" :key="i">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="8" md="10">
                        <v-text-field
                            readonly
                            label="Instruction Name" 
                            v-model="workOrderTask.name"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-btn-toggle v-model="workOrderTask.status">
                            <v-btn value="NOT_DONE">
                                NOT DONE
                            </v-btn>
                            <v-btn value="DONE">
                                DONE
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="12" md="12">
                        <v-textarea
                            outlined
                            :label="workOrderTask.require_note ? 'Note ( required )': 'Note '" 
                            v-model="workOrderTask.note"
                            hide-details
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <label>Images <span v-if="workOrderTask.require_image">( required )</span></label><br/>
                        <v-row>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_1 && !workOrderTask.newimage_1" :src="workOrderTask.image_1" :src_large="workOrderTask.image_1" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_1 && !workOrderTask.newimage_1" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_1" :src="workOrderTask.newimage_1Preview" :src_large="workOrderTask.newimage_1Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_1Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 1"
                                    v-model="workOrderTask.newimage_1"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_2 && !workOrderTask.newimage_2" :src="workOrderTask.image_2" :src_large="workOrderTask.image_2" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_2 && !workOrderTask.newimage_2" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_2" :src="workOrderTask.newimage_2Preview" :src_large="workOrderTask.newimage_2Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_2Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 2"
                                    v-model="workOrderTask.newimage_2"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_3 && !workOrderTask.newimage_3" :src="workOrderTask.image_3" :src_large="workOrderTask.image_3" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_3 && !workOrderTask.newimage_3" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_3" :src="workOrderTask.newimage_3Preview" :src_large="workOrderTask.newimage_3Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_3Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 3"
                                    v-model="workOrderTask.newimage_3"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_4 && !workOrderTask.newimage_4" :src="workOrderTask.image_4" :src_large="workOrderTask.image_4" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_4 && !workOrderTask.newimage_4" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_4" :src="workOrderTask.newimage_4Preview" :src_large="workOrderTask.newimage_4Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_4Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 4"
                                    v-model="workOrderTask.newimage_4"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_5 && !workOrderTask.newimage_5" :src="workOrderTask.image_5" :src_large="workOrderTask.image_5" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_5 && !workOrderTask.newimage_5" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_5" :src="workOrderTask.newimage_5Preview" :src_large="workOrderTask.newimage_5Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_5Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 5"
                                    v-model="workOrderTask.newimage_5"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.image_6 && !workOrderTask.newimage_6" :src="workOrderTask.image_6" :src_large="workOrderTask.image_6" />
                                <v-img class="ba-1" contain v-if="!workOrderTask.image_6 && !workOrderTask.newimage_6" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                <enlargeable-image class="img-wrapper" v-if="workOrderTask.newimage_6" :src="workOrderTask.newimage_6Preview" :src_large="workOrderTask.newimage_6Preview" />
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    v-on:change="previewImage($event, workOrderTask.id, 'newimage_6Preview')"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Image 6"
                                    v-model="workOrderTask.newimage_6"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <!-- <v-btn small text class="mr-2"><v-icon color="grey" dark @click="remove(workOrderTask.index, workOrderTask.id)">mdi-delete</v-icon></v-btn> -->
                        <v-btn color="primary" @click="save(workOrderTask.id, workOrderTask.note, workOrderTask.newimage_1, workOrderTask.newimage_2, workOrderTask.newimage_3, workOrderTask.newimage_4, workOrderTask.newimage_5,  workOrderTask.newimage_6, workOrderTask.status )"><v-icon>mdi-content-save</v-icon> Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- <div>
            <v-btn class="mr-4 mt-2" @click="newWorkOrderTask()">Add Task</v-btn>
        </div> -->
    </div>
    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
	export default {
        props: {
            workOrderId: {
                type: Number
            },
        },
        components: {
        EnlargeableImage
        },
        mixins: [Vue2Filters.mixin],
		data() {
            return {
                id: [],
                loading: true,
                workOrderTasksbyChecklist: [],
                users:[],
                assetListFull: [],
                masterParamList: [],
                baseParamList: [],
                additionalDataLoaded: false,
                iconRules: [
                    v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
                ],
            }
        },
        computed: {
        },
        methods: {
            list() {
                if (this.workOrderId) {
                    this.getWorkOrderTasksbyChecklist().then((res) => {
                        this.workOrderTasksbyChecklist = res;
                        res.forEach((item) => { 
                            Vue.set(item,'newimage_1', null);
                            Vue.set(item,'newimage_2', null);
                            Vue.set(item,'newimage_3', null);
                            Vue.set(item,'newimage_4', null);
                            Vue.set(item,'newimage_5', null);
                            Vue.set(item,'newimage_6', null);
                            Vue.set(item,'newimage_1Preview', null);
                            Vue.set(item,'newimage_2Preview', null);
                            Vue.set(item,'newimage_3Preview', null);
                            Vue.set(item,'newimage_4Preview', null);
                            Vue.set(item,'newimage_5Preview', null);
                            Vue.set(item,'newimage_6Preview', null);
                        })
                    });
                }
            },
			async getWorkOrderTasksbyChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.workOrderTask.search({ order: this.workOrderId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newWorkOrderTask() {
                // this.$router.push({ name: 'workOrderTaskForm', params: {workOrderId: this.workOrderId } })
                this.workOrderTasksbyChecklist.push({id: '', name: '', order: this.workOrderId, sort_order: 1, require_status: false, require_reading: false, require_image: false });
            },
            save(id, note, image_1, image_2, image_3, image_4, image_5, image_6, status ) {
                this.saveWorkOrderTask(id, note, image_1, image_2, image_3, image_4, image_5, image_6, status ).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveWorkOrderTask(id, note, image_1, image_2, image_3, image_4, image_5, image_6, status ) {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();
                    formData.append("id", id);
                    formData.append("note", note);
                    formData.append("status", status);
                    if (image_1) {
                        formData.append("image_1", image_1 );
                    }
                    if (image_2) {
                        formData.append("image_2", image_2 );
                    }
                    if (image_3) {
                        formData.append("image_3", image_3 );
                    }
                    if (image_4) {
                        formData.append("image_4", image_4 );
                    }
                    if (image_5) {
                        formData.append("image_5", image_5 );
                    }
                    if (image_6) {
                        formData.append("image_6", image_6 );
                    }
                    if (id) {
                        res = await this.$api.workOrderTask.saveForm( id, formData );
                    } else {
                        res = await this.$api.workOrderTask.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.workOrderTask.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.workOrderTasksbyChecklist, index)
                }
            },
            previewImage(e, instructionId, previewTarget) {
                if (e) {
                    this.workOrderTasksbyChecklist.find(instruction => instruction.id == instructionId)[previewTarget] = URL.createObjectURL(e);
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>